<div class="container-fluid">
  <mat-card appearance="outlined" class="content-container">
    <app-page-title></app-page-title>

    <mat-card-content>
      <app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
      <div *ngIf="isRateLimitReached" class="scenario-rate-limit-reached">
        Kingfisher's API did not respond. Check Backend.
      </div>

      <div class="kf-button-group">
        <button (click)="getProgressList()" aria-label="Refresh Page" color="primary" id="scenarioStatusRefreshId"
                mat-raised-button matTooltip="Refresh Page">
          <mat-icon aria-hidden="false">refresh</mat-icon>
          <span>Refresh Page</span>
        </button>
      </div>

      <div class="scenario-list-container">
        <table class="kf-table" [dataSource]="data" aria-describedby="tableDescription" mat-table multiTemplateDataRows>

          <colgroup>
            <col span="1" style="width:5%" />
            <col span="1" style="width:15%" />
            <col span="1" style="width:10%" />
            <col span="1" style="width:10%" />
            <col span="1" style="width:30%" />
            <col span="1" style="width:4%" />
            <col span="1" style="width:14%" />
            <col span="1" style="width:12%" />
          </colgroup>

          <ng-container matColumnDef="runId">
            <th *matHeaderCellDef mat-header-cell scope="col">Run</th>
            <td *matCellDef="let element" mat-cell>
              {{element.runId}}
            </td>
          </ng-container>

          <ng-container matColumnDef="scenarioId">
            <th *matHeaderCellDef mat-header-cell scope="col">Scenario</th>
            <td *matCellDef="let element" mat-cell>
              <span matTooltip="ID: {{element.scenarioId}}">{{element.scenarioId|scenarioDetails:'name'}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="area">
            <th *matHeaderCellDef mat-header-cell scope="col">Area(s)</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.areaIds | areaName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell scope="col">Status</th>
            <td *matCellDef="let element" mat-cell>
              {{element.status}}
            </td>
          </ng-container>

          <ng-container matColumnDef="message">
            <th *matHeaderCellDef mat-header-cell scope="col">Message</th>
            <td *matCellDef="let element" mat-cell>
              <ng-container *ngIf="element.restartedRunId && element.restartedRunId !== -1 ; else hideContent">
                {{ 'restartedRunId: ' + element.restartedRunId + ' ' + element.message }}
              </ng-container>
              <ng-template #hideContent>
                {{ element.message }}
              </ng-template>
            </td>
          </ng-container>


          <ng-container matColumnDef="priority">
            <th *matHeaderCellDef mat-header-cell scope="col">Prio</th>
            <td *matCellDef="let element" mat-cell>
              <mat-icon *ngIf="!!element.priority">done</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="statusUpdateTimeStamp">
            <th *matHeaderCellDef mat-header-cell scope="col">Last Update</th>
            <td *matCellDef="let element" mat-cell>
              {{element.statusUpdateTimeStamp | date: 'dd/MM/yyyy HH:mm:ss z'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
            <td *matCellDef="let element" mat-cell>
              <div class="kf-button-group">
                <button
                  (click)="openScenarioProgressDetailsDialog(element.runId)"
                  aria-label="Show Details"
                  color="primary"
                  mat-mini-fab
                  matTooltip="Show Details"
                >
                  <mat-icon aria-hidden="false">read_more</mat-icon>
                </button>
                <a *ngIf="element.runId && authService.hasPrivileges(['VIEW_RESULTS_BY_RUNID'])"
                   [queryParams]="{view: 'scenario-run', runId: element.runId}"
                   [routerLink]="['/test-results']"
                   aria-label="Show Scenario Run"
                   color="primary"
                   mat-mini-fab
                   matTooltip="Show Scenario Run"
                >
                  <mat-icon aria-hidden="false">content_paste</mat-icon>
                </a>
                <button
                  (click)="reRunScenario(element)"
                  aria-label="Re-Run Scenario"
                  color="primary"
                  mat-mini-fab
                  matTooltip="Re-Run Scenario"
                >
                  <mat-icon aria-hidden="false">replay</mat-icon>
                </button>
                <a
                  (click)="confirmScenarioRunCancel(element)"
                  *ngIf="!['SCENARIO_EXECUTED', 'SCENARIO_FAILURE', 'SCENARIO_CANCELLED', 'EXECUTED_WITH_PROBLEMS'].includes(element.status)"
                  aria-label="Cancel Scenario Run"
                  color="warn"
                  mat-mini-fab
                  matTooltip="Cancel Scenario Run"
                >
                  <mat-icon aria-hidden="false">stop</mat-icon>
                </a>

              </div>
            </td>
          </ng-container>

          <!--Table Header start-->
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            *matRowDef="let element; columns: displayedColumns;"
            [class.element-row-priority-run]="!!element.priority"
            class="element-row"
            mat-row
          ></tr>
          <!--Table Header end-->
        </table>

        <!--pagination start-->
        <app-paginator (page)="handlePageEvent($event)" [length]="length" [pageIndex]="pageIndex"
                       [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [showFirstLastButtons]=true
                       [showJumpTo]=true></app-paginator>
        <!--pagination end-->

      </div>
    </mat-card-content>
  </mat-card>
</div>
