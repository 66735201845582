import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocomplete as MatAutocomplete
} from '@angular/material/legacy-autocomplete';
import { ScenarioService } from '@modules/test-execution/service/scenario.service';
import { ScenarioApiEntity } from '@shared/model/productserver';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface ScenariosMap {
  [key: string]: ScenarioApiEntity;
}

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'app-scenario-selection',
  templateUrl: './scenario-selection.component.html',
  styleUrls: ['./scenario-selection.component.scss']
})
export class ScenarioSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('scenarioInput') scenarioInput: ElementRef<HTMLInputElement>;
  @ViewChild('scenarioAutocomplete') matAutocomplete: MatAutocomplete;

  @Input() scenarioIds: number[];

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public scenarioCtrl = new FormControl();
  public filteredScenarios$: Observable<ScenarioApiEntity[]>;
  public scenarios: Array<number> = [];
  public allScenarios: ScenariosMap = {};
  public form: FormGroup;

  private subscription: Subscription = new Subscription();

  constructor(
    private scenarioService: ScenarioService,
    private fb: FormBuilder
  ) {}

  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  get scenariosSelected(): Array<ScenarioApiEntity> {
    const result = new Array<ScenarioApiEntity>();
    const scenarioIds = this.form.get('scenarioIds').value || [];
    for (const scenarioId of scenarioIds) {
      const scenarioKey = `${scenarioId}`;
      if (this.allScenarios.hasOwnProperty(scenarioKey)) {
        result.push(this.allScenarios[scenarioKey]);
      }
    }
    return result;
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.getScenarioList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createFormGroup(): void {
    this.form = this.fb.group({
      scenarioIds: [
        this.scenarioIds,
        [Validators.required, Validators.minLength(1)]
      ]
    });
  }

  remove(scenario: ScenarioApiEntity): void {
    const scenarioIds = this.form.get('scenarioIds').value || [];
    const index = scenarioIds.indexOf(scenario.id);
    if (index >= 0) {
      scenarioIds.splice(index, 1);
      this.form.get('scenarioIds').setValue(scenarioIds);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const scenario: ScenarioApiEntity = event.option.value;
    const scenarioIds = this.form.get('scenarioIds').value || [];
    this.form.get('scenarioIds').setValue([...scenarioIds, scenario.id]);
    this.scenarioInput.nativeElement.value = '';
    this.scenarioInput.nativeElement.blur();
    this.scenarioCtrl.setValue(null);
  }

  filterScenarios(value: any) {
    const filterValue =
      value === null || value instanceof Object
        ? ''
        : value.trim().toLowerCase();
    const scenarioIds = this.form.get('scenarioIds').value || [];
    return Object.values(this.allScenarios)
      .filter((scenario) => !scenarioIds.includes(scenario.id))
      .filter((scenario) =>
        scenario.name.toLocaleLowerCase().includes(filterValue)
      );
  }

  getScenarioList(): void {
    this.subscription.add(
      this.scenarioService.getScenarioList().subscribe(
        (response) => {
          response.data.forEach(
            (scenario) => (this.allScenarios[`${scenario.id}`] = scenario)
          );
          this.filteredScenarios$ = this.scenarioCtrl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map((value: string | null) => this.filterScenarios(value))
          );
        },
        (err) => {
          console.error(err);
          return observableOf([]);
        }
      )
    );
  }
}
