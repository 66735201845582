import { Pipe, PipeTransform } from '@angular/core';
import { TestCasesService } from '../service/test-cases.service';

@Pipe({
  name: 'testCaseDetails',
  pure: false
})
export class TestCaseDetailsPipe implements PipeTransform {
  testCaseDetails: string;

  constructor(private testCasesService: TestCasesService) {}

  transform(value: string, property): string {
    this.testCaseDetails = '';
    this.testCasesService.getTestCaseById(value).subscribe((testCase) => {
      this.testCaseDetails = testCase?.[property];
    });
    return this.testCaseDetails;
  }
}
