import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LoginComponent } from './page/login/login.component';

import { SecurityRoutingModule } from './security.routing';

@NgModule({
  declarations: [LoginComponent],
  imports: [SharedModule, SecurityRoutingModule]
})
export class SecurityModule {}
