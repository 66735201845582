import { Bounds, ResultType } from '@shared/model/datastore';
import {RunStatus} from "@shared/enum/RunStatus";

export interface ScenarioApiEntity {
  id: number;
  name: string;
  description?: string;
  testCases: string[];
}

export interface AreasApiEntity {
  areaId: number;
  name: string;
  description?: string;
  tileIds: number[];
  updated: string;
  bounds: string;
  tileCount: number;
}

export interface MapViewerAreasApiEntity {
  areaId: number;
  name: string;
  description?: string;
  tileIds: string[];
  bounds: Bounds;
  updated: string;
}

export interface AreasListApiEntity {
  areaId: number;
  name: string;
}

export enum Catalog {
  DEFAULT_HNATIVE = 'here-hdmap-ext-weu-1',
  DEFAULT_NDS = 'here-hdlm-nds-weu-3',
  OLP_HNATIVE = 'hrn:here:data::olp-here-had:here-hdlm-protobuf-weu-2',
  OLP_NDS = 'hrn:here:data::olp-here-had:here-hdlm-nds-weu-3',
  OLP_HNATIVE_WALL_E = 'hrn:here:data::olp-here:here-cit-onemap-hdlm-native-global-1',
  OLP_NDS_WALL_E = 'hrn:here:data::olp-here:here-cit-onemap-hdlm-nds-global-1',
  OLP_HNATIVE_STG_ADPC = 'hrn:here:data::olp-here:here-stg-adpc-hdlm-native-weu-1',
  OLP_NDS_STG_ADPC = 'hrn:here:data::olp-here:here-stg-adpc-hdlm-nds-weu-1'
}

export interface CatalogApiEntity {
  name: string;
  mapDataType: string;
  latestVersion: number;
  publishedAt: string;
}

export interface CatalogVersionApiEntity {
  version: number;
  publishedAt: string;
}

export interface CatalogInfoApiEntity {
  mapDataType: string;
  name: string;
  version?: number;
  deltaName?: string;
  deltaVersion?: number;
}

export class CatalogInfoEntity {
  constructor(
    public name: string,
    public version: number,
    public deltaName: string,
    public deltaVersion: number
  ) {}
}

export interface DetailedScenarioProgressApiEntity
  extends ScenarioProgressApiEntity {
  testcaseProgress: TestCaseProgressApiEntity;
}

export interface ScenarioProgressApiEntity {
  runId: number;
  scenarioId: number;
  tileIds?: number[];
  areaIds?: number[];
  restartedRunId?: number;
  routeIds?: number[];
  versionBackendKf: string;
  status: string;
  message: string;
  statusUpdateTimeStamp: string;
  catalogInfos?: CatalogInfoApiEntity[];
  priority: number;
}

export type ScenarioRequestObject = Omit<ScenarioApiEntity, 'id'>;
export type AreaRequestObject = Omit<AreasApiEntity, 'areaId'>;

export interface StartScenarioRequestObject {
  scenarioId: number;
  tileIds?: number[];
  areaIds?: number[];
  routeIds?: number[];
  mapCatalogInfos: CatalogInfoApiEntity[];
  priority: number;
}

export interface ScenarioRunParameters {
  tileIds: number[];
  areaIds: number[];
  routeIds: number[];
  mapCatalogInfos: CatalogInfoApiEntity[];
}

export interface TestCaseApiEntity {
  id: string;
  version: number;
  active: boolean;
  name: string;
  description: string;
  details: string;
  mapDataType: string;
  mainLayer: string;
  comparisonMapDataType: string;
  dependencies: string[];
  scenarios: number[];
  resultType: ResultType;
}

export interface TestCaseProgressApiEntity {
  numberTcPending?: number;
  numberTcStarted?: number;
  numberTcFailed?: number;
  numberTcPassed?: number;
  numberTcNotExecuted?: number;
  numberTcFailure?: number;
  numberTcScenarioFailure?: number;
  numberTcCancelled?: number;
}

export enum TriggerType {
  TIME = 'TIME',
  VERSION = 'VERSION',
  TIMECOMPLEX = 'TIMECOMPLEX',
  VERSIONCOMPLEX = 'VERSIONCOMPLEX',
  CHILD = 'CHILD'
}

export enum TestAreaType {
  AREA = 'area',
  ROUTE = 'route',
  TILE = 'tile',
  UNKNOWN = 'unknown'
}

export interface ComplexTriggerJobApiEntity {
  type: TriggerType;
  active: boolean;
  triggerJobId: number;
  name: string;
  observedCatalog: string;
  catalogVersionInterval: number;
  cronPattern: string;
  lastExecutionTime: string;
  triggerActions: Array<ComplexTriggerActionApiEntity>;
  nextTriggerTime: string;
  nextTriggerVersion: number;
  lastModified: string;
}

export interface ComplexTriggerActionApiEntity {
  scenarioIds: number[];
  areaIds: number[];
  routeIds: number[];
  tileIds: number[];
  priority: number;
  mapCatalogInfo: CatalogInfoEntity;
  parentId: number;
  parentIndex: number;
  triggerJobId: number;
}

export interface ComplexTriggerJob {
  type: TriggerType;
  active: boolean;
  triggerJobId: number;
  name: string;
  observedCatalog: string;
  catalogVersionInterval: number;
  cronPattern: string;
  triggerActions: Array<ComplexTriggerActionObject>;
  nextTriggerTime: string;
  nextTriggerVersion: number;
  lastModified: string;
}

export interface ComplexTriggerAction {
  scenarioIds: number[];
  testAreaType: TestAreaType;
  areaIds: number[];
  routeIds: number[];
  tileIds: number[];
  priority: number;
  mapCatalogInfo: CatalogInfoEntity;
  parentId: number;
  parentIndex: number;
  triggerJobId: number;
}

export interface ComplexTriggerJobRequest {
  triggerJobId: number;
  type: TriggerType;
  active: boolean;
  name: string;
  observedCatalog: string;
  catalogVersionInterval: number;
  cronPattern: string;
  triggerActions: Array<ComplexTriggerActionRequest>;
}

export interface ComplexTriggerActionRequest {
  scenarioIds: number[];
  areaIds: number[];
  routeIds: number[];
  tileIds: number[];
  priority: number;
  mapCatalogInfo: CatalogInfoEntity;
  parentId: number;
  parentIndex: number;
  triggerJobId: number;
}

export class ComplexTriggerJobObject implements ComplexTriggerJob {
  constructor(
    public triggerJobId: number = null,
    public active: boolean = true,
    public name: string = '',
    public type: TriggerType = TriggerType.VERSIONCOMPLEX,
    public observedCatalog: string = null,
    public catalogVersionInterval: number = null,
    public cronPattern: string = null,
    public triggerActions: Array<ComplexTriggerActionObject> = new Array<ComplexTriggerActionObject>(),
    public lastModified: string = null,
    public nextTriggerTime: string = null,
    public nextTriggerVersion: number = null,
    public lastExecutionTime: string = null
  ) {}

  static fromApi(
    apiEntity: ComplexTriggerJobApiEntity
  ): ComplexTriggerJobObject {
    const {
      triggerJobId,
      active,
      name,
      type,
      observedCatalog,
      catalogVersionInterval,
      cronPattern,
      lastModified,
      nextTriggerTime,
      nextTriggerVersion,
      lastExecutionTime
    } = apiEntity;

    const triggerActions =
      apiEntity.triggerActions?.map((triggerAction) =>
        ComplexTriggerActionObject.fromApi(triggerAction)
      ) || [];

    return new ComplexTriggerJobObject(
      triggerJobId,
      active,
      name,
      type,
      observedCatalog,
      catalogVersionInterval,
      cronPattern,
      triggerActions,
      lastModified,
      nextTriggerTime,
      nextTriggerVersion,
      lastExecutionTime
    );
  }
}

export class ComplexTriggerActionObject implements ComplexTriggerAction {
  public testAreaType: TestAreaType = TestAreaType.AREA;
  constructor(
    public scenarioIds: number[] = [],
    public areaIds: number[] = [],
    public routeIds: number[] = [],
    public tileIds: number[] = [],
    public priority: number = 0,
    public mapCatalogInfo: CatalogInfoEntity = null,
    public parentId: number = null,
    public parentIndex: number = null,
    public triggerJobId: number = null
  ) {
    this.testAreaType = ComplexTriggerActionObject.mapTestAreaType(this);
  }

  static mapTestAreaType(
    triggerAction: ComplexTriggerActionObject
  ): TestAreaType {
    const { areaIds = [], routeIds = [], tileIds = [] } = triggerAction;
    if (areaIds?.length > 0) {
      return TestAreaType.AREA;
    }
    if (routeIds?.length > 0) {
      return TestAreaType.ROUTE;
    }
    if (tileIds?.length > 0) {
      return TestAreaType.TILE;
    }
    return TestAreaType.AREA;
  }

  static fromApi(
    apiEntity: ComplexTriggerActionApiEntity
  ): ComplexTriggerActionObject {
    const {
      scenarioIds = [],
      areaIds = [],
      routeIds = [],
      tileIds = [],
      priority,
      mapCatalogInfo,
      parentId,
      parentIndex,
      triggerJobId
    } = apiEntity;

    return new ComplexTriggerActionObject(
      scenarioIds || [],
      areaIds || [],
      routeIds || [],
      tileIds || [],
      priority,
      mapCatalogInfo,
      parentId,
      parentIndex,
      triggerJobId
    );
  }
}

export class ComplexTriggerActionRequestObject
  implements ComplexTriggerActionRequest
{
  constructor(
    public scenarioIds: number[] = [],
    public areaIds: number[] = [],
    public routeIds: number[] = [],
    public tileIds: number[] = [],
    public priority: number = 0,
    public mapCatalogInfo: CatalogInfoEntity | null = null,
    public parentId: number | null = null,
    public parentIndex: number | null = null,
    public triggerJobId: number | null = null
  ) {}

  static fromForm(formValues: any): ComplexTriggerActionRequestObject {
    const {
      scenarioIds = [],
      areaIds = [],
      routeIds = [],
      tileIds = [],
      priority = 0,
      mapCatalogInfo = null,
      parentId = null,
      parentIndex = null,
      triggerJobId = null
    } = formValues;

    return new ComplexTriggerActionRequestObject(
      scenarioIds || [],
      areaIds || [],
      routeIds || [],
      tileIds || [],
      priority,
      mapCatalogInfo,
      parentId,
      parentIndex,
      triggerJobId
    );
  }
}

export class ComplexTriggerJobRequestObject
  implements ComplexTriggerJobRequest
{
  constructor(
    public triggerJobId: number = null,
    public active: boolean = true,
    public name: string = '',
    public type: TriggerType = TriggerType.VERSIONCOMPLEX,
    public observedCatalog: string = null,
    public catalogVersionInterval: number = null,
    public cronPattern: string = null,
    public triggerActions: Array<ComplexTriggerActionRequestObject> = new Array<ComplexTriggerActionRequestObject>()
  ) {}

  static fromForm(formValues: any): ComplexTriggerJobRequestObject {
    const { triggerJobId, active, name, type } = formValues;
    let { observedCatalog, catalogVersionInterval, cronPattern } = formValues;
    if (type == TriggerType.TIMECOMPLEX) {
      observedCatalog = null;
      catalogVersionInterval = null;
    } else {
      cronPattern = null;
    }

    const triggerActions =
      formValues.triggerActions?.map((triggerAction) =>
        ComplexTriggerActionRequestObject.fromForm(triggerAction)
      ) || [];

    return new ComplexTriggerJobRequestObject(
      triggerJobId,
      active,
      name,
      type,
      observedCatalog,
      catalogVersionInterval,
      cronPattern,
      triggerActions
    );
  }
}

export class StatusDurationApiEntity {
  constructor(
    public runId: number,
    public status: RunStatus,
    public absoluteDurationInMs: number,
    public shortestAbsoluteDurationInMs: number,
    public runIdForShortestDuration: number,
    public lastAbsoluteDurationInMs: number,
    public runIdForLastDuration: number
  ) {}
}

export class DetailedPerformanceProfileApiEntity  {
  constructor(
    public averageDurationForStatus: StatusDurationApiEntity[] = new Array<StatusDurationApiEntity>()
  ) {}
}
