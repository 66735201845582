<div class="kf-threshold-value-actions">
  <button mat-raised-button color="primary" (click)="editThreshold()" [disabled]="isEditable"><mat-icon>edit</mat-icon> Edit</button>
  <button mat-raised-button color="warn" (click)="deleteThreshold()"><mat-icon>delete</mat-icon> Delete</button>
</div>

<table class="kf-threshold-table" aria-label="Table of threshold values">
      <thead>
      <tr>
        <th scope="row"></th>
        <th scope="row" colspan="2">All Attribution Status</th>
        <th scope="row" colspan="2">Fully Attributed</th>
        <th scope="row" colspan="2">Partly Attributed</th>
        <th scope="row" colspan="2">Not Field Surveyed</th>
      </tr>
      </thead>
      <tbody *ngIf="isEditable">
      <tr>
        <th scope="col">ALL FRC</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateAllFrcGreen')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateAllFrcYellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateAllFrcGreen')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateAllFrcYellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateAllFrcGreen')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateAllFrcYellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateAllFrcGreen')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateAllFrcYellow')"></td>
      </tr>
      <tr>
        <th scope="col">FRC0</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc0Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc0Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc0Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc0Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc0Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc0Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc0Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc0Yellow')"></td>
      </tr>
      <tr>
        <th scope="col">FRC1</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc1Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc1Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc1Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc1Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc1Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc1Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc1Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc1Yellow')"></td>
      </tr>
      <tr>
        <th scope="col">FRC2</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc2Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc2Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc2Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc2Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc2Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc2Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc2Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc2Yellow')"></td>
      </tr>
      <tr>
        <th scope="col">FRC3</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc3Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc3Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc3Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc3Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc3Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc3Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc3Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc3Yellow')"></td>
      </tr>
      <tr>
        <th scope="col">FRC4</th>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc4Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdRateFrc4Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc4Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc4Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc4Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc4Yellow')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc4Green')"></td>
        <td><input type="number" step=".001" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc4Yellow')"></td>
      </tr>
      </tbody>
      <tbody *ngIf="!isEditable">
      <tr>
        <th scope="col">ALL FRC</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateAllFrcGreen').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateAllFrcYellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateAllFrcGreen').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateAllFrcYellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateAllFrcGreen').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateAllFrcYellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateAllFrcGreen').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateAllFrcYellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      <tr>
        <th scope="col">FRC0</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc0Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc0Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc0Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc0Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc0Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc0Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc0Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc0Yellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      <tr>
        <th scope="col">FRC1</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc1Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc1Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc1Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc1Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc1Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc1Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc1Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc1Yellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      <tr>
        <th scope="col">FRC2</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc2Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc2Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc2Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc2Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc2Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc2Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc2Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc2Yellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      <tr>
        <th scope="col">FRC3</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc3Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc3Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc3Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc3Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc3Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc3Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc3Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc3Yellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      <tr>
        <th scope="col">FRC4</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc4Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdRateFrc4Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc4Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdRateFrc4Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc4Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdRateFrc4Yellow').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc4Green').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdRateFrc4Yellow').value|number:"1.0-3":"de"}}</td>
      </tr>
      </tbody>
</table>
