import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';

import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthGuard } from '@shared/service/auth-guard.service';
import { AuthService } from '@shared/service/auth.service';
import { TestCasesService } from '@shared/service/test-cases.service';
import { CookieService } from 'ngx-cookie-service';
import { MainToolbarComponent } from './layout/component/main-toolbar/main-toolbar.component';
import { SidebarComponent } from './layout/component/sidebar/sidebar.component';
import { SidebarItemComponent } from './layout/component/sidebar-item/sidebar-item.component';
import { MainToolbarItemComponent } from './layout/component/main-toolbar-item/main-toolbar-item.component';
import { MainToolbarUserMenuComponent } from './layout/component/main-toolbar-user-menu/main-toolbar-user-menu.component';
import { initializeKeycloak } from '@app/utility/app.init';
import { CatalogService } from '@shared/service/catalog.service';
import { AreaCacheService } from '@shared/service/area-cache.service';
import { LayerService } from '@shared/service/layer.service';

registerLocaleData(localeDe);

// List of providers
@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    MainToolbarComponent,
    SidebarComponent,
    SidebarItemComponent,
    MainToolbarItemComponent,
    MainToolbarUserMenuComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    ScrollingModule
  ],
  exports: [SharedModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthService, CookieService]
    },
    AuthGuard,
    AuthService,
    CookieService,
    TestCasesService,
    CatalogService,
    AreaCacheService,
    LayerService,
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
