<div class="container-fluid">
  <mat-card appearance="outlined" class="scenario-container">
    <app-page-title></app-page-title>

    <mat-card-content>
      <app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>

      <div class="scenario-list-container">
        <table
          [dataSource]="data"
          aria-describedby="tableDescription"
          mat-table
          multiTemplateDataRows
        >
          <colgroup>
            <col span="1" style="width:2%" />
            <col span="1" style="width:15%" />
            <col span="1" style="width:8%" />
            <col span="1" style="width:25%" />
            <col span="1" style="width:10%" />
            <col span="1" style="width:20%" />
            <col span="1" style="width:20%" />
          </colgroup>

          <ng-container matColumnDef="localizationArea">
            <th *matHeaderCellDef mat-header-cell scope="col">Localization Test Area</th>
            <td *matCellDef="let element" mat-cell>
              {{element.localizationArea}}
            </td>
          </ng-container>

          <ng-container matColumnDef="localizationSoftwareVersion">
            <th *matHeaderCellDef mat-header-cell scope="col">Localization Software Version</th>
            <td *matCellDef="let element" mat-cell>
              {{element.localizationSoftwareVersion}}
            </td>
          </ng-container>

          <ng-container matColumnDef="mapCatalog">
            <th *matHeaderCellDef mat-header-cell scope="col">Map Catalog</th>
            <td *matCellDef="let element" mat-cell>
              {{element.mapCatalog}}
            </td>
          </ng-container>

          <ng-container matColumnDef="mapCatalogVersion">
            <th *matHeaderCellDef mat-header-cell scope="col">Map Catalog Version</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.mapCatalogVersion || '0' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="timestampExecution">
            <th *matHeaderCellDef mat-header-cell scope="col">Timestamp Execution</th>
            <td *matCellDef="let element" mat-cell>
              {{element.timestampExecution | date: 'dd/MM/yyyy HH:mm:ss z'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="timestampImport">
            <th *matHeaderCellDef mat-header-cell scope="col">Timestamp Import</th>
            <td *matCellDef="let element" mat-cell>
              {{element.timestampImport | date: 'dd/MM/yyyy HH:mm:ss z'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="expandDetails">
            <th *matHeaderCellDef mat-header-cell scope="col"></th>
            <td *matCellDef="let element" mat-cell>
              <button
                mat-icon-button
                (click)="element.isExpanded = !element.isExpanded"
                aria-label="Show Details" matTooltip="Show Details">
                <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
              <div
                [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
                class="details-table-wrapper">
                <app-localization-test-details [localizationTestApiEntity]="element"></app-localization-test-details>
              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            *matRowDef="let element; columns: displayedColumns;"
            [class.details-table-expanded]="element.isExpanded"
            class="element-row element-row-expandable"
            mat-row
          ></tr>
          <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
          <caption id="tableDescription">List of available test results</caption>
        </table>
        <!--Table end-->
        <!--pagination start-->
        <app-paginator (page)="handlePageEvent($event)" [length]="length" [pageIndex]="pageIndex"
                       [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                       [showFirstLastButtons]="true" [showJumpTo]="true"></app-paginator>
        <!--pagination end-->
      </div>
    </mat-card-content>
  </mat-card>
</div>
