import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AuthService } from '@shared/service/auth.service';

@Component({
  selector: 'app-testcase-results',
  templateUrl: './testcase-results.component.html',
  styleUrls: ['./testcase-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestcaseResultsComponent implements OnInit {
  selected = new FormControl(0);

  tabs = ['details', 'scenario-run'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.parseQueryString();
  }

  onTabChanged(): void {
    this.updateQueryString();
  }

  parseQueryString(): void {
    const { view } = this.route.snapshot.queryParams;
    if (view) {
      const selected = this.tabs.indexOf(view);
      this.selected.setValue(selected);
    } else {
      this.selected.setValue(0);
    }
  }

  updateQueryString(): void {
    const { value } = this.selected;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        view: this.tabs[value]
      },
      queryParamsHandling: '' // remove to replace all query params by provided
    });
  }
}
