import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  Inject
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  NgControl,
  Validators
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import { CatalogInfoEntity } from '@shared/model/productserver';

@Component({
  selector: 'app-catalog-info-form-control',
  templateUrl: './catalog-info-form-control.component.html',
  styleUrls: ['./catalog-info-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CatalogInfoFormControlComponent
    }
  ]
})
export class CatalogInfoFormControlComponent extends AbstractInputFormControlComponent<CatalogInfoEntity> {
  parts = this._formBuilder.group({
    name: this._formBuilder.control<string | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    version: this._formBuilder.control<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    deltaName: this._formBuilder.control<string | null>(null, {
      nonNullable: false,
      validators: []
    }),
    deltaVersion: this._formBuilder.control<number | null>(null, {
      nonNullable: false,
      validators: []
    })
  });

  readonly controlType: string = 'catalog-info-form';
  @HostBinding() id: string = `${
    this.controlType
  }-${CatalogInfoFormControlComponent.nextId++}`;

  get empty(): boolean {
    const {
      value: { name, version, deltaName, deltaVersion }
    } = this.parts;
    return !name && !version && !deltaName && !deltaVersion;
  }

  @Input()
  get value(): CatalogInfoEntity | null {
    if (this.parts.valid) {
      const {
        value: { name, version, deltaName, deltaVersion }
      } = this.parts;
      return new CatalogInfoEntity(name, version, deltaName, deltaVersion);
    }
    return null;
  }

  set value(value: CatalogInfoEntity) {
    const { name, version, deltaName, deltaVersion } =
      value ?? new CatalogInfoEntity(null, null, null, null);
    this.parts.setValue({
      name,
      version,
      deltaName,
      deltaVersion
    });
    this.stateChanges.next();
  }

  constructor(
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);

    this.parts.get('name').valueChanges.subscribe((catalogName) => {
      if (!catalogName) {
        this.parts.get('version').reset();
      }
    });
    this.parts.get('deltaName').valueChanges.subscribe((catalogName) => {
      if (catalogName) {
        this.parts.get('deltaVersion').setValidators([Validators.required]);
      } else {
        this.parts.get('deltaVersion').clearValidators();
        this.parts.get('deltaVersion').reset();
      }
      this.parts.get('deltaVersion').updateValueAndValidity();
    });
  }

  registerOnChange(fn: any): void {
    this.parts.valueChanges.subscribe((value) => {
      this.onChange = fn(this.value);
    });
  }

  getSelectedMainCatalog(): string {
    return this.parts?.controls?.name.value || null;
  }

  getSelectedDeltaCatalog(): string {
    return this.parts?.controls?.deltaName.value || null;
  }

  getFormControl(formControlName: string): AbstractControl {
    return this.parts.get(formControlName);
  }

  isRequiredField(formControlName: string) {
    const formControl = this.getFormControl(formControlName);
    if (!formControl.validator) {
      return false;
    }
    const validator = formControl.validator({} as AbstractControl);
    return validator && validator.required;
  }
}
