import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() color: ThemePalette;
  @Input() disabled: boolean;
  @Input() hidePageSize: boolean;
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() showFirstLastButtons: boolean;

  @Output() page: EventEmitter<PageEvent>;

  @Input() showJumpTo: boolean;

  constructor() {
    this.page = new EventEmitter<PageEvent>();
  }

  updatePage($event: PageEvent) {
    this.page.emit($event);
  }

  jumpToPage(event: MatSelectChange): void {
    this.pageIndex = event.value;
    this.page.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length
    });
  }

  getPagesArray(): number[] {
    if (!this.length) {
      return [];
    }
    if (!this.pageSize) {
      return [];
    }

    const totalPages = Math.ceil(this.length / this.pageSize);

    return Array(totalPages)
      .fill(0)
      .map((_x, i) => i);
  }
}
