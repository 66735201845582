import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import { CatalogService } from '@shared/service/catalog.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { MapViewerSelectResultVisualizationComponent } from '@modules/map-viewer/component/map-viewer-select-catalog/views/map-viewer-select-result-visualization/map-viewer-select-result-visualization.component';
import { FormControl } from '@angular/forms';
import { NotificationService } from '@shared/service/notification.service';
import { Observable, of as observableOf } from 'rxjs';
import { CatalogApiEntity } from '@shared/model/productserver';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-map-viewer-select-delta-visualization',
  templateUrl: './map-viewer-select-delta-visualization.component.html',
  styleUrls: ['./map-viewer-select-delta-visualization.component.scss']
})
export class MapViewerSelectDeltaVisualizationComponent
  extends MapViewerSelectResultVisualizationComponent
  implements OnInit, OnDestroy
{
  @ViewChild('deltaCatalogVersionInput') deltaCatalogVersionInput: ElementRef;
  @ViewChild('deltaComparisonCatalogVersionInput')
  deltaComparisonCatalogVersionInput: ElementRef;

  deltaCatalogVersionsFormControl = new FormControl();
  autoCompleteDeltaCatalogVersionList: Observable<number[]>;

  deltaComparisonCatalogVersionsFormControl = new FormControl();
  autoCompleteDeltaComparisonCatalogVersionList: Observable<number[]>;

  protected $deltaCatalogVersionList: number[];
  protected $deltaComparisonCatalogVersionList: number[];

  protected $deltaCatalog: CatalogApiEntity;
  protected $deltaCatalogVersion: number;

  protected $deltaComparisonCatalog: CatalogApiEntity;
  protected $deltaComparisonCatalogVersion: number;

  constructor(
    protected mapViewerUserSelectionService: MapViewerUserSelectionService,
    protected catalogService: CatalogService,
    protected datastoreService: DatastoreService,
    protected notificationService: NotificationService
  ) {
    super(
      mapViewerUserSelectionService,
      catalogService,
      datastoreService,
      notificationService
    );
    this.registerDeltaCatalogListener();
    this.registerDeltaComparisonCatalogListener();
  }

  get deltaCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.deltaCatalog;
  }

  get deltaComparisonCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.deltaComparisonCatalog;
  }

  get deltaCatalogList(): CatalogApiEntity[] {
    return (
      (this.$catalogs.has(this.mapViewerUserSelectionService.mapDataType) &&
        this.$catalogs.get(this.mapViewerUserSelectionService.mapDataType)) ||
      []
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDeltaCatalogVersionList();

    this.updateDeltaCatalogVersionsFormControl();
    this.updateDeltaComparisonCatalogVersionsFormControl();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  selectDeltaCatalog(catalogName: string) {
    if (catalogName === this.mapViewerUserSelectionService.deltaCatalog?.name) {
      return;
    }
    this.mapViewerUserSelectionService.deltaCatalog = null;
    this.mapViewerUserSelectionService.deltaCatalogVersion = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mapViewerUserSelectionService.deltaCatalog = catalog;
      });
  }

  selectDeltaCatalogVersion(value: number): void {
    this.mapViewerUserSelectionService.deltaCatalogVersion =
      parseInt(String(value).trim(), 10) || null;
    this.deltaCatalogVersionInput.nativeElement.blur();
  }

  selectDeltaComparisonCatalog(catalogName: string) {
    if (
      catalogName ===
      this.mapViewerUserSelectionService.deltaComparisonCatalog?.name
    ) {
      return;
    }
    this.mapViewerUserSelectionService.deltaComparisonCatalog = null;
    this.mapViewerUserSelectionService.deltaComparisonCatalogVersion = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mapViewerUserSelectionService.deltaComparisonCatalog = catalog;
      });
  }

  selectDeltaComparisonCatalogVersion(value: number): void {
    this.mapViewerUserSelectionService.deltaComparisonCatalogVersion =
      parseInt(String(value).trim(), 10) || null;
    this.deltaComparisonCatalogVersionInput.nativeElement.blur();
  }

  getDeltaCatalogVersionList(): void {
    this.$deltaCatalogVersionList = [];
    this.autoCompleteDeltaCatalogVersionList = new Observable<number[]>();
    if (this.mapViewerUserSelectionService.deltaCatalog?.name) {
      this.isLoadingResults = true;
      this.$subscription.add(
        this.datastoreService
          .getCatalogVersionList(
            this.mapViewerUserSelectionService.deltaCatalog?.name
          )
          .subscribe(
            (response) => {
              this.$deltaCatalogVersionList = response.data;
              this.$deltaCatalogVersionList.sort((a, b) => a - b);
              this.$deltaCatalogVersionList.reverse();
              if (this.$deltaCatalogVersionList.length) {
                this.autoCompleteDeltaCatalogVersionList =
                  this.deltaCatalogVersionsFormControl.valueChanges.pipe(
                    startWith(''),
                    map((value) =>
                      this.getAutoCompleteCatalogVersions(
                        this.$deltaCatalogVersionList,
                        value
                      )
                    )
                  );
              }
            },
            (err) => {
              this.showApiError(err);
              this.isLoadingResults = false;
              return observableOf([]);
            },
            () => {
              this.isLoadingResults = false;
            }
          )
      );
    }
  }

  getDeltaComparisonCatalogVersionList(): void {
    this.$deltaComparisonCatalogVersionList = [];
    if (this.mapViewerUserSelectionService.deltaComparisonCatalog?.name) {
      this.$subscription.add(
        this.datastoreService
          .getComparisonCatalogVersionList(
            this.mapViewerUserSelectionService.deltaComparisonCatalog.name
          )
          .subscribe(
            (response) => {
              this.$deltaComparisonCatalogVersionList = response.data;
              this.$deltaComparisonCatalogVersionList.sort((a, b) => a - b);
              this.$deltaComparisonCatalogVersionList.reverse();
              if (this.$deltaComparisonCatalogVersionList.length) {
                this.autoCompleteDeltaComparisonCatalogVersionList =
                  this.deltaComparisonCatalogVersionsFormControl.valueChanges.pipe(
                    startWith(''),
                    map((value) =>
                      this.getAutoCompleteCatalogVersions(
                        this.$deltaComparisonCatalogVersionList,
                        value
                      )
                    )
                  );
              }
            },
            (err) => {
              this.showApiError(err);
              return observableOf([]);
            }
          )
      );
    }
  }

  protected registerDeltaCatalogListener() {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaCatalog()
        .subscribe((catalog: CatalogApiEntity) => {
          this.$deltaCatalog = catalog;
          this.getDeltaCatalogVersionList();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaCatalogVersion()
        .subscribe((catalogVersion: number) => {
          this.$deltaCatalogVersion = catalogVersion;
          this.updateDeltaCatalogVersionsFormControl();
        })
    );
  }

  protected registerDeltaComparisonCatalogListener() {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaComparisonCatalog()
        .subscribe((catalog: CatalogApiEntity) => {
          this.$deltaComparisonCatalog = catalog;
          this.getDeltaComparisonCatalogVersionList();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaComparisonCatalogVersion()
        .subscribe((catalogVersion: number) => {
          this.$deltaComparisonCatalogVersion = catalogVersion;
          this.updateDeltaComparisonCatalogVersionsFormControl();
        })
    );
  }

  private updateDeltaCatalogVersionsFormControl(): void {
    const value = this.mapViewerUserSelectionService.deltaCatalogVersion;
    this.deltaCatalogVersionsFormControl.setValue(value);
  }

  private updateDeltaComparisonCatalogVersionsFormControl(): void {
    const value =
      this.mapViewerUserSelectionService.deltaComparisonCatalogVersion;
    this.deltaComparisonCatalogVersionsFormControl.setValue(value);
  }
}
