import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  EmitEvent,
  MapEvent,
  MapEventService,
  ShowLegendPayload
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerValueScale } from '@modules/map-viewer/service/map-viewer-value-scale';
import {
  FailVisualization,
  ResultOutcome,
  ResultType
} from '@shared/model/datastore';
import { Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';

@Component({
  selector: 'app-map-viewer-result-outcome-filter',
  templateUrl: './map-viewer-result-outcome-filter.component.html',
  styleUrls: ['./map-viewer-result-outcome-filter.component.scss']
})
export class MapViewerResultOutcomeFilterComponent
  implements OnInit, OnDestroy
{
  private $type = MapViewerVisualizationMode.RESULT;
  private $isVisible = false;

  private $subscription: Subscription = new Subscription();
  private $scale: MapViewerValueScale;
  private $failRateResultTypes: ResultType[] = [
    ResultType.FORMAT_CONTENT,
    ResultType.COMBINED_DETAILED_STATISTIC,
    ResultType.FORMAT_CONTENT_DETAILED,
    ResultType.MAP_APPROVAL
  ];

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get sliderMinValue(): number {
    const [min] = this.$scale.domainMinMax;
    return this.isFailCountVisualization() ? min : 0;
  }

  get sliderMaxValue(): number {
    const [, max] = this.$scale.domainMinMax;
    return this.isFailCountVisualization() ? max : 100;
  }

  get visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService?.resultType;
  }

  get resultOutcomes(): Map<ResultOutcome, boolean> {
    return this.mapViewerUserSelectionService.resultOutcomes;
  }

  get resultOutcomeOptions(): ResultOutcome[] {
    return Object.keys(ResultOutcome) as ResultOutcome[];
  }

  get isVisible() {
    return this.$isVisible;
  }

  get rangeMinValue(): number {
    return isFinite(this.mapViewerUserSelectionService.failRateMinValue)
      ? this.mapViewerUserSelectionService.failRateMinValue
      : 0;
  }

  get rangeMaxValue(): number {
    return isFinite(this.mapViewerUserSelectionService.failRateMaxValue)
      ? this.mapViewerUserSelectionService.failRateMaxValue
      : 100;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.SHOW_LEGEND,
        (payload: ShowLegendPayload) => {
          this.$isVisible = this.visualizationMode === this.$type;
          this.$scale = payload.scale;
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.HIDE_LEGEND, () => {
        this.$isVisible = false;
      })
    );
  }

  isResultOutcomeChecked(key: ResultOutcome) {
    return this.resultOutcomes.has(key) && this.resultOutcomes.get(key);
  }

  selectResultOutcomes($event: MatCheckboxChange) {
    const key = $event.source.value as ResultOutcome;
    const value = $event.checked || false;
    this.mapViewerUserSelectionService.setResultOutcome(key, value);
    this.emitSelectResultOutcomes();
  }

  emitSelectFailRateRange(): void {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SELECT_FAIL_RATE_RANGE, {})
    );
  }

  emitSelectResultOutcomes() {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SELECT_RESULT_OUTCOMES, {})
    );
  }

  isCompleted() {
    if (this.isFilterRateSliderAvailable()) {
      return (
        Number.isFinite(this.mapViewerUserSelectionService.failRateMinValue) &&
        Number.isFinite(this.mapViewerUserSelectionService.failRateMaxValue)
      );
    }
  }

  isFilterRateSliderAvailable() {
    return this.isFailRateResultType() && !this.isFailCountVisualization();
  }

  formatRangeLabel(value: number): string {
    return `${value}%`;
  }

  setRangeMinValue(value: number) {
    this.mapViewerUserSelectionService.failRateMinValue = value;
    this.emitSelectFailRateRange();
  }

  setRangeMaxValue(value: number) {
    this.mapViewerUserSelectionService.failRateMaxValue = value;
    this.emitSelectFailRateRange();
  }

  private isFailCountVisualization(): boolean {
    return (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_COUNT
    );
  }

  private isFailRateResultType(): boolean {
    return this.$failRateResultTypes.includes(this.resultType);
  }
}
