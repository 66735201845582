<ng-container *ngIf="areaId; else elseCreateHeader">
  <h3 mat-dialog-title>Edit Area (ID {{areaId}})</h3>
</ng-container>
<ng-template #elseCreateHeader>
  <h3 mat-dialog-title>Create Area</h3>
</ng-template>

<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>


<div mat-dialog-content [formGroup]="areaFormData">
  <div class="ialog-form">
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required id="areaNameId">
    </mat-form-field>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" id="areaDescriptionId">
    </mat-form-field>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Tile Ids</mat-label>
      <textarea
        matInput
        formControlName="tileIds"
        required
        cdkTextareaAutosize
        cdkAutosizeMinRows=4
        cdkAutosizeMaxRows=10
        id="areaTileId"
      ></textarea>
      <mat-hint align="end">Comma separated list of Tile Ids</mat-hint>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button mat-stroked-button (click)="reset()">Reset</button>
  <button
    id="dialogScenarioSubmit"
    mat-raised-button
    color="primary"
    [disabled]="disableButton()"
    (click)="save()"
  >
    Save
  </button>
</div>

