import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AreasApiEntity } from '@shared/model/productserver';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AreaCacheService } from '@shared/service/area-cache.service';

@Component({
  selector: 'app-area-dialogue-entry',
  templateUrl: './area-dialogue-entry.component.html',
  styleUrls: ['./area-dialogue-entry.component.scss']
})
export class AreaDialogueEntryComponent {
  areaFormData: FormGroup;
  areaId: number | null;
  areaData: AreasApiEntity;
  isLoadingResults = false;

  constructor(
    private areaCacheService: AreaCacheService,
    public dialogRef: MatDialogRef<AreaDialogueEntryComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    {
      areaId,
      name,
      description,
      tileIds,
      updated,
      bounds,
      tileCount
    }: AreasApiEntity
  ) {
    this.areaId = areaId;
    this.areaData = {
      areaId,
      name,
      description,
      tileIds,
      updated,
      bounds,
      tileCount
    };
    this.initForm();
    this.loadArea();
  }

  save(): void {
    if (this.areaFormData.invalid) {
      return;
    }
    this.dialogRef.close(this.areaFormData.getRawValue());
  }

  close(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.initForm();
    this.loadArea();
  }

  disableButton(): boolean {
    return this.areaFormData.invalid;
  }

  private initForm(): void {
    this.areaFormData = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      tileIds: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\s*[0-9]{8,}(\\s*,\\s*[0-9]{8,})*\\s*$') // NOSONAR
        ]
      ]
    });
    // disable tile ids block to restrict user to update the tile ids only
    if (this.areaId) {
      this.areaFormData.controls.tileIds.disable();
    }
  }

  private loadArea(): void {
    if (this.areaId) {
      this.isLoadingResults = true;
      this.areaCacheService.getArea(this.areaId).subscribe((area) => {
        this.areaFormData.get('name').setValue(area.name);
        this.areaFormData.get('description').setValue(area.description);
        this.areaFormData.get('tileIds').setValue(area.tileIds.join(','));
        this.isLoadingResults = false;
      });
    }
  }
}
