import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-map-viewer-select-catalog',
  templateUrl: './map-viewer-select-catalog.component.html',
  styleUrls: ['./map-viewer-select-catalog.component.scss']
})
export class MapViewerSelectCatalogComponent implements OnInit, OnDestroy {
  private $visualizationOptions: Map<MapViewerVisualizationMode, string> =
    new Map<MapViewerVisualizationMode, string>([
      [MapViewerVisualizationMode.RESULT, 'Result Visualization'],
      [MapViewerVisualizationMode.DELTA, 'Delta Visualization'],
      [MapViewerVisualizationMode.DATA, 'Data Visualization']
    ]);

  private $subscription: Subscription = new Subscription();

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  get visualizationOptions(): Map<MapViewerVisualizationMode, string> {
    return this.$visualizationOptions;
  }

  get isResultVisualization(): boolean {
    return this.visualizationMode === MapViewerVisualizationMode.RESULT;
  }

  get isDeltaVisualization(): boolean {
    return this.visualizationMode === MapViewerVisualizationMode.DELTA;
  }

  get isDataVisualization(): boolean {
    return this.visualizationMode === MapViewerVisualizationMode.DATA;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.RESET_VISUALIZATION_MODE, () => {
        this.resetCatalogSelection();
        this.resetComparisonCatalog();
      })
    );
  }

  resetCatalogSelection() {
    this.mapViewerUserSelectionService.visualizationMode =
      MapViewerVisualizationMode.RESULT;
    this.resetCatalog();
    this.resetComparisonCatalog();
    this.resetDeltaCatalog();
    this.resetDeltaComparisonCatalog();
  }

  selectVisualizationMode(event: MatButtonToggleChange) {
    this.mapViewerUserSelectionService.visualizationMode =
      event.value || MapViewerVisualizationMode.RESULT;
  }

  isCompleted() {
    return this.mapViewerUserSelectionService.isCompleted();
  }

  asIsOrder(_a: any, _b: any) {
    return 1;
  }

  private resetCatalog() {
    this.mapViewerUserSelectionService.mainCatalog = null;
    this.mapViewerUserSelectionService.mainCatalogVersion = null;
  }

  private resetComparisonCatalog() {
    this.mapViewerUserSelectionService.mainComparisonCatalog = null;
    this.mapViewerUserSelectionService.mainComparisonCatalogVersion = null;
  }

  private resetDeltaCatalog() {
    this.mapViewerUserSelectionService.deltaCatalog = null;
    this.mapViewerUserSelectionService.deltaCatalogVersion = null;
  }

  private resetDeltaComparisonCatalog() {
    this.mapViewerUserSelectionService.deltaComparisonCatalog = null;
    this.mapViewerUserSelectionService.deltaComparisonCatalogVersion = null;
  }
}
