<div class="container-fluid">
  <mat-card appearance="outlined" class="scenario-container">
    <app-page-title></app-page-title>

    <mat-card-content>
      <app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
      <div class="kf-button-group">
        <button (click)="openCreateDialog()" *ngIf="authService.hasPrivileges(['AREAS_CREATE'])" aria-label="Create Area"
                color="primary" id="createAreaId" mat-raised-button matTooltip="Create Area">
          <mat-icon aria-hidden="false">add</mat-icon>
          <span>Create Area</span>
        </button>
      </div>

      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Filter</mat-label>
        <input (keyup)="applyFilter($event)" [value]="this.filterValue" id="filterListing" matInput placeholder="Search for Details...">
        <button (click)="applyFilter(null)" aria-label="Clear" mat-icon-button matSuffix>
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div class="scenario-list-container">
        <table
          class="kf-table"
          [dataSource]="data"
          aria-describedby="tableDescription"
          mat-table
          matSort
          matSortActive="areaId"
          matSortDirection="asc"
          multiTemplateDataRows
        >
          <colgroup>
            <col span="1" style="width:5%" />
            <col span="1" style="width:5%" />
            <col span="1" style="width:20%" />
            <col span="1" style="width:30%" />
            <col span="1" style="width:5%" />
            <col span="1" style="width:20%" />
            <col span="1" style="width:15%" />
          </colgroup>

          <ng-container matColumnDef="areaId">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">ID</th>
            <td *matCellDef="let element" mat-cell>
              {{element.areaId}}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">Name</th>
            <td *matCellDef="let element" mat-cell>
              {{element.name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell scope="col">Description</th>
            <td *matCellDef="let element" mat-cell>
              {{element.description}}
            </td>
          </ng-container>

          <ng-container matColumnDef="tileCount">
            <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"># Tiles</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.tileCount}}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastModified">
            <th *matHeaderCellDef mat-header-cell scope="col">Last Modified</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.updated | date: 'dd/MM/yyyy HH:mm:ss z' || '' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
            <td *matCellDef="let element" mat-cell>
              <div class="kf-button-group">
                <button (click)="openEditDialog(element)" *ngIf="authService.hasPrivileges(['AREAS_UPDATE'])" aria-label="Edit Area"
                        color="primary" id="areasUpdateId" mat-mini-fab
                        matTooltip="Edit Area">
                  <mat-icon aria-hidden="false">edit</mat-icon>
                </button>
                <button (click)="confirmDelete(element)" *ngIf="authService.hasPrivileges(['AREAS_DELETE'])" aria-label="Delete Area"
                        color="warn" id="areasDeleteId" mat-mini-fab
                        matTooltip="Delete Area">
                  <mat-icon aria-hidden="false">delete</mat-icon>
                </button>
                <a
                  [queryParams]="prepareUrl(element)"
                  [routerLink]="['/map-viewer']"
                  aria-label="Jump to Test Result Map"
                  color="primary"
                  mat-mini-fab
                  matTooltip="Jump to Test Result Map"
                >
                  <mat-icon aria-hidden="false">location_on</mat-icon>
                </a>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandDetails">
            <th *matHeaderCellDef mat-header-cell scope="col"></th>
            <td *matCellDef="let element" mat-cell>
              <button
                (click)="showAreaDetails(element)"
                *ngIf="!loadingSpinner.get(element.areaId)"
                aria-label="Show Details"
                mat-icon-button matTooltip="Show Details"
              >
                <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
              </button>
              <!-- Loading spinner -->
              <mat-spinner
                *ngIf="loadingSpinner.get(element.areaId)"
                [diameter]="20"
                [strokeWidth]="2"></mat-spinner>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
              <div
                *ngIf="areasDetails.has(element.areaId)"
                [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
                class="details-table-wrapper"
              >
                <table aria-describedby="detailsTableDescription" class="details-table">
                  <colgroup>
                    <col span="1" style="width:10%" />
                    <col span="1" style="width:90%" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th scope="row">id:</th>
                    <td>{{areasDetails.get(element.areaId).areaId}}</td>
                  </tr>
                  <tr>
                    <th scope="row">name:</th>
                    <td>{{areasDetails.get(element.areaId).name}}</td>
                  </tr>
                  <tr>
                    <th scope="row">description:</th>
                    <td>{{areasDetails.get(element.areaId).description}}</td>
                  </tr>
                  <tr>
                    <th scope="row">tiles:</th>
                    <td>
                      <div class="area-list-screen-size">
                        {{areasDetails.get(element.areaId).tileIds | join }}
                      </div>
                    </td>
                  </tr>
                  </tbody>

                  <caption id="detailsTableDescription">Scenario details</caption>
                </table>
              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            *matRowDef="let element; columns: displayedColumns;"
            [class.details-table-expanded]="element.isExpanded"
            class="element-row element-row-expandable"
            mat-row
          ></tr>
          <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
          <caption id="tableDescription">List of available scenarios</caption>
        </table>

        <!--pagination start-->
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
        <!--pagination end-->
      </div>
    </mat-card-content>
  </mat-card>
</div>
