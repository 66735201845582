import * as d3 from 'd3-scale';
import * as d3sc from 'd3-scale-chromatic';

export interface ValueRangeMarker {
  label: string;
  color: string;
  position: number;
  value: number;
}

export class MapViewerValueScale {
  domain: number[] = [];
  range: number[] = [];

  minValuePrefix = '';
  maxValuePrefix = '';

  protected $scale;

  constructor(domain: number[], range: number[]) {
    this.domain = domain;
    this.range = range;
    this.initScale();
  }

  get scale() {
    return this.$scale;
  }

  get domainMinMax(): number[] {
    const minValue = this.domain[0] || 0;
    const maxValue = this.domain[this.domain.length - 1];
    return [minValue, maxValue];
  }

  get domainDistance() {
    const [minValue, maxValue] = this.domainMinMax;
    return Math.abs(maxValue - minValue);
  }

  initScale() {
    this.$scale = d3.scaleLinear().domain(this.domain).range(this.range);
  }

  scaleValue(value: number) {
    return this.$scale(value);
  }

  scaleColor(value: number) {
    return this.getColor(this.$scale(value));
  }

  getColor(scaledValue) {
    return d3sc.interpolateSinebow(scaledValue);
  }

  calcMarkerRange(parts = 4): ValueRangeMarker[] {
    if (this.domainDistance <= 1) {
      parts = 1;
    }
    const distance = 1 / parts;
    const markers = [];
    let rangeValue = 0;
    for (let step = 0; step <= parts; step++) {
      const realValue = this.invert(rangeValue);
      let prefix = '';
      if (step === 0) {
        prefix = this.minValuePrefix;
      }
      if (step === parts) {
        prefix = this.maxValuePrefix;
      }
      markers.push({
        position: Math.round(100 * rangeValue),
        value: realValue,
        label: `${prefix}${Math.round(realValue)}`,
        color: this.scaleColor(realValue)
      });
      rangeValue += distance;
    }
    return markers;
  }

  invert(value) {
    return this.$scale.invert(value);
  }
}

export class MapViewerStatisticValueScale extends MapViewerValueScale {
  static readonly COLOR_MIN = 'rgb(0, 255, 0)';
  static readonly COLOR_MAX = 'rgb(0, 0, 255)';

  constructor(domain: number[]) {
    super(domain, [0, 1]);
  }

  initScale() {
    this.$scale = d3.scaleSqrt().domain(this.domain).range(this.range);
  }

  getColor(scaledValue) {
    const colorScale = d3.scaleLinear(
      [0, 1],
      [
        MapViewerStatisticValueScale.COLOR_MIN,
        MapViewerStatisticValueScale.COLOR_MAX
      ]
    );
    return colorScale(scaledValue);
  }
}

export class MapViewerFailCountScale extends MapViewerValueScale {
  static readonly COLOR_MIN = 'rgb(255, 255, 0)';
  static readonly COLOR_MID = 'rgb(255, 0, 0)';
  static readonly COLOR_MAX = 'rgb(128, 0, 128)';

  minValuePrefix = '> ';

  constructor(domain: number[]) {
    super(domain, [0, 1]);
  }
  initScale() {
    this.$scale = d3.scaleSqrt().domain(this.domain).range(this.range);
  }

  getColor(scaledValue) {
    const colorScale = d3.scaleLinear(
      [0, 0.5, 1],
      [
        MapViewerFailCountScale.COLOR_MIN,
        MapViewerFailCountScale.COLOR_MID,
        MapViewerFailCountScale.COLOR_MAX
      ]
    );
    return colorScale(scaledValue);
  }
}

export class MapViewerFailRateScale extends MapViewerValueScale {
  static readonly COLOR_MIN = 'rgb(255, 255, 0)';
  static readonly COLOR_MID = 'rgb(255, 0, 0)';
  static readonly COLOR_MAX = 'rgb(128, 0, 128)';

  minValuePrefix = '> ';

  constructor() {
    super([0, 100], [0, 1]);
  }

  getColor(scaledValue) {
    const colorScale = d3.scaleLinear(
      [0, 0.5, 1],
      [
        MapViewerFailRateScale.COLOR_MIN,
        MapViewerFailRateScale.COLOR_MID,
        MapViewerFailRateScale.COLOR_MAX
      ]
    );
    return colorScale(scaledValue);
  }
}

export class MapViewerStatisticValueDeltaScale extends MapViewerValueScale {
  static readonly COLOR_INCREMENT_MIN = 'rgb(255, 255, 0)';
  static readonly COLOR_INCREMENT_MAX = 'rgb(0, 128, 0)';
  static readonly COLOR_DECREMENT_MIN = 'rgb(255, 64, 64)';
  static readonly COLOR_DECREMENT_MAX = 'rgb(70, 0, 128)';
  static readonly COLOR_EQUAL = 'rgb(0, 0, 255)';

  private colorScaleImprovement = d3.scaleLinear(
    [0.5, 1],
    [
      MapViewerStatisticValueDeltaScale.COLOR_INCREMENT_MIN,
      MapViewerStatisticValueDeltaScale.COLOR_INCREMENT_MAX
    ]
  );
  private colorScaleDegradation = d3.scaleLinear(
    [0, 0.5],
    [
      MapViewerStatisticValueDeltaScale.COLOR_DECREMENT_MAX,
      MapViewerStatisticValueDeltaScale.COLOR_DECREMENT_MIN
    ]
  );

  constructor(domain: number[]) {
    super(domain, [0, 0.5, 1]);
  }

  initScale() {
    this.$scale = d3.scaleSqrt().domain(this.domain).range(this.range);
  }

  getColor(scaledValue) {
    if (scaledValue < 0.5) {
      return this.colorScaleDegradation(scaledValue);
    }
    if (scaledValue > 0.5) {
      return this.colorScaleImprovement(scaledValue);
    }
    return MapViewerStatisticValueDeltaScale.COLOR_EQUAL;
  }
}

export class MapViewerDeltaFailRateScale extends MapViewerValueScale {
  static readonly COLOR_INCREMENT_MIN = 'rgb(255, 255, 0)';
  static readonly COLOR_INCREMENT_MAX = 'rgb(0, 128, 0)';
  static readonly COLOR_DECREMENT_MIN = 'rgb(255, 64, 64)';
  static readonly COLOR_DECREMENT_MAX = 'rgb(70, 0, 128)';
  static readonly COLOR_EQUAL = 'rgb(0, 0, 255)';

  minValuePrefix = '> ';
  maxValuePrefix = '< ';

  private colorScaleImprovement = d3.scaleLinear(
    [0, 0.5],
    [
      MapViewerDeltaFailRateScale.COLOR_INCREMENT_MAX,
      MapViewerDeltaFailRateScale.COLOR_INCREMENT_MIN
    ]
  );
  private colorScaleDegradation = d3.scaleLinear(
    [0.5, 1],
    [
      MapViewerDeltaFailRateScale.COLOR_DECREMENT_MIN,
      MapViewerDeltaFailRateScale.COLOR_DECREMENT_MAX
    ]
  );

  constructor() {
    super([-100, 0, 100], [0, 0.5, 1]);
  }

  initScale() {
    this.$scale = d3.scaleSqrt().domain(this.domain).range(this.range);
  }

  getColor(scaledValue) {
    if (scaledValue < 0.5) {
      return this.colorScaleImprovement(scaledValue);
    }
    if (scaledValue > 0.5) {
      return this.colorScaleDegradation(scaledValue);
    }
    return MapViewerDeltaFailRateScale.COLOR_EQUAL;
  }
}

export class MapViewerDeltaFailCountScale extends MapViewerValueScale {
  static readonly COLOR_INCREMENT_MIN = 'rgb(255, 255, 0)';
  static readonly COLOR_INCREMENT_MAX = 'rgb(0, 128, 0)';
  static readonly COLOR_DECREMENT_MIN = 'rgb(255, 64, 64)';
  static readonly COLOR_DECREMENT_MAX = 'rgb(70, 0, 128)';
  static readonly COLOR_EQUAL = 'rgb(0, 0, 255)';

  minValuePrefix = '> ';
  maxValuePrefix = '< ';

  private colorScaleImprovement = d3.scaleLinear(
    [0, 0.5],
    [
      MapViewerDeltaFailCountScale.COLOR_INCREMENT_MAX,
      MapViewerDeltaFailCountScale.COLOR_INCREMENT_MIN
    ]
  );
  private colorScaleDegradation = d3.scaleLinear(
    [0.5, 1],
    [
      MapViewerDeltaFailCountScale.COLOR_DECREMENT_MIN,
      MapViewerDeltaFailCountScale.COLOR_DECREMENT_MAX
    ]
  );

  constructor(domain: number[]) {
    super(domain, [0, 0.5, 1]);
  }

  initScale() {
    this.$scale = d3.scaleSqrt().domain(this.domain).range(this.range);
  }

  getColor(scaledValue) {
    if (scaledValue < 0.5) {
      return this.colorScaleImprovement(scaledValue);
    }
    if (scaledValue > 0.5) {
      return this.colorScaleDegradation(scaledValue);
    }
    return MapViewerDeltaFailCountScale.COLOR_EQUAL;
  }
}
