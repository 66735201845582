import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AreaRequestObject, AreasApiEntity } from '@shared/model/productserver';
import { NotificationService } from '@shared/service/notification.service';
import { of as observableOf, Subscription } from 'rxjs';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { catchError, map } from 'rxjs/operators';
import Util from '@shared/helper/util';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';
import { AreasService } from '@modules/areas/service/areas.service';
import { AreaDialogueEntryComponent } from '@modules/areas/component/area-dialogue-entry/area-dialogue-entry.component';
import { AuthService } from '@shared/service/auth.service';
import { HttpApiErrorResponse } from '@shared/model/response';
import { Params } from '@angular/router';
import { DatastoreService } from '@shared/service/datastore.service';
import { environment } from '@env';
import { AreaCacheService } from '@shared/service/area-cache.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class AreasComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'expandDetails',
    'areaId',
    'name',
    'description',
    'tileCount',
    'lastModified',
    'actions'
  ];
  data: MatTableDataSource<AreasApiEntity>;

  resultsLength = 0;
  isLoadingResults = true;

  dialogWidth = '500px';
  confirmWidth = '300px';

  pageSize = environment.pageSize;
  pageSizeOptions = environment.pageSizeOptions;
  totalPages = 0;
  showFirstLastButtons = true;
  filterValue = '';
  areasDetails: Map<number, AreasApiEntity> = new Map<number, AreasApiEntity>();
  loadingSpinner = new Map<number, boolean>();
  protected datastoreService: DatastoreService;
  private subscription: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private areaService: AreasService,
    private areaCacheService: AreaCacheService,
    public authService: AuthService,
    datastoreService: DatastoreService
  ) {
    this.datastoreService = datastoreService;
  }

  ngOnInit(): void {
    this.getAreas();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.notificationService.clear();
  }

  getPagesArray(): number[] {
    return this.totalPages > 0
      ? Array(this.totalPages)
          .fill(0)
          .map((_x, i) => i)
      : [];
  }

  getAreas(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.areaService
        .getAreas()
        .pipe(
          map((response) => {
            const { data } = response;
            this.resultsLength = response.size;
            data.sort((a, b) => a.areaId - b.areaId);
            return data;
          }),
          catchError((err: HttpApiErrorResponse) => {
            this.showError(err);
            return observableOf([]);
          })
        )
        .subscribe(
          // scenarioArray is the response.data which is received from the rest call
          (areasArray) => {
            this.data = new MatTableDataSource(areasArray);
            this.data.sort = this.sort;
            this.data.paginator = this.paginator;
            this.isLoadingResults = false;
          }
        )
    );
  }

  applyFilter(event: Event): void {
    this.filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.data.filter = this.filterValue.trim().toLowerCase();
  }

  parseArea(element: any): AreaRequestObject {
    const {
      name = '',
      description = '',
      updated = '',
      bounds = '',
      tileCount = 0
    } = element;
    let { tileIds = [] } = element;

    if (typeof tileIds === 'string') {
      tileIds = Util.valueStringToArray(tileIds, ',').map((value) =>
        parseInt(value, 10)
      );
    }

    return {
      name,
      description,
      tileIds,
      updated,
      bounds,
      tileCount
    };
  }

  openEditDialog(element: any): MatDialogRef<AreaDialogueEntryComponent> {
    const dialogRef = this.dialog.open(AreaDialogueEntryComponent, {
      width: this.dialogWidth,
      data: {
        ...element
      }
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        const updateArea = this.parseArea(formData);
        this.updateArea(element.areaId, updateArea);
      }
    });
    return dialogRef;
  }

  openCreateDialog(): MatDialogRef<AreaDialogueEntryComponent> {
    const dialogRef = this.dialog.open(AreaDialogueEntryComponent, {
      width: this.dialogWidth,
      data: {}
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        const addArea = this.parseArea(formData);
        this.createArea(addArea);
      }
    });
    return dialogRef;
  }

  confirmDelete(element: any): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Delete Area`,
        message: `Are you sure to delete an Area "${element.name}" (ID: ${element.areaId})`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.deleteArea(element.areaId);
      }
    });
    return dialogRef;
  }

  createArea(area: AreaRequestObject): void {
    this.isLoadingResults = true;
    this.areaService.createArea(area).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.showResponse(response);
        this.getAreas();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  updateArea(id: number, area: AreaRequestObject): void {
    this.isLoadingResults = true;
    this.areaService.updateArea(id, area).subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.showResponse(response);
        this.getAreas();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  deleteArea(id: number): void {
    this.areaService.deleteArea(id).subscribe(
      (response) => {
        this.showResponse(response);
        this.getAreas();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  prepareUrl(element: AreasApiEntity): Params {
    const queryParams: any = {};
    queryParams.areaId = element.areaId;
    return queryParams;
  }

  showAreaDetails(element) {
    element.isExpanded = !element.isExpanded;
    if (element.isExpanded) {
      if (this.areasDetails.has(element.areaId)) {
        this.areasDetails.get(element.areaId);
      } else {
        this.loadingSpinner.set(element.areaId, true);
        this.areaCacheService
          .getSingleArea(element.areaId)
          .subscribe((response) => {
            this.loadingSpinner.set(element.areaId, false);
            // create a map and store the area id as key and details as value
            this.areasDetails.set(element.areaId, response);
          });
      }
    } else {
      // Do something when the accordion is collapsed
      element.isExpanded = false;
    }
  }

  private showResponse(response: HttpResponse<string>): void {
    this.notificationService.success(response.statusText, `${response.status}`);
  }

  private showError(err: HttpApiErrorResponse): void {
    this.isLoadingResults = false;
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }
}
