import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  Inject
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import { CatalogApiEntity } from '@shared/model/productserver';
import { CatalogService } from '@shared/service/catalog.service';
import { ProductServerService } from '@shared/service/product-server.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-catalog-form-control',
  templateUrl: './catalog-form-control.component.html',
  styleUrls: ['./catalog-form-control.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: CatalogFormControlComponent }
  ]
})
export class CatalogFormControlComponent extends AbstractInputFormControlComponent<string> {
  public isLoading: boolean = false;

  catalogs: Map<string, CatalogApiEntity[]> = new Map<
    string,
    CatalogApiEntity[]
  >();
  mapDataTypes: Observable<string[]>;

  parts = this._formBuilder.group({
    catalogName: this._formBuilder.control('', { nonNullable: true })
  });

  autofilled: boolean;

  readonly controlType: string = 'catalog-form';

  @HostBinding() id: string = `${
    this.controlType
  }-${CatalogFormControlComponent.nextId++}`;

  get empty(): boolean {
    const {
      value: { catalogName }
    } = this.parts;
    return !catalogName;
  }

  @Input()
  get value(): string | null {
    if (this.parts.valid) {
      const {
        value: { catalogName }
      } = this.parts;
      return catalogName;
    }
    return null;
  }

  set value(value: string) {
    this.parts.setValue({ catalogName: value?.trim() ?? null });
    this.stateChanges.next();
  }

  constructor(
    private productServerService: ProductServerService,
    private catalogService: CatalogService,
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);

    this.getCatalogList();
  }

  private _mainCatalog: CatalogApiEntity = null;
  @Input() set mainCatalog(value) {
    this._mainCatalog = this.getMainCatalog(value);
  }

  getCatalogList(): void {
    this.isLoading = true;
    this.catalogService.getCatalogs().subscribe(
      (catalogs) => {
        this.catalogs =
          this.productServerService.groupCatalogsByMapDataType(catalogs);
        const mapDataTypes = [
          ...new Set(catalogs.map((catalog) => catalog.mapDataType))
        ];
        this.mapDataTypes = of(mapDataTypes);
        this.isLoading = false;
      },
      (_error) => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  private getMainCatalog(catalogName: string): CatalogApiEntity {
    let mainCatalog: CatalogApiEntity = null;
    this.catalogService.getCatalogByName(catalogName).subscribe((catalog) => {
      mainCatalog = catalog;
    });
    return mainCatalog;
  }

  private getMapDataType(): string {
    return this._mainCatalog?.mapDataType;
  }

  isVisible(mapDataType): boolean {
    const mainMapDataType = this.getMapDataType();
    return !(!!mainMapDataType && mainMapDataType !== mapDataType);
  }
}
