import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AutomatedExecutionFormComponent } from '@shared/component/trigger-job/automated-execution-form/automated-execution-form.component';
import { ItemWorkProgress } from '@shared/model/generic';
import {
  ComplexTriggerJobObject,
  ComplexTriggerJobRequestObject
} from '@shared/model/productserver';
import { HttpApiErrorResponse } from '@shared/model/response';
import { ProductServerService } from '@shared/service/product-server.service';

@Component({
  selector: 'app-automated-execution-dialog',
  templateUrl: './automated-execution-dialog.component.html',
  styleUrls: ['./automated-execution-dialog.component.scss']
})
export class AutomatedExecutionDialogComponent implements OnInit {
  @ViewChild(AutomatedExecutionFormComponent, { static: true })
  public automatedExecutionForm: AutomatedExecutionFormComponent;
  public form: FormGroup;
  public progress: ItemWorkProgress = ItemWorkProgress.OPEN;
  public response: any = null;
  public error: any = null;
  public triggerJobId: number | null;
  public triggerJob: ComplexTriggerJobObject | null;

  constructor(
    public dialogRef: MatDialogRef<AutomatedExecutionFormComponent>,
    private formBuilder: FormBuilder,
    private productServerService: ProductServerService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    triggerJob: ComplexTriggerJobObject
  ) {
    this.triggerJobId = triggerJob.triggerJobId;
    this.triggerJob = triggerJob;
    dialogRef.disableClose = true;
  }

  get isOpen(): boolean {
    return this.progress === ItemWorkProgress.OPEN;
  }

  get isProcessing(): boolean {
    return this.progress === ItemWorkProgress.PROCESSING;
  }

  get isDone(): boolean {
    return this.progress === ItemWorkProgress.DONE;
  }

  get isFail(): boolean {
    return this.progress === ItemWorkProgress.FAIL;
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    this.form = this.formBuilder.group({
      active: [!!this.triggerJob.active, [Validators.required]]
    });
    return this.form;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    const triggerJobRequest: ComplexTriggerJobRequestObject =
      ComplexTriggerJobRequestObject.fromForm({
        ...this.getFormValues(),
        triggerJobId: this.triggerJobId
      });
    this.response = null;
    this.error = null;
    if (this.triggerJobId) {
      this.updateTriggerJob(triggerJobRequest);
    } else {
      this.createTriggerJob(triggerJobRequest);
    }
  }

  getFormValues(): any {
    const { active } = this.form.getRawValue();
    return {
      ...this.automatedExecutionForm.complexTriggerJobForm.getRawValue(),
      active
    } as any;
  }

  private createTriggerJob(
    triggerJobRequest: ComplexTriggerJobRequestObject
  ): void {
    this.progress = ItemWorkProgress.PROCESSING;
    this.productServerService
      .createComplexTriggerJob(triggerJobRequest)
      .subscribe(
        (response) => {
          this.response = response;
          this.handleSaveResponse(response);
        },
        (error: HttpApiErrorResponse) => this.handleError(error)
      );
  }

  private updateTriggerJob(
    triggerJobRequest: ComplexTriggerJobRequestObject
  ): void {
    this.progress = ItemWorkProgress.PROCESSING;
    this.productServerService
      .replaceComplexTriggerJob(this.triggerJobId, triggerJobRequest)
      .subscribe(
        (response) => {
          this.response = response;
          this.handleSaveResponse(response);
        },
        (error: HttpApiErrorResponse) => this.handleError(error)
      );
  }

  private handleSaveResponse(response: any): void {
    if (
      [
        HttpStatusCode.Accepted,
        HttpStatusCode.Created,
        HttpStatusCode.NoContent
      ].includes(response.status)
    ) {
      // Successful response, close the dialog
      this.progress = ItemWorkProgress.DONE;
      this.close();
    }
  }

  private handleError(error: HttpApiErrorResponse): void {
    this.progress = ItemWorkProgress.OPEN;
    console.error(error);
    this.error = error;
    this.dialogRef.afterClosed().subscribe(() => {
      // When the user clicks on close, reset the progress to OPEN and other relevant data
      this.progress = ItemWorkProgress.OPEN;
    });
  }
}
