<mat-form-field appearance="fill" floatLabel="auto" id="areasId">
  <mat-label>Add threshold for {{isTileBasedAllowed(threshold.resultType) ? '...' : 'area'}}</mat-label>
  <span matSuffix [class.spinner]="isLoading"></span>
  <input matInput *ngIf="isLoading" />
  <mat-select *ngIf="!isLoading" [(ngModel)]="selectedOption" #thresholdAreaSelector>
    <mat-optgroup *ngIf="isTileBasedAllowed(threshold.resultType)" label="Tiles">
      <mat-option [disabled]="threshold.isThresholdTileDefined" [value]="tileBasedArea">{{tileBasedArea.name}}</mat-option>
    </mat-optgroup>
    <mat-optgroup *ngIf="isTileBasedAllowed(threshold.resultType)" label="Areas">
      <mat-option *ngFor="let area of getAreaList()" [value]="area">{{area.name}}</mat-option>
    </mat-optgroup>
    <ng-container *ngIf="!isTileBasedAllowed(threshold.resultType)">
      <mat-option *ngFor="let area of getAreaList()" [value]="area">{{area.name}}</mat-option>
    </ng-container>
  </mat-select>
  <button matSuffix mat-icon-button (click)="selectArea()"><mat-icon>add</mat-icon></button>
</mat-form-field>
