import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ROUTES } from '@shared/service/navigation.config';
import { NavigationNode } from '@shared/model/navigation';
import { AuthService } from '@shared/service/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined);
  public routeToNodeMapping: Map<string, NavigationNode> = new Map<
    string,
    NavigationNode
  >();

  private readonly $routes: NavigationNode[];

  constructor(
    private $authService: AuthService,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });

    this.$routes = ROUTES;
    this.initializeRouteToNodeMapping(this.$routes);
  }

  get menuItems(): NavigationNode[] {
    return this.$routes;
  }

  getNavigationNodeByRoute(route: string): NavigationNode {
    const searchRoute = `/${route}`.replace('//', '/');
    return this.routeToNodeMapping.has(searchRoute)
      ? this.routeToNodeMapping.get(searchRoute)
      : null;
  }

  filterNavigation(
    navigationNodes: Array<NavigationNode>,
    position: string = null
  ): Array<NavigationNode> {
    return navigationNodes
      .filter((menuItem: NavigationNode) =>
        this.$authService.hasPrivileges(menuItem.privileges)
      )
      .filter(
        (menuItem: NavigationNode) =>
          (!menuItem.position && !position) || menuItem.position === position
      )
      .map((menuItem: NavigationNode) => {
        if (menuItem.children && menuItem.children.length) {
          menuItem.children = this.filterNavigation(menuItem.children, null);
        }
        return menuItem;
      });
  }

  private initializeRouteToNodeMapping(
    navigationNodes: NavigationNode[]
  ): void {
    navigationNodes.forEach((navigationNode) => {
      this.routeToNodeMapping.set(`/${navigationNode.path}`, navigationNode);
      if (navigationNode.children && navigationNode.children.length > 0) {
        this.initializeRouteToNodeMapping(navigationNode.children);
      }
    });
  }
}
