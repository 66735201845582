import { Bounds } from '@shared/model/datastore';
import { TileNds, TileUtilsService } from '@shared/service/tile-utils.service';

export class MapViewerBoundsCache {
  public static readonly PARTITION_TILE_MIN_LEVEL = 7;

  private $cache: Map<number, TileNds> = new Map<number, TileNds>();

  get cache() {
    return this.$cache;
  }

  public add(tile: TileNds): boolean {
    this.$cache.set(tile.tileId, tile);
    return true;
  }

  public addAll(tiles: TileNds[]): boolean {
    tiles.forEach((tile) => {
      this.$cache.set(tile.tileId, tile);
    });
    return true;
  }

  public clear() {
    this.$cache.clear();
  }

  public has(tile: TileNds): boolean {
    return this.$cache.has(tile.tileId);
  }

  public hasAll(tiles: TileNds[]): boolean {
    return tiles.every((tile) => this.$cache.has(tile.tileId));
  }

  public difference(tiles: TileNds[]): TileNds[] {
    const cachedTileIds = Array.from(this.$cache.keys());
    return tiles.filter((tile) => !cachedTileIds.includes(tile.tileId));
  }

  public getPartitions(bounds: Bounds): TileNds[] {
    return this.difference(this.mapViewPortToTiles(bounds));
  }

  public getPartitionTileLevel(): number {
    return MapViewerBoundsCache.PARTITION_TILE_MIN_LEVEL;
  }

  public mapViewPortToTiles(bounds: Bounds): TileNds[] {
    const targetLevel = this.getPartitionTileLevel();
    const normalizedBounds = TileUtilsService.fitBoundsToTileGrid(bounds);
    const {
      ne: [neLat, neLng],
      sw: [swLat, swLng]
    } = normalizedBounds;
    const neTileNorm = TileUtilsService.wgs84ToNdsTile(
      neLng,
      neLat,
      targetLevel
    );
    const swTileNorm = TileUtilsService.wgs84ToNdsTile(
      swLng,
      swLat,
      targetLevel
    );
    return TileUtilsService.calcTileIdRectangle(
      swTileNorm,
      neTileNorm,
      targetLevel
    ).map((tileId) => new TileNds(tileId));
  }
}
