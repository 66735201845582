<form [formGroup]="form">
  <section>
    <h2>Define Test Area</h2>
    <app-test-area-form [areaIds]="areaIds" [tileIds]="tileIds" [routeIds]="routeIds"></app-test-area-form>
  </section>
  <section>
    <h2>Define Map Catalog Infos</h2>
    <app-map-catalog-info-form
            [mapCatalogInfo]="mapCatalogInfos?.[0]"
            [enabledRelativeCatalogVersions]="enabledRelativeCatalogVersions"
    ></app-map-catalog-info-form>
    <mat-hint *ngIf="enabledRelativeCatalogVersions" align="start">Note: Use <code>0</code> for latest catalog version,
      <code>&lt;0</code> for
      versions relative to latest, e.g. <code>-1</code></mat-hint>
    <mat-hint *ngIf="!enabledRelativeCatalogVersions" align="start">Note: Select catalog version or provide version
      number <code>&gt;0</code></mat-hint>
  </section>
</form>
