<ng-container>
  <label class="kf-label">Select Catalog</label>
  <div class="kf-form-catalog-select">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <span matSuffix [class.spinner]="isLoadingResults"></span>
      <input matInput *ngIf="isLoadingResults" />
      <mat-select *ngIf="!isLoadingResults"
                  id="selectedCatalogName"
                  [value]="mainCatalog?.name"
                  (valueChange)="selectMainCatalog($event)"
                  panelClass="kf-catalog-select"
                  required
      >
        <mat-option [value]="null">None</mat-option>
        <mat-optgroup *ngFor="let catalogGroup of catalogs | keyvalue:asIsOrder" [label]="catalogGroup.key">
          <mat-option
            *ngFor="let catalog of catalogGroup.value"
            [value]="catalog.name"
          >{{catalog.name|catalogName}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Version</mat-label>
      <input
        id="selectedCatalogVersion"
        #mainCatalogVersionInput
        [formControl]="mainCatalogVersionsFormControl"
        [matAutocomplete]="autocompleteCatalogVersion"
        matInput
        placeholder="eg. 123"
        type="text"
        required
      >
      <mat-autocomplete
        #autocompleteCatalogVersion="matAutocomplete"
        (optionSelected)="selectMainCatalogVersion($event.option.value)"
      >
        <mat-option
          *ngFor="let catalogVersion of autoCompleteCatalogVersionList | async"
          [value]="catalogVersion"
        >{{catalogVersion}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="comparisonMapDataType">
  <label class="kf-label">Select Comparison Catalog</label>
  <div class="kf-form-catalog-select">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <mat-select
        id="selectedComparisonCatalogName"
        [value]="mainComparisonCatalog?.name"
        (valueChange)="selectComparisonCatalog($event)"
        panelClass="kf-catalog-select"
        required
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option
          *ngFor="let comparisonCatalog of comparisonCatalogList"
          [value]="comparisonCatalog.name"
        >{{comparisonCatalog.name|catalogName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Version</mat-label>
      <input
        id="selectedComparisonCatalogVersion"
        #mainComparisonCatalogVersionInput
        [formControl]="mainComparisonCatalogVersionsFormControl"
        [matAutocomplete]="autocompleteMainComparisonCatalogVersion"
        matInput
        placeholder="eg. 123"
        type="text"
        required
      >
      <mat-autocomplete
        #autocompleteMainComparisonCatalogVersion="matAutocomplete"
        (optionSelected)="selectMainComparisonCatalogVersion($event.option.value)"
      >
        <mat-option
          *ngFor="let comparisonCatalogVersion of autoCompleteComparisonCatalogVersionList | async"
          [value]="comparisonCatalogVersion"
        >{{comparisonCatalogVersion}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-container>
