import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export abstract class AbstractInputFormControlComponent<T>
  implements ControlValueAccessor, MatFormFieldControl<T>, OnDestroy
{
  static nextId = 0;
  parts: FormGroup;
  readonly autofilled: boolean;
  readonly controlType: string;
  readonly id: string;
  readonly stateChanges: Subject<void> = new Subject<void>();
  readonly userAriaDescribedBy: string;
  protected _formBuilder: FormBuilder;
  protected _focusMonitor: FocusMonitor;
  protected _elementRef: ElementRef<HTMLElement>;

  protected constructor(
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this._formBuilder = _formBuilder;
    this._focusMonitor = _focusMonitor;
    this._elementRef = _elementRef;

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _disabled: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  get empty(): boolean {
    return false;
  }

  private _focused: boolean = false;

  @Input()
  get focused(): boolean {
    return this._focused;
  }

  set focused(value: BooleanInput) {
    this._focused = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _touched: boolean = false;

  @Input()
  get touched(): boolean {
    return this._touched;
  }

  set touched(value: BooleanInput) {
    this._touched = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required: boolean;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): T | null {
    return null;
  }

  set value(value: T) {}

  @HostBinding('class.floating') get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.ngControl.errors !== null && this.touched;
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]) {
    this._elementRef.nativeElement
      .querySelector(`${this.controlType}-container`)
      ?.setAttribute('aria-describedby', ids.join(' '));
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onFocusIn(_: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched(this.value);
      this.stateChanges.next();
    }
  }

  registerOnChange(fn: any): void {
    this.parts.valueChanges.subscribe((_: any) => {
      this.onChange = fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: T): void {
    this.value = value;
  }

  idListStringToArray(value: any): any {
    return value;
  }

  protected onChange = (_: any) => {};

  protected onTouched = (_: any) => {};
}
