import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'catalogName'
})
export class CatalogNamePipe implements PipeTransform {
  transform(value: string, ..._args: unknown[]): unknown {
    return value && value.includes('::')
      ? value.split('::').slice(1).join('::')
      : value;
  }
}
