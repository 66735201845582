import { Component, Input } from '@angular/core';
import { LocalizationTestApiEntity } from '@shared/model/results';

@Component({
  selector: 'app-localization-test-details',
  templateUrl: './localization-test-details.component.html',
  styleUrls: ['./localization-test-details.component.scss']
})
export class LocalizationTestDetailsComponent {
  @Input() localizationTestApiEntity: LocalizationTestApiEntity;
}
