<table class="kf-item-details">
  <colgroup>
    <col span="1" style="width:1%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
    <col span="1" style="width:5%" />
  </colgroup>
  <thead>
    <tr>
      <th>#</th>
      <th>Scenario(s)</th>
      <th>Area(s)</th>
      <th>Tile(s)</th>
      <th>Route(s)</th>
      <th>Catalog</th>
      <th>Catalog Version</th>
      <th>Comparison Catalog</th>
      <th>Comparison Catalog Version</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let action of triggerActions; let i = index">
      <td>{{ i + 1 }}</td>
      <td>
        <ng-template ngFor [ngForOf]="action.scenarioIds || []" let-scenarioId let-i="index">
          <span *ngIf="i > 0">, </span>
          <span matTooltip="ID: {{scenarioId}}">{{scenarioId|scenarioDetails:'name'}}</span>
        </ng-template>
      </td>
      <td>{{ action.areaIds | areaName }}</td>
      <td>{{ action.tileIds }}</td>
      <td>{{ action.routeIds }}</td>
      <td>{{ action.mapCatalogInfo?.name }}</td>
      <td>{{ action.mapCatalogInfo?.version }}</td>
      <td>{{ action.mapCatalogInfo?.deltaName }}</td>
      <td>{{ action.mapCatalogInfo?.deltaVersion }}</td>
    </tr>
  </tbody>
</table>
<br/>
