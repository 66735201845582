import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cron-pattern-form',
  templateUrl: './cron-pattern-form.component.html',
  styleUrls: ['./cron-pattern-form.component.scss']
})
export class CronPatternFormComponent implements OnInit {
  @Input() cronPattern = '0 * * * * *';

  public form: FormGroup | null = null;

  constructor(private fb: FormBuilder) {}

  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  createFormGroup(): FormGroup {
    const { minutes, hours, dayOfMonth, month, dayOfWeek } = this.fromString();
    this.form = this.fb.group({
      seconds: [0, Validators.required],
      minutes: [minutes, Validators.required],
      hours: [hours, Validators.required],
      dayOfMonth: [dayOfMonth, Validators.required],
      month: [month, Validators.required],
      dayOfWeek: [dayOfWeek, Validators.required]
    });
    return this.form;
  }

  fromString(): any {
    const [
      seconds = '0',
      minutes = '*',
      hours = '*',
      dayOfMonth = '*',
      month = '*',
      dayOfWeek = '*'
    ] = this.cronPattern?.split(' ') || [];
    return { seconds, minutes, hours, dayOfMonth, month, dayOfWeek };
  }

  getValue(): string {
    const { minutes, hours, dayOfMonth, month, dayOfWeek } = this.form.value;
    return `0 ${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`;
  }

  getPreviewValue(): string {
    const { minutes, hours, dayOfMonth, month, dayOfWeek } = this.form.value;
    return `${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`;
  }
}
