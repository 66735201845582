import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcToLocal' })
export class UtcToLocalPipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(
    value: string,
    format?: string,
    timezone?: string,
    locale?: string
  ): any {
    const utcDateString = !value.endsWith('Z') ? `${value}Z` : value;
    return super.transform(utcDateString, format, timezone, locale);
  }
}
