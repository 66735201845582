<ng-container *ngIf="testCaseResult">
  <table class="kf-result-single-view" aria-label="Result Details">
    <colgroup>
      <col span="1" style="width:30%;">
      <col span="1" style="width:70%;">
    </colgroup>
    <tbody>
    <tr>
      <th scope="row">Test</th>
      <td
        matTooltip="{{testCaseId|testCaseDetails:'description'}}">{{testCaseId}}
        - {{testCaseId|testCaseDetails:'name'}}</td>
    </tr>
    <tr>
      <th scope="row">Outcome</th>
      <td>{{resultOutcome}}</td>
    </tr>
    <tr>
      <th scope="row">Total Count</th>
      <td>{{totalCount}}</td>
    </tr>
    <tr>
      <th scope="row">Fail Count</th>
      <td>{{failCount}}</td>
    </tr>
    <tr *ngIf="hasFailRate">
      <th scope="row">Fail Rate</th>
      <td>{{failRate|number:"1.0-3":"de"}}%</td>
    </tr>
    <tr>
      <th scope="row">Counted Feature</th>
      <td>{{countFeature}}</td>
    </tr>
    </tbody>
  </table>
</ng-container>
