<a
  *ngIf="!item.children || item.children.length === 0"
  mat-button
  [id]="item.id"
  [routerLink]="item.path"
  [ngClass]="{
    'active': isActive(item.path),
    'expanded': expanded
  }"
  (click)="onMenuSelect()"
>
  <mat-icon [attr.aria-label]="item.title" aria-hidden="false">{{item.icon}}</mat-icon>
  <span>{{item.title}}</span>
</a>
<a
  *ngIf="item.children && item.children.length > 0"
  mat-button
  [id]="item.id"
  (onMenuOpen)="onMenuOpen()"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  [ngClass]="{
    'active': isActive(item.path),
    'expanded': expanded
  }"
  [matMenuTriggerFor]="submenu"
>
  <mat-icon [attr.aria-label]="item.title" aria-hidden="false">{{item.icon}}</mat-icon>
  <span>{{item.title}}</span>
  <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="toggle">
    expand_more
  </mat-icon>
</a>

<mat-menu #submenu="matMenu" xPosition="after" class="kf-main-toolbar-menu {{item.id}}-collapse">
  <a
    *ngFor="let subItem of item.children || []"
    mat-menu-item
    [id]="subItem.id"
    [routerLink]="subItem.path"
    [ngClass]="{'active': isActive(subItem.path)}"
  >
    <mat-icon [attr.aria-label]="subItem.title" aria-hidden="false">{{subItem.icon}}</mat-icon>
    <span>{{subItem.title}}</span>
  </a>
</mat-menu>
