import { Component } from '@angular/core';

@Component({
  selector: 'app-thresholds',
  styleUrls: ['./thresholds.component.scss'],
  templateUrl: './thresholds.component.html'
})
export class ThresholdsComponent {
  constructor() {
    // Nothing here
  }
}
