/* eslint-disable */
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ThresholdFormEmitEvent,
  ThresholdFormEvent,
  ThresholdFormEventService
} from '@modules/thresholds/service/threshold-form-event.service';
import { ResultType } from '@shared/model/datastore';
import {
  ThresholdDefinitionIdentity,
  ThresholdId
} from '@shared/model/thresholds';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';

@Component({ template: '' })
export abstract class AbstractThresholdDefinitionComponent {
  @Input() isEditable = false;
  @Input() thresholdValuesFormArray: FormArray;
  dialogWidth = '500px';
  confirmWidth = '300px';
  thresholdValueForm: FormGroup;

  private $_thresholdId: ThresholdId;
  private $_thresholdValue: ThresholdDefinitionIdentity;
  private $_resultType: ResultType;

  protected constructor(
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected thresholdFormEventService: ThresholdFormEventService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.populateForm();
    this.thresholdValuesFormArray.push(this.thresholdValueForm);
  }

  get resultType(): ResultType {
    return this.$_resultType;
  }

  @Input()
  set resultType(resultType: ResultType) {
    if (resultType === null || resultType === undefined) {
      this.$_resultType = null;
    } else {
      this.$_resultType = resultType;
    }
  }

  get thresholdValue(): ThresholdDefinitionIdentity {
    return this.$_thresholdValue;
  }

  @Input()
  set thresholdValue(thresholdValue: any) {
    if (thresholdValue === null || thresholdValue === undefined) {
      this.$_thresholdValue = null;
    } else {
      this.$_thresholdValue = thresholdValue;
    }
  }

  @Input()
  set thresholdId(thresholdId: ThresholdId) {
    if (thresholdId === null || thresholdId === undefined) {
      this.$_thresholdId = null;
    } else {
      this.$_thresholdId = thresholdId;
    }
  }

  getFormControl(name: string): FormControl {
    return this.thresholdValueForm.get(name) as FormControl;
  }

  editThreshold() {
    this.isEditable = true;
    this.thresholdFormEventService.emit(
      new ThresholdFormEmitEvent(ThresholdFormEvent.THRESHOLD_TRIGGER_EDIT, {
        key: this.$_thresholdId
      })
    );
  }

  deleteThreshold() {
    this.confirmDelete(this.thresholdValueForm);
  }

  isStatistic(): boolean {
    return this.resultType === ResultType.STATISTIC;
  }

  private confirmDelete(
    thresholdValueForm: FormGroup
  ): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Clear Threshold`,
        message: `Are you sure to clear the Threshold entries in this table`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        thresholdValueForm.reset();
        this.thresholdFormEventService.emit(
          new ThresholdFormEmitEvent(
            ThresholdFormEvent.THRESHOLD_TRIGGER_DELETE,
            {
              key: this.$_thresholdId,
              thresholdValues: [this.thresholdValue]
            }
          )
        );
      }
    });
    return dialogRef;
  }

  protected initForm() {
    this.thresholdValueForm = this.formBuilder.group({
      areaId: ['']
    });
  }

  protected populateForm(): void {
    if (!this.thresholdValue) {
      return;
    }
    this.thresholdValueForm.patchValue({ ...this.thresholdValue });
  }
}
