import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from '@env';
import { ScenarioStartDialogComponent } from '@modules/test-execution/component/scenario-start-dialog/scenario-start-dialog.component';
import { NotificationService } from '@shared/service/notification.service';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ScenarioService } from '@app/modules/test-execution/service/scenario.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioDialogEntryComponent } from '../../component/scenario-dialog-entry/scenario-dialog-entry.component';
import { ScenarioConfirmComponent } from '../../component/scenario-confirm/scenario-confirm.component';
import Util from '@shared/helper/util';
import {
  ScenarioApiEntity,
  ScenarioRequestObject
} from '@app/shared/model/productserver';
import { HttpApiErrorResponse } from '@app/shared/model/response';
import { AuthService } from '@app/shared/service/auth.service';

@Component({
  selector: 'app-scenario-listing',
  templateUrl: './scenario-listing.component.html',
  styleUrls: ['./scenario-listing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ScenarioListingComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'expandDetails',
    'id',
    'name',
    'description',
    'numTestCases',
    'actions'
  ];
  data: MatTableDataSource<ScenarioApiEntity>;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  dialogWidth = '500px';
  confirmWidth = '300px';

  pageSizeOptions = environment.pageSizeOptions;
  pageSize = environment.pageSize;

  filterValue = '';

  private subscription: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private scenarioService: ScenarioService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getScenarioList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.notificationService.clear();
  }

  getScenarioList(): void {
    this.isLoadingResults = true;

    this.subscription.add(
      this.scenarioService
        .getScenarioList()
        .pipe(
          map((response) => {
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
            this.resultsLength = response.size;
            return response.data;
          }),
          catchError((err: HttpApiErrorResponse) => {
            console.error(err.error);
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            this.showError(err);
            return observableOf([]);
          })
        )
        .subscribe(
          // scenarioArray is the response.data which is received from the rest call
          (scenarioArray) => {
            this.data = new MatTableDataSource(scenarioArray);
            this.data.sort = this.sort;
            this.data.paginator = this.paginator;
          }
        )
    );
  }

  applyFilter(event: Event): void {
    this.filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.data.filter = this.filterValue.trim().toLowerCase();
  }

  parseScenario(element: any): ScenarioRequestObject {
    const { name = '', description = '' } = element;
    let { testCases = [] } = element;

    if (typeof testCases === 'string') {
      testCases = Util.valueStringToArray(testCases, ',');
    }

    return {
      name,
      description,
      testCases
    };
  }

  openEditDialog(element: any): MatDialogRef<ScenarioDialogEntryComponent> {
    const dialogRef = this.dialog.open(ScenarioDialogEntryComponent, {
      width: this.dialogWidth,
      data: {
        ...element
      }
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        const updateScenario = this.parseScenario(formData);
        this.updateScenario(element.id, updateScenario);
      }
    });
    return dialogRef;
  }

  openCreateDialog(): MatDialogRef<ScenarioDialogEntryComponent> {
    const dialogRef = this.dialog.open(ScenarioDialogEntryComponent, {
      width: this.dialogWidth,
      data: {}
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        const addScenario = this.parseScenario(formData);
        this.createScenario(addScenario);
      }
    });
    return dialogRef;
  }

  confirmDelete(element: any): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Delete Scenario`,
        message: `Are you sure to delete scenario "${element.name}" (ID: ${element.id})`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.deleteScenario(element.id);
      }
    });
    return dialogRef;
  }

  startScenario(element: ScenarioApiEntity): MatDialogRef<any> {
    return this.dialog.open(ScenarioStartDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {
        scenario: element
      }
    });
  }

  createScenario(scenario: ScenarioRequestObject): void {
    this.scenarioService.createScenario(scenario).subscribe(
      (response) => {
        this.showResponse(response);
        this.getScenarioList();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  updateScenario(id: number, scenario: ScenarioRequestObject): void {
    this.scenarioService.updateScenario(id, scenario).subscribe(
      (response) => {
        this.showResponse(response);
        this.getScenarioList();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  deleteScenario(id: number): void {
    this.scenarioService.deleteScenario(id).subscribe(
      (response) => {
        this.showResponse(response);
        this.getScenarioList();
      },
      (err: HttpApiErrorResponse) => this.showError(err)
    );
  }

  private showResponse(response: HttpResponse<string>): void {
    this.notificationService.success(response.statusText, `${response.status}`);
  }

  private showError(err: HttpApiErrorResponse): void {
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }
}
