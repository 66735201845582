<ng-container *ngIf="threshold">
  <mat-accordion class="kf-threshold-list" multi>
    <mat-expansion-panel *ngIf="threshold.isThresholdTileDefined && !threshold.isStatistic">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tile
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>grid_view</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-threshold-delta-detailed-entity *ngIf="threshold.isStatisticDetailed" [thresholdId]="threshold.id" [thresholdValues]="threshold.getTileBasedThresholdDetailedValues()" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-delta-detailed-entity>
      <app-threshold-failrate-detailed-entity *ngIf="threshold.isFormatContentDetailed" [thresholdId]="threshold.id" [thresholdValues]="threshold.getTileBasedThresholdDetailedValues()" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-failrate-detailed-entity>
      <app-threshold-failrate-delta-entity *ngIf="threshold.isStatistic || threshold.isFormatContent || threshold.isCombinedDetailedStatistic || threshold.isMapApproval" [resultType]="threshold.resultType" [thresholdId]="threshold.id" [thresholdValue]="threshold.getTileBasedThresholdValue()" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-failrate-delta-entity>
    </mat-expansion-panel>

    <ng-container *ngIf="threshold.isThresholdAreaDefined">
      <mat-expansion-panel *ngFor="let area of threshold.getAreas() | keyvalue">
        <mat-expansion-panel-header>
          <mat-panel-title>{{area.value}}</mat-panel-title>
          <mat-panel-description>
            <mat-icon>map</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-threshold-delta-detailed-entity *ngIf="threshold.isStatisticDetailed" [thresholdId]="threshold.id" [thresholdValues]="threshold.getThresholdDetailedValues(area.key)" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-delta-detailed-entity>
        <app-threshold-failrate-detailed-entity *ngIf="threshold.isFormatContentDetailed" [thresholdValues]="threshold.getThresholdDetailedValues(area.key)" [thresholdId]="threshold.id" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-failrate-detailed-entity>
        <app-threshold-failrate-delta-entity *ngIf="threshold.isStatistic || threshold.isFormatContent || threshold.isCombinedDetailedStatistic || threshold.isMapApproval" [resultType]="threshold.resultType" [thresholdId]="threshold.id" [thresholdValue]="threshold.getThresholdValue(area.key)" [thresholdValuesFormArray]="getThresholdValuesFormArray()"></app-threshold-failrate-delta-entity>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

</ng-container>
