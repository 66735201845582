import { Component, OnInit } from '@angular/core';
import { AuthService } from '@shared/service/auth.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectService } from '@shared/service/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    username: '',
    password: ''
  });
  authService: AuthService;

  constructor(
    authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private redirectService: RedirectService
  ) {
    this.authService = authService;
    this.authService.loggedInStatusUpdate.subscribe((value) => {
      if (value) {
        this.route.queryParams.subscribe(async (params) => {
          if (params.returnUrl) {
            await this.redirectService.navigate(params.returnUrl, {
              target: '_top'
            });
          } else {
            await this.router.navigate(['/']);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      if (params.logout) {
        this.authService.logout();
      }
      await this.router.navigate(['/dashboard']);
    });
  }

  onSubmit(): void {}
}
