import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarMenuComponent } from '@app/layout/component/common/toolbar-menu.component';

@Component({
  selector: 'app-main-toolbar-item',
  templateUrl: './main-toolbar-item.component.html',
  styleUrls: ['./main-toolbar-item.component.scss']
})
export class MainToolbarItemComponent extends ToolbarMenuComponent {
  constructor(router: Router) {
    super(router);
  }
}
