export const ROUTES = [
  {
    path: 'dashboard',
    id: 'nav-dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    children: null,
    privileges: ['GRAFANA_USER', 'GRAFANA_ADMIN']
  },
  {
    path: 'testcase-list',
    id: 'nav-tests-testcases',
    title: 'Test Cases',
    icon: 'rule',
    privileges: ['TESTCASES_LIST', 'TESTCASES_FETCH']
  },
  {
    path: 'areas',
    id: 'nav-areas',
    title: 'Areas',
    icon: 'map',
    privileges: ['AREAS_LIST', 'AREAS_CREATE', 'AREAS_UPDATE', 'AREAS_DELETE']
  },
  {
    path: 'tests',
    id: 'nav-tests',
    title: 'Test Execution',
    icon: 'settings',
    isExpanded: false,
    privileges: [
      'SCENARIOS_LIST',
      'SCENARIOS_FETCH',
      'TESTCASES_LIST',
      'TESTCASES_FETCH',
      'PROGRESS_LIST',
      'PROGRESS_FETCH',
      'AREAS_LIST',
      'AREAS_FETCH'
    ],
    children: [
      {
        path: 'tests/scenario-list',
        id: 'nav-tests-scenarios-list',
        title: 'Scenarios',
        icon: 'library_add_check',
        privileges: [
          'SCENARIOS_LIST',
          'SCENARIOS_FETCH',
          'SCENARIOS_CREATE',
          'SCENARIOS_UPDATE',
          'SCENARIOS_DELETE'
        ]
      },
      {
        path: 'tests/scenario-auto-start',
        id: 'nav-tests-scenario-auto-start',
        title: 'Automatic Scenario Execution',
        icon: 'event',
        privileges: ['SCENARIOS_START']
      },
      {
        path: 'tests/scenario-status',
        id: 'nav-tests-scenario-status',
        title: 'Scenario Status',
        icon: 'monitor',
        privileges: ['PROGRESS_LIST', 'PROGRESS_FETCH']
      }
    ]
  },
  {
    path: 'test-results',
    id: 'nav-testresults',
    title: 'Test Results',
    icon: 'insert_chart_outlined',
    children: null,
    privileges: ['DATASTORE_READ']
  },
  // {
  //   path: 'health',
  //   id: 'nav-health',
  //   title: 'Health',
  //   icon: 'medical_services',
  //   children: null
  // },
  {
    path: 'map-viewer',
    id: 'nav-status',
    title: 'Test Result Map',
    icon: 'location_on',
    children: null,
    privileges: ['MAP_VIEWER_ACCESS']
  },
  {
    path: 'thresholds',
    id: 'nav-thresholds',
    title: 'Thresholds',
    icon: 'notifications',
    children: null,
    privileges: ['DATASTORE_READ']
  },
  {
    path: 'status',
    id: 'nav-status',
    title: 'Status',
    icon: 'info',
    children: null,
    position: 'right',
    privileges: ['ACTIVE_USER']
  },
  {
    path: 'login',
    id: 'nav-logout',
    title: 'Logout',
    icon: 'logout',
    children: null,
    position: 'right',
    queryParams: { logout: 1 }
  }
];
