import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationNode } from '@shared/model/navigation';
import { NavigationService } from '@shared/service/navigation.service';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ]
})
export class SidebarItemComponent implements OnInit {
  @Input() item: NavigationNode;
  @Input() depth: number;
  @Input() hideSidebarTitles = true;
  @Output() sidenavClose = new EventEmitter();
  @HostBinding('attr.aria-expanded') ariaExpanded = false;

  expanded = false;

  constructor(
    private navigationService: NavigationService,
    public router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
    this.navigationService.currentUrl.subscribe((url: string) => {
      if (this.item.path && url) {
        this.expanded = this.isExpanded(url);
        this.ariaExpanded = this.expanded;
      }
    });
  }

  isExpanded(url: string): boolean {
    return (
      this.item.path &&
      this.item.children &&
      this.item.children.length &&
      url &&
      url.indexOf(`/${this.item.path}`) === 0
    );
  }

  async onItemSelected(item: NavigationNode) {
    if (!item.children || !item.children.length) {
      await this.router.navigate([item.path]);
      this.onSidenavClose();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  toggleMenu() {
    this.expanded = !this.expanded;
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };
}
