<div class="container-fluid">
  <mat-card appearance="outlined">
    <app-page-title></app-page-title>

    <mat-card-content>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          [src]="url"
          allowfullscreen
          title="Health - System Status"
        ></iframe>
      </div>
    </mat-card-content>
  </mat-card>
</div>
