<div *ngIf="scale" class="legend-container">

  <label *ngIf="resultType === RESULT_TYPE_FORMAT_CONTENT && visualizationFailRate()">Fail Rate (%)</label>
  <label *ngIf="resultType === RESULT_TYPE_COMBINED_DETAILED_STATISTIC && visualizationFailRate()">Fail Rate (%)</label>
  <label *ngIf="resultType === RESULT_TYPE_FORMAT_CONTENT_DETAILED && visualizationFailRate()">Fail Rate (%)</label>
  <label *ngIf="resultType === RESULT_TYPE_FORMAT_CONTENT && visualizationFailCount()">Fail Count</label>
  <label *ngIf="resultType === RESULT_TYPE_COMBINED_DETAILED_STATISTIC && visualizationFailCount()">Fail Count</label>
  <label *ngIf="resultType === RESULT_TYPE_FORMAT_CONTENT_DETAILED && visualizationFailCount()">Fail Count</label>
  <label *ngIf="resultType === RESULT_TYPE_STATISTIC">Statistic
    Values{{unit && unit !== 'none' ? ' (in ' + unit + ')' : ''}}</label>
  <label *ngIf="resultType === RESULT_TYPE_STATISTIC_DETAILED">Statistic
    Values{{unit && unit !== 'none' ? ' (in ' + unit + ')' : ''}}</label>
  <label *ngIf="layerName && layerName !== undefined"><strong>Data Version of the layer: {{layerName}}</strong></label>
  <app-value-range-bar *ngIf="scale"
    width="344"
    padding="20"
    [scale]="scale"
  ></app-value-range-bar>
</div>
