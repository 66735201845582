import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';
import { AutomatedExecutionDialogComponent } from '@shared/component/automated-execution-dialog/automated-execution-dialog.component';
import {
  ComplexTriggerJobObject,
  ComplexTriggerJobApiEntity,
  ComplexTriggerJobRequestObject
} from '@shared/model/productserver';
import { AuthService } from '@shared/service/auth.service';
import { ProductServerService } from '@shared/service/product-server.service';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  selector: 'app-trigger-job-list',
  templateUrl: './trigger-job-list.component.html',
  styleUrls: ['./trigger-job-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class TriggerJobListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'expandDetails',
    'type',
    'name',
    'lastExecutionTime',
    'nextTriggered',
    'lastModified',
    'active',
    'actions'
  ];
  data: MatTableDataSource<ComplexTriggerJobApiEntity>;

  isLoadingResults = false;

  resultsLength = 0;
  pageSizeOptions = environment.pageSizeOptions;
  pageSize = environment.pageSize;
  showFirstLastButtons = true;

  private dialogWidth = '800px';
  private subscription: Subscription = new Subscription();

  constructor(
    public productServerService: ProductServerService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadTriggerJobs();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadTriggerJobs(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.productServerService.getComplexTriggerJobs().subscribe(
        (triggerJobs: ComplexTriggerJobApiEntity[]) => {
          this.data = new MatTableDataSource<ComplexTriggerJobApiEntity>(
            triggerJobs
          );
          this.resultsLength = triggerJobs.length;
          this.data.sort = this.sort;
          this.data.paginator = this.paginator;
          this.isLoadingResults = false;
        },
        (error) => {
          this.isLoadingResults = false;
          console.error(error);
        }
      )
    );
  }

  createAutomatedExecution(): MatDialogRef<AutomatedExecutionDialogComponent> {
    const dialogRef = this.dialog.open(AutomatedExecutionDialogComponent, {
      width: this.dialogWidth,
      data: {}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadTriggerJobs();
    });
    return dialogRef;
  }

  editAutomatedExecution(element: ComplexTriggerJobApiEntity) {
    this.openAutomatedExecutionForm(ComplexTriggerJobObject.fromApi(element));
  }

  openAutomatedExecutionForm(
    element: ComplexTriggerJobObject | null
  ): MatDialogRef<AutomatedExecutionDialogComponent> {
    const dialogRef = this.dialog.open(AutomatedExecutionDialogComponent, {
      width: this.dialogWidth,
      data: {
        ...element
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadTriggerJobs();
    });
    return dialogRef;
  }

  confirmDeleteAutomatedExecution(
    triggerJob: ComplexTriggerJobApiEntity
  ): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      data: {
        title: `Delete Automated Execution`,
        message: `Are you sure to delete the automated execution "${triggerJob.name}" (ID: ${triggerJob.triggerJobId})`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.deleteAutomatedExecution(triggerJob);
      }
    });
    return dialogRef;
  }

  deleteAutomatedExecution(triggerJob: ComplexTriggerJobApiEntity): void {
    const { triggerJobId } = triggerJob;
    this.productServerService.deleteComplexTriggerJob(triggerJobId).subscribe(
      () => {
        this.loadTriggerJobs();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  toggleActiveAutomatedExecution(triggerJob: ComplexTriggerJobApiEntity): void {
    const { triggerJobId, active } = triggerJob;

    const complexTriggerJobRequestObject =
      ComplexTriggerJobRequestObject.fromForm(triggerJob);
    complexTriggerJobRequestObject.active = !active;
    this.productServerService
      .replaceComplexTriggerJob(triggerJobId, complexTriggerJobRequestObject)
      .subscribe(
        () => {
          this.loadTriggerJobs();
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
