import { Component, ViewChild } from '@angular/core';
import { MapViewerSelectAreaComponent } from '@modules/map-viewer/component/map-viewer-select-area/map-viewer-select-area.component';
import { MapViewerSelectVisualizationComponent } from '@modules/map-viewer/component/map-viewer-select-visualization/map-viewer-select-visualization.component';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';

@Component({
  selector: 'app-map-viewer-control',
  templateUrl: './map-viewer-control.component.html',
  styleUrls: ['./map-viewer-control.component.scss']
})
export class MapViewerControlComponent {
  @ViewChild(MapViewerSelectAreaComponent)
  private $mapViewerSelectAreaComponent: MapViewerSelectAreaComponent;

  @ViewChild(MapViewerSelectVisualizationComponent)
  private $mapViewerSelectVisualizationComponent: MapViewerSelectVisualizationComponent;

  constructor(
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  get mapViewerSelectAreaComponent() {
    return this.$mapViewerSelectAreaComponent;
  }

  get mapViewerSelectVisualizationComponent() {
    return this.$mapViewerSelectVisualizationComponent;
  }

  isAreaSelectionCompleted(): boolean {
    if (this.$mapViewerSelectAreaComponent) {
      return this.$mapViewerSelectAreaComponent.isCompleted();
    }
    return false;
  }

  isVisualisationSelectionCompleted(): boolean {
    if (this.$mapViewerSelectVisualizationComponent) {
      return this.$mapViewerSelectVisualizationComponent.isCompleted();
    }
    return false;
  }
}
