<ng-container *ngIf="triggerJobId; else elseCreateHeader">
  <h1 mat-dialog-title>Edit Automated Execution (ID {{triggerJobId}})</h1>
</ng-container>
<ng-template #elseCreateHeader>
  <h1 mat-dialog-title>Create Automated Execution</h1>
</ng-template>
<div *ngIf="isProcessing" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
<div class="mat-typography" mat-dialog-content>
  <div *ngIf="error?.error">
    <h2>Error!</h2>
    <p>Something went wrong:</p>
    <p *ngIf="error.error?.message" class="mat-error">{{error.error.message}}</p>
    <pre class="mat-small"><code>{{error.statusText}} ({{error.status}})</code></pre>
  </div>
  <app-automated-execution-form [hidden]="!isOpen" [complexTriggerJob]="triggerJob"></app-automated-execution-form>

  <div *ngIf="isDone">
    <h2>Finished!</h2>
    <p>The automated execution has been saved:</p>
    <pre class="mat-small"><code>{{response.statusText}} ({{response.status}})</code></pre>
  </div>
</div>
<div *ngIf="isOpen" mat-dialog-actions>
  <div [formGroup]="form">
    <mat-slide-toggle
      color="primary"
      formControlName="active"
    >
      Active
    </mat-slide-toggle>
    <button (click)="close()" mat-stroked-button>Cancel</button>
    <button
      (click)="save()"
      color="primary"
      id="triggerJobSaveButton"
      mat-raised-button
      [disabled]="automatedExecutionForm.invalid"
    >
      Save
    </button>
  </div>
</div>
<div *ngIf="isFail || isDone" mat-dialog-actions>
  <button (click)="close()" mat-stroked-button>Close</button>
</div>
