import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThresholdEntity } from '@shared/model/thresholds';

@Component({
  selector: 'app-threshold-entity',
  templateUrl: './threshold-entity.component.html',
  styleUrls: ['./threshold-entity.component.scss']
})
export class ThresholdEntityComponent implements OnInit {
  formData: FormGroup;
  private $_threshold: ThresholdEntity;

  constructor(private formBuilder: FormBuilder) {}

  get threshold(): ThresholdEntity {
    return this.$_threshold;
  }

  @Input() set threshold(threshold: ThresholdEntity) {
    if (threshold === null || threshold === undefined) {
      this.$_threshold = null;
    } else {
      this.$_threshold = threshold;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  getThresholdValuesFormArray(): FormArray {
    return this.formData.get('thresholdValues') as FormArray;
  }

  private initForm(): void {
    this.formData = this.formBuilder.group({
      testCaseId: [this.$_threshold.testCaseId, Validators.required],
      testCaseVersion: [this.$_threshold.testCaseVersion],
      cluster: [this.$_threshold.cluster, Validators.required],
      thresholdVersion: [this.$_threshold.thresholdVersion],
      comments: [this.$_threshold.comments],
      active: [this.$_threshold.active],
      lastChange: [this.$_threshold.lastChange],
      testCaseName: [this.$_threshold.testCaseName],
      resultType: [this.$_threshold.resultType],
      thresholdValues: this.formBuilder.array([])
    });
  }
}
