import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioService } from '@modules/test-execution/service/scenario.service';
import { RunParametersFormComponent } from '@shared/component/scenario/run-parameters-form/run-parameters-form.component';
import {
  DetailedScenarioProgressApiEntity,
  ScenarioApiEntity,
  ScenarioRunParameters,
  StartScenarioRequestObject
} from '@shared/model/productserver';
import {
  HttpApiErrorResponse,
  ResponseObject,
  SpringBootApiError
} from '@shared/model/response';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export enum StartScenarioProgress {
  OPEN = 'OPEN',
  STARTING = 'STARTING',
  FAIL = 'FAIL',
  OK = 'OK'
}

@Component({
  selector: 'app-scenario-start-dialog',
  templateUrl: './scenario-start-dialog.component.html',
  styleUrls: ['./scenario-start-dialog.component.scss']
})
export class ScenarioStartDialogComponent implements OnInit, OnDestroy {
  @ViewChild(RunParametersFormComponent)
  runParametersForm: RunParametersFormComponent;

  isLoadingScenarioDetails = false;
  isLoadingScenario = false;

  runId: number;
  scenarioId: number;

  public form: FormGroup;
  public scenario: ScenarioApiEntity;
  public scenarioProgressDetails: DetailedScenarioProgressApiEntity;

  public progress: StartScenarioProgress = StartScenarioProgress.OPEN;
  public numProcesses: number = null;

  public requests: Map<string, StartScenarioRequestObject> = new Map<
    string,
    StartScenarioRequestObject
  >();
  public responses: Map<string, any> = new Map<string, any>();
  public errors: Map<string, any> = new Map<string, any>();

  private subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ScenarioStartDialogComponent>,
    private scenarioService: ScenarioService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) { scenario, runId, scenarioId }: any
  ) {
    this.scenario = scenario;

    this.runId = runId;
    if (this.runId) {
      this.isLoadingScenarioDetails = true;
      this.loadScenarioDetailsByRunId(this.runId);
    }

    this.scenarioId = scenarioId;
    if (this.scenarioId) {
      this.isLoadingScenario = true;
      this.loadScenarioByScenarioId(this.scenarioId);
    }
  }

  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  get processIds(): string[] {
    return [...this.requests.keys()];
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createFormGroup(): FormGroup {
    this.form = this.formBuilder.group({
      priority: [0, [Validators.required]]
    });
    return this.form;
  }

  getFormValues(): StartScenarioRequestObject {
    const { priority } = this.form.getRawValue();
    return {
      priority
    } as StartScenarioRequestObject;
  }

  submitStartScenario(): void {
    if (this.runParametersForm.invalid) {
      return;
    }

    const scenarioRunParameters: ScenarioRunParameters =
      this.runParametersForm.getFormValues();
    const priority = +this.form.get('priority').value;
    if (scenarioRunParameters.tileIds?.length) {
      this.requests.set(uuidv4(), {
        tileIds: scenarioRunParameters.tileIds,
        areaIds: scenarioRunParameters.areaIds,
        mapCatalogInfos: scenarioRunParameters.mapCatalogInfos,
        priority,
        scenarioId: this.scenario.id
      });
    }
    if (scenarioRunParameters.areaIds?.length) {
      for (const areaId of scenarioRunParameters.areaIds) {
        this.requests.set(uuidv4(), {
          tileIds: [],
          areaIds: [areaId],
          mapCatalogInfos: scenarioRunParameters.mapCatalogInfos,
          priority,
          scenarioId: this.scenario.id
        });
      }
    }
    if (scenarioRunParameters.routeIds?.length) {
      this.requests.set(uuidv4(), {
        tileIds: [],
        areaIds: [],
        routeIds: scenarioRunParameters.routeIds,
        mapCatalogInfos: scenarioRunParameters.mapCatalogInfos,
        priority,
        scenarioId: this.scenario.id
      });
    }

    this.progress = StartScenarioProgress.STARTING;
    this.numProcesses = 0;
    for (const processId of this.processIds) {
      this.startProcess();
      this.subscription.add(
        this.scenarioService
          .startScenario(this.requests.get(processId))
          .subscribe(
            (response) => {
              this.responses.set(processId, response);
              this.stopProcess();
            },
            (error: HttpApiErrorResponse) => {
              console.error(error);
              this.errors.set(processId, error);
              this.stopProcess();
            }
          )
      );
    }
  }

  loadScenarioDetailsByRunId(runId: number) {
    this.isLoadingScenarioDetails = true;
    this.isLoadingScenario = true;
    this.subscription.add(
      this.scenarioService.getScenarioProgress(runId).subscribe(
        (response: ResponseObject<DetailedScenarioProgressApiEntity>) => {
          this.scenarioProgressDetails = response.data;
          this.isLoadingScenarioDetails = false;
        },
        (error: HttpApiErrorResponse) => {
          console.error(error.error);
          this.isLoadingScenarioDetails = false;
        }
      )
    );
  }

  loadScenarioByScenarioId(scenarioId: number) {
    this.isLoadingScenario = true;
    this.subscription.add(
      this.scenarioService.getScenario(scenarioId).subscribe(
        (t) => {
          this.scenario = t.data;
          this.isLoadingScenario = false;
        },
        (error: HttpApiErrorResponse) => {
          console.error(error.error);
          this.isLoadingScenario = false;
        }
      )
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  disableButton(): boolean {
    return this.runParametersForm?.invalid || false;
  }

  isOpen(): boolean {
    return this.progress === StartScenarioProgress.OPEN;
  }

  isRunning(): boolean {
    return (
      this.progress === StartScenarioProgress.STARTING && this.numProcesses > 0
    );
  }

  isFinished(): boolean {
    return (
      this.progress !== StartScenarioProgress.OPEN && this.numProcesses === 0
    );
  }

  getRequest(processId: string): StartScenarioRequestObject {
    return this.requests.get(processId);
  }

  getResponse(processId: string): HttpResponse<any> {
    return this.responses.get(processId);
  }

  getError(processId: string): SpringBootApiError {
    return this.errors.get(processId)?.error;
  }

  isStarting(processId: string): boolean {
    return (
      this.progress === StartScenarioProgress.STARTING &&
      !this.isSuccess(processId) &&
      !this.isError(processId)
    );
  }

  isSuccess(processId: string): boolean {
    return !this.isError(processId) && this.responses.has(processId);
  }

  isError(processId: string): boolean {
    return this.errors.has(processId);
  }

  private startProcess() {
    this.numProcesses++;
  }

  private stopProcess() {
    this.numProcesses--;
    if (this.numProcesses === 0) {
      this.progress = StartScenarioProgress.OK;
    }
  }
}
