import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { SettingsService } from '@shared/service/settings.service';
import { ActuatorHealthResponse } from '@app/shared/model/actuator';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  public executionNodeHealthUrl: string;
  public productServerHealthUrl: string;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.executionNodeHealthUrl = settingsService.getExecutionNodeUrl(
      environment.executionNodeHealthUrl
    );
    this.productServerHealthUrl = settingsService.getProductServerUrl(
      environment.productServerHealthUrl
    );
  }

  getExecutionNodeStatus(): Observable<ActuatorHealthResponse> {
    return this.httpClient.get<ActuatorHealthResponse>(
      this.executionNodeHealthUrl
    );
  }

  getStartScenarioNodeStatus(): Observable<ActuatorHealthResponse> {
    return this.httpClient.get<ActuatorHealthResponse>(
      this.productServerHealthUrl
    );
  }
}
