import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env';
import { KeycloakService } from 'keycloak-angular';

/**
 * Handles auth with the Kingfisher backend.
 */
@Injectable()
export class AuthService {
  pendingUserSource = new BehaviorSubject<string>('');
  pendingUserMessage = this.pendingUserSource.asObservable();

  pendingUserGrafanaSource = new BehaviorSubject<boolean>(true);
  pendingUserGrafanaStatus = this.pendingUserGrafanaSource.asObservable();

  /** Allows subscription on auth events */
  public loggedInStatusUpdate: EventEmitter<boolean> = new EventEmitter();

  /** Contains the raw token as string */
  private bearerToken: string;

  /** Contains the parsed token */
  private kfToken: KingfisherJwtToken;

  /** In case of errors contains error messages */
  private errorMessage: string;

  /**
   * Constructs the service
   *
   * @param keycloakService Service for Keycloak utilization
   */
  constructor(private keycloakService: KeycloakService) {}

  /**
   * true if logged in, false if not
   */
  get loggedIn(): boolean {
    return this.bearerToken !== null && !this.tokenExpired;
  }

  /** returns the token as string */
  get token(): string {
    return this.bearerToken;
  }

  /** gets the username from the token */
  get username(): string {
    if (this.kfToken === null || this.kfToken === undefined) {
      return null;
    }
    return this.kfToken.preferred_username;
  }

  /**
   * Token expiration date
   */
  async getTokenExpiration(): Promise<Date> {
    const token = await this.keycloakService.getToken();
    if (token) {
      const tokenPayload = this.parseTokenPayload(token);
      if (tokenPayload?.exp) {
        return new Date(tokenPayload.exp * 1000);
      }
    }
    return null;
  }

  /** true if token is expired, false if not */
  async tokenExpired(): Promise<boolean> {
    const expirationDate = await this.getTokenExpiration();
    if (expirationDate) {
      return Date.now() >= expirationDate.getTime();
    }
    return true;
  }

  /** removes the token from local storage */
  public logout(): void {
    this.bearerToken = null;
    this.kfToken = null;
    this.loggedInStatusUpdate.emit(false);
    this.keycloakService
      .logout(environment.keycloakGrafanaLogoutRedirectUri)
      .then((success) => {
        console.log('--> log: logout success ', success);
      })
      .catch((error) => {
        console.error('--> log: logout error ', error);
      });
  }

  /** true if privilege is available false if not */
  public hasPrivilege(privilege: string): boolean {
    if (this.kfToken) {
      if (privilege === 'ACTIVE_USER') {
        return this.hasAnyPrivileges();
      }
      return this.kfToken.privileges.includes(privilege);
    }
    return false;
  }

  public hasPrivileges(privileges: string[]): boolean {
    if (privileges) {
      return privileges.some((privilege) => this.hasPrivilege(privilege));
    }
    return true;
  }

  public hasAnyPrivileges(): boolean {
    return this.kfToken?.privileges?.length > 0;
  }

  public validateAndSaveToken(token: string): boolean {
    try {
      this.kfToken = this.decodeToken(token);
      this.bearerToken = token;
      this.loggedInStatusUpdate.emit(true);
      return true;
    } catch (e) {
      this.kfToken = null;
      this.bearerToken = null;
      this.loggedInStatusUpdate.emit(false);
      return false;
    }
  }

  public decodeToken(token: string): KingfisherJwtToken {
    const token_payload = token.split('.')[1];
    const payload_decoded = window.atob(token_payload);
    this.noPrivilegesError(payload_decoded);
    return KingfisherJwtToken.fromJson(payload_decoded);
  }

  private parseTokenPayload(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  private noPrivilegesError(tokenJsonString: string) {
    const { privileges = undefined } = JSON.parse(tokenJsonString);
    if (privileges === undefined) {
      const pendingMessage =
        'Welcome to Kingfisher! \n' +
        'Your Account has been created, but Role-Assignment still needs to take place. \n' +
        'Please be patient.';
      this.pendingUserSource.next(pendingMessage);
      this.pendingUserGrafanaSource.next(false);
    }
  }
}

/**
 * Class representing the kingfisher JWT token.
 */
export class KingfisherJwtToken {
  public privileges: string[];
  public preferred_username: string;
  public iss: string;
  public iat: number;
  public exp: number;

  /**
   * List of privileges
   */
  get privilegeList(): string[] {
    return this.privileges;
  }

  /**
   * Username from JWT token
   */
  get username(): string {
    return this.preferred_username;
  }

  /**
   * Token issuer determining the environment
   */
  get issuer(): string {
    return this.iss;
  }

  /**
   * Token issue date
   */
  get issuedAt(): number {
    return this.iat;
  }

  /**
   * Token expiration date
   */
  get expiration(): number {
    return this.exp;
  }

  public static fromJson(tokenJsonString: string): KingfisherJwtToken {
    const {
      privileges = [],
      preferred_username = undefined,
      iss = undefined,
      iat = undefined,
      exp = undefined
    } = JSON.parse(tokenJsonString);
    const kingfisherJwtToken = new KingfisherJwtToken();
    kingfisherJwtToken.privileges = privileges;
    kingfisherJwtToken.preferred_username = preferred_username;
    kingfisherJwtToken.iss = iss;
    kingfisherJwtToken.iat = iat;
    kingfisherJwtToken.exp = exp;
    return kingfisherJwtToken;
  }

  /**
   * Checks for privilege.
   *
   * @param privilege The privilege
   */
  public hasPrivilege(privilege: string): boolean {
    return this.privileges.includes(privilege);
  }
}

/**
 * Kingfisher login response
 */
class KingfisherLoginResponse {
  token: string;
  expiration: Date;
  error?: string;
}
