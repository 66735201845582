import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationNode } from '@shared/model/navigation';
import { NavigationService } from '@shared/service/navigation.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent {
  @Output() sidenavToggle = new EventEmitter();
  @Output() subMenuClose = new EventEmitter();

  eventsSubject: Subject<void> = new Subject<void>();

  public color: string;
  public currentRoute: string;
  public hideMainMenu = false;

  constructor(
    private $router: Router,
    private $navigationService: NavigationService,
    private responsive: BreakpointObserver
  ) {
    this.currentRoute = this.$router.url;
    this.registerResponsiveHandler();
  }

  get mainNavigation(): Array<NavigationNode> {
    return this.$navigationService.filterNavigation(
      this.$navigationService.menuItems
    );
  }

  public onSubmenuClose = () => {
    this.eventsSubject.next();
  };

  public emitSidenavToggle = () => {
    this.sidenavToggle.emit();
  };

  private registerResponsiveHandler(): void {
    this.responsive
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        this.hideMainMenu = false;
        if (result.matches) {
          this.hideMainMenu = true;
        }
      });
  }
}
