import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';
import { of as observableOf, Subscription } from 'rxjs';
import {
  AreasApiEntity,
  AreasListApiEntity
} from '@shared/model/productserver';
import { AreaCacheService } from '@shared/service/area-cache.service';

@Component({
  selector: 'app-map-viewer-select-area',
  templateUrl: './map-viewer-select-area.component.html',
  styleUrls: ['./map-viewer-select-area.component.scss']
})
export class MapViewerSelectAreaComponent implements OnInit, OnDestroy {
  isLoadingResults = false;
  private $subscription: Subscription = new Subscription();
  private $areasList: AreasListApiEntity[] = [];

  constructor(
    private areaCacheService: AreaCacheService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get areaId(): number {
    return this.mapViewerUserSelectionService.areaId;
  }

  get areasList(): AreasListApiEntity[] {
    return this.$areasList;
  }

  set areasList(areasList: AreasListApiEntity[]) {
    this.$areasList = areasList || [];
  }

  ngOnInit(): void {
    this.getAreasList();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  getAreasList(): void {
    this.areasList = [];
    this.isLoadingResults = true;
    this.$subscription.add(
      this.areaCacheService.allAreas.subscribe(
        (response) => {
          this.isLoadingResults = false;
          this.areasList = response;
        },
        (err) => {
          this.isLoadingResults = false;
          console.error(err);
          return observableOf([]);
        }
      )
    );
  }

  selectArea(value: number): void {
    this.mapViewerUserSelectionService.area = null;
    this.areaCacheService
      .getSingleArea(value)
      .subscribe((area: AreasApiEntity) => {
        this.mapViewerUserSelectionService.area = area;
      });
  }

  isCompleted(): boolean {
    return !!this.areaId;
  }
}
