<ng-container *ngIf="testCaseResult && testCaseDeltaResult">
  <table class="kf-result-single-view" aria-label="Result Details">
    <colgroup>
      <col span="1" style="width:30%;">
      <col span="1" style="width:35%;">
      <col span="1" style="width:35%;">
    </colgroup>
    <tbody>
    <tr>
      <th scope="row">Test</th>
      <td matTooltip="{{testCaseId|testCaseDetails:'description'}}"
          colspan="2">{{testCaseId}}
        - {{testCaseId|testCaseDetails:'name'}}</td>
    </tr>
    <tr>
      <th scope="col">&nbsp;</th>
      <th scope="col">Main Catalog</th>
      <th scope="col">Delta Catalog</th>

    </tr>
    <tr>
      <th scope="row">Outcome</th>
      <td>{{resultOutcome}}</td>
      <td>{{comparisonResultOutcome}}</td>
    </tr>
    <tr>
      <th scope="row">Total Count</th>
      <td>{{totalCount}}</td>
      <td>{{comparisonTotalCount}}</td>
    </tr>
    <tr>
      <th scope="row">Fail Count</th>
      <td>{{failCount}}</td>
      <td>{{comparisonFailCount}}</td>
    </tr>
    <tr *ngIf="hasFailRate">
      <th scope="row">Fail Rate</th>
      <td>{{failRate|number:"1.0-3":"de"}}%</td>
      <td>{{comparisonFailRate|number:"1.0-3":"de"}}%</td>
    </tr>
    <tr>
      <th scope="row">Delta Fail Rate</th>
      <td>{{comparisonFailRate - failRate|number:"1.0-3":"de"}}%</td>
    </tr>
    <tr>
      <th scope="row">Delta Fail Count</th>
      <td>{{deltaValue}}</td>
    </tr>
    <tr>
      <th scope="row">Counted Feature</th>
      <td>{{countFeature}}</td>
      <td>{{comparisonCountFeature}}</td>
    </tr>
    </tbody>
  </table>
</ng-container>
