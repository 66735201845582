

<label class="kf-label">Select Test Case</label>

<div class="kf-form-testCase-select">
  <mat-form-field class="kf-form-testcase-input" appearance="fill" color="accent" floatLabel="always">
    <mat-label>TestCase *</mat-label>
    <input
      id="testCaseSelect"
      #testCaseInput="matInput"
      [formControl]="testCaseFormControl"
      [matAutocomplete]="testCaseAutocomplete"
      (change)="onTestCaseChangeEvent($event)"
      matInput
      placeholder="eg. 01.02.03.04"
      type="text"
    >
    <span *ngIf="isLoadingResults" matSuffix [class.spinner]="isLoadingResults"></span>
    <button *ngIf="!isLoadingResults" (click)="selectTestCase(null)" id="cancelTestCase" aria-label="clear" color="accent" mat-icon-button
            matSuffix>
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="isTestCaseDisabled" align="start">Please select catalog and version first!</mat-hint>
    <mat-autocomplete
      #testCaseAutocomplete="matAutocomplete"
      (optionSelected)="selectTestCase($event)"
      panelWidth="auto"
    >
      <mat-option
        *ngFor="let testCase of autoCompleteTestCases | async"
        [value]="testCase.testCaseId"
      >
        {{testCase.testCaseId}} - {{testCase.testCaseName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="kf-form-testcase-submit">
    <button (click)="submitTestCaseSelect()" id="submitTestCase" aria-label="Search" [color]="testCaseChanged() ? 'primary' : 'accent'" [disabled]="!testCaseChanged()" mat-icon-button
            matSuffix>
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>

