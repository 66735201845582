import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { AbstractThresholdDetailedDefinitionComponent } from '@modules/thresholds/component/shared/abstract-threshold-detailed-definition.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ThresholdFormEventService } from '@modules/thresholds/service/threshold-form-event.service';
import { ErrorStateMatcher } from '@angular/material/core';

class ThresholdValueErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-threshold-delta-detailed-entity',
  templateUrl: './threshold-delta-detailed-entity.component.html',
  styleUrls: ['../shared/shared.scss']
})
export class ThresholdDeltaDetailedEntityComponent extends AbstractThresholdDetailedDefinitionComponent {
  matcher = new ThresholdValueErrorStateMatcher();

  constructor(
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected thresholdFormEventService: ThresholdFormEventService
  ) {
    super(formBuilder, dialog, thresholdFormEventService);
  }

  protected buildForm(): FormGroup {
    const formGroup = super.buildForm();
    formGroup.addControl(
      'thresholdDeltaAllFrcRelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc0RelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc1RelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc2RelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc3RelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc4RelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaAllFrcRelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc0RelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc1RelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc2RelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc3RelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc4RelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaAllFrcAbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc0AbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc1AbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc2AbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc3AbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc4AbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaAllFrcAbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc0AbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc1AbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc2AbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc3AbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl(
      'thresholdDeltaFrc4AbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    formGroup.addControl('areaName', this.formBuilder.control(''));
    return formGroup;
  }
}
