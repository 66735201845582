import { Component, ViewChild } from '@angular/core';
import { MapViewerDeltaOutcomeFilterComponent } from '@modules/map-viewer/component/map-viewer-delta-outcome-filter/map-viewer-delta-outcome-filter.component';
import { MapViewerLegendComponent } from '@modules/map-viewer/component/map-viewer-legend/map-viewer-legend.component';
import { MapViewerResultOutcomeFilterComponent } from '@modules/map-viewer/component/map-viewer-result-outcome-filter/map-viewer-result-outcome-filter.component';

@Component({
  selector: 'app-map-viewer-result-filter',
  templateUrl: './map-viewer-result-filter.component.html',
  styleUrls: ['./map-viewer-result-filter.component.scss']
})
export class MapViewerResultFilterComponent {
  @ViewChild(MapViewerResultOutcomeFilterComponent)
  private $mapViewerResultOutcomeFilter: MapViewerResultOutcomeFilterComponent;

  @ViewChild(MapViewerDeltaOutcomeFilterComponent)
  private $mapViewerDeltaOutcomeFilter: MapViewerDeltaOutcomeFilterComponent;

  @ViewChild(MapViewerLegendComponent)
  private $mapViewerLegendComponent: MapViewerLegendComponent;
}
