import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum ThresholdFormEvent {
  THRESHOLD_TRIGGER_EDIT = 'THRESHOLD_TRIGGER_EDIT',
  THRESHOLD_TRIGGER_DELETE = 'THRESHOLD_TRIGGER_DELETE'
}

export class ThresholdFormEmitEvent {
  event: ThresholdFormEvent;
  value?: any;

  constructor(event: ThresholdFormEvent, value?: any) {
    this.event = event;
    this.value = value;
  }
}

export class ThresholdValuePayload {
  key: any;
  thresholdValues: any;
}

@Injectable({
  providedIn: 'root'
})
export class ThresholdFormEventService {
  private subject$ = new Subject<ThresholdFormEmitEvent>();

  emit(event: ThresholdFormEmitEvent) {
    this.subject$.next(event);
  }

  on(event: ThresholdFormEvent, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: ThresholdFormEmitEvent) => e.event === event),
        map((e: ThresholdFormEmitEvent) => e.value)
      )
      .subscribe(action);
  }
}
