import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacyTextOnlySnackBar as TextOnlySnackBar
} from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AreasService } from '@modules/areas/service/areas.service';
import Util from '@shared/helper/util';
import {
  CatalogInfoVisualization,
  ResultDataApiEntity,
  ResultFilter,
  ResultOutcome
} from '@shared/model/datastore';
import { AreasListApiEntity } from '@shared/model/productserver';
import { HttpApiErrorResponse, ResponsePage } from '@shared/model/response';
import { DatastoreService } from '@shared/service/datastore.service';
import { saveAs } from 'file-saver';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env';
import JSONbig from 'json-bigint';

@Component({ template: '' })
export abstract class TestResultsListComponent implements OnInit, OnDestroy {
  @Input() catalogsInfo: CatalogInfoVisualization[];
  isLoadingResults = false;
  isLoadingFilters = false;
  data: MatTableDataSource<ResultDataApiEntity>;
  areasList: Array<AreasListApiEntity> = [];
  filter: ResultFilter = {
    testCaseIds: [],
    tileIds: [],
    areaIds: [],
    resultOutcomes: []
  };
  activeTileFilter = 'areas';
  resultsLength = 0;
  length = 0;
  pageSize = environment.pageSize;
  pageIndex = 0;
  totalPages = 0;
  showFirstLastButtons = true;
  pageSizeOptions = environment.pageSizeOptions;

  protected datastoreService: DatastoreService;
  protected snackBar: MatSnackBar;
  protected cdr: ChangeDetectorRef;
  protected route: ActivatedRoute;
  protected router: Router;
  protected areasService: AreasService;
  protected subscription: Subscription = new Subscription();

  protected constructor(
    datastoreService: DatastoreService,
    snackBar: MatSnackBar,
    cdr: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router,
    areasService: AreasService
  ) {
    this.datastoreService = datastoreService;
    this.snackBar = snackBar;
    this.cdr = cdr;
    this.route = route;
    this.router = router;
    this.areasService = areasService;
  }

  public get resultOutcomeOptions(): Array<string> {
    return Object.keys(ResultOutcome);
  }

  ngOnInit(): void {
    this.filter.resultOutcomes = this.resultOutcomeOptions as ResultOutcome[];
    this.cdr.detectChanges();
    this.parseQueryString();
    this.getAreasList();
    this.getTestCaseResults();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handlePageEvent(event: PageEvent): void {
    const sizeSwitched = this.pageSize !== event.pageSize;
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = sizeSwitched ? 0 : event.pageIndex;
    this.updateQueryString();
    this.getTestCaseResults();
  }

  jumpToPage(event): void {
    this.pageIndex = event.value;
    this.updateQueryString();
    this.getTestCaseResults();
  }

  getPagesArray(): number[] {
    return this.totalPages > 0
      ? Array(this.totalPages)
          .fill(0)
          .map((_x, i) => i)
      : [];
  }

  toggleTileFilter(event): void {
    if (event.value === 'tileIds') {
      this.filter.areaIds = [];
      this.updateQueryString();
    }
    if (event.value === 'areas') {
      this.filter.tileIds = [];
      this.updateQueryString();
    }
  }

  submitResetFilter(): void {
    this.filter.testCaseIds = [];
    this.filter.tileIds = [];
    this.filter.areaIds = [];
    this.filter.resultOutcomes = this.resultOutcomeOptions as ResultOutcome[];
    this.activeTileFilter = 'areas';
    this.pageIndex = 0;
    this.pageSize = environment.pageSize;
    this.data = null;
    this.updateQueryString();
  }

  setResult(testcaseArray: ResultDataApiEntity[]): void {
    this.data = new MatTableDataSource(testcaseArray);
  }

  getTestCaseResults(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.callTestCaseResults()
        .pipe(
          map((response) => {
            const { data, pagination } = response;
            this.isLoadingResults = false;
            this.totalPages = pagination.totalPages;
            this.length = pagination.totalItems;
            this.pageSize = pagination.pageSize;
            this.pageIndex = pagination.currentPage - 1;
            this.resultsLength = response.size;
            return data;
          }),
          catchError((err: HttpApiErrorResponse) => {
            console.error(err.error);
            this.isLoadingResults = false;
            this.showError(
              `${err.error.message} (${err.error.error})`,
              `${err.status}`
            );
            return observableOf([]);
          })
        )
        .subscribe(
          // testcaseArray is the response.data which is received from the rest call
          (testcaseArray) => {
            this.setResult(testcaseArray);
          }
        )
    );
  }

  handleFormValues(): void {
    if (typeof this.filter.testCaseIds === 'string') {
      this.filter.testCaseIds = Util.valueStringToArray(
        String(this.filter.testCaseIds)
      );
    }
    if (typeof this.filter.tileIds === 'string') {
      this.filter.tileIds = Util.valueStringToArray(String(this.filter.tileIds))
        .map((val: string) => parseInt(val.trim(), 10))
        .filter((val: any) => !Number.isNaN(val));
    }
    this.updateQueryString();
  }

  submitUpdateFilter(): void {
    this.handleFormValues();
    this.getTestCaseResults();
  }

  parseQueryString(): void {
    this.setFilter(this.getFilterFromQueryString());
    const { page, size } = this.getPagingFromQueryString();
    this.pageIndex = page - 1;
    this.pageSize = size;
    this.activeTileFilter = this.filter.tileIds.length ? 'tileIds' : 'areas';
  }

  setFilter(filter: ResultFilter) {
    this.filter = filter;
  }

  getFilter(): ResultFilter {
    const {
      tileIds = [],
      testCaseIds = [],
      areaIds = [],
      resultOutcomes = []
    } = this.filter;

    const resultFilter: ResultFilter = {};
    if (tileIds && tileIds.length) {
      resultFilter.tileIds = tileIds;
    }
    if (testCaseIds && testCaseIds.length) {
      resultFilter.testCaseIds = testCaseIds;
    }
    if (areaIds && areaIds.length) {
      resultFilter.areaIds = areaIds;
    }
    if (resultOutcomes && resultOutcomes.length) {
      resultFilter.resultOutcomes = resultOutcomes;
    }
    return resultFilter;
  }

  getQueryParams(): Params {
    const filter = this.getFilter();
    const { view } = this.route.snapshot.queryParams;
    return {
      view,
      ...filter,
      page: this.pageIndex + 1,
      size: this.pageSize
    };
  }

  updateQueryString(): void {
    const queryParams: Params = this.getQueryParams();
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams
      })
      .then(() => {
        // Do nothing
      });
  }

  getAreasList(): void {
    this.isLoadingFilters = true;
    this.subscription.add(
      this.areasService.getAreasList().subscribe(
        (response) => {
          this.isLoadingFilters = false;
          this.areasList = response.data;
        },
        () => {
          this.isLoadingFilters = false;
          return observableOf([]);
        }
      )
    );
  }

  showError(
    message: string = '',
    action: string = ''
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, { panelClass: ['error'] });
  }

  hasResultDetails(element: ResultDataApiEntity): boolean {
    return !!element.resultDetails;
  }

  downloadResultDetails(element: ResultDataApiEntity): void {
    const { resultDetails } = element;
    const jsonData = new Blob([JSONbig.stringify(resultDetails)], {
      type: 'text/json;charset=UTF-8'
    });
    saveAs(
      jsonData,
      `${element.testCaseId}-${element.tileId}-${element.catalogName}-${element.catalogVersion}.json`
    );
  }

  getFilterFromQueryString(): ResultFilter {
    const { testCaseIds, tileIds, areaIds, resultOutcomes } =
      this.route.snapshot.queryParams;
    return {
      testCaseIds: this.toArrayString(testCaseIds),
      tileIds: this.toArrayNumber(tileIds),
      areaIds: this.toArrayNumber(areaIds),
      resultOutcomes: (resultOutcomes
        ? this.toArrayString(resultOutcomes)
        : this.resultOutcomeOptions) as ResultOutcome[]
    };
  }

  getPagingFromQueryString(): any {
    const { page, size } = this.route.snapshot.queryParams;
    return {
      page: page || 1,
      size: size || environment.pageSize
    };
  }

  prepareUrl(resultDetails: ResultDataApiEntity): Params {
    const queryParams: any = {};
    queryParams.resultOutcomes = JSON.stringify(this.filter.resultOutcomes);
    queryParams.areaId = this.route.snapshot.queryParams.areaIds;
    const {
      catalogName,
      catalogVersion,
      testCaseId,
      tileId,
      resultType,
      comparisonCatalogName,
      comparisonCatalogVersion
    } = resultDetails;
    queryParams.catalog = catalogName;
    queryParams.catalogVersion = catalogVersion;
    queryParams.testCaseId = testCaseId;
    queryParams.comparisonCatalog = comparisonCatalogName;
    queryParams.comparisonCatalogVersion = comparisonCatalogVersion;
    queryParams.tileId = tileId;
    queryParams.resultType = resultType;
    this.addMapDataType(catalogName, queryParams);
    return queryParams;
  }
  addMapDataType(catalogName: string, queryParams: Params) {
    if (this.catalogsInfo) {
      const catalogInfo = this.catalogsInfo.find(
        (catalogInfoVisualization) =>
          catalogInfoVisualization.name === catalogName
      );
      queryParams.mapDataType = catalogInfo ? catalogInfo.mapDataType : null;
    }
  }
  private toArrayNumber(input: any, split: string = ','): number[] {
    let resultArray;
    if (typeof input === 'object') {
      resultArray = Object.values(input);
    } else {
      resultArray = String(input).split(split);
    }
    return resultArray
      .map((value) => String(value))
      .map((value) => value.trim())
      .map((val: string) => parseInt(String(val).trim(), 10))
      .filter((val: any) => !Number.isNaN(val));
  }

  private toArrayString(input: any, split: string = ','): string[] {
    let resultArray;
    if (typeof input === 'object') {
      resultArray = Object.values(input);
    } else {
      resultArray = String(input || '').split(split);
    }
    return resultArray
      .map((value) => String(value))
      .map((value) => value.trim())
      .filter((value) => value !== '');
  }
  abstract callTestCaseResults(): Observable<ResponsePage<ResultDataApiEntity>>;
}
