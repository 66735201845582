import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public sidebarImageIndex = 0;
  public sidebarImageIndexUpdate: EventEmitter<number> = new EventEmitter();
  public sidebarFilter = '#fff';
  public sidebarFilterUpdate: EventEmitter<string> = new EventEmitter();
  public sidebarColor = '#fff';
  public sidebarColorUpdate: EventEmitter<string> = new EventEmitter();
  public grafanaUrl = '';
  public adminUrl = '';
  public productServerUrl = '';
  public executionNodeUrl = '';
  public dataStoreUrl = '';

  constructor() {
    this.grafanaUrl = environment.grafanaUrl;
    this.adminUrl = environment.adminUrl;
    this.productServerUrl = environment.productServerUrl;
    this.executionNodeUrl = environment.executionNodeUrl;
    this.dataStoreUrl = environment.dataStoreUrl;
  }

  /**
   * Gets the grafana URL with an optional suffix.
   */
  getGrafanaUrl(suffix?: string): string {
    if (suffix) {
      return this.grafanaUrl + suffix;
    } else {
      return this.grafanaUrl;
    }
  }

  /**
   * Gets the Admin URL with an optional suffix.
   */
  getAdminUrl(suffix?: string): string {
    if (suffix) {
      return this.adminUrl + suffix;
    } else {
      return this.adminUrl;
    }
  }

  /**
   * Gets the Execution Node URL with an optional suffix.
   */
  getExecutionNodeUrl(suffix?: string): string {
    if (suffix) {
      return this.executionNodeUrl + suffix;
    } else {
      return this.executionNodeUrl;
    }
  }

  /**
   * Gets the Product Server URL with an optional suffix.
   */
  getProductServerUrl(suffix?: string): string {
    if (suffix) {
      return this.productServerUrl + suffix;
    } else {
      return this.productServerUrl;
    }
  }

  /**
   * Gets the Data Store URL with an optional suffix.
   */
  getDataStoreUrl(suffix?: string): string {
    if (suffix) {
      return this.dataStoreUrl + suffix;
    } else {
      return this.dataStoreUrl;
    }
  }
}
