import {
  AttributionStatus,
  ControlledAccess,
  ResultType
} from '@shared/model/datastore';
import { InvalidResultTypeException } from '@shared/model/errors';
import moment from 'moment';

export const THRESHOLD_DEFAULT_CLUSTER = 'Cluster43';

export const THRESHOLD_CLUSTERS = [
  'Cluster41',
  'Cluster42',
  'Cluster43',
  'Cluster44',
  'Cluster45',
  'Cluster46',
  'Cluster47',
  'Cluster48',
  'Cluster49'
];

export type ThresholdRequestObject = Omit<ThresholdEntity, 'thresholdId'>;

export interface ThresholdIdentityDto {
  testCaseId: string;
  testCaseVersion: number;
  cluster: string;
  thresholdVersion: number;
}

export interface ThresholdDefinitionIdentityDto {
  areaId: number | null;
  areaName: string | null;
}

export interface ThresholdDetailedDefinitionIdentityDto
  extends ThresholdDefinitionIdentityDto {
  controlledAccess: string;
  attributionStatus: string;
}

export interface ThresholdDto extends ThresholdIdentityDto {
  testCaseName: string;
  resultType: string;
  comments: string;
  active: boolean;
  lastChange: string;
  thresholdValues:
    | ThresholdDefinitionIdentityDto[]
    | ThresholdFailRateDeltaDto[]
    | ThresholdFailRateDetailedDto[]
    | ThresholdDeltaDetailedDto[];
  testCaseVersionChanged: boolean;
}

export interface ThresholdFailRateDeltaDto
  extends ThresholdDefinitionIdentityDto {
  thresholdRateGreen: number;
  thresholdRateYellow: number;
  thresholdDeltaRelPlus: number;
  thresholdDeltaRelMinus: number;
  thresholdDeltaAbsPlus: number;
  thresholdDeltaAbsMinus: number;
}

export interface ThresholdFailRateDetailedDto
  extends ThresholdDetailedDefinitionIdentityDto {
  thresholdRateAllFrcGreen: number;
  thresholdRateFrc0Green: number;
  thresholdRateFrc1Green: number;
  thresholdRateFrc2Green: number;
  thresholdRateFrc3Green: number;
  thresholdRateFrc4Green: number;
  thresholdRateAllFrcYellow: number;
  thresholdRateFrc0Yellow: number;
  thresholdRateFrc1Yellow: number;
  thresholdRateFrc2Yellow: number;
  thresholdRateFrc3Yellow: number;
  thresholdRateFrc4Yellow: number;
}

export interface ThresholdDeltaDetailedDto
  extends ThresholdDetailedDefinitionIdentityDto {
  thresholdDeltaAllFrcRelPlus: number;
  thresholdDeltaFrc0RelPlus: number;
  thresholdDeltaFrc1RelPlus: number;
  thresholdDeltaFrc2RelPlus: number;
  thresholdDeltaFrc3RelPlus: number;
  thresholdDeltaFrc4RelPlus: number;
  thresholdDeltaAllFrcRelMinus: number;
  thresholdDeltaFrc0RelMinus: number;
  thresholdDeltaFrc1RelMinus: number;
  thresholdDeltaFrc2RelMinus: number;
  thresholdDeltaFrc3RelMinus: number;
  thresholdDeltaFrc4RelMinus: number;
  thresholdDeltaAllFrcAbsPlus: number;
  thresholdDeltaFrc0AbsPlus: number;
  thresholdDeltaFrc1AbsPlus: number;
  thresholdDeltaFrc2AbsPlus: number;
  thresholdDeltaFrc3AbsPlus: number;
  thresholdDeltaFrc4AbsPlus: number;
  thresholdDeltaAllFrcAbsMinus: number;
  thresholdDeltaFrc0AbsMinus: number;
  thresholdDeltaFrc1AbsMinus: number;
  thresholdDeltaFrc2AbsMinus: number;
  thresholdDeltaFrc3AbsMinus: number;
  thresholdDeltaFrc4AbsMinus: number;
}

export interface ThresholdId {
  testCaseId: string;
  cluster: string;
}

export interface ThresholdDefinitionId {
  areaId: number;
}

export interface ThresholdDetailedDefinitionId extends ThresholdDefinitionId {
  controlledAccess: ControlledAccess;
  attributionStatus: AttributionStatus;
}

export class ThresholdIdentity {
  testCaseId: string;
  testCaseVersion: number;
  cluster: string;
  thresholdVersion: number;

  constructor(fromDto: ThresholdIdentityDto) {
    const { cluster, testCaseId, testCaseVersion, thresholdVersion } = fromDto;
    this.testCaseId = testCaseId;
    this.testCaseVersion = testCaseVersion;
    this.cluster = cluster;
    this.thresholdVersion = thresholdVersion;
  }
  get id(): ThresholdId {
    if (this.testCaseId && this.cluster) {
      return {
        testCaseId: this.testCaseId,
        cluster: this.cluster
      };
    }
    return null;
  }
}

export class ThresholdDefinitionIdentity {
  static NO_AREA_DEFINED = -1;

  areaId: number | null;
  areaName: string | null;

  constructor(fromDto: ThresholdDefinitionIdentityDto) {
    const { areaId, areaName } = fromDto;
    this.areaId = areaId;
    this.areaName = areaName;
  }

  get id(): ThresholdDefinitionId {
    if (this.areaId) {
      return {
        areaId: this.areaId
      };
    }
    return null;
  }

  isTileBased(): boolean {
    return this.areaId === ThresholdDefinitionIdentity.NO_AREA_DEFINED;
  }

  isAreaBased(): boolean {
    return this.areaId !== ThresholdDefinitionIdentity.NO_AREA_DEFINED;
  }
}

export class ThresholdDetailedDefinitionIdentity extends ThresholdDefinitionIdentity {
  controlledAccess: ControlledAccess;
  attributionStatus: AttributionStatus;

  constructor(fromDto: ThresholdDetailedDefinitionIdentityDto) {
    super(fromDto);
    const { controlledAccess, attributionStatus } = fromDto;
    this.controlledAccess = ControlledAccess[controlledAccess];
    this.attributionStatus = AttributionStatus[attributionStatus];
  }

  get id(): ThresholdDetailedDefinitionId {
    if (this.controlledAccess && this.attributionStatus && super.id) {
      return {
        ...super.id,
        controlledAccess: this.controlledAccess,
        attributionStatus: this.attributionStatus
      };
    }
    return null;
  }
}

export class ThresholdEntity extends ThresholdIdentity {
  comments: string;
  active: boolean;
  lastChange: Date;
  thresholdValues:
    | ThresholdDefinitionIdentity[]
    | ThresholdFailRateDeltaEntity[]
    | ThresholdFailRateDetailedEntity[]
    | ThresholdDeltaDetailedEntity[];
  testCaseVersionChanged: boolean;
  testCaseName: string;
  resultType: ResultType;

  constructor(fromDto: ThresholdDto) {
    super(fromDto);
    const { comments, active, lastChange, testCaseVersionChanged } = fromDto;
    this.comments = comments;
    this.active = active;
    this.lastChange = moment(lastChange).toDate();
    this.testCaseVersionChanged = testCaseVersionChanged;

    const { testCaseName, resultType } = fromDto;
    this.testCaseName = testCaseName;
    this.resultType = ResultType[resultType];

    const { thresholdValues } = fromDto;
    switch (this.resultType) {
      case ResultType.FORMAT_CONTENT:
      case ResultType.STATISTIC:
      case ResultType.COMBINED_DETAILED_STATISTIC:
        this.thresholdValues = thresholdValues.map(
          (thresholdValue) => new ThresholdFailRateDeltaEntity(thresholdValue)
        );
        break;
      case ResultType.FORMAT_CONTENT_DETAILED:
        this.thresholdValues = thresholdValues.map(
          (thresholdValue) =>
            new ThresholdFailRateDetailedEntity(thresholdValue)
        );
        break;
      case ResultType.STATISTIC_DETAILED:
        this.thresholdValues = thresholdValues.map(
          (thresholdValue) => new ThresholdDeltaDetailedEntity(thresholdValue)
        );
        break;
      default:
        throw new InvalidResultTypeException(this.resultType);
    }
    this.thresholdValues.sort((a, b) => {
      const nameA = (a.areaName || '').toUpperCase();
      const nameB = (b.areaName || '').toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  get isThresholdTileDefined(): boolean {
    return this.thresholdValues.some((entry) => entry.isTileBased());
  }

  get isThresholdAreaDefined(): boolean {
    return this.thresholdValues.some((entry) => entry.isAreaBased());
  }

  get isFormatContentDetailed(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT_DETAILED;
  }

  get isStatisticDetailed(): boolean {
    return this.resultType === ResultType.STATISTIC_DETAILED;
  }

  get isStatistic(): boolean {
    return this.resultType === ResultType.STATISTIC;
  }

  get isFormatContent(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT;
  }

  get isCombinedDetailedStatistic(): boolean {
    return this.resultType === ResultType.COMBINED_DETAILED_STATISTIC;
  }

  get isMapApproval(): boolean {
    return this.resultType === ResultType.MAP_APPROVAL;
  }

  static isTileBasedAllowed(resultType: ResultType): boolean {
    return [
      ResultType.FORMAT_CONTENT,
      ResultType.FORMAT_CONTENT_DETAILED,
      ResultType.COMBINED_DETAILED_STATISTIC,
      ResultType.MAP_APPROVAL
    ].includes(resultType);
  }

  getTileBasedThresholdValue(): ThresholdFailRateDeltaEntity {
    if (
      ![
        ResultType.STATISTIC,
        ResultType.FORMAT_CONTENT,
        ResultType.COMBINED_DETAILED_STATISTIC,
        ResultType.MAP_APPROVAL
      ].includes(this.resultType)
    ) {
      throw new InvalidResultTypeException(this.resultType);
    }
    for (const thresholdValue of this.thresholdValues) {
      if (thresholdValue.isTileBased()) {
        return thresholdValue as ThresholdFailRateDeltaEntity;
      }
    }
    return null;
  }

  getTileBasedThresholdDetailedValues(): Map<
    AttributionStatus,
    ThresholdFailRateDetailedEntity | ThresholdDeltaDetailedEntity
  > {
    if (
      ![
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.STATISTIC_DETAILED
      ].includes(this.resultType)
    ) {
      throw new InvalidResultTypeException(this.resultType);
    }
    const thresholdValuesFiltered = new Map<
      AttributionStatus,
      ThresholdFailRateDetailedEntity | ThresholdDeltaDetailedEntity
    >();
    for (const thresholdValue of this.thresholdValues) {
      if (thresholdValue.isTileBased()) {
        const { attributionStatus } = thresholdValue as any;
        thresholdValuesFiltered.set(attributionStatus, thresholdValue as any);
      }
    }
    return thresholdValuesFiltered;
  }

  getAreas(): Map<number, string> {
    const areas = new Map<number, string>();

    for (const thresholdValue of this.thresholdValues) {
      if (thresholdValue.isAreaBased()) {
        const { areaId, areaName } = thresholdValue;
        areas.set(areaId, areaName);
      }
    }
    return areas;
  }

  getThresholdDetailedValues(
    areaId: number
  ): Map<
    AttributionStatus,
    ThresholdFailRateDetailedEntity | ThresholdDeltaDetailedEntity
  > {
    if (
      ![
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.STATISTIC_DETAILED
      ].includes(this.resultType)
    ) {
      throw new InvalidResultTypeException(this.resultType);
    }
    const thresholdValuesFiltered = new Map<
      AttributionStatus,
      ThresholdFailRateDetailedEntity | ThresholdDeltaDetailedEntity
    >();
    for (const thresholdValue of this.thresholdValues) {
      if (thresholdValue.isAreaBased() && thresholdValue.areaId === areaId) {
        const { attributionStatus } = thresholdValue as any;
        thresholdValuesFiltered.set(attributionStatus, thresholdValue as any);
      }
    }
    return thresholdValuesFiltered;
  }

  getThresholdValue(areaId: number): ThresholdFailRateDeltaEntity {
    if (
      ![
        ResultType.STATISTIC,
        ResultType.FORMAT_CONTENT,
        ResultType.COMBINED_DETAILED_STATISTIC,
        ResultType.MAP_APPROVAL
      ].includes(this.resultType)
    ) {
      throw new InvalidResultTypeException(this.resultType);
    }
    for (const thresholdValue of this.thresholdValues) {
      if (thresholdValue.isAreaBased() && thresholdValue.areaId === areaId) {
        return thresholdValue as ThresholdFailRateDeltaEntity;
      }
    }
    return null;
  }
}

export class ThresholdFailRateDeltaEntity extends ThresholdDefinitionIdentity {
  thresholdRateGreen: number;
  thresholdRateYellow: number;
  thresholdDeltaRelPlus: number;
  thresholdDeltaRelMinus: number;
  thresholdDeltaAbsPlus: number;
  thresholdDeltaAbsMinus: number;

  constructor(fromDto: ThresholdFailRateDeltaDto) {
    super(fromDto);
    const {
      thresholdRateGreen,
      thresholdRateYellow,
      thresholdDeltaRelPlus,
      thresholdDeltaRelMinus,
      thresholdDeltaAbsPlus,
      thresholdDeltaAbsMinus
    } = fromDto;
    this.thresholdRateGreen = thresholdRateGreen;
    this.thresholdRateYellow = thresholdRateYellow;
    this.thresholdDeltaRelPlus = thresholdDeltaRelPlus;
    this.thresholdDeltaRelMinus = thresholdDeltaRelMinus;
    this.thresholdDeltaAbsPlus = thresholdDeltaAbsPlus;
    this.thresholdDeltaAbsMinus = thresholdDeltaAbsMinus;
  }

  static isEmpty(value: ThresholdFailRateDeltaEntity): boolean {
    const checkProperties = [
      'thresholdRateGreen',
      'thresholdRateYellow',
      'thresholdDeltaRelPlus',
      'thresholdDeltaRelMinus',
      'thresholdDeltaAbsPlus',
      'thresholdDeltaAbsMinus'
    ];
    return checkProperties
      .map(
        (property) =>
          value[property] === undefined ||
          value[property] === null ||
          value[property] === ''
      )
      .every((check) => check === true);
  }
}

export class ThresholdFailRateDetailedEntity extends ThresholdDetailedDefinitionIdentity {
  thresholdRateAllFrcGreen: number;
  thresholdRateFrc0Green: number;
  thresholdRateFrc1Green: number;
  thresholdRateFrc2Green: number;
  thresholdRateFrc3Green: number;
  thresholdRateFrc4Green: number;
  thresholdRateAllFrcYellow: number;
  thresholdRateFrc0Yellow: number;
  thresholdRateFrc1Yellow: number;
  thresholdRateFrc2Yellow: number;
  thresholdRateFrc3Yellow: number;
  thresholdRateFrc4Yellow: number;

  constructor(fromDto: ThresholdFailRateDetailedDto) {
    super(fromDto);
    const {
      thresholdRateAllFrcGreen,
      thresholdRateFrc0Green,
      thresholdRateFrc1Green,
      thresholdRateFrc2Green,
      thresholdRateFrc3Green,
      thresholdRateFrc4Green,
      thresholdRateAllFrcYellow,
      thresholdRateFrc0Yellow,
      thresholdRateFrc1Yellow,
      thresholdRateFrc2Yellow,
      thresholdRateFrc3Yellow,
      thresholdRateFrc4Yellow
    } = fromDto;
    this.thresholdRateAllFrcGreen = thresholdRateAllFrcGreen;
    this.thresholdRateFrc0Green = thresholdRateFrc0Green;
    this.thresholdRateFrc1Green = thresholdRateFrc1Green;
    this.thresholdRateFrc2Green = thresholdRateFrc2Green;
    this.thresholdRateFrc3Green = thresholdRateFrc3Green;
    this.thresholdRateFrc4Green = thresholdRateFrc4Green;
    this.thresholdRateAllFrcYellow = thresholdRateAllFrcYellow;
    this.thresholdRateFrc0Yellow = thresholdRateFrc0Yellow;
    this.thresholdRateFrc1Yellow = thresholdRateFrc1Yellow;
    this.thresholdRateFrc2Yellow = thresholdRateFrc2Yellow;
    this.thresholdRateFrc3Yellow = thresholdRateFrc3Yellow;
    this.thresholdRateFrc4Yellow = thresholdRateFrc4Yellow;
  }

  static isEmpty(value: ThresholdFailRateDetailedEntity): boolean {
    const checkProperties = [
      'thresholdRateAllFrcGreen',
      'thresholdRateFrc0Green',
      'thresholdRateFrc1Green',
      'thresholdRateFrc2Green',
      'thresholdRateFrc3Green',
      'thresholdRateFrc4Green',
      'thresholdRateAllFrcYellow',
      'thresholdRateFrc0Yellow',
      'thresholdRateFrc1Yellow',
      'thresholdRateFrc2Yellow',
      'thresholdRateFrc3Yellow',
      'thresholdRateFrc4Yellow'
    ];
    return checkProperties
      .map(
        (property) =>
          value[property] === undefined ||
          value[property] === null ||
          value[property] === ''
      )
      .every((check) => check === true);
  }
}

export class ThresholdDeltaDetailedEntity extends ThresholdDetailedDefinitionIdentity {
  thresholdDeltaAllFrcRelPlus: number;
  thresholdDeltaFrc0RelPlus: number;
  thresholdDeltaFrc1RelPlus: number;
  thresholdDeltaFrc2RelPlus: number;
  thresholdDeltaFrc3RelPlus: number;
  thresholdDeltaFrc4RelPlus: number;
  thresholdDeltaAllFrcRelMinus: number;
  thresholdDeltaFrc0RelMinus: number;
  thresholdDeltaFrc1RelMinus: number;
  thresholdDeltaFrc2RelMinus: number;
  thresholdDeltaFrc3RelMinus: number;
  thresholdDeltaFrc4RelMinus: number;
  thresholdDeltaAllFrcAbsPlus: number;
  thresholdDeltaFrc0AbsPlus: number;
  thresholdDeltaFrc1AbsPlus: number;
  thresholdDeltaFrc2AbsPlus: number;
  thresholdDeltaFrc3AbsPlus: number;
  thresholdDeltaFrc4AbsPlus: number;
  thresholdDeltaAllFrcAbsMinus: number;
  thresholdDeltaFrc0AbsMinus: number;
  thresholdDeltaFrc1AbsMinus: number;
  thresholdDeltaFrc2AbsMinus: number;
  thresholdDeltaFrc3AbsMinus: number;
  thresholdDeltaFrc4AbsMinus: number;

  constructor(fromDto: ThresholdDeltaDetailedDto) {
    super(fromDto);
    const {
      thresholdDeltaAllFrcRelPlus,
      thresholdDeltaFrc0RelPlus,
      thresholdDeltaFrc1RelPlus,
      thresholdDeltaFrc2RelPlus,
      thresholdDeltaFrc3RelPlus,
      thresholdDeltaFrc4RelPlus,
      thresholdDeltaAllFrcRelMinus,
      thresholdDeltaFrc0RelMinus,
      thresholdDeltaFrc1RelMinus,
      thresholdDeltaFrc2RelMinus,
      thresholdDeltaFrc3RelMinus,
      thresholdDeltaFrc4RelMinus,
      thresholdDeltaAllFrcAbsPlus,
      thresholdDeltaFrc0AbsPlus,
      thresholdDeltaFrc1AbsPlus,
      thresholdDeltaFrc2AbsPlus,
      thresholdDeltaFrc3AbsPlus,
      thresholdDeltaFrc4AbsPlus,
      thresholdDeltaAllFrcAbsMinus,
      thresholdDeltaFrc0AbsMinus,
      thresholdDeltaFrc1AbsMinus,
      thresholdDeltaFrc2AbsMinus,
      thresholdDeltaFrc3AbsMinus,
      thresholdDeltaFrc4AbsMinus
    } = fromDto;
    this.thresholdDeltaAllFrcRelPlus = thresholdDeltaAllFrcRelPlus;
    this.thresholdDeltaFrc0RelPlus = thresholdDeltaFrc0RelPlus;
    this.thresholdDeltaFrc1RelPlus = thresholdDeltaFrc1RelPlus;
    this.thresholdDeltaFrc2RelPlus = thresholdDeltaFrc2RelPlus;
    this.thresholdDeltaFrc3RelPlus = thresholdDeltaFrc3RelPlus;
    this.thresholdDeltaFrc4RelPlus = thresholdDeltaFrc4RelPlus;
    this.thresholdDeltaAllFrcRelMinus = thresholdDeltaAllFrcRelMinus;
    this.thresholdDeltaFrc0RelMinus = thresholdDeltaFrc0RelMinus;
    this.thresholdDeltaFrc1RelMinus = thresholdDeltaFrc1RelMinus;
    this.thresholdDeltaFrc2RelMinus = thresholdDeltaFrc2RelMinus;
    this.thresholdDeltaFrc3RelMinus = thresholdDeltaFrc3RelMinus;
    this.thresholdDeltaFrc4RelMinus = thresholdDeltaFrc4RelMinus;
    this.thresholdDeltaAllFrcAbsPlus = thresholdDeltaAllFrcAbsPlus;
    this.thresholdDeltaFrc0AbsPlus = thresholdDeltaFrc0AbsPlus;
    this.thresholdDeltaFrc1AbsPlus = thresholdDeltaFrc1AbsPlus;
    this.thresholdDeltaFrc2AbsPlus = thresholdDeltaFrc2AbsPlus;
    this.thresholdDeltaFrc3AbsPlus = thresholdDeltaFrc3AbsPlus;
    this.thresholdDeltaFrc4AbsPlus = thresholdDeltaFrc4AbsPlus;
    this.thresholdDeltaAllFrcAbsMinus = thresholdDeltaAllFrcAbsMinus;
    this.thresholdDeltaFrc0AbsMinus = thresholdDeltaFrc0AbsMinus;
    this.thresholdDeltaFrc1AbsMinus = thresholdDeltaFrc1AbsMinus;
    this.thresholdDeltaFrc2AbsMinus = thresholdDeltaFrc2AbsMinus;
    this.thresholdDeltaFrc3AbsMinus = thresholdDeltaFrc3AbsMinus;
    this.thresholdDeltaFrc4AbsMinus = thresholdDeltaFrc4AbsMinus;
  }

  static isEmpty(value: ThresholdDeltaDetailedEntity): boolean {
    const checkProperties = [
      'thresholdDeltaAllFrcRelPlus',
      'thresholdDeltaFrc0RelPlus',
      'thresholdDeltaFrc1RelPlus',
      'thresholdDeltaFrc2RelPlus',
      'thresholdDeltaFrc3RelPlus',
      'thresholdDeltaFrc4RelPlus',
      'thresholdDeltaAllFrcRelMinus',
      'thresholdDeltaFrc0RelMinus',
      'thresholdDeltaFrc1RelMinus',
      'thresholdDeltaFrc2RelMinus',
      'thresholdDeltaFrc3RelMinus',
      'thresholdDeltaFrc4RelMinus',
      'thresholdDeltaAllFrcAbsPlus',
      'thresholdDeltaFrc0AbsPlus',
      'thresholdDeltaFrc1AbsPlus',
      'thresholdDeltaFrc2AbsPlus',
      'thresholdDeltaFrc3AbsPlus',
      'thresholdDeltaFrc4AbsPlus',
      'thresholdDeltaAllFrcAbsMinus',
      'thresholdDeltaFrc0AbsMinus',
      'thresholdDeltaFrc1AbsMinus',
      'thresholdDeltaFrc2AbsMinus',
      'thresholdDeltaFrc3AbsMinus',
      'thresholdDeltaFrc4AbsMinus'
    ];
    return checkProperties
      .map(
        (property) =>
          value[property] === undefined ||
          value[property] === null ||
          value[property] === ''
      )
      .every((check) => check === true);
  }
}
