<ng-container *ngIf="scenarioId; else elseCreateHeader">
  <h3 mat-dialog-title>Edit Scenario (ID {{scenarioId}})</h3>
</ng-container>
<ng-template #elseCreateHeader>
  <h3 mat-dialog-title>Create Scenario</h3>
</ng-template>

<div mat-dialog-content [formGroup]="scenarioFormData">
  <div class="dialog-form">
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required id="scenarioNameId">
    </mat-form-field>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" id="scenarioDescriptionId">
    </mat-form-field>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Test Cases</mat-label>
      <mat-chip-list #testCaseList aria-label="TestCase selection">
        <mat-chip
          *ngFor="let testCase of scenarioData.testCases"
          [selectable]="true"
          [removable]="true"
          (removed)="removeTestCase(testCase)">
          {{testCase}} - {{testCase|testCaseDetails:'mapDataType'}} - {{testCase|testCaseDetails:'name'}}
          <mat-icon matChipRemove>remove</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Add TestCases</mat-label>
      <input #addTestCases matInput>
      <button (click)="submitTestCases()" aria-label="Search" color="primary" mat-icon-button
              matSuffix>
        <mat-icon>add</mat-icon>
      </button>
      <mat-hint align="end">Comma separated list of test cases</mat-hint>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button mat-stroked-button (click)="reset()">Reset</button>
  <button
    id="dialogScenarioSubmit"
    mat-raised-button
    color="primary"
    [disabled]="disableButton()"
    (click)="save()"
  >
    Save
  </button>
</div>

