import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';

@Pipe({
  name: 'cronParse'
})
export class CronParsePipe implements PipeTransform {
  transform(value: string, ..._args: any[]): string {
    return cronstrue.toString(value, { use24HourTimeFormat: true });
  }
}
