import { NgModule } from '@angular/core';
import { LocalizationTestComponent } from '@modules/localization-test/page/localization-test.component';
import { SharedModule } from '@shared/shared.module';
import { LocalizationTestRoutingModule } from '@modules/localization-test/localization-test.routing';
import { LocalizationTestDetailsComponent } from './component/localization-test-details/localization-test-details.component';

@NgModule({
  declarations: [LocalizationTestComponent, LocalizationTestDetailsComponent],
  imports: [SharedModule, LocalizationTestRoutingModule]
})
export class LocalizationTestModule {}
