import {
  AttributionStatus,
  ControlledAccess,
  FailVisualization,
  FunctionalRoadClass
} from '@shared/model/datastore';

export enum MapViewerSearchFor {
  NDS = 'NDS',
  HNATIVE = 'HNATIVE',
  WGS84 = 'WGS84'
}

export enum MapViewerVisualizationMode {
  RESULT = 'RESULT',
  DELTA = 'DELTA',
  DATA = 'DATA VERSION'
}

export interface MapViewerUriParams {
  areaId?: number;
  tileId?: number;
  searchQuery?: string;
  searchFor?: MapViewerSearchFor;
  zoom?: number;
  ne?: string;
  sw?: string;
  catalog?: string;
  catalogVersion?: number;
  comparisonCatalog?: string;
  comparisonCatalogVersion?: number;
  deltaCatalog?: string;
  deltaCatalogVersion?: number;
  deltaComparisonCatalog?: string;
  deltaComparisonCatalogVersion?: number;
  layerName?: string;
  testCaseId?: string;
  failRateMinValue?: number;
  failRateMaxValue?: number;
  failRateDeltaMinValue?: number;
  failRateDeltaMaxValue?: number;
  failVisualization?: FailVisualization;
  resultOutcomes?: string;
  resultDeltaOutcomes?: string;
  frc?: FunctionalRoadClass;
  attributionStatus?: AttributionStatus;
  controlledAccess?: ControlledAccess;
  visualizationMode?: MapViewerVisualizationMode;
}
