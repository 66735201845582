import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private defaultConfig: Partial<IndividualConfig> = {
    closeButton: true,
    progressBar: true,
    progressAnimation: 'decreasing',
    timeOut: 10000,
    positionClass: 'toast-bottom-right'
  };
  private errorConfig: Partial<IndividualConfig> = {
    tapToDismiss: true,
    disableTimeOut: true
  };
  private infoConfig: Partial<IndividualConfig> = {};
  private warnConfig: Partial<IndividualConfig> = {
    closeButton: true,
    timeOut: 20000
  };
  private successConfig: Partial<IndividualConfig> = {
    timeOut: 5000
  };
  private staticConfig: Partial<IndividualConfig> = {
    closeButton: false,
    progressBar: false,
    tapToDismiss: false,
    disableTimeOut: true,
    positionClass: 'toast-center-center'
  };

  constructor(private toastr: ToastrService) {}

  /**
   * Show successful toast
   *
   * @param message
   * @param title
   */
  success(message: string, title?: string): number {
    return this.toastr.success(message, title, {
      ...this.defaultConfig,
      ...this.successConfig
    }).toastId;
  }

  /**
   * Show error toast
   *
   * @param message
   * @param title
   */
  error(message: string, title?: string): number {
    return this.toastr.error(message, title, {
      ...this.defaultConfig,
      ...this.errorConfig
    }).toastId;
  }

  /**
   * Show info notification
   *
   * @param message
   * @param title
   */
  info(message: string, title?: string): number {
    return this.toastr.info(message, title, {
      ...this.defaultConfig,
      ...this.infoConfig
    }).toastId;
  }

  /**
   * Show warning notification
   *
   * @param message
   * @param title
   */
  warning(message: string, title?: string): number {
    return this.toastr.warning(message, title, {
      ...this.defaultConfig,
      ...this.warnConfig
    }).toastId;
  }

  /**
   * Show static notification (not closable)
   *
   * @param message
   * @param title
   */
  staticInfo(message: string, title?: string): number {
    return this.toastr.info(message, title, {
      ...this.defaultConfig,
      ...this.staticConfig
    }).toastId;
  }

  /**
   * Remove all notifications or a single notification by id
   *
   * @param toastId
   */
  clear(toastId?: number): void {
    this.toastr.clear(toastId);
  }

  /**
   * Remove and destroy a single notification by id
   *
   * @param toastId
   */
  remove(toastId: number): boolean {
    return this.toastr.remove(toastId);
  }
}
