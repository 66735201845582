import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector
} from '@angular/core';
import { MapViewerMarkerPopupComponent } from '@modules/map-viewer/component/map-viewer-marker-popup/map-viewer-marker-popup.component';

@Injectable({
  providedIn: 'root'
})
export class MapViewerMarkerPopupService {
  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  returnMarkerPopUpHTML(data: any): HTMLElement {
    // Create element
    const markerPopup = document.createElement('marker-popup-component');
    // Create the component and wire it up with the element
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      MapViewerMarkerPopupComponent
    );
    const popupComponentRef = factory.create(this.injector, [], markerPopup);
    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(popupComponentRef.hostView);

    // Set the message
    popupComponentRef.instance.data = data;

    // Return rendered Component
    return markerPopup;
  }
}
