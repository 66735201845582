import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ResultQueryFilterParams } from '@modules/map-viewer/service/map-viewer-result-outcome.service';
import {
  AttributionStatus,
  Bounds,
  CatalogInfoVisualization,
  ControlledAccess,
  DataVersionTileEntity,
  DeltaTileEntity,
  FunctionalRoadClass,
  MapViewerAreasApiEntity,
  ResultDataApiEntity,
  ResultDeltaOutcome,
  ResultFilter,
  ResultOutcome,
  ResultType,
  TestCaseApiEntity,
  TileEntity,
  TilesApiEntity
} from '@shared/model/datastore';
import { LocalizationTestApiEntity } from '@shared/model/results';
import {
  THRESHOLD_DEFAULT_CLUSTER,
  ThresholdDto,
  ThresholdEntity,
  ThresholdIdentity
} from '@shared/model/thresholds';
import { ApiClientService } from '@shared/service/api-client.service';
import { SettingsService } from '@shared/service/settings.service';
import { TileNds } from '@shared/service/tile-utils.service';

import JSONbig from 'json-bigint';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ResponseCollection,
  ResponseMap,
  ResponsePage
} from '../model/response';

@Injectable({
  providedIn: 'root'
})
export class DatastoreService extends ApiClientService {
  localizationTestUrl: string;
  dataStoreMapViewerUrl: string;
  dataStoreAreasUrl: string;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    super();
    this.localizationTestUrl = settingsService.getDataStoreUrl(
      `${environment.dataStoreLocalizationTestUrl}`
    );
    this.dataStoreMapViewerUrl = settingsService.getDataStoreUrl(
      `${environment.dataStoreMapViewerUrl}`
    );
    this.dataStoreAreasUrl = settingsService.getDataStoreUrl(
      `${environment.dataStoreAreasUrl}`
    );
  }

  static mapResultResponse(
    responsePage: ResponsePage<ResultDataApiEntity>
  ): ResponsePage<ResultDataApiEntity> {
    const { data, pagination } = responsePage;
    for (const entity of data) {
      try {
        entity.resultDetails =
          typeof entity.resultDetails === 'string'
            ? JSONbig.parse(entity.resultDetails)
            : entity.resultDetails;
      } catch (e) {
        console.error(
          `error occurred while parsing resultDetails:`,
          entity.resultDetails
        );
        entity.resultDetails = null;
      }
    }
    return new ResponsePage(data, pagination);
  }

  private static mapResultTypeOfFormatContent(
    failRateDelta: number,
    mainResultOutcome: ResultOutcome,
    deltaResultOutcome: ResultOutcome
  ): ResultDeltaOutcome {
    if (
      [mainResultOutcome, deltaResultOutcome].some(
        (outcome) => outcome === ResultOutcome.NOT_EXECUTED
      )
    ) {
      return ResultDeltaOutcome.NOT_EXECUTED;
    }
    if (!Number.isFinite(failRateDelta)) {
      return ResultDeltaOutcome.FAILURE;
    }
    if (failRateDelta < 0) {
      return ResultDeltaOutcome.INC;
    }
    if (failRateDelta > 0) {
      return ResultDeltaOutcome.DEC;
    }
    return ResultDeltaOutcome.EQUAL;
  }

  private static mapResultTypeOfStatistic(
    valueDelta: number
  ): ResultDeltaOutcome {
    if (!Number.isFinite(valueDelta)) {
      return ResultDeltaOutcome.FAILURE;
    }
    if (valueDelta > 0) {
      return ResultDeltaOutcome.INC;
    }
    if (valueDelta < 0) {
      return ResultDeltaOutcome.DEC;
    }
    return ResultDeltaOutcome.EQUAL;
  }

  private static mapResultTypeOfStatisticDetailed(
    valueDelta: number,
    mainResultOutcome: ResultOutcome,
    deltaResultOutcome: ResultOutcome
  ): ResultDeltaOutcome {
    if (
      [mainResultOutcome, deltaResultOutcome].some(
        (outcome) => outcome === ResultOutcome.NOT_EXECUTED
      )
    ) {
      return ResultDeltaOutcome.NOT_EXECUTED;
    }
    if (!Number.isFinite(valueDelta)) {
      return ResultDeltaOutcome.FAILURE;
    }
    if (valueDelta > 0) {
      return ResultDeltaOutcome.INC;
    }
    if (valueDelta < 0) {
      return ResultDeltaOutcome.DEC;
    }
    return ResultDeltaOutcome.EQUAL;
  }

  getLocalizationTests(
    page: number = 1,
    size: number = 25
  ): Observable<ResponsePage<LocalizationTestApiEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('size', String(size));

    return this.httpClient.get<ResponsePage<LocalizationTestApiEntity>>(
      this.localizationTestUrl,
      { params }
    );
  }

  getTiles(partitionTile: TileNds): Observable<ResponseMap<TilesApiEntity>> {
    return this.httpClient
      .post<ResponseMap<TilesApiEntity>>(
        this.dataStoreMapViewerUrl,
        partitionTile.getBounds(),
        {
          observe: 'response'
        }
      )
      .pipe(map((httpResponse) => httpResponse.body));
  }

  getAreas(): Observable<ResponseCollection<MapViewerAreasApiEntity>> {
    return this.httpClient.get<ResponseCollection<MapViewerAreasApiEntity>>(
      this.dataStoreAreasUrl
    );
  }

  /**
   * get all catalog names
   */
  getCatalogNameList(): Observable<CatalogInfoVisualization[]> {
    const catalogs = this.settingsService.getDataStoreUrl(
      environment.dataStoreCatalogsUrl
    );
    return this.httpClient
      .get<ResponseCollection<CatalogInfoVisualization>>(catalogs)
      .pipe(
        map((response) => response.data),
        map((entities) => {
          entities.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
          );
          return entities;
        })
      );
  }

  /**
   * get all comparison catalog names
   */
  getDeltaCatalogNameList(): Observable<CatalogInfoVisualization[]> {
    const catalogs = this.settingsService.getDataStoreUrl(
      environment.dataStoreComparisonCatalogsUrl
    );
    return this.httpClient
      .get<ResponseCollection<CatalogInfoVisualization>>(catalogs)
      .pipe(
        map((response) => response.data),
        map((entities) => {
          entities.sort((a, b) =>
            a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
          );
          return entities;
        })
      );
  }

  /**
   * get all catalog versions
   */
  getCatalogVersionList(
    catalogName: string
  ): Observable<ResponseCollection<number>> {
    const catalogVersions = this.settingsService.getDataStoreUrl(
      `${environment.dataStoreCatalogsUrl}/${catalogName}/versions`
    );
    return this.httpClient.get<ResponseCollection<number>>(catalogVersions);
  }

  /**
   * get all comparison catalog versions
   */
  getComparisonCatalogVersionList(
    catalogName: string
  ): Observable<ResponseCollection<number>> {
    const catalogVersions = this.settingsService.getDataStoreUrl(
      `${environment.dataStoreCatalogsUrl}/${catalogName}/comparison-versions`
    );
    return this.httpClient.get<ResponseCollection<number>>(catalogVersions);
  }

  /**
   * get all Testcase results
   */
  getTestCaseResults(
    catalogName: string,
    filter: ResultFilter = {},
    page: number = 1,
    size: number = 10
  ): Observable<ResponsePage<ResultDataApiEntity>> {
    return this.getResults(
      `${environment.dataStoreCatalogsUrl}/${catalogName}`,
      filter,
      page,
      size
    );
  }

  /**
   * get all Scenario Run results
   */
  getScenarioRunResults(
    runId: string,
    filter: ResultFilter = {},
    page: number = 1,
    size: number = 10
  ): Observable<ResponsePage<ResultDataApiEntity>> {
    return this.getResults(
      `${environment.dataStoreScenarioRunsUrl}/${runId}`,
      filter,
      page,
      size
    );
  }

  getTestCases(
    catalogName: string,
    catalogVersion: number
  ): Observable<ResponseCollection<TestCaseApiEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('catalogName', String(catalogName));
    params = params.append('catalogVersion', String(catalogVersion));
    return this.httpClient.get<ResponseCollection<TestCaseApiEntity>>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreTestCasesUrl}`
      ),
      { params }
    );
  }

  getDataVersionTilesOutcome(
    catalogName: string,
    catalogVersion: number,
    layerName: string,
    bounds: Bounds
  ): Observable<ResponseMap<DataVersionTileEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('catalogName', String(catalogName));
    params = params.append('catalogVersion', String(catalogVersion));
    params = params.append('layerName', String(layerName));
    params = params.append('ne', JSON.stringify(bounds.ne));
    params = params.append('sw', JSON.stringify(bounds.sw));
    return this.httpClient.get<ResponseMap<DataVersionTileEntity>>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreDataVersionOutcomeUrl}`
      ),
      { params }
    );
  }

  getResultsTilesResultOutcome(
    catalogName: string,
    catalogVersion: number,
    testcaseId: string,
    bounds: Bounds,
    functionalRoadClass: FunctionalRoadClass = null,
    attributionStatus: AttributionStatus = null,
    controlledAccess: ControlledAccess = null
  ): Observable<ResponseMap<TileEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('catalogName', String(catalogName));
    params = params.append('catalogVersion', String(catalogVersion));
    params = params.append('testcaseId', String(testcaseId));
    params = params.append('ne', JSON.stringify(bounds.ne));
    params = params.append('sw', JSON.stringify(bounds.sw));
    params = this.appendFilterParams(params, {
      functionalRoadClass,
      attributionStatus,
      controlledAccess
    });
    return this.httpClient.get<ResponseMap<TileEntity>>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreResultsTilesResultOutcomeUrl}`
      ),
      { params }
    );
  }

  getComparisonResultsTilesResultOutcome(
    catalogName: string,
    catalogVersion: number,
    comparisonCatalogName: string,
    comparisonCatalogVersion: number,
    testcaseId: string,
    bounds: Bounds,
    functionalRoadClass: FunctionalRoadClass = null,
    attributionStatus: AttributionStatus = null,
    controlledAccess: ControlledAccess = null
  ): Observable<ResponseMap<TileEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('catalogName', String(catalogName));
    params = params.append('catalogVersion', String(catalogVersion));
    params = params.append(
      'comparisonCatalogName',
      String(comparisonCatalogName)
    );
    params = params.append(
      'comparisonCatalogVersion',
      String(comparisonCatalogVersion)
    );
    params = params.append('testcaseId', String(testcaseId));
    params = params.append('ne', JSON.stringify(bounds.ne));
    params = params.append('sw', JSON.stringify(bounds.sw));
    params = this.appendFilterParams(params, {
      functionalRoadClass,
      attributionStatus,
      controlledAccess
    });
    return this.httpClient.get<ResponseMap<TileEntity>>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreResultsTilesResultOutcomeUrl}`
      ),
      { params }
    );
  }

  getResultsTilesResultOutcomeDelta(
    mainCatalogName: string,
    mainCatalogVersion: number,
    mainComparisonCatalogName: string,
    mainComparisonCatalogVersion: number,
    deltaCatalogName: string,
    deltaCatalogVersion: number,
    deltaComparisonCatalogName: string,
    deltaComparisonCatalogVersion: number,
    testcaseId: string,
    resultType: ResultType,
    bounds: Bounds,
    functionalRoadClass: FunctionalRoadClass = null,
    attributionStatus: AttributionStatus = null,
    controlledAccess: ControlledAccess = null
  ): Observable<ResponseMap<DeltaTileEntity>> {
    let params: HttpParams = new HttpParams();
    params = params.append('catalogName1', String(deltaCatalogName));
    params = params.append('catalogVersion1', String(deltaCatalogVersion));
    params = params.append('catalogName2', String(mainCatalogName));
    params = params.append('catalogVersion2', String(mainCatalogVersion));

    if (deltaComparisonCatalogName) {
      params = params.append(
        'comparisonCatalogName1',
        String(deltaComparisonCatalogName)
      );
    }
    if (deltaComparisonCatalogVersion) {
      params = params.append(
        'comparisonCatalogVersion1',
        String(deltaComparisonCatalogVersion)
      );
    }
    if (mainComparisonCatalogName) {
      params = params.append(
        'comparisonCatalogName2',
        String(mainComparisonCatalogName)
      );
    }
    if (mainComparisonCatalogVersion) {
      params = params.append(
        'comparisonCatalogVersion2',
        String(mainComparisonCatalogVersion)
      );
    }

    params = params.append('testcaseId', String(testcaseId));
    params = params.append('ne', JSON.stringify(bounds.ne));
    params = params.append('sw', JSON.stringify(bounds.sw));
    params = this.appendFilterParams(params, {
      functionalRoadClass,
      attributionStatus,
      controlledAccess
    });

    return this.httpClient
      .get<ResponseMap<DeltaTileEntity>>(
        this.settingsService.getDataStoreUrl(
          `${environment.dataStoreResultsTilesResultOutcomeDeltaUrl}`
        ),
        { params }
      )
      .pipe(
        map((response) => {
          for (const tileId in response.data) {
            if (response.data[tileId]) {
              response.data[tileId].deltaOutcome =
                this.mapDeltaTileEntityOutcome(
                  response.data[tileId],
                  resultType
                );
            }
          }
          return response;
        })
      );
  }

  getThresholds(
    cluster: string = THRESHOLD_DEFAULT_CLUSTER
  ): Observable<ThresholdEntity[]> {
    const params: HttpParams = new HttpParams();
    return this.httpClient
      .get<ResponseCollection<ThresholdDto>>(
        this.settingsService.getDataStoreUrl(
          `${environment.dataStoreThresholdsUrl}/${cluster}`
        ),
        { params }
      )
      .pipe(
        map((response: ResponseCollection<ThresholdDto>) => {
          const { data } = response;
          data.sort((a, b) => {
            if (a.testCaseId < b.testCaseId) {
              return -1;
            }
            if (a.testCaseId > b.testCaseId) {
              return 1;
            }
            return 0;
          });
          return data.map((thresholdDto) => new ThresholdEntity(thresholdDto));
        })
      );
  }

  // provide usage create (POST): Body complete threshold + thresholdValues
  createThreshold(
    thresholdDto: ThresholdDto
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreThresholdsUrl}`
      ),
      thresholdDto,
      {
        observe: 'response'
      }
    );
  }

  // provide usage update (PUT): Body complete threshold + thresholdValues
  updateThreshold(
    thresholdDto: ThresholdDto
  ): Observable<HttpResponse<string>> {
    return this.httpClient.put<string>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreThresholdsUrl}`
      ),
      thresholdDto,
      {
        observe: 'response'
      }
    );
  }

  // provide usage delete (DELETE)
  deleteThreshold(
    thresholdKey: ThresholdIdentity
  ): Observable<HttpResponse<string>> {
    const { cluster, testCaseId } = thresholdKey;
    return this.httpClient.delete<string>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreThresholdsUrl}/${cluster}/${testCaseId}`
      ),
      {
        observe: 'response',
        body: { key: thresholdKey }
      }
    );
  }

  // provide active flag change (PUT): Body: { active: true|false }
  toggleThresholdActive(
    testCaseId: string,
    cluster: string,
    active: boolean
  ): Observable<HttpResponse<string>> {
    return this.httpClient.put<string>(
      this.settingsService.getDataStoreUrl(
        `${environment.dataStoreThresholdsUrl}/${cluster}/${testCaseId}`
      ),
      { active },
      {
        observe: 'response'
      }
    );
  }

  private appendFilterParams(
    httpParams: HttpParams,
    params: ResultQueryFilterParams
  ): HttpParams {
    const { functionalRoadClass, attributionStatus, controlledAccess } = params;
    if (
      functionalRoadClass &&
      functionalRoadClass !== FunctionalRoadClass.ALL
    ) {
      httpParams = httpParams.append('frc', String(functionalRoadClass));
    }
    if (attributionStatus && attributionStatus !== AttributionStatus.ALL) {
      httpParams = httpParams.append(
        'attributionStatus',
        String(attributionStatus)
      );
    }
    if (controlledAccess && controlledAccess !== ControlledAccess.ALL) {
      httpParams = httpParams.append(
        'controlledAccess',
        String(controlledAccess)
      );
    }
    return httpParams;
  }

  private mapDeltaTileEntityOutcome(
    entity: DeltaTileEntity,
    resultType: ResultType
  ): ResultDeltaOutcome {
    const {
      resultOutcome1: deltaResultOutcome,
      resultOutcome2: mainResultOutcome,
      failRateDelta,
      valueDelta
    } = entity;

    // FAILURE if one is FAILURE
    if (
      [mainResultOutcome, deltaResultOutcome].some(
        (outcome) => outcome === ResultOutcome.FAILURE
      )
    ) {
      return ResultDeltaOutcome.FAILURE;
    }
    // NOT_EXECUTED if both are NOT_EXECUTED
    if (
      [mainResultOutcome, deltaResultOutcome].every(
        (outcome) => outcome === ResultOutcome.NOT_EXECUTED
      )
    ) {
      return ResultDeltaOutcome.NOT_EXECUTED;
    }

    switch (resultType) {
      case ResultType.FORMAT_CONTENT:
      case ResultType.COMBINED_DETAILED_STATISTIC:
      case ResultType.FORMAT_CONTENT_DETAILED:
      case ResultType.MAP_APPROVAL:
        return DatastoreService.mapResultTypeOfFormatContent(
          failRateDelta,
          mainResultOutcome,
          deltaResultOutcome
        );
      case ResultType.STATISTIC:
        return DatastoreService.mapResultTypeOfStatistic(valueDelta);
      case ResultType.STATISTIC_DETAILED:
        return DatastoreService.mapResultTypeOfStatisticDetailed(
          valueDelta,
          mainResultOutcome,
          deltaResultOutcome
        );
    }
    return ResultDeltaOutcome.FAILURE;
  }

  private buildParamsFromResultFilter(
    resultFilter: ResultFilter,
    page,
    size
  ): HttpParams {
    let params: HttpParams = new HttpParams();
    const {
      catalogVersion = '',
      comparisonCatalogName = '',
      comparisonCatalogVersion = '',
      testCaseIds = [],
      tileIds = [],
      areaIds = [],
      resultOutcomes = []
    } = resultFilter || {};
    if (catalogVersion) {
      params = params.append('catalogVersion', String(catalogVersion));
    }
    if (comparisonCatalogVersion) {
      params = params.append(
        'comparisonCatalogVersion',
        String(comparisonCatalogVersion)
      );
    }
    if (comparisonCatalogName) {
      params = params.append(
        'comparisonCatalogName',
        String(comparisonCatalogName)
      );
    }
    if (testCaseIds.length) {
      testCaseIds.forEach((element) => {
        params = params.append('testCaseIds', String(element));
      });
    }
    if (tileIds.length) {
      tileIds.forEach((element) => {
        params = params.append('tileIds', String(element));
      });
    }
    if (areaIds.length) {
      areaIds.forEach((element) => {
        params = params.append('areaIds', String(element));
      });
    }
    if (resultOutcomes.length) {
      resultOutcomes.forEach((element) => {
        params = params.append('resultOutcomes', String(element));
      });
    }
    params = params.append('page', String(page));
    params = params.append('size', String(size));
    return params;
  }

  private getResults(
    endpoint: string,
    filter: ResultFilter,
    page: number,
    size: number
  ): Observable<ResponsePage<ResultDataApiEntity>> {
    const params: HttpParams = this.buildParamsFromResultFilter(
      filter,
      page,
      size
    );
    const url = this.settingsService.getDataStoreUrl(`${endpoint}`);
    return this.httpClient
      .get<ResponsePage<ResultDataApiEntity>>(url, {
        params
      })
      .pipe(
        map((responsePage: ResponsePage<ResultDataApiEntity>) =>
          DatastoreService.mapResultResponse(responsePage)
        )
      );
  }

  private geComparisonResults(
    endpoint: string,
    filter: ResultFilter,
    page: number,
    size: number
  ): Observable<ResponsePage<ResultDataApiEntity>> {
    const params: HttpParams = this.buildParamsFromResultFilter(
      filter,
      page,
      size
    );
    const url = this.settingsService.getDataStoreUrl(`${endpoint}`);
    return this.httpClient
      .get<ResponsePage<ResultDataApiEntity>>(url, {
        params
      })
      .pipe(
        map((responsePage: ResponsePage<ResultDataApiEntity>) =>
          DatastoreService.mapResultResponse(responsePage)
        )
      );
  }
}
