<p>
  <span class="label">NDS Tile ID</span>
  <span class="value"><code>{{infoTileId}}</code></span>
</p>
<p *ngIf="infoTileFailRate !== null">
  <span class="label">Fail Rate</span>
  <span class="value"><code>{{infoTileFailRate|number:"1.0-3":"de"}}%</code></span>
</p>
<p *ngIf="infoTileFailCount !== null">
  <span class="label">Fail Count</span>
  <span class="value"><code>{{infoTileFailCount}}</code></span>
</p>
<p *ngIf="infoTileValue !== null">
  <span class="label">Statistics Value</span>
  <span class="value">{{infoTileValue|number:"1.0-2":"de"}} {{infoTileUnit !== 'none' ? infoTileUnit : ''}}</span>
</p>
<p *ngIf="infoDataVersionTileValue !== null">
  <span class="label">Data Version Value</span>
  <span class="value" *ngIf="+infoDataVersionTileValue; else nonNumberTemplate">{{infoDataVersionTileValue|number:"1.0-2":"de"}}</span>
  <ng-template #nonNumberTemplate>
    {{ infoDataVersionTileValue }}
  </ng-template>
</p>
<ng-container  *ngIf="isDeltaVisualization">
  <p *ngIf="infoDeltaMainTileResultOutcome && infoDeltaComparisonTileResultOutcome">
    <span class="label">Delta Result Outcome</span>
    <span class="value" style="white-space: nowrap">
      <code>{{infoDeltaComparisonTileResultOutcome}} &rArr; {{infoDeltaMainTileResultOutcome}}</code>
    </span>
  </p>
  <p *ngIf="(isResultTypeWithFailRate) && infoDeltaTileDeltaFailRate !== null">
    <span class="label">Delta Fail Rate</span>
    <span class="value"><code>{{infoDeltaTileDeltaFailRate|number:"1.0-3":"de"}}%</code></span>
  </p>
  <p *ngIf="infoDeltaTileDeltaValue !== null">
    <span class="label">{{isResultTypeWithFailRate ? "Delta Fail Count" : "Delta Value"}}</span>
    <span class="value"><code>{{infoDeltaTileDeltaValue|number:"1.0-2":"de"}}  {{infoDeltaTileUnit !== 'none' ? infoDeltaTileUnit : ''}}</code></span>
  </p>
</ng-container>

<ng-container *ngIf="hasUserSelection()">
  <hr />
  <p *ngIf="tileId">
    <span class="label">NDS Tile ID</span>
    <span class="value">
      <code>{{tileId}}</code>
      <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="tileId.toString(10)"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
    </span>
  </p>
  <p *ngIf="area">
    <span class="label">Area</span>
    <span class="value">
      <code>
        {{area.name}} (ID:{{area.areaId}}){{area.description ? ', ' + area.description : ''}}, {{area.tileIds.length}} Tiles
      </code>
    </span>
  </p>
  <p *ngIf="mapDataType">
    <span class="label">Map Data Type</span>
    <span class="value"><code>{{mapDataType}}</code></span>
  </p>
  <p *ngIf="catalogName">
    <span class="label">Catalog</span>
    <span class="value">
      <code>{{catalogName|catalogName}}</code>
      <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="catalogName"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
    </span>
  </p>
  <p *ngIf="catalogVersion">
    <span class="label">Catalog Version</span>
    <span class="value"><code>{{catalogVersion}}</code></span>
  </p>

  <p *ngIf="comparisonCatalogName">
    <span class="label">Comparison Catalog</span>
    <span class="value">
      <code>{{comparisonCatalogName|catalogName}}</code>
      <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="comparisonCatalogName" onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
    </span>
  </p>
  <p *ngIf="comparisonCatalogVersion">
    <span class="label">Comparison Catalog Version</span>
    <span class="value"><code>{{comparisonCatalogVersion}}</code></span>
  </p>

  <p *ngIf="layerName && visualizationMode === 'DATA VERSION'">
    <span class="value"><code>{{layerName}}</code></span>
  </p>
  <p *ngIf="dataVersionTileValue">
    <span class="label">Data Version</span>
    <span class="value"><code>{{dataVersionTileValue}}</code></span>
  </p>

  <div *ngIf="visualizationMode !== 'DATA VERSION'">
    <p *ngIf="isDeltaVisualization && deltaCatalogName">
      <span class="label">Delta Catalog</span>
      <span class="value">
      <code>{{deltaCatalogName|catalogName}}</code>
      <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="deltaCatalogName" onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
    </span>
    </p>
    <p *ngIf="isDeltaVisualization && deltaCatalogVersion">
      <span class="label">Delta Catalog Version</span>
      <span class="value"><code>{{deltaCatalogVersion}}</code></span>
    </p>

    <p *ngIf="isDeltaVisualization && deltaComparisonCatalogName">
      <span class="label">Delta Comparison Catalog</span>
      <span class="value">
      <code>{{deltaComparisonCatalogName|catalogName}}</code>
      <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="deltaComparisonCatalogName" onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
    </span>
    </p>
    <p *ngIf="isDeltaVisualization && deltaComparisonCatalogVersion">
      <span class="label">Delta Catalog Comparison Version</span>
      <span class="value"><code>{{deltaComparisonCatalogVersion}}</code></span>
    </p>
    <p *ngIf="testCaseId">
      <span class="label">Test Case</span>
      <span class="value">
        <code matTooltip="{{testCaseId|testCaseDetails:'description'}}">{{testCaseId}}</code>
        <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="testCaseId"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
        <code>- {{testCaseId|testCaseDetails:'name'}}</code>
      </span>
    </p>
    <div *ngIf="ifDetailedTestCasesAvailable">
      <p *ngIf="functionalRoadClass">
        <span class="label">Functional Road Class</span>
        <span class="value"><code>{{functionalRoadClass}}</code></span>
      </p>
      <div *ngIf="isTestCaseDetailedAttributed">
        <p *ngIf="attributionStatus">
          <span class="label">Attribution Status</span>
          <span class="value"><code>{{attributionStatus}}</code></span>
        </p>
      </div>
      <p *ngIf="controlledAccess">
        <span class="label">Controlled Access</span>
        <span class="value"><code>{{controlledAccess}}</code></span>
      </p>
    </div>
  </div>
</ng-container>
