<div class="container-fluid">
  <mat-card appearance="outlined" *ngIf="authService.hasAnyPrivileges()">
    <app-page-title></app-page-title>

    <mat-card-content>
      <div class="kf-teaser-container">
        <div class="kf-teaser" *ngIf="authService.hasPrivileges(['GRAFANA_USER'])">
          <div class="kf-teaser-header h3">Analytics</div>
          <div *ngIf="pendingStatus" class="kf-teaser-body">
            <div class="kf-teaser-image">
              <div class="kf-curved-shadow">
                <img src="/assets/img/kf_dashboard.jpg" alt="Grafana">
              </div>
            </div>
            <div class="kf-teaser-content">
              <p>
                Kingfisher uses Grafana for detailed test result analysis.
              </p>
              <p>
                Click the following link to open Grafana.
              </p>
            </div>
          </div>
          <div class="kf-teaser-footer"><a mat-raised-button [href]="grafanaDashboardUrl" color="primary" target="_blank">Visit Grafana<mat-icon>open_in_new</mat-icon></a></div>
        </div>

        <div class="kf-teaser" *ngIf="ndsMapViewerUrl && authService.hasPrivileges(['VIEW_MAPVIEWER'])">
          <div class="kf-teaser-header h3">NDS.Live MapViewer</div>
          <div *ngIf="pendingStatus" class="kf-teaser-body">
            <div class="kf-teaser-image">
              <div class="kf-curved-shadow">
                <img src="/assets/img/ndslive-mapviewer.jpeg" alt="NDS.Live MapViewer">
              </div>
            </div>
            <div class="kf-teaser-content">
              <p>
                Click the following link to open NDS.Live MapViewer.
              </p>
            </div>
          </div>
          <div class="kf-teaser-footer"><a mat-raised-button [href]="ndsMapViewerUrl" color="primary" target="_blank">Visit NDS.Live MapViewer<mat-icon>open_in_new</mat-icon></a></div>
        </div>

      </div>


      <ng-container *ngIf="authService.hasPrivileges(['CATALOGS_READ'])">
        <h3>Latest version of supported catalogs</h3>

        <app-catalog-list></app-catalog-list>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
