import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MapCatalogInfoFormComponent } from '@shared/component/scenario/map-catalog-info-form/map-catalog-info-form.component';
import { TestAreaFormComponent } from '@shared/component/scenario/test-area-form/test-area-form.component';
import {
  CatalogInfoApiEntity,
  ScenarioRunParameters
} from '@shared/model/productserver';
import { CatalogService } from '@shared/service/catalog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-run-parameters-form',
  templateUrl: './run-parameters-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunParametersFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MapCatalogInfoFormComponent, { static: true })
  mapCatalogInfoForm: MapCatalogInfoFormComponent;

  @ViewChild(TestAreaFormComponent, { static: true })
  testAreaForm: TestAreaFormComponent;

  public form: FormGroup;

  private $mapCatalogInfos: CatalogInfoApiEntity[];
  private $areaIds: number[];
  private $tileIds: number[];
  private $routeIds: number[];
  private $enabledRelativeCatalogVersions = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private catalogService: CatalogService
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get mapCatalogInfos(): CatalogInfoApiEntity[] {
    return this.$mapCatalogInfos;
  }

  @Input() set mapCatalogInfos(value: CatalogInfoApiEntity[]) {
    if (value === null || value === undefined) {
      this.$mapCatalogInfos = [];
    } else {
      this.$mapCatalogInfos = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get areaIds(): number[] {
    return this.$areaIds;
  }

  @Input() set areaIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$areaIds = [];
    } else {
      this.$areaIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get tileIds(): number[] {
    return this.$tileIds;
  }

  @Input() set tileIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$tileIds = [];
    } else {
      this.$tileIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get routeIds(): number[] {
    return this.$routeIds;
  }

  @Input() set routeIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$routeIds = [];
    } else {
      this.$routeIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get enabledRelativeCatalogVersions(): boolean {
    return this.$enabledRelativeCatalogVersions;
  }

  @Input() set enabledRelativeCatalogVersions(value: boolean) {
    if (value === null || value === undefined) {
      this.$enabledRelativeCatalogVersions = false;
    } else {
      this.$enabledRelativeCatalogVersions = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get invalid(): boolean {
    return this.mapCatalogInfoForm.invalid || this.testAreaForm.invalid;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
  }

  ngAfterViewInit(): void {
    this.form.addControl('testArea', this.testAreaForm.form);
    this.form.addControl('mapCatalogInfos', this.mapCatalogInfoForm.form);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getFormValues(): ScenarioRunParameters {
    const { tileIds, areaIds, routeIds } = this.testAreaForm.getFormValues();
    const mapCatalogInfo = this.mapCatalogInfoForm.getFormValues();
    return {
      tileIds,
      areaIds,
      routeIds,
      mapCatalogInfos: [mapCatalogInfo]
    };
  }
}
