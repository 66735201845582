<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <div class="kf-form-row kf-layout-gap-2">
    <div class="kf-form-col-50">
      <h3>Main Catalog</h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <app-catalog-form-control
          formControlName="name"
          [required]="isRequiredField('name')"></app-catalog-form-control>
        <mat-error *ngIf="getFormControl('name').hasError('required')">
          Please select a catalog
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <app-catalog-version-form-control
          formControlName="version"
          [required]="isRequiredField('version')"
          [catalog]="getSelectedMainCatalog()"></app-catalog-version-form-control>
        <mat-error *ngIf="getFormControl('version').hasError('required')">
          Please provide a version
        </mat-error>
      </mat-form-field>
    </div>
    <div class="kf-form-col-50">
      <h3 class="draggable-header" style="font-size: 16px">
        Comparison Catalog
      </h3>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Name</mat-label>
        <app-catalog-form-control
          formControlName="deltaName"
          [required]="isRequiredField('deltaName')"
        ></app-catalog-form-control>
        <mat-error *ngIf="getFormControl('deltaName').hasError('required')">
          Please select a comparison catalog
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Catalog Version</mat-label>
        <app-catalog-version-form-control
          formControlName="deltaVersion"
          [required]="isRequiredField('deltaVersion')"
          [catalog]="getSelectedDeltaCatalog()"></app-catalog-version-form-control>
        <mat-error *ngIf="getFormControl('deltaVersion').hasError('required')">
          Please provide a comparison version
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
