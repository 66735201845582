import { Component } from '@angular/core';
import { environment } from '@env';
import { AuthService } from '@shared/service/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  authService: AuthService;
  pendingStatus: boolean;

  constructor(authService: AuthService) {
    this.authService = authService;
    this.authService.pendingUserGrafanaStatus.subscribe(
      (status) => (this.pendingStatus = status)
    );
  }

  get grafanaDashboardUrl(): string {
    return `${environment.grafanaUrl}`;
  }

  get ndsMapViewerUrl(): string {
    return `${environment.ndsMapViewerUrl}`;
  }

}
