import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SettingsService } from '@shared/service/settings.service';
import { Observable } from 'rxjs';
import { ResponseCollection, ResponseObject } from '@shared/model/response';
import { AreaRequestObject, AreasApiEntity } from '@shared/model/productserver';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AreasService {
  areasUrl: string;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.areasUrl = settingsService.getProductServerUrl(
      environment.productServerAreaUrl
    );
  }

  getAreas(): Observable<ResponseCollection<AreasApiEntity>> {
    return this.httpClient.get<ResponseCollection<AreasApiEntity>>(
      this.areasUrl
    );
  }

  getAreasList(): Observable<ResponseCollection<AreasApiEntity>> {
    return this.httpClient.get<ResponseCollection<AreasApiEntity>>(
      `${this.areasUrl}/info`
    );
  }

  getArea(id: number): Observable<ResponseObject<AreasApiEntity>> {
    return this.httpClient.get<ResponseObject<AreasApiEntity>>(
      `${this.areasUrl}/${id}`
    );
  }

  createArea(area: AreaRequestObject): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(this.areasUrl, area, {
      observe: 'response'
    });
  }

  updateArea(
    id: number,
    area: AreaRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.put<string>(`${this.areasUrl}/${id}`, area, {
      observe: 'response'
    });
  }

  deleteArea(id: number): Observable<HttpResponse<string>> {
    return this.httpClient.delete<string>(`${this.areasUrl}/${id}`, {
      observe: 'response'
    });
  }
}
