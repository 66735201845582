import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  THRESHOLD_DEFAULT_CLUSTER,
  ThresholdDeltaDetailedDto,
  ThresholdFailRateDeltaDto,
  ThresholdFailRateDetailedDto
} from '@shared/model/thresholds';
import { catchError, map, startWith } from 'rxjs/operators';
import { HttpApiErrorResponse } from '@shared/model/response';
import { Observable, of as observableOf } from 'rxjs';
import { TestCaseApiEntity } from '@shared/model/productserver';
import { ResultType } from '@shared/model/datastore';
import { TestCasesService } from '@shared/service/test-cases.service';

@Component({
  selector: 'app-threshold-dialogue',
  templateUrl: './threshold-dialogue.component.html',
  styleUrls: ['./threshold-dialogue.component.scss']
})
export class ThresholdDialogueComponent implements OnInit {
  testCaseFormControl = new FormControl('');
  filteredTestCases: Observable<TestCaseApiEntity[]>;

  thresholdFormData: FormGroup;
  testCaseId: string;
  testCaseVersion: number;
  cluster: string = THRESHOLD_DEFAULT_CLUSTER;
  thresholdVersion: number;
  comments: string;
  active = true;
  lastChange: Date;
  testCaseName: string;
  resultType: ResultType;
  thresholdValues:
    | ThresholdFailRateDeltaDto[]
    | ThresholdFailRateDetailedDto[]
    | ThresholdDeltaDetailedDto[];

  assignedTestCaseIds: string[];

  isLoadingResults = false;
  private $testCasesList: TestCaseApiEntity[] = [];

  constructor(
    private testCasesService: TestCasesService,
    public dialogRef: MatDialogRef<ThresholdDialogueComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    const { threshold = {}, assignedTestCaseIds = [] } = data;
    const {
      testCaseId,
      testCaseVersion,
      cluster,
      thresholdVersion,
      comments,
      active,
      lastChange,
      testCaseName,
      resultType,
      thresholdValues
    } = threshold;
    this.assignedTestCaseIds = assignedTestCaseIds;

    this.testCaseId = testCaseId;
    this.testCaseVersion = testCaseVersion;
    this.cluster = cluster || THRESHOLD_DEFAULT_CLUSTER;
    this.thresholdVersion = thresholdVersion;
    this.comments = comments || '';
    this.active = active !== undefined ? active : true;
    this.lastChange = lastChange;
    this.testCaseName = testCaseName;
    this.resultType = resultType;
    this.thresholdValues = thresholdValues || [];
    this.initForm();
  }

  get testCasesList(): TestCaseApiEntity[] {
    return this.$testCasesList;
  }

  set testCasesList($testcaseIdsList: TestCaseApiEntity[]) {
    this.$testCasesList = $testcaseIdsList || [];
    if (this.$testCasesList.length) {
      this.filteredTestCases = this.testCaseFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterTestCases(value))
      );
    }
  }

  ngOnInit(): void {
    this.getAllTestCases();
  }

  close(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.initForm();
  }

  disableButton(): boolean {
    return this.thresholdFormData.invalid;
  }

  save(): void {
    this.dialogRef.close(this.thresholdFormData.value);
  }

  selectTestCase(value: TestCaseApiEntity): void {
    const { id = null, version = null } = value || {};
    this.testCaseFormControl.setValue(id);
    this.thresholdFormData.get('testCaseId').setValue(id);
    this.thresholdFormData.get('testCaseVersion').setValue(version);
  }

  private initForm(): void {
    const thresholdValues = this.thresholdValues ? this.thresholdValues : [];
    this.thresholdFormData = this.formBuilder.group({
      testCaseId: [this.testCaseId, Validators.required],
      testCaseVersion: [this.testCaseVersion, { optional: true }],
      cluster: [this.cluster, Validators.required],
      thresholdVersion: [this.thresholdVersion, { optional: true }],
      comments: [this.comments, { optional: true }],
      active: [this.active, { optional: true }],
      lastChange: [this.lastChange, { optional: true }],
      resultType: [this.resultType, { optional: true }],
      thresholdValues: [thresholdValues, { optional: true }]
    });
  }

  private getAllTestCases() {
    this.isLoadingResults = true;
    this.testCasesService.testCases.subscribe(
      (response) => {
        this.isLoadingResults = false;
        this.testCasesList = response
          .filter((testCase) => testCase.active === true)
          .filter(
            (testCase) => !this.assignedTestCaseIds.includes(testCase.id)
          );
        this.testCasesList.sort((a, b) => (a.id > b.id ? 1 : -1));
      },
      catchError((err: HttpApiErrorResponse) => {
        this.isLoadingResults = false;
        this.testCasesList = [];
        console.error(err);
        return observableOf([]);
      })
    );
  }

  private filterTestCases(value: string): TestCaseApiEntity[] {
    const filterValue = (typeof value === 'string' ? value : '')
      .trim()
      .toLowerCase();

    return this.testCasesList.filter(
      (option) =>
        String(option.id).toLowerCase().startsWith(filterValue) ||
        String(option.name).toLowerCase().includes(filterValue)
    );
  }
}
