<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
    <mat-select
      class="{{controlType}}-element"
      formControlName="areaIds"
      multiple
      [required]="required"
    >
      <mat-option *ngFor="let area of areasList" [value]="area.areaId">{{area.name}}</mat-option>
    </mat-select>
</div>
