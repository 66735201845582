const defaults = {
  production: false,
  executionNodeHealthUrl: '/actuator/health',
  scenarioProgressUrl: '/api/scenarios/progress',
  productServerScenarioUrl: '/api/scenarios',
  productServerAreaUrl: '/api/areas',
  productServerLayerUrl: '/api/layers',
  productServerHealthUrl: '/api/actuator/health',
  productServerInfoUrl: '/api/actuator/info',
  productServerScenarioStartUrl: '/api/scenarios/start',
  productServerScenarioCancelUrl: '/api/scenarios/cancel',
  productServerTestcaseUrl: '/api/testcases',
  productServerCatalogsUrl: '/api/catalogs',
  productServerAutomaticExecutionsUrl: '/api/complex-automatic-executions',
  productServerPerformanceProfileByRunIdUrl: '/api/scenarios/performance',
  adminLoginUrl: '/api/login',
  apiClientMaxConcurrency: 2,
  dataStoreCatalogsUrl: '/datastore/results/catalogs',
  dataStoreComparisonCatalogsUrl:
    '/datastore/results/catalogs/comparison-catalogs',
  dataStoreScenarioRunsUrl: '/datastore/results/scenario-runs',
  dataStoreLocalizationTestUrl: '/datastore/results/localization',
  dataStoreMapViewerUrl: '/datastore/tiles',
  dataStoreTestCasesUrl: '/datastore/testcases',
  dataStoreThresholdsUrl: '/datastore/thresholds',
  dataStoreResultsTilesResultOutcomeUrl:
    '/datastore/results/tiles/resultOutcome',
  dataStoreDataVersionOutcomeUrl: '/datastore/results/tiles/data-version',
  dataStoreResultsTilesResultOutcomeDeltaUrl:
    '/datastore/results/tiles/resultOutcomeDelta',
  dataStoreAreasUrl: '/datastore/areas',
  defaultMessageDuration: 3000,
  defaultRefreshPeriod: 1000 * 60 * 3,
  grafanaDashboardDefaultUrl:
    '/d/kingfisher-default-dashboard/kingfisher-default-dashboard?orgId=1',
  mapViewerBaseMaps: [
    {
      key: 'osm',
      title: 'Open Street Map',
      urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  ],
  mapViewerOptions: {
    zoom: 12,
    maxZoom: 18,
    minZoom: 5
  },
  mapViewerLayerOptions: {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  },
  pageSizeOptions: [10, 20, 50, 100, 250, 500],
  pageSize: 20,
  realm: 'kingfisher',
  clientId: 'login-app'
};

export default defaults;
