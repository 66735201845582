import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export default class Util {
  static valueStringToArray(
    valueString: string,
    _split: string = ','
  ): Array<string> {
    return valueString
      .split(',')
      .map((value) => value.trim())
      .filter((value) => value !== '');
  }

  static cleanValueString(valueString: string, split: string = ','): string {
    return this.valueStringToArray(valueString).join(split);
  }

  static calcPagination(numEntries, page = 1, size = 10): any {
    const total = numEntries;
    const pages = Math.ceil(total / size);
    const start = (page - 1) * size;
    const end = Math.min(start + size, total);
    return { total, pages, start, end };
  }
}

export function getFormValidationErrors(form: FormGroup) {
  const result = [];
  Object.keys(form.controls).forEach((key) => {
    const formProperty = form.get(key);
    if (formProperty instanceof FormArray) {
      for (const subFormProperty of (formProperty as FormArray).controls) {
        result.push(...getFormValidationErrors(subFormProperty as FormGroup));
      }
    }
    if (formProperty instanceof FormGroup) {
      result.push(...getFormValidationErrors(formProperty as FormGroup));
    }
    const controlErrors: ValidationErrors = formProperty.errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        result.push({
          control: key,
          error: keyError,
          value: controlErrors[keyError]
        });
      });
    }
  });
  return result;
}
