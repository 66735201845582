import { Component, OnDestroy, OnInit } from '@angular/core';
import { AreasService } from '@modules/areas/service/areas.service';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  MapEvent,
  MapEventService,
  TilePayload
} from '@modules/map-viewer/service/map-event.service';
import {
  AttributionStatus,
  ControlledAccess,
  DataVersionTileEntity,
  DeltaTileEntity,
  FunctionalRoadClass,
  ResultOutcome,
  ResultType,
  TileEntity
} from '@shared/model/datastore';
import { AreasApiEntity } from '@shared/model/productserver';
import { Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';

@Component({
  selector: 'app-map-viewer-user-selection',
  templateUrl: './map-viewer-user-selection.component.html',
  styleUrls: ['./map-viewer-user-selection.component.scss']
})
export class MapViewerUserSelectionComponent implements OnInit, OnDestroy {
  private $infoTileId: number;
  private $subscription: Subscription = new Subscription();
  private $infoTileEntity: TileEntity;
  private $infoDeltaTileEntity: DeltaTileEntity;
  private $infoDataVersionTileEntity: DataVersionTileEntity;
  private $dataVersionTileEntity: DataVersionTileEntity;

  constructor(
    private mapEventService: MapEventService,
    private areaService: AreasService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get tileId(): number {
    return this.mapViewerUserSelectionService.tileId;
  }

  get areaId(): number {
    return this.mapViewerUserSelectionService.areaId;
  }

  get visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  get mapDataType(): string {
    return this.mapViewerUserSelectionService.mapDataType;
  }

  get layerName(): string {
    return this.mapViewerUserSelectionService.layerName;
  }

  get catalogName(): string {
    return this.mapViewerUserSelectionService.mainCatalogName;
  }

  get catalogVersion(): number {
    return this.mapViewerUserSelectionService.mainCatalogVersion;
  }

  get comparisonCatalogName(): string {
    return this.mapViewerUserSelectionService.mainComparisonCatalogName;
  }

  get comparisonCatalogVersion(): number {
    return this.mapViewerUserSelectionService.mainComparisonCatalogVersion;
  }

  get deltaCatalogName(): string {
    return this.mapViewerUserSelectionService.deltaCatalogName;
  }

  get deltaCatalogVersion(): number {
    return this.mapViewerUserSelectionService.deltaCatalogVersion;
  }

  get deltaComparisonCatalogName(): string {
    return this.mapViewerUserSelectionService.deltaComparisonCatalogName;
  }

  get deltaComparisonCatalogVersion(): number {
    return this.mapViewerUserSelectionService.deltaComparisonCatalogVersion;
  }

  get testCaseId(): string {
    return this.mapViewerUserSelectionService.testCaseId;
  }

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService.resultType;
  }

  get area(): AreasApiEntity {
    return this.mapViewerUserSelectionService.area;
  }

  get infoTile(): TileEntity {
    return this.$infoTileEntity;
  }

  get infoTileFailRate(): number {
    if (
      this.$infoTileEntity &&
      this.outcomeHasNoResult(this.$infoTileEntity.resultOutcome)
    ) {
      return null;
    }
    return this.$infoTileEntity &&
      Number.isFinite(this.$infoTileEntity.failRate)
      ? this.$infoTileEntity.failRate
      : null;
  }

  get infoTileFailCount(): number {
    if (
      this.$infoTileEntity &&
      this.outcomeHasNoResult(this.$infoTileEntity.resultOutcome)
    ) {
      return null;
    }
    return this.$infoTileEntity &&
      Number.isFinite(this.$infoTileEntity.failCount)
      ? this.$infoTileEntity.failCount
      : null;
  }

  get infoTileValue(): number {
    return this.$infoTileEntity && Number.isFinite(this.$infoTileEntity.value)
      ? this.$infoTileEntity.value
      : null;
  }

  get infoDataVersionTileValue(): number | string {
    const result =
      this.$infoDataVersionTileEntity &&
      Number.isFinite(this.$infoDataVersionTileEntity.dataVersion)
        ? this.$infoDataVersionTileEntity.dataVersion
        : null;
    if (result === 0) {
      return 'NO DATA';
    }
    return result;
  }

  get dataVersionTileValue(): number | string {
    const result =
      this.$dataVersionTileEntity &&
      Number.isFinite(this.$dataVersionTileEntity.dataVersion)
        ? this.$dataVersionTileEntity.dataVersion
        : null;
    if (result === 0) {
      return 'NO DATA';
    }
    return result;
  }

  get infoTileUnit(): string {
    return this.$infoTileEntity && this.$infoTileEntity.unit;
  }

  get isResultVisualization(): boolean {
    return this.visualizationMode === MapViewerVisualizationMode.RESULT;
  }

  get isDeltaVisualization(): boolean {
    return this.visualizationMode === MapViewerVisualizationMode.DELTA;
  }

  get isResultTypeFormatContent(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT;
  }

  get isResultTypeCombinedDetailedStatistic(): boolean {
    return this.resultType === ResultType.COMBINED_DETAILED_STATISTIC;
  }

  get isResultTypeFormatContentDetailed(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT_DETAILED;
  }

  get isResultTypeMapApproval(): boolean {
    return this.resultType === ResultType.MAP_APPROVAL;
  }

  get isResultTypeStatistic(): boolean {
    return this.resultType === ResultType.STATISTIC;
  }

  get isResultTypeStatisticDetailed(): boolean {
    return this.resultType === ResultType.STATISTIC_DETAILED;
  }

  get isResultTypeWithValue(): boolean {
    return [ResultType.STATISTIC, ResultType.STATISTIC_DETAILED].includes(
      this.resultType
    );
  }

  get isResultTypeWithFailRate(): boolean {
    return [
      ResultType.FORMAT_CONTENT,
      ResultType.FORMAT_CONTENT_DETAILED,
      ResultType.COMBINED_DETAILED_STATISTIC
    ].includes(this.resultType);
  }

  get infoDeltaMainTileResultOutcome(): ResultOutcome {
    return (
      this.$infoDeltaTileEntity && this.$infoDeltaTileEntity.resultOutcome2
    );
  }

  get infoDeltaComparisonTileResultOutcome(): ResultOutcome {
    return (
      this.$infoDeltaTileEntity && this.$infoDeltaTileEntity.resultOutcome1
    );
  }

  get infoDeltaTileDeltaFailRate(): number {
    if (
      this.$infoDeltaTileEntity &&
      this.outcomeHasNoResult(this.$infoDeltaTileEntity.resultOutcome1) &&
      this.outcomeHasNoResult(this.$infoDeltaTileEntity.resultOutcome2)
    ) {
      return null;
    }
    return this.$infoDeltaTileEntity &&
      Number.isFinite(this.$infoDeltaTileEntity.failRateDelta)
      ? this.$infoDeltaTileEntity.failRateDelta
      : null;
  }

  get infoDeltaTileDeltaValue(): number {
    return this.$infoDeltaTileEntity &&
      Number.isFinite(this.$infoDeltaTileEntity.valueDelta)
      ? this.$infoDeltaTileEntity.valueDelta
      : null;
  }

  get infoDeltaTileUnit(): string {
    return this.$infoDeltaTileEntity && this.$infoDeltaTileEntity.unit;
  }

  get functionalRoadClass(): FunctionalRoadClass {
    return this.mapViewerUserSelectionService.functionalRoadClass;
  }

  get attributionStatus(): AttributionStatus {
    return this.mapViewerUserSelectionService.attributionStatus;
  }

  get controlledAccess(): ControlledAccess {
    return this.mapViewerUserSelectionService.controlledAccess;
  }

  get ifDetailedTestCasesAvailable(): boolean {
    if (
      this.isResultTypeFormatContentDetailed ||
      this.isResultTypeStatisticDetailed
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isTestCaseDetailed(): boolean {
    return (
      this.mapViewerUserSelectionService.testCase &&
      [
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.STATISTIC_DETAILED
      ].includes(this.mapViewerUserSelectionService.testCase.resultType)
    );
  }

  get isTestCaseDetailedAttributed(): boolean {
    return (
      this.isTestCaseDetailed &&
      this.mapViewerUserSelectionService.testCase.mainLayer === 'lane'
    );
  }

  get infoTileId(): number {
    return this.$infoTileId;
  }

  set infoTileId(tileId: number) {
    this.$infoTileId = tileId;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.MOUSEOVER_TILE, (value: TilePayload) => {
        const { tileId, tileEntity, deltaTileEntity, dataVersionTileEntity } =
          value;
        this.$infoTileId = parseInt(String(tileId), 10);
        this.$infoTileEntity = tileEntity;
        this.$infoDeltaTileEntity = deltaTileEntity;
        this.$infoDataVersionTileEntity = dataVersionTileEntity;
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SUBMIT_LAYER, (value: TilePayload) => {
        const { tileId, dataVersionTileEntity } = value;
        this.$infoTileId = parseInt(String(tileId), 10);
        this.$dataVersionTileEntity = dataVersionTileEntity;
      })
    );
  }

  hasUserSelection(): boolean {
    return (
      !!this.areaId ||
      !!this.catalogName ||
      !!this.catalogVersion ||
      !!this.testCaseId ||
      !!this.tileId
    );
  }

  private outcomeHasNoResult(outcome: ResultOutcome) {
    return [ResultOutcome.FAILURE, ResultOutcome.NOT_EXECUTED].includes(
      outcome
    );
  }
}
