import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TriggerJobMapCatalogInfoFormComponent } from '@shared/component/trigger-job/map-catalog-info-form/trigger-job-map-catalog-info-form.component';
import { TriggerJobTestAreaFormComponent } from '@shared/component/trigger-job/test-area-form/trigger-job-test-area-form.component';
import {
  CatalogInfoApiEntity,
  ScenarioRunParameters
} from '@shared/model/productserver';
import { CatalogService } from '@shared/service/catalog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trigger-job-run-parameters-form',
  templateUrl: './trigger-job-run-parameters-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriggerJobRunParametersFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(TriggerJobMapCatalogInfoFormComponent, { static: true })
  mapCatalogInfoForm: TriggerJobMapCatalogInfoFormComponent;

  @ViewChild(TriggerJobTestAreaFormComponent, { static: true })
  testAreaForm: TriggerJobTestAreaFormComponent;
  @Output() areaSelected: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() tileIdsSelected: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() routeIdsSelected: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() selectionTypeChanged = new EventEmitter<{
    selectionType: string;
    index: number;
  }>();
  @Input() index: number;

  @Output() mapDataTypeSelected = new EventEmitter<any>();
  @Output() comparisonMapDataTypeSelected = new EventEmitter<any>();
  @Output() catalogNameSelected = new EventEmitter<string>();
  @Output() comparisonCatalogNameSelected = new EventEmitter<string>();
  @Output() catalogVersionSelected = new EventEmitter<number>();
  @Output() comparisonCatalogVersionSelected = new EventEmitter<number>();
  public form: FormGroup;

  private $mapCatalogInfos: CatalogInfoApiEntity[];
  private $areaIds: number[];
  private $tileIds: number[];
  private $routeIds: number[];
  private $enabledRelativeCatalogVersions = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private catalogService: CatalogService
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get mapCatalogInfos(): CatalogInfoApiEntity[] {
    return this.$mapCatalogInfos;
  }

  @Input() set mapCatalogInfos(value: CatalogInfoApiEntity[]) {
    if (value === null || value === undefined) {
      this.$mapCatalogInfos = [];
    } else {
      this.$mapCatalogInfos = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get areaIds(): number[] {
    return this.$areaIds;
  }

  @Input() set areaIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$areaIds = [];
    } else {
      this.$areaIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get tileIds(): number[] {
    return this.$tileIds;
  }

  @Input() set tileIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$tileIds = [];
    } else {
      this.$tileIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get routeIds(): number[] {
    return this.$routeIds;
  }

  @Input() set routeIds(value: number[]) {
    if (value === null || value === undefined) {
      this.$routeIds = [];
    } else {
      this.$routeIds = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get enabledRelativeCatalogVersions(): boolean {
    return this.$enabledRelativeCatalogVersions;
  }

  @Input() set enabledRelativeCatalogVersions(value: boolean) {
    if (value === null || value === undefined) {
      this.$enabledRelativeCatalogVersions = false;
    } else {
      this.$enabledRelativeCatalogVersions = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get invalid(): boolean {
    return this.mapCatalogInfoForm.invalid || this.testAreaForm.invalid;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
  }

  ngAfterViewInit(): void {
    this.form.addControl('testArea', this.testAreaForm.form);
    this.form.addControl('mapCatalogInfos', this.mapCatalogInfoForm.form);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getFormValues(): ScenarioRunParameters {
    const { tileIds, areaIds, routeIds } = this.testAreaForm.getFormValues();
    const mapCatalogInfo = this.mapCatalogInfoForm.getFormValues();
    return {
      tileIds,
      areaIds,
      routeIds,
      mapCatalogInfos: [mapCatalogInfo]
    };
  }

  onAreaSelected(areaIds: number[]): void {
    this.areaSelected.emit(areaIds);
  }

  onTileIdsSelected(tileIds: number[]): void {
    this.tileIdsSelected.emit(tileIds);
  }

  onRouteIdsSelected(routeIds: number[]): void {
    this.routeIdsSelected.emit(routeIds);
  }

  onSelectionTypeChanged(selectionType: string, index: number): void {
    this.selectionTypeChanged.emit({ selectionType, index });
  }

  onComparisonCatalogVersionSelectionChange(comparisonCatalogVersion: number) {
    this.comparisonCatalogVersionSelected.emit(comparisonCatalogVersion);
  }

  onCatalogNameSelectionChange(catalogName: string) {
    this.catalogNameSelected.emit(catalogName);
  }

  onCatalogVersionSelectionChange(catalogVersion: number) {
    this.catalogVersionSelected.emit(catalogVersion);
  }

  onComparisonCatalogNameSelectionChange(comparisonCatalogName: string) {
    this.comparisonCatalogNameSelected.emit(comparisonCatalogName);
  }

  onComparisonMapDataSelectionChange(comparisonMapDataType: any) {
    this.comparisonMapDataTypeSelected.emit(comparisonMapDataType);
  }

  onMapDataSelectionChange(mapDataType: any) {
    this.mapDataTypeSelected.emit(mapDataType);
  }
}
