<table *ngIf="scenarioApiEntity" aria-describedby="detailsTableDescription" class="details-table">
  <colgroup>
    <col span="1" style="width:10%"/>
    <col span="1" style="width:90%"/>
  </colgroup>
  <tbody>
  <tr>
    <th scope="row">id:</th>
    <td>{{scenarioApiEntity.id}}</td>
  </tr>
  <tr>
    <th scope="row">name:</th>
    <td>{{scenarioApiEntity.name}}</td>
  </tr>
  <tr>
    <th scope="row">description:</th>
    <td>{{scenarioApiEntity.description}}</td>
  </tr>
  <tr>
    <th scope="row">testCases:</th>
    <td>
      <ng-template ngFor let-item [ngForOf]="scenarioApiEntity.testCases || []" let-testCaseId let-i="index">
        <span *ngIf="i > 0">, </span>
        <span
          matTooltip="{{testCaseId|testCaseDetails:'name'}}: {{testCaseId|testCaseDetails:'description'}}">{{testCaseId}}</span>
      </ng-template>
    </td>
  </tr>
  </tbody>
  <caption id="detailsTableDescription">Scenario details</caption>
</table>
