<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
    <mat-progress-spinner *ngIf="isLoading" color="accent" diameter="20" matSuffix mode="indeterminate"></mat-progress-spinner>
    <input
      matInput
      class="{{controlType}}-element"
      [matAutocomplete]="autoVersion"
      [readonly]="isLoading"
      formControlName="version"
      placeholder="eg. 123"
      type="number"
    />
    <mat-autocomplete #autoVersion="matAutocomplete" (optionSelected)="_onSelectionChange($event.option.value)">
      <mat-option *ngFor="let option of autocomplete$ || []" [value]="option.version">
        {{option.version}}
      </mat-option>
    </mat-autocomplete>
</div>

