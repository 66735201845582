<ng-container *ngIf="testCaseResult">
  <table class="kf-result-single-view" aria-label="Result Details">
    <colgroup>
      <col span="1" style="width:30%;">
      <col span="1" style="width:70%;">
    </colgroup>
    <tbody>
    <tr>
      <th scope="row">Test</th>
      <td
        matTooltip="{{testCaseId|testCaseDetails:'description'}}">{{testCaseId}}
        - {{testCaseId|testCaseDetails:'name'}}</td>
    </tr>
    <tr>
      <th scope="row">Outcome</th>
      <td>{{resultOutcome}}</td>
    </tr>
    <tr>
      <th scope="row">Feature</th>
      <td>{{featureType}}</td>
    </tr>
    <tr>
      <th scope="row">Total</th>
      <td
      >{{total|number:"1.0-2":"de"}} {{unit !== 'none' ? unit : ''}}</td>
    </tr>
    </tbody>
  </table>
</ng-container>
