import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  ComplexTriggerActionObject,
  ComplexTriggerJobObject,
  TestAreaType,
  TriggerType
} from '@shared/model/productserver';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-automated-execution-form',
  templateUrl: './automated-execution-form.component.html',
  styleUrls: ['./automated-execution-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomatedExecutionFormComponent implements OnInit, OnDestroy {
  public complexTriggerJobForm: FormGroup = null;
  complexTriggerTypes: Array<TriggerType> = [
    TriggerType.VERSIONCOMPLEX,
    TriggerType.TIMECOMPLEX
  ];
  private $complexTriggerJob: ComplexTriggerJobObject = null;
  private subscription: Subscription = new Subscription();

  constructor(private formBuilder: FormBuilder) {
    this.complexTriggerJobForm = this.createComplexTriggerJobForm();
  }

  get complexTriggerJob(): ComplexTriggerJobObject {
    return this.$complexTriggerJob;
  }

  @Input() set complexTriggerJob(value: ComplexTriggerJobObject) {
    if (value === null || value === undefined) {
      this.$complexTriggerJob = null;
    } else {
      this.$complexTriggerJob = value;
      this.patchForm();
      this.complexTriggerJobForm.patchValue(value);
    }
  }

  get triggerActions() {
    return this.complexTriggerJobForm.get('triggerActions') as FormArray;
  }

  get invalid(): boolean {
    return !this.complexTriggerJobForm.valid;
  }

  get selectedComplexTriggerType(): number {
    return this.complexTriggerTypes.indexOf(
      this.complexTriggerJobForm.get('type').value
    );
  }

  set selectedComplexTriggerType(index: number) {
    this.complexTriggerJobForm
      .get('type')
      .setValue(this.complexTriggerTypes[index]);
  }

  patchForm(): void {
    this.complexTriggerJobForm.patchValue({
      active: this.$complexTriggerJob.active,
      observedCatalog: this.$complexTriggerJob.observedCatalog,
      catalogVersionInterval: this.$complexTriggerJob.catalogVersionInterval,
      cronPattern: this.$complexTriggerJob.cronPattern || '* * * * * *',
      name: this.$complexTriggerJob.name,
      type: this.$complexTriggerJob.type || TriggerType.VERSIONCOMPLEX
    });
    this.patchTriggerActions();
  }

  patchTriggerActions(): void {
    this.$complexTriggerJob.triggerActions?.sort(
      (a, b) => a.parentIndex - b.parentIndex
    );
    this.$complexTriggerJob.triggerActions?.map((triggerAction) => {
      const triggerActionGroup = this.createComplexTriggerActionForm();
      triggerActionGroup.patchValue(triggerAction);
      this.triggerActions.push(triggerActionGroup);
    });
  }

  public createComplexTriggerActionForm(): FormGroup {
    const triggerActionForm = this.formBuilder.group({
      triggerJobId: null,
      scenarioIds: [[], [Validators.required]],
      areaIds: [],
      routeIds: [],
      tileIds: [],
      mapCatalogInfo: [null, [Validators.required]],
      priority: [0, [Validators.required]],
      parentId: null,
      parentIndex: null,
      testAreaType: [TestAreaType.AREA, [Validators.required]]
    });
    triggerActionForm.controls.testAreaType.valueChanges.subscribe(
      (testAreaType) => {
        switch (testAreaType) {
          case TestAreaType.AREA:
            triggerActionForm
              .get('areaIds')
              .setValidators([Validators.required]);
            triggerActionForm.get('routeIds').clearValidators();
            triggerActionForm.get('routeIds').reset();
            triggerActionForm.get('tileIds').clearValidators();
            triggerActionForm.get('tileIds').reset();
            break;
          case TestAreaType.ROUTE:
            triggerActionForm.get('areaIds').clearValidators();
            triggerActionForm.get('areaIds').reset();
            triggerActionForm
              .get('routeIds')
              .setValidators([Validators.required]);
            triggerActionForm.get('tileIds').clearValidators();
            triggerActionForm.get('tileIds').reset();
            break;
          case TestAreaType.TILE:
            triggerActionForm.get('areaIds').clearValidators();
            triggerActionForm.get('areaIds').reset();
            triggerActionForm.get('routeIds').clearValidators();
            triggerActionForm.get('routeIds').reset();
            triggerActionForm
              .get('tileIds')
              .setValidators([Validators.required]);
            break;
        }
        triggerActionForm.get('areaIds').updateValueAndValidity();
        triggerActionForm.get('routeIds').updateValueAndValidity();
        triggerActionForm.get('tileIds').updateValueAndValidity();
      }
    );
    return triggerActionForm;
  }

  toggleTriggerAction(idx: number, checked: any) {
    this.triggerActions.at(idx).patchValue({ priority: checked ? 1 : 0 });
  }

  removeTriggerAction(idx: number): void {
    this.triggerActions.removeAt(idx);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addTriggerAction() {
    this.triggerActions.push(this.createComplexTriggerActionForm());
    this.reindexTriggerActions();
  }

  moveTriggerAction(event: CdkDragDrop<FormArray>) {
    const array = this.triggerActions.value;
    moveItemInArray(array, event.previousIndex, event.currentIndex);
    array.forEach((x: any, i: number) => (x.parentIndex = i + 1));
    this.triggerActions.setValue(array);
  }

  public reindexTriggerActions() {
    const array = this.triggerActions.value;
    array.forEach((x: any, i: number) => (x.parentIndex = i + 1));
    this.triggerActions.setValue(array);
  }

  getTriggerActionFormControl(
    idx: number,
    formControlName: string
  ): AbstractControl {
    return this.triggerActions.at(idx).get(formControlName);
  }

  isTriggerActionRequiredField(idx: number, formControlName: string) {
    const formControl = this.triggerActions.at(idx).get(formControlName);
    if (!formControl.validator) {
      return false;
    }
    const validator = formControl.validator({} as AbstractControl);
    return validator && validator.required;
  }

  getFormControl(formControlName: string): AbstractControl {
    return this.complexTriggerJobForm.get(formControlName);
  }

  isRequiredField(formControlName: string) {
    const formControl = this.getFormControl(formControlName);
    if (!formControl.validator) {
      return false;
    }
    const validator = formControl.validator({} as AbstractControl);
    return validator && validator.required;
  }

  public createComplexTriggerJobForm(): FormGroup {
    const triggerJobForm = this.formBuilder.group({
      active: false,
      observedCatalog: ['', [Validators.required]],
      catalogVersionInterval: [0, [Validators.required]],
      cronPattern: '* * * * * *',
      name: ['', [Validators.required]],
      triggerActions: this.formBuilder.array([], [Validators.required]),
      type: [TriggerType.VERSIONCOMPLEX, [Validators.required]]
    });
    triggerJobForm.controls.type.valueChanges.subscribe((type) => {
      switch (type) {
        case TriggerType.VERSIONCOMPLEX:
          triggerJobForm.controls.observedCatalog.setValidators([
            Validators.required
          ]);
          triggerJobForm.controls.catalogVersionInterval.setValidators([
            Validators.required
          ]);
          triggerJobForm.controls.cronPattern.clearValidators();
          break;
        case TriggerType.TIMECOMPLEX:
          triggerJobForm.controls.observedCatalog.clearValidators();
          triggerJobForm.controls.catalogVersionInterval.clearValidators();
          triggerJobForm.controls.cronPattern.setValidators([
            Validators.required
          ]);
          break;
      }
      triggerJobForm.controls.observedCatalog.updateValueAndValidity();
      triggerJobForm.controls.catalogVersionInterval.updateValueAndValidity();
      triggerJobForm.controls.cronPattern.updateValueAndValidity();
    });
    return triggerJobForm;
  }
}
