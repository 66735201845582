import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors
} from '@angular/forms';
import { AreasService } from '@modules/areas/service/areas.service';
import Util from '@shared/helper/util';
import { AreasListApiEntity } from '@shared/model/productserver';
import { of as observableOf, Subscription } from 'rxjs';

@Component({
  selector: 'app-test-area-form',
  templateUrl: './test-area-form.component.html',
  styleUrls: ['./test-area-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestAreaFormComponent implements OnInit, OnDestroy {
  @Input() areaIds: number[] = [];
  @Input() tileIds: number[] = [];
  @Input() routeIds: number[] = [];

  public form: FormGroup;

  private areasList$: Array<AreasListApiEntity> = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private areasService: AreasService
  ) {}

  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  get areasList(): Array<AreasListApiEntity> {
    return this.areasList$;
  }

  get countTiles(): number {
    return (
      this.form
        .get('tileIds')
        ?.value?.split(',')
        .filter((tileId: string) => !!tileId.trim()).length || 0
    );
  }

  get countAreas(): number {
    return this.form.get('areaIds')?.value?.length || 0;
  }

  get hasTilesAndAreas(): boolean {
    return this.countTiles > 0 && this.countAreas > 0;
  }

  ngOnInit(): void {
    this.getAreaList();
    this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createFormGroup(): FormGroup {
    this.form = this.fb.group(
      {
        tileIds: [this.tileIds?.join(',')],
        areaIds: [this.areaIds],
        routeIds: [this.routeIds?.join(',')]
      },
      {
        validators: [this.checkTestArea]
      }
    );
    return this.form;
  }

  getAreaList(): void {
    this.subscription.add(
      this.areasService.getAreasList().subscribe(
        (response) => {
          this.areasList$ = response.data;
          this.form.get('areaIds').setValue(this.areaIds);
        },
        (err) => {
          console.error(err);
          return observableOf([]);
        }
      )
    );
  }

  checkTestArea(control: AbstractControl): ValidationErrors | null {
    const tileIds = control.get('tileIds').value;
    const areaIds = control.get('areaIds').value;
    const routeIds = control.get('routeIds').value;

    const hasTileIds = tileIds?.length;
    const hasAreaIds = areaIds?.length;
    const hasRouteIds = routeIds?.length;

    if (!hasTileIds && !hasAreaIds && !hasRouteIds) {
      return { required: true };
    }

    return null;
  }

  public getFormValues(): any {
    const { tileIds, areaIds, routeIds } = this.form.getRawValue();
    return {
      tileIds: tileIds
        ? Util.valueStringToArray(tileIds)
            .map((val: string) => parseInt(val.trim(), 10))
            .filter((value) => !isNaN(value))
        : [],
      areaIds: areaIds || [],
      routeIds: routeIds
        ? Util.valueStringToArray(routeIds)
            .map((val: string) => parseInt(val.trim(), 10))
            .filter((value) => !isNaN(value))
        : []
    };
  }

  public clearTileIds(): void {
    this.form.get('tileIds').setValue(null);
  }
}
