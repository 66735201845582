<form [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Observed Catalog</mat-label>
    <mat-icon matSuffix>map</mat-icon>
    <mat-select formControlName="observedCatalog" panelClass="kf-catalog-select">
      <mat-optgroup *ngFor="let mapDataType of catalogsList.keys()" [label]="mapDataType">
        <mat-option *ngFor="let catalogApiEntity of catalogsList.get(mapDataType)"
                    [value]="catalogApiEntity.name">
          {{catalogApiEntity.name}}</mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-hint>Scenarios will be triggered by updates of selected catalog</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Version Interval</mat-label>
    <input formControlName="catalogVersionInterval" matInput min="1" type="number">
    <mat-hint>Scenarios will be triggered by every n-th version</mat-hint>
  </mat-form-field>
</form>
