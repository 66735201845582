<div class="container">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined" class="login-card">
      <mat-card-title>
        <img src="/assets/img/kf_logo_blank.svg" class="logo-img" alt="logo" />
      </mat-card-title>
      <mat-card-content>
        <mat-form-field color="accent">
          <input id="username" type="text" matInput formControlName="username" placeholder="Username"/>
          <mat-icon matSuffix>account_circle</mat-icon>
        </mat-form-field>
        <mat-form-field color="accent">
          <input id="password" type="password" matInput formControlName="password" placeholder="Password"/>
          <mat-icon matSuffix>vpn_key</mat-icon>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button id="submit" mat-raised-button color="primary" type="submit" value="Login"><mat-icon class="logo-img">lock_outline</mat-icon> Login</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
