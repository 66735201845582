
  <table *ngIf="data" style="width:100%" aria-label="Context Infos">
    <colgroup>
      <col span="1" style="width:30%" />
      <col span="1" style="width:70%" />
    </colgroup>
    <tbody>
    <tr>
      <th scope="row">Lat/Lng</th>
      <td style="white-space: nowrap;">
        <code>{{data.pointWgs84.latitude|number:"1.0-9":"en"}},{{data.pointWgs84.longitude|number:"1.0-9":"en"}}</code><button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="(data.pointWgs84.latitude)+','+data.pointWgs84.longitude"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
      </td>
    </tr>
    <tr>
      <th scope="row">NDS TileId</th>
      <td>
        <code>{{data.ndsTileId}}</code>
        <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="data.ndsTileId"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
      </td>
    </tr>
    <tr>
      <th scope="row">HNative TileId</th>
      <td>
        <code>{{data.hnativeTileId}}</code>
        <button type="button" class="button-content-copy" matRipple [matRippleRadius]="12" [matRippleCentered]="true" aria-label="Copy to clipboard" [cdkCopyToClipboard]="data.hnativeTileId"  onclick="this.blur()"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
      </td>
    </tr>
    <tr>
      <th scope="row">Map Viewer</th>
      <td>
        <a *ngIf="data.hdmlViewerUrl" [href]="data.hdmlViewerUrl" target="_blank" rel="noopener">
          Show in HDLM v2 Viewer
        </a>
        <a *ngIf="!data.hdmlViewerUrl" [href]="data.hdml3ViewerUrl" target="_blank" rel="noopener">
          Show in HDLM v3 Viewer
        </a>
      </td>
    </tr>
    <tr *ngIf="data.hdmlViewerUrl">
      <th scope="row"></th>
      <td>
        <a [href]="data.hdml3ViewerUrl" target="_blank" rel="noopener">
          Show in HDLM v3 Viewer
        </a>
      </td>
    </tr>
    </tbody>
  </table>
