import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';

import { PageTitleComponent } from './component/page-title/page-title.component';
import { JsonBlockComponent } from './component/jsonblock/jsonblock.component';

import { JoinPipe } from '@shared/pipe/join.pipe';
import { UtcToLocalPipe } from '@shared/pipe/utc-to-local.pipe';
import { PaginatorComponent } from './component/paginator/paginator.component';
import { TestCaseDetailsPipe } from './pipe/test-case-details.pipe';
import { CatalogListComponent } from './component/catalog/catalog-list/catalog-list.component';
import { TriggerJobListComponent } from './component/trigger-job/trigger-job-list/trigger-job-list.component';
import { LoadingSpinnerComponent } from './component/loading-spinner/loading-spinner.component';
import { MapCatalogInfoFormComponent } from '@shared/component/scenario/map-catalog-info-form/map-catalog-info-form.component';
import { TestAreaFormComponent } from './component/scenario/test-area-form/test-area-form.component';
import { RunParametersFormComponent } from './component/scenario/run-parameters-form/run-parameters-form.component';
import { ScenarioSelectionComponent } from '@shared/component/scenario/scenario-selection/scenario-selection.component';
import { AutomatedExecutionFormComponent } from './component/trigger-job/automated-execution-form/automated-execution-form.component';
import { AutomatedExecutionDialogComponent } from './component/automated-execution-dialog/automated-execution-dialog.component';
import { CronPatternFormComponent } from './component/cron-pattern-form/cron-pattern-form.component';
import { CronParsePipe } from './pipe/cronParse.pipe';
import { CatalogVersionFormComponent } from './component/catalog-version-form/catalog-version-form.component';
import { ScenarioDetailsPipe } from './pipe/scenario-id-to-name.pipe';
import { HighlightSearchPipe } from './pipe/highlight-search.pipe';
import { ValueRangeBarComponent } from './component/value-range-bar/value-range-bar.component';
import { CatalogNamePipe } from './pipe/catalog-name.pipe';
import { ToastrModule } from 'ngx-toastr';
import { MatSpinnerOverlayComponent } from './component/mat-spinner-overlay/mat-spinner-overlay.component';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { AreaNamePipe } from '@shared/pipe/area-name.pipe';
import { TruncatePipe } from '@shared/pipe/truncate.pipe';
import { UcfirstPipe } from '@shared/pipe/ucfirst.pipe';
import { TriggerActionDetailsComponent } from '@shared/component/trigger-job/trigger-action-details/trigger-action-details.component';
import { TriggerJobMapCatalogInfoFormComponent } from '@shared/component/trigger-job/map-catalog-info-form/trigger-job-map-catalog-info-form.component';
import { TriggerJobTestAreaFormComponent } from '@shared/component/trigger-job/test-area-form/trigger-job-test-area-form.component';
import { TriggerJobRunParametersFormComponent } from '@shared/component/trigger-job/run-parameters-form/trigger-job-run-parameters-form.component';
import { TriggerJobScenarioSelectionComponent } from '@shared/component/trigger-job/scenario-selection/trigger-job-scenario-selection.component';
import { CronPatternFormControlComponent } from './component/form-control/cron-pattern-form-control/cron-pattern-form-control.component';
import { CatalogFormControlComponent } from './component/form-control/catalog-form-control/catalog-form-control.component';
import { CatalogInfoFormControlComponent } from './component/form-control/catalog-info-form-control/catalog-info-form-control.component';
import { ScenarioIdListFormControlComponent } from './component/form-control/scenario-id-list-form-control/scenario-id-list-form-control.component';
import { AreaIdListFormControlComponent } from './component/form-control/area-id-list-form-control/area-id-list-form-control.component';
import { RouteIdListFormControlComponent } from './component/form-control/route-id-list-form-control/route-id-list-form-control.component';
import { TileIdListFormControlComponent } from './component/form-control/tile-id-list-form-control/tile-id-list-form-control.component';
import { CatalogVersionFormControlComponent } from './component/form-control/catalog-version-form-control/catalog-version-form-control.component';
import { TriggerActionTitleComponent } from './component/trigger-job/trigger-action-title/trigger-action-title.component';
import { LinkifyPipe } from './pipe/linkify.pipe';

@NgModule({
  declarations: [
    JoinPipe,
    CatalogListComponent,
    JsonBlockComponent,
    LoadingSpinnerComponent,
    MapCatalogInfoFormComponent,
    PageTitleComponent,
    PaginatorComponent,
    RunParametersFormComponent,
    TestAreaFormComponent,
    TestCaseDetailsPipe,
    TriggerJobListComponent,
    TriggerActionDetailsComponent,
    UtcToLocalPipe,
    ScenarioSelectionComponent,
    AutomatedExecutionFormComponent,
    AutomatedExecutionDialogComponent,
    CronPatternFormComponent,
    CronParsePipe,
    CatalogVersionFormComponent,
    ScenarioDetailsPipe,
    AreaNamePipe,
    UcfirstPipe,
    TruncatePipe,
    HighlightSearchPipe,
    ValueRangeBarComponent,
    CatalogNamePipe,
    MatSpinnerOverlayComponent,
    TriggerJobMapCatalogInfoFormComponent,
    TriggerJobTestAreaFormComponent,
    TriggerJobRunParametersFormComponent,
    TriggerJobScenarioSelectionComponent,
    CronPatternFormControlComponent,
    CatalogFormControlComponent,
    CatalogInfoFormControlComponent,
    ScenarioIdListFormControlComponent,
    ScenarioIdListFormControlComponent,
    AreaIdListFormControlComponent,
    RouteIdListFormControlComponent,
    TileIdListFormControlComponent,
    CatalogVersionFormControlComponent,
    TriggerActionTitleComponent,
    LinkifyPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      includeTitleDuplicates: true
    }),
    CdkDrag,
    CdkDragHandle,
    CdkDropList
  ],
  exports: [
    JoinPipe,
    CatalogListComponent,
    CommonModule,
    FormsModule,
    JsonBlockComponent,
    LoadingSpinnerComponent,
    MapCatalogInfoFormComponent,
    MaterialModule,
    PageTitleComponent,
    PaginatorComponent,
    ReactiveFormsModule,
    RouterModule,
    RunParametersFormComponent,
    TestAreaFormComponent,
    TestCaseDetailsPipe,
    TriggerJobListComponent,
    UtcToLocalPipe,
    ScenarioSelectionComponent,
    AutomatedExecutionFormComponent,
    ScenarioDetailsPipe,
    HighlightSearchPipe,
    ValueRangeBarComponent,
    CatalogNamePipe,
    MatSpinnerOverlayComponent,
    AreaNamePipe,
    LinkifyPipe,
    TriggerJobMapCatalogInfoFormComponent,
    TriggerJobTestAreaFormComponent,
    TriggerJobRunParametersFormComponent,
    TriggerJobScenarioSelectionComponent,
    CronPatternFormControlComponent,
    CatalogFormControlComponent,
    ScenarioIdListFormControlComponent,
    RouteIdListFormControlComponent,
    TileIdListFormControlComponent,
    AreaIdListFormControlComponent,
    CatalogVersionFormControlComponent,
    TriggerActionTitleComponent
  ]
})
export class SharedModule {}
