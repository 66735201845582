import { Component } from '@angular/core';
import { ResultComponent } from '@modules/map-viewer/component/map-viewer-results-overview/views/common/result.component';
import {
  AttributionStatus,
  ControlledAccess,
  FunctionalRoadClass,
  ResultOutcome
} from '@shared/model/datastore';

export interface AttributionCounts {
  attributionStatus: AttributionStatus;
  controlledAccess: ControlledAccess;
  frcCounts: FrcCounts[];
}

export interface FrcCounts {
  functionalRoadClass: FunctionalRoadClass;
  totalCount: number;
  failCount: number;
}

@Component({ template: '' })
export abstract class FormatContentDetailedResultComponent extends ResultComponent {
  get resultOutcome(): ResultOutcome {
    const failCount = this.failCount;
    const resultOutcome = super.resultOutcome;
    if (
      [ResultOutcome.FAILURE, ResultOutcome.NOT_EXECUTED].includes(
        resultOutcome
      )
    ) {
      return resultOutcome;
    }
    if (failCount > 0) {
      return ResultOutcome.FAIL;
    }
    return ResultOutcome.PASS;
  }

  get comparisonResultOutcome(): ResultOutcome {
    const failCount = this.comparisonFailCount;
    const resultOutcome = super.comparisonResultOutcome;
    if (
      [ResultOutcome.FAILURE, ResultOutcome.NOT_EXECUTED].includes(
        resultOutcome
      )
    ) {
      return resultOutcome;
    }
    if (failCount > 0) {
      return ResultOutcome.FAIL;
    }
    return ResultOutcome.PASS;
  }

  get totalCount(): any {
    const attributeCountsList: AttributionCounts[] =
      (this.resultDetails || {}).attributeCounts || [];
    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.totalCount) || 0;
  }

  get failCount(): any {
    const attributeCountsList: AttributionCounts[] =
      (this.resultDetails || {}).attributeCounts || [];
    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.failCount) || 0;
  }

  get comparisonTotalCount(): any {
    const attributeCountsList: AttributionCounts[] =
      (this.comparisonResultDetails || {}).attributeCounts || [];

    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.totalCount) || 0;
  }

  get comparisonFailCount(): any {
    const attributeCountsList: AttributionCounts[] =
      (this.comparisonResultDetails || {}).attributeCounts || [];
    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.failCount) || 0;
  }

  get functionalRoadClass(): FunctionalRoadClass {
    return this.$mapViewerUserSelectionService.functionalRoadClass;
  }

  get attributionStatus(): AttributionStatus {
    return this.$mapViewerUserSelectionService.attributionStatus;
  }

  get controlledAccess(): ControlledAccess {
    return this.$mapViewerUserSelectionService.controlledAccess;
  }

  private filterAttributeCounts(
    attributionStatus: AttributionStatus,
    controlledAccess: ControlledAccess,
    attributeCountsList: AttributionCounts[]
  ): AttributionCounts {
    return attributeCountsList.find(
      (elem) =>
        elem.attributionStatus === attributionStatus.valueOf() &&
        elem.controlledAccess === controlledAccess.valueOf()
    );
  }

  private filterFrcCounts(
    attributionStatus: AttributionStatus,
    controlledAccess: ControlledAccess,
    functionalRoadClass: FunctionalRoadClass,
    attributeCountsList: AttributionCounts[]
  ): FrcCounts {
    const attributeCounts: AttributionCounts = this.filterAttributeCounts(
      attributionStatus,
      controlledAccess,
      attributeCountsList
    );
    if (!attributeCounts) {
      return null;
    }
    return attributeCounts.frcCounts.find(
      (elem) => elem.functionalRoadClass === functionalRoadClass.valueOf()
    );
  }
}
