<mat-form-field appearance="fill" color="accent" floatLabel="always">
  <mat-label>Enter Search String</mat-label>
  <input [(ngModel)]="searchQuery" id="searchQuery" matInput
         placeholder="Search for tileId or coordinates">
  <button (click)="submitSearch()" aria-label="Search" color="primary" mat-icon-button
          matSuffix>
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Select an option</mat-label>
  <mat-select [(value)]="searchFor">
    <mat-option *ngFor="let searchForOption of searchForOptions"
                [value]="searchForOption.value">{{searchForOption.name}}</mat-option>
  </mat-select>
</mat-form-field>
<p>
  <strong>Examples:</strong><br />
</p>
<ul class="list list-no-bullets">
  <li>Lat/Lng: <code>52.3638393,13.5062316</code></li>
  <li>NDS: <code>545666604</code></li>
  <li>HNative: <code>377894444</code></li>
</ul>
<p *ngIf="authService.hasPrivileges(['SCENARIOS_CREATE'])">
  <mat-slide-toggle [(ngModel)]='disableZoomLimit'>Disable Zoom Limit</mat-slide-toggle>
</p>
