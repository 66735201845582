<div>
  <div class="kf-row">
    <div class="kf-cell">
      <button mat-icon-button aria-label="expand row" (click)="(expandedElement = !expandedElement); $event.stopPropagation()">
        <mat-icon *ngIf="!expandedElement">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement">keyboard_arrow_up</mat-icon>
      </button>
    </div>
    <div class="kf-cell align-left">{{threshold.testCaseId}}</div>
    <div class="kf-cell align-left">{{threshold.testCaseName}}</div>
    <div class="kf-cell align-left">{{threshold.resultType}}</div>
    <div class="kf-cell"><mat-icon *ngIf="isTileBasedAllowed(threshold.resultType)" [color]="threshold.isThresholdTileDefined ? 'primary' : 'accent'">check_circle</mat-icon></div>
    <div class="kf-cell"><mat-icon [color]="threshold.isThresholdAreaDefined ? 'primary' : 'accent'">check_circle</mat-icon></div>
    <div class="kf-cell align-left" matTooltip="{{threshold.comments}}"><p class="truncate">{{threshold.comments}}</p></div>
    <div class="kf-cell"><mat-slide-toggle [checked]="threshold.active" color="primary" (toggleChange)="toggleThresholdActive()"></mat-slide-toggle></div>
    <div class="kf-cell align-left">{{threshold.lastChange|date:'dd/MM/yyyy HH:mm:ss z'}}</div>
    <div class="kf-cell align-left">
      <button *ngIf="authService.hasPrivileges(['THRESHOLD_UPDATE'])" mat-mini-fab color="primary" (click)="editThreshold()" aria-label="Edit Threshold" matTooltip="Edit Threshold">
        <mat-icon aria-hidden="false">edit</mat-icon>
      </button>
      <button *ngIf="authService.hasPrivileges(['THRESHOLD_DELETE'])" mat-mini-fab color="warn" (click)="deleteThreshold()" aria-label="Delete Threshold" matTooltip="Delete Threshold">
        <mat-icon aria-hidden="false">delete</mat-icon>
      </button>
      <button *ngIf="hasChangedEntries && authService.hasPrivileges(['THRESHOLD_CREATE'])" mat-mini-fab color="primary" (click)="saveThreshold()" aria-label="Save Threshold" matTooltip="Save Threshold">
        <mat-icon aria-hidden="false">save</mat-icon>
      </button>
    </div>
  </div>
  <div class="kf-row-detail"
       [@detailExpand]="expandedElement ? 'expanded' : 'collapsed'">
    <div class="threshold-detail">
      <div>
        <app-threshold-area-selector [threshold]="threshold" (areaSelectorEmitter)="handleSelectAreaEvent($event)"></app-threshold-area-selector>
      </div>
      <app-threshold-entity [threshold]="threshold"></app-threshold-entity>
    </div>
  </div>
</div>
