import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LocalizationTestApiEntity } from '@shared/model/results';
import { Subscription } from 'rxjs';
import { DatastoreService } from '@shared/service/datastore.service';
import { HttpApiErrorResponse } from '@shared/model/response';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacyTextOnlySnackBar as TextOnlySnackBar
} from '@angular/material/legacy-snack-bar';
import { environment } from '@env';

@Component({
  selector: 'app-localization-test',
  templateUrl: './localization-test.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class LocalizationTestComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'expandDetails',
    'localizationArea',
    'localizationSoftwareVersion',
    'mapCatalog',
    'mapCatalogVersion',
    'timestampExecution',
    'timestampImport'
  ];
  data: MatTableDataSource<LocalizationTestApiEntity>;

  resultsLength = 0;
  isLoadingResults = true;
  pageEvent: PageEvent;
  length = 0;
  pageIndex = 0;
  pageSize = environment.pageSize;
  totalPages = 0;
  pageSizeOptions = environment.pageSizeOptions;

  private subscription: Subscription = new Subscription();

  constructor(
    private datastoreService: DatastoreService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.getLocalizationTestList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handlePageEvent(event: PageEvent): void {
    const sizeSwitched = this.pageSize !== event.pageSize;
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = sizeSwitched ? 0 : event.pageIndex;
    this.getLocalizationTestList();
  }

  getLocalizationTestList(): void {
    this.isLoadingResults = true;

    this.subscription.add(
      this.datastoreService
        .getLocalizationTests(this.pageIndex + 1, this.pageSize)
        .subscribe(
          // scenarioArray is the response.data which is received from the rest call
          (response) => {
            const { data, pagination } = response;
            this.totalPages = pagination.totalPages;
            this.length = pagination.totalItems;
            this.pageSize = pagination.pageSize;
            this.pageIndex = pagination.currentPage - 1;
            this.resultsLength = response.size;
            this.data = new MatTableDataSource(data);
            this.isLoadingResults = false;
          },
          (error: HttpApiErrorResponse) => {
            this.isLoadingResults = false;
            console.error(error.error);
            this.showError(
              `${error.error.message} (${error.error.error})`,
              `${error.status}`
            );
          }
        )
    );
  }

  showError(
    message: string = '',
    action: string = ''
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, { panelClass: ['error'] });
  }
}
