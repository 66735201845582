<ng-container *ngIf="testCaseId; else elseCreateHeader">
  <h3 mat-dialog-title>Edit Threshold</h3>
</ng-container>
<ng-template #elseCreateHeader>
  <h3 mat-dialog-title>Create Threshold</h3>
</ng-template>
<div mat-dialog-content [formGroup]="thresholdFormData">
  <div class="dialog-form">
    <div *ngIf="testCaseId">
      <mat-form-field  appearance="fill">
        <mat-label>TestCase</mat-label>
        <input type="email" matInput readonly disabled value="{{testCaseId}}">
      </mat-form-field>
    </div>
    <ng-container *ngIf="!testCaseId">
      <mat-form-field appearance="fill" color="accent" floatLabel="always" style="display: block">
      <mat-label>TestCase</mat-label>
      <input matInput
             aria-label="TestCase"
             [formControl]="testCaseFormControl"
             [matAutocomplete]="testCaseAutocomplete"
             placeholder="eg. 01.01.01.01">
      <span matSuffix [class.spinner]="isLoadingResults"></span>
      <button (click)="selectTestCase(null)" aria-label="clear" color="accent" mat-icon-button
              matSuffix>
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete
        #testCaseAutocomplete="matAutocomplete"
        (optionSelected)="selectTestCase($event.option.value)"
        panelWidth="auto"
      >
        <mat-option
          *ngFor="let testCase of filteredTestCases | async"
          [value]="testCase"
        >
          {{testCase.id}} - {{testCase.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    </ng-container>
    <div *ngIf="testCaseId">
      <mat-form-field appearance="fill">
        <mat-label>Cluster</mat-label>
        <input type="email" matInput readonly disabled value="{{cluster}}">
      </mat-form-field>
    </div>
    <ng-container *ngIf="!testCaseId">
      <mat-form-field appearance="fill" class="dialog-input-full-width">
        <mat-label>Cluster</mat-label>
        <input matInput formControlName="cluster" id="clusterName" readonly>
      </mat-form-field>
    </ng-container>
    <mat-form-field appearance="fill" class="dialog-input-full-width">
      <mat-label>Comment</mat-label>
      <textarea
        #comments
        matInput
        formControlName="comments"
        cdkTextareaAutosize
        cdkAutosizeMinRows=4
        cdkAutosizeMaxRows=10
        maxlength="255"
        id="areaTileId"
      ></textarea>
      <mat-hint>{{(255 - comments.value.length)}} of 255 characters left</mat-hint>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <mat-slide-toggle
      color="primary"
      formControlName="active"
    >
      Active
    </mat-slide-toggle>
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-stroked-button (click)="reset()">Reset</button>
    <button
      id="dialogScenarioSubmit"
      mat-raised-button
      color="primary"
      [disabled]="disableButton()"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>

