import { Component, Input } from '@angular/core';
import JSONbig from 'json-bigint';

@Component({
  selector: 'app-jsonblock',
  templateUrl: './jsonblock.component.html',
  styleUrls: ['./jsonblock.component.scss']
})
export class JsonBlockComponent {
  @Input() data: any;

  render(): string {
    return JSONbig.stringify(this.data, null, '  ');
  }
}
