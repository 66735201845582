import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '@shared/service/auth.service';
import { environment } from '@env';

const initializeKeycloak =
  (
    keycloak: KeycloakService,
    authService: AuthService
  ): (() => Promise<void>) =>
  () =>
    keycloak
      .init({
        config: {
          url: environment.keycloakServiceUrl,
          realm: environment.realm,
          clientId: environment.clientId
        },
        initOptions: {
          onLoad: 'login-required', // allowed values 'login-required', 'check-sso';
          flow: 'standard' // allowed values 'standard', 'implicit', 'hybrid';
        }
      })
      .then(async (authenticated) => {
        console.log('Authentication successful: ' + authenticated);
        if (authenticated) {
          const token = await keycloak.getToken();
          authService.validateAndSaveToken(token);
        }
      })
      .catch((error) => {
        console.error('Error initializing Keycloak: ' + JSON.stringify(error));
      });
export { initializeKeycloak };
