<div class="container-fluid">
  <mat-card appearance="outlined">
    <app-page-title></app-page-title>

    <mat-card-content>
      <h4>Backend</h4>

      <ng-container *ngIf="systemInfo">
        <ng-container *ngIf="systemInfo.build">
          <section>
            <h5>Build Information</h5>

            <p *ngIf="systemInfo.build.artifact">Artifact: <strong>{{systemInfo.build.artifact}}</strong></p>
            <p *ngIf="systemInfo.build.name">Name: <strong>{{systemInfo.build.name}}</strong></p>
            <p *ngIf="systemInfo.build.time">Time: <strong>{{systemInfo.build.time|date:'dd/MM/yyyy hh:mm:ss'}}</strong></p>
            <p *ngIf="systemInfo.build.version">Version: <strong>{{systemInfo.build.version}}</strong></p>
            <p *ngIf="systemInfo.display_id">Branch: <strong>{{systemInfo.display_id}}</strong></p>
          </section>
          <hr/>
        </ng-container>
      </ng-container>

      <section>
        <h5>Health</h5>
        <p>Start Scenario Node Status : <strong>{{startScenarioNodeStatus}}</strong></p>
      </section>
      <hr/>

      <h4>Frontend</h4>
      <section>
        <p>Version: <strong>{{frontendVersion}}</strong></p>
      </section>

    </mat-card-content>
  </mat-card>
</div>

<div class="kf-flying-logo-container"><img class="kf-flying-logo" src="/assets/img/animated_kf.gif" alt="Flying Image"></div>
