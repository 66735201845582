<div
  *ngIf="showLayerDropDownInDataTab"
  [ngClass]="{'not-attributed': !disableTestCaseDropDown}"
>
<label class="kf-label">Select Layer</label>

<mat-form-field appearance="fill" color="accent" floatLabel="always">
  <mat-label>Layer</mat-label>
  <mat-hint *ngIf="isLayerDisabled" align="start">Please select catalog and version first!</mat-hint>
  <mat-select
    [value]="layerName"
    [disabled]="isLayerDisabled"
    panelClass="kf-catalog-select"
    required
  >
    <mat-option [value]="null">None</mat-option>
    <mat-option *ngFor="let catalog of getLayersByMapDataType()" [value]="catalog.name" (click)="selectLayer(catalog.name)">{{catalog.name|catalogName}}</mat-option>
  </mat-select>
</mat-form-field>
</div>
