/* eslint-disable */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MapViewerSearchFor,
  MapViewerUriParams,
  MapViewerVisualizationMode
} from '@modules/map-viewer/model/map-viewer';
import {
  AttributionStatus,
  ControlledAccess,
  FailVisualization,
  FunctionalRoadClass,
  ResultDeltaOutcome,
  ResultOutcome,
  ResultType
} from '@shared/model/datastore';
import {
  AreasApiEntity,
  CatalogApiEntity,
  TestCaseApiEntity
} from '@shared/model/productserver';
import { AreaCacheService } from '@shared/service/area-cache.service';
import { CatalogService } from '@shared/service/catalog.service';
import { TestCasesService } from '@shared/service/test-cases.service';
import * as L from 'leaflet';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapViewerUserSelectionService {
  readonly DEFAULT_ZOOM = 12;

  private $zoom: number = this.DEFAULT_ZOOM;
  private $ne: number[];
  private $sw: number[];
  private $visualizationMode: MapViewerVisualizationMode =
    MapViewerVisualizationMode.RESULT;

  private $layerName: string;
  private $failVisualization: FailVisualization;
  private $failRateMinValue: number;
  private $failRateMaxValue: number;
  private $failRateDeltaMinValue: number;
  private $failRateDeltaMaxValue: number;
  private $tileId: number;
  private $searchQuery: string;
  private $searchFor: MapViewerSearchFor = MapViewerSearchFor.NDS;

  private $area: AreasApiEntity;
  private $testCase: TestCaseApiEntity;
  private $mainCatalog: CatalogApiEntity;
  private $mainCatalogVersion: number;
  private $mainComparisonCatalog: CatalogApiEntity;
  private $mainComparisonCatalogVersion: number;
  private $deltaCatalog: CatalogApiEntity;
  private $deltaCatalogVersion: number;
  private $deltaComparisonCatalog: CatalogApiEntity;
  private $deltaComparisonCatalogVersion: number;

  private $functionalRoadClass: FunctionalRoadClass;
  private $attributionStatus: AttributionStatus;
  private $controlledAccess: ControlledAccess;

  private $resultOutcomes: Map<ResultOutcome, boolean>;
  private $resultDeltaOutcomes: Map<ResultDeltaOutcome, boolean>;

  private $areaSubject: Subject<AreasApiEntity> = new Subject<AreasApiEntity>();
  private $testCaseSubject: Subject<TestCaseApiEntity> =
    new Subject<TestCaseApiEntity>();
  private $mainCatalogSubject: Subject<CatalogApiEntity> =
    new Subject<CatalogApiEntity>();
  private $mainCatalogVersionSubject: Subject<number> = new Subject<number>();
  private $mainComparisonCatalogSubject: Subject<CatalogApiEntity> =
    new Subject<CatalogApiEntity>();
  private $mainComparisonCatalogVersionSubject: Subject<number> =
    new Subject<number>();
  private $deltaCatalogSubject: Subject<CatalogApiEntity> =
    new Subject<CatalogApiEntity>();
  private $deltaCatalogVersionSubject: Subject<number> = new Subject<number>();
  private $deltaComparisonCatalogSubject: Subject<CatalogApiEntity> =
    new Subject<CatalogApiEntity>();
  private $deltaComparisonCatalogVersionSubject: Subject<number> =
    new Subject<number>();

  private $disableZoomLimit: boolean = false;

  private $minTileZoomLevel = 8;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private areaCacheService: AreaCacheService,
    private catalogService: CatalogService,
    private testCasesService: TestCasesService
  ) {
    this.$resultOutcomes = this.defaultResultOutcomes;
    this.$resultDeltaOutcomes = this.defaultResultDeltaOutcomes;
    this.init();
  }

  get resultType(): ResultType {
    return this.$testCase?.resultType;
  }

  get zoom(): number {
    return this.$zoom;
  }

  set zoom(value: number) {
    this.$zoom = value;
    this.updateQueryParams();
  }

  get bounds(): L.LatLngBounds {
    if (!this.$ne || !this.$sw) {
      return;
    }
    return new L.LatLngBounds(
      this.$ne as L.LatLngTuple,
      this.$sw as L.LatLngTuple
    );
  }

  set bounds(value: L.LatLngBounds) {
    const northEastLat = value.getNorthEast().lat;
    const northEastLng = value.getNorthEast().lng;
    const southWestLat = value.getSouthWest().lat;
    const southWestLng = value.getSouthWest().lng;
    this.$ne = [northEastLat, northEastLng];
    this.$sw = [southWestLat, southWestLng];
    this.updateQueryParams();
  }

  get tileId(): number {
    return this.$tileId;
  }

  set tileId(value: number) {
    this.$tileId = value;
    this.updateQueryParams();
  }

  get searchFor(): MapViewerSearchFor {
    return this.$searchFor;
  }

  set searchFor(value: MapViewerSearchFor) {
    this.$searchFor = value;
    this.updateQueryParams();
  }

  get searchQuery(): string {
    return this.$searchQuery;
  }

  set searchQuery(value: string) {
    this.$searchQuery = typeof value === 'string' ? value.trim() : null;
    this.updateQueryParams();
  }

  get areaId(): number {
    return this.$area?.areaId;
  }

  get testCaseId(): string {
    return this.$testCase?.id;
  }

  get layerName(): string {
    return this.$layerName;
  }

  set layerName(value: string) {
    this.$layerName = value;
    this.updateQueryParams();
  }

  get failVisualization(): FailVisualization {
    return this.$failVisualization;
  }

  set failVisualization(value: FailVisualization) {
    this.$failVisualization = value;
    this.updateQueryParams();
  }

  get failRateMaxValue(): number {
    return this.$failRateMaxValue;
  }

  set failRateMaxValue(value: number) {
    this.$failRateMaxValue = value;
    this.updateQueryParams();
  }

  get failRateMinValue(): number {
    return this.$failRateMinValue;
  }

  set failRateMinValue(value: number) {
    this.$failRateMinValue = value;
    this.updateQueryParams();
  }

  get failRateDeltaMaxValue(): number {
    return this.$failRateDeltaMaxValue;
  }

  set failRateDeltaMaxValue(value: number) {
    this.$failRateDeltaMaxValue = value;
    this.updateQueryParams();
  }

  get failRateDeltaMinValue(): number {
    return this.$failRateDeltaMinValue;
  }

  set failRateDeltaMinValue(value: number) {
    this.$failRateDeltaMinValue = value;
    this.updateQueryParams();
  }

  get visualizationMode(): MapViewerVisualizationMode {
    return this.$visualizationMode;
  }

  set visualizationMode(value: MapViewerVisualizationMode) {
    if (this.$visualizationMode === value) {
      return;
    }
    this.$visualizationMode = value;
    switch (this.$visualizationMode) {
      case MapViewerVisualizationMode.RESULT:
        this.resetDeltaCatalogSelection();
        this.resetLayerSelection();
        break;
      case MapViewerVisualizationMode.DELTA:
        this.resetLayerSelection();
        break;
      case MapViewerVisualizationMode.DATA:
        this.resetTestCaseSelection();
        this.resetDeltaCatalogSelection();
        break;
    }
    this.updateQueryParams();
  }

  get mapDataType(): string {
    return this.$mainCatalog?.mapDataType;
  }

  get mainCatalog(): CatalogApiEntity {
    return this.$mainCatalog;
  }

  get mainComparisonCatalog(): CatalogApiEntity {
    return this.$mainComparisonCatalog;
  }

  get deltaCatalog(): CatalogApiEntity {
    return this.$deltaCatalog;
  }

  get deltaComparisonCatalog(): CatalogApiEntity {
    return this.$deltaComparisonCatalog;
  }

  set mainCatalog(value: CatalogApiEntity) {
    if (this.$mainCatalog === value) {
      return;
    }
    this.$mainCatalog = value;
    this.$mainCatalogSubject.next(value);
    this.$mainCatalogVersionSubject.next(this.$mainCatalogVersion);
    this.updateQueryParams();
  }

  set mainComparisonCatalog(value: CatalogApiEntity) {
    if (this.$mainComparisonCatalog === value) {
      return;
    }
    this.$mainComparisonCatalog = value;
    this.$mainComparisonCatalogSubject.next(value);
    this.$mainComparisonCatalogVersionSubject.next(
      this.$mainComparisonCatalogVersion
    );
    this.updateQueryParams();
  }

  set deltaCatalog(value: CatalogApiEntity) {
    if (this.$deltaCatalog === value) {
      return;
    }
    this.$deltaCatalog = value;
    this.$deltaCatalogSubject.next(value);
    this.$deltaCatalogVersionSubject.next(this.$deltaCatalogVersion);
    this.updateQueryParams();
  }

  set deltaComparisonCatalog(value: CatalogApiEntity) {
    if (this.$deltaComparisonCatalog === value) {
      return;
    }
    this.$deltaComparisonCatalog = value;
    this.$deltaComparisonCatalogSubject.next(value);
    this.$deltaComparisonCatalogVersionSubject.next(
      this.$deltaComparisonCatalogVersion
    );
    this.updateQueryParams();
  }

  get mainCatalogName(): string {
    return this.$mainCatalog?.name;
  }

  get mainCatalogVersion(): number {
    return this.$mainCatalogVersion;
  }

  set mainCatalogVersion(value: number) {
    if (this.$mainCatalogVersion === value) {
      return;
    }
    this.$mainCatalogVersion = value;
    this.$mainCatalogVersionSubject.next(value);
    this.updateQueryParams();
  }

  get mainComparisonCatalogName(): string {
    return this.$mainComparisonCatalog?.name;
  }

  get mainComparisonCatalogVersion(): number {
    return this.$mainComparisonCatalogVersion;
  }

  set mainComparisonCatalogVersion(value: number) {
    if (this.$mainComparisonCatalogVersion === value) {
      return;
    }
    this.$mainComparisonCatalogVersion = value;
    this.$mainComparisonCatalogVersionSubject.next(value);
    this.updateQueryParams();
  }

  get deltaCatalogName(): string {
    return this.$deltaCatalog?.name;
  }

  get deltaCatalogVersion(): number {
    return this.$deltaCatalogVersion;
  }

  set deltaCatalogVersion(value: number) {
    if (this.$deltaCatalogVersion === value) {
      return;
    }
    this.$deltaCatalogVersion = value;
    this.$deltaCatalogVersionSubject.next(value);
    this.updateQueryParams();
  }

  get deltaComparisonCatalogName(): string {
    return this.$deltaComparisonCatalog?.name;
  }

  get deltaComparisonCatalogVersion(): number {
    return this.$deltaComparisonCatalogVersion;
  }

  set deltaComparisonCatalogVersion(value: number) {
    if (this.$deltaComparisonCatalogVersion === value) {
      return;
    }
    this.$deltaComparisonCatalogVersion = value;
    this.$deltaComparisonCatalogVersionSubject.next(value);
    this.updateQueryParams();
  }

  get resultOutcomes(): Map<ResultOutcome, boolean> {
    return this.$resultOutcomes;
  }

  set resultOutcomes(value: Map<ResultOutcome, boolean>) {
    this.$resultOutcomes = value;
    this.updateQueryParams();
  }

  get resultDeltaOutcomes(): Map<ResultDeltaOutcome, boolean> {
    return this.$resultDeltaOutcomes;
  }

  set resultDeltaOutcomes(value: Map<ResultDeltaOutcome, boolean>) {
    this.$resultDeltaOutcomes = value;
    this.updateQueryParams();
  }

  get functionalRoadClass(): FunctionalRoadClass {
    return this.$functionalRoadClass;
  }

  set functionalRoadClass(value: FunctionalRoadClass) {
    this.$functionalRoadClass = value;
    this.updateQueryParams();
  }

  get attributionStatus(): AttributionStatus {
    return this.$attributionStatus;
  }

  set attributionStatus(value: AttributionStatus) {
    this.$attributionStatus = value;
    this.updateQueryParams();
  }

  get controlledAccess(): ControlledAccess {
    return this.$controlledAccess;
  }

  set controlledAccess(value: ControlledAccess) {
    this.$controlledAccess = value;
    this.updateQueryParams();
  }

  get testCase(): TestCaseApiEntity {
    return this.$testCase;
  }

  set testCase(testCase: TestCaseApiEntity) {
    if (this.$testCase === testCase) {
      return;
    }
    this.$testCase = testCase;
    this.$testCaseSubject.next(this.$testCase);
    if (testCase?.resultType) {
      if (
        ![
          ResultType.FORMAT_CONTENT,
          ResultType.FORMAT_CONTENT_DETAILED,
          ResultType.MAP_APPROVAL
        ].includes(testCase.resultType)
      ) {
        this.failVisualization = FailVisualization.FAIL_RATE;
      }
    }

    this.updateQueryParams();
  }

  get area(): AreasApiEntity {
    return this.$area;
  }

  set area(value: AreasApiEntity) {
    if (this.$area === value) {
      return;
    }
    this.$area = value;
    this.$areaSubject.next(this.$area);
    this.updateQueryParams();
  }

  get comparisonMapDataType(): string {
    return this.$testCase?.comparisonMapDataType;
  }

  get disableZoomLimit(): boolean {
    return this.$disableZoomLimit;
  }

  set disableZoomLimit(value: boolean) {
    this.$disableZoomLimit = value;
  }

  get minTileZoomLevel(): number {
    return this.$minTileZoomLevel;
  }

  get defaultResultOutcomes(): Map<ResultOutcome, boolean> {
    return new Map<ResultOutcome, boolean>([
      [ResultOutcome.PASS, true],
      [ResultOutcome.FAIL, true],
      [ResultOutcome.FAILURE, true],
      [ResultOutcome.NOT_EXECUTED, true]
    ]);
  }

  get defaultResultDeltaOutcomes(): Map<ResultDeltaOutcome, boolean> {
    return new Map<ResultDeltaOutcome, boolean>([
      [ResultDeltaOutcome.EQUAL, true],
      [ResultDeltaOutcome.INC, true],
      [ResultDeltaOutcome.DEC, true],
      [ResultDeltaOutcome.FAILURE, true],
      [ResultDeltaOutcome.NOT_EXECUTED, true]
    ]);
  }

  getResultOutcome(key: ResultOutcome): boolean {
    return this.$resultOutcomes.get(key);
  }

  setResultOutcome(key: ResultOutcome, value: boolean) {
    this.$resultOutcomes.set(key, value);
    this.updateQueryParams();
  }

  setResultDeltaOutcome(key: ResultDeltaOutcome, value: boolean) {
    this.$resultDeltaOutcomes.set(key, value);
    this.updateQueryParams();
  }

  init(): void {
    this.parseQuery();
  }

  getMapDataType(catalogName: string): string {
    return this.$mainCatalog?.mapDataType;
  }

  observeTestCase(): Observable<TestCaseApiEntity> {
    return this.$testCaseSubject.asObservable();
  }

  observeMainCatalog(): Observable<CatalogApiEntity> {
    return this.$mainCatalogSubject.asObservable();
  }

  observeMainCatalogVersion(): Observable<number> {
    return this.$mainCatalogVersionSubject.asObservable();
  }

  observeMainComparisonCatalog(): Observable<CatalogApiEntity> {
    return this.$mainComparisonCatalogSubject.asObservable();
  }

  observeMainComparisonCatalogVersion(): Observable<number> {
    return this.$mainComparisonCatalogVersionSubject.asObservable();
  }

  observeDeltaCatalog(): Observable<CatalogApiEntity> {
    return this.$deltaCatalogSubject.asObservable();
  }

  observeDeltaCatalogVersion(): Observable<number> {
    return this.$deltaCatalogVersionSubject.asObservable();
  }

  observeDeltaComparisonCatalog(): Observable<CatalogApiEntity> {
    return this.$deltaComparisonCatalogSubject.asObservable();
  }

  observeDeltaComparisonCatalogVersion(): Observable<number> {
    return this.$deltaComparisonCatalogVersionSubject.asObservable();
  }

  observeArea(): Observable<AreasApiEntity> {
    return this.$areaSubject.asObservable();
  }

  private updateQueryParams(): void {
    const queryParams: MapViewerUriParams = {};

    this.collectViewPortSelection(queryParams);
    this.collectCatalogSelection(queryParams);
    this.collectTestCaseSelection(queryParams);
    this.collectTestCaseFilterSelection(queryParams);
    this.collectSearchSelection(queryParams);

    if (this.$tileId) {
      queryParams.tileId = this.$tileId;
    }
    if (this.$area?.areaId) {
      queryParams.areaId = this.$area?.areaId;
    }

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: ''
      })
      .then(() => {
        //
      });
  }

  private collectSearchSelection(queryParams: MapViewerUriParams): void {
    if (this.$searchQuery) {
      queryParams.searchQuery = this.$searchQuery;
    }
    if (this.$searchFor && this.$searchFor !== MapViewerSearchFor.NDS) {
      queryParams.searchFor = this.$searchFor;
    }
  }

  private collectViewPortSelection(queryParams: MapViewerUriParams): void {
    if (this.$zoom) {
      queryParams.zoom = this.$zoom;
    }
    if (this.$ne && this.$sw) {
      queryParams.ne = JSON.stringify(this.$ne);
      queryParams.sw = JSON.stringify(this.$sw);
    }
  }

  private collectCatalogSelection(queryParams: MapViewerUriParams): void {
    if (this.$visualizationMode) {
      queryParams.visualizationMode = this.$visualizationMode;
    }
    if (this.$mainCatalog) {
      queryParams.catalog = this.$mainCatalog.name;
    }
    if (this.$mainCatalogVersion) {
      queryParams.catalogVersion = this.$mainCatalogVersion;
    }
    if (this.$mainComparisonCatalog) {
      queryParams.comparisonCatalog = this.$mainComparisonCatalog.name;
    }
    if (this.$mainComparisonCatalogVersion) {
      queryParams.comparisonCatalogVersion = this.$mainComparisonCatalogVersion;
    }
    if (this.$deltaCatalog) {
      queryParams.deltaCatalog = this.$deltaCatalog.name;
    }
    if (this.$deltaCatalogVersion) {
      queryParams.deltaCatalogVersion = this.$deltaCatalogVersion;
    }
    if (this.$deltaComparisonCatalog) {
      queryParams.deltaComparisonCatalog = this.$deltaComparisonCatalog.name;
    }
    if (this.$deltaComparisonCatalogVersion) {
      queryParams.deltaComparisonCatalogVersion =
        this.$deltaComparisonCatalogVersion;
    }
    if (this.$layerName) {
      queryParams.layerName = this.$layerName;
    }
  }

  private collectTestCaseSelection(queryParams: MapViewerUriParams): void {
    if (this.testCaseId) {
      queryParams.testCaseId = this.testCaseId;
    }
    if (typeof this.$failRateMinValue === 'number') {
      queryParams.failRateMinValue = this.$failRateMinValue;
    }
    if (typeof this.$failRateMaxValue === 'number') {
      queryParams.failRateMaxValue = this.$failRateMaxValue;
    }
    if (typeof this.$failRateDeltaMinValue === 'number') {
      queryParams.failRateDeltaMinValue = this.$failRateDeltaMinValue;
    }
    if (typeof this.$failRateDeltaMaxValue === 'number') {
      queryParams.failRateDeltaMaxValue = this.$failRateDeltaMaxValue;
    }
    if (this.$failVisualization) {
      queryParams.failVisualization = this.$failVisualization;
    }
    if (this.isCustomizedResultOutcomes()) {
      queryParams.resultOutcomes = JSON.stringify(
        [...this.$resultOutcomes.keys()].filter((resultOutcome) =>
          this.$resultOutcomes.get(resultOutcome)
        )
      );
    }
    if (this.isCustomizedResultDeltaOutcomes()) {
      queryParams.resultDeltaOutcomes = JSON.stringify(
        [...this.$resultDeltaOutcomes.keys()].filter((resultDeltaOutcome) =>
          this.$resultDeltaOutcomes.get(resultDeltaOutcome)
        )
      );
    }
  }

  private collectTestCaseFilterSelection(
    queryParams: MapViewerUriParams
  ): void {
    if (
      this.$functionalRoadClass &&
      this.$functionalRoadClass !== FunctionalRoadClass.ALL
    ) {
      queryParams.frc = this.$functionalRoadClass;
    }
    if (
      this.$attributionStatus &&
      this.$attributionStatus !== AttributionStatus.ALL
    ) {
      queryParams.attributionStatus = this.$attributionStatus;
    }
    if (
      this.$controlledAccess &&
      this.$controlledAccess !== ControlledAccess.ALL
    ) {
      queryParams.controlledAccess = this.$controlledAccess;
    }
  }

  private isCustomizedResultOutcomes(): boolean {
    return ![...this.$resultOutcomes.values()].every((value) => value === true);
  }

  private isCustomizedResultDeltaOutcomes(): boolean {
    return ![...this.$resultDeltaOutcomes.values()].every(
      (value) => value === true
    );
  }

  private parseQuery(): void {
    const queryParams = this.route.snapshot.queryParams as MapViewerUriParams;
    const { areaId, tileId } = queryParams;

    this.$tileId = this.parseInt(tileId);

    this.setAreaById(areaId);
    this.parseSearchSelection(queryParams);
    this.parseViewPortSelection(queryParams);
    this.parseCatalogSelection(queryParams);
    this.parseTestCaseSelection(queryParams);
    this.parseTestCaseDetailedSelection(queryParams);
  }

  private parseSearchSelection({ searchQuery, searchFor }: MapViewerUriParams) {
    this.$searchQuery =
      typeof searchQuery !== 'undefined' ? searchQuery : undefined;
    this.$searchFor =
      typeof searchFor !== 'undefined'
        ? MapViewerSearchFor[searchFor]
        : MapViewerSearchFor.NDS;
  }

  private parseTestCaseSelection({
    testCaseId,
    failRateMinValue,
    failRateMaxValue,
    failRateDeltaMinValue,
    failRateDeltaMaxValue,
    resultOutcomes,
    resultDeltaOutcomes,
    failVisualization
  }: MapViewerUriParams) {
    this.setTestCaseById(testCaseId);
    this.$failVisualization =
      typeof failVisualization !== 'undefined'
        ? FailVisualization[failVisualization]
        : undefined;
    this.$failRateMinValue = this.parseInt(failRateMinValue, 0);
    this.$failRateMaxValue = this.parseInt(failRateMaxValue, 100);
    this.$failRateDeltaMinValue = this.parseInt(failRateDeltaMinValue, -100);
    this.$failRateDeltaMaxValue = this.parseInt(failRateDeltaMaxValue, 100);
    const resultOutcomesActive =
      typeof resultOutcomes !== 'undefined'
        ? JSON.parse(resultOutcomes)
        : undefined;
    if (resultOutcomesActive) {
      const resultOutcomesKeys = [...this.$resultOutcomes.keys()];
      const resultOutcomesInactive = resultOutcomesKeys.filter(
        (x) => !resultOutcomesActive.includes(x)
      );
      resultOutcomesActive.forEach((key: ResultOutcome) =>
        this.$resultOutcomes.set(key, true)
      );
      resultOutcomesInactive.forEach((key: ResultOutcome) =>
        this.$resultOutcomes.set(key, false)
      );
    }
    const resultDeltaOutcomesActive =
      typeof resultDeltaOutcomes !== 'undefined'
        ? JSON.parse(resultDeltaOutcomes)
        : undefined;
    if (resultDeltaOutcomesActive) {
      const resultDeltaOutcomesKeys = [...this.$resultDeltaOutcomes.keys()];
      const resultDeltaOutcomesInactive = resultDeltaOutcomesKeys.filter(
        (x) => !resultDeltaOutcomesActive.includes(x)
      );
      resultDeltaOutcomesActive.forEach((key: ResultDeltaOutcome) =>
        this.$resultDeltaOutcomes.set(key, true)
      );
      resultDeltaOutcomesInactive.forEach((key: ResultDeltaOutcome) =>
        this.$resultDeltaOutcomes.set(key, false)
      );
    }
  }

  private parseTestCaseDetailedSelection({
    frc = null,
    attributionStatus = null,
    controlledAccess = null
  }: MapViewerUriParams) {
    this.$functionalRoadClass = frc ? frc : FunctionalRoadClass.ALL;
    this.$attributionStatus = attributionStatus
      ? attributionStatus
      : AttributionStatus.ALL;
    this.$controlledAccess = controlledAccess
      ? controlledAccess
      : ControlledAccess.ALL;
  }

  private parseCatalogSelection({
    visualizationMode,
    catalog,
    catalogVersion,
    comparisonCatalog,
    comparisonCatalogVersion,
    deltaCatalog,
    deltaCatalogVersion,
    deltaComparisonCatalog,
    deltaComparisonCatalogVersion,
    layerName
  }: MapViewerUriParams) {
    this.$visualizationMode =
      typeof visualizationMode !== 'undefined'
        ? visualizationMode
        : MapViewerVisualizationMode.RESULT;

    this.setMainCatalogById(catalog);
    this.mainCatalogVersion = this.parseInt(catalogVersion);

    this.setMainComparisonCatalogById(comparisonCatalog);
    this.mainComparisonCatalogVersion = this.parseInt(comparisonCatalogVersion);

    this.setDeltaCatalogById(deltaCatalog);
    this.deltaCatalogVersion = this.parseInt(deltaCatalogVersion);

    this.setDeltaComparisonCatalogById(deltaComparisonCatalog);
    this.deltaComparisonCatalogVersion = this.parseInt(
      deltaComparisonCatalogVersion
    );

    this.layerName = typeof layerName !== 'undefined' ? layerName : undefined;
  }

  private parseViewPortSelection({ zoom, ne, sw }: MapViewerUriParams) {
    this.$zoom = this.parseInt(zoom, this.DEFAULT_ZOOM);
    this.$ne = typeof ne !== 'undefined' ? JSON.parse(ne) : undefined;
    this.$sw = typeof sw !== 'undefined' ? JSON.parse(sw) : undefined;
  }

  isCompleted(): boolean {
    return (
      this.hasTestCase() && this.hasMainCatalog() && this.hasDeltaCatalog()
    );
  }

  hasTestCase(): boolean {
    return !!this.$testCase?.id;
  }

  hasMainCatalog(): boolean {
    if (!this.$mainCatalog || !this.$mainCatalogVersion) {
      return false;
    }
    if (!!this.$testCase?.comparisonMapDataType) {
      if (!this.$mainComparisonCatalog || !this.$mainComparisonCatalogVersion) {
        return false;
      }
    }
    return true;
  }

  hasDeltaCatalog(): boolean {
    if (this.$visualizationMode === MapViewerVisualizationMode.DELTA) {
      if (!this.$deltaCatalog || !this.$deltaCatalogVersion) {
        return false;
      }
      if (!!this.$testCase?.comparisonMapDataType) {
        if (
          !this.$deltaComparisonCatalog ||
          !this.$deltaComparisonCatalogVersion
        ) {
          return false;
        }
      }
    }
    return true;
  }

  resetAll() {
    this.resetMapSelection();
    this.resetSearch();
    this.resetTestCaseSelection();
    this.resetTileSelection();
    this.resetAreaSelection();
    this.resetCatalogSelection();
    this.resetDeltaCatalogSelection();
    this.resetVisualizationSelection();
    this.resetFailVisualizationSelection();
    this.resetLayerSelection();
    this.resetDetailsSelection();
    this.resetResultOutcomes();
    this.resetDeltaResultOutcomes();
    this.resetFailRateSelection();
  }

  resetMapSelection() {
    this.zoom = this.DEFAULT_ZOOM;
    this.$ne = undefined;
    this.$sw = undefined;
  }

  resetSearch() {
    this.searchFor = MapViewerSearchFor.NDS;
    this.searchQuery = null;
  }

  resetTileSelection() {
    this.tileId = null;
  }

  resetAreaSelection() {
    this.area = null;
  }

  resetTestCaseSelection() {
    this.testCase = null;
  }

  resetCatalogSelection() {
    this.mainCatalog = null;
    this.mainCatalogVersion = null;
    this.mainComparisonCatalog = null;
    this.mainComparisonCatalogVersion = null;
  }

  resetDeltaCatalogSelection() {
    this.deltaCatalog = null;
    this.deltaCatalogVersion = null;
    this.deltaComparisonCatalog = null;
    this.deltaComparisonCatalogVersion = null;
  }

  resetVisualizationSelection() {
    this.visualizationMode = MapViewerVisualizationMode.RESULT;
  }

  resetDetailsSelection() {
    this.functionalRoadClass = FunctionalRoadClass.ALL;
    this.controlledAccess = ControlledAccess.ALL;
    this.attributionStatus = AttributionStatus.ALL;
  }

  resetLayerSelection() {
    this.layerName = null;
  }

  resetFailVisualizationSelection() {
    this.failVisualization = FailVisualization.FAIL_RATE;
  }

  resetResultOutcomes(): void {
    this.resultOutcomes = this.defaultResultOutcomes;
  }

  resetDeltaResultOutcomes(): void {
    this.resultDeltaOutcomes = this.defaultResultDeltaOutcomes;
  }

  resetFailRateSelection(): void {
    this.failRateMinValue = 0;
    this.failRateMaxValue = 100;
    this.failRateDeltaMinValue = -100;
    this.failRateDeltaMaxValue = 100;
  }

  private setAreaById(areaId: number): void {
    this.area = null;
    this.areaCacheService
      .getSingleArea(areaId)
      .subscribe((area: AreasApiEntity) => {
        this.area = area;
      });
  }

  private setTestCaseById(testCaseId: string): void {
    this.testCase = null;
    this.testCasesService
      .getTestCaseById(testCaseId)
      .subscribe((testCase: TestCaseApiEntity) => {
        this.testCase = testCase || null;
      });
  }

  private setMainCatalogById(catalogName: string): void {
    this.mainCatalog = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mainCatalog = catalog;
      });
  }

  private setMainComparisonCatalogById(catalogName: string): void {
    this.mainComparisonCatalog = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mainComparisonCatalog = catalog;
      });
  }

  private setDeltaCatalogById(catalogName: string): void {
    this.deltaCatalog = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.deltaCatalog = catalog;
      });
  }

  private setDeltaComparisonCatalogById(catalogName: string): void {
    this.deltaComparisonCatalog = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.deltaComparisonCatalog = catalog;
      });
  }

  private parseInt(value: any, defaultValue = undefined): number {
    return typeof value !== 'undefined'
      ? parseInt(String(value), 10)
      : defaultValue;
  }
}
