<form [formGroup]="form">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label label="Second">by Area ({{countAreas}})</ng-template>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label>Areas</mat-label>
        <mat-select formControlName="areaIds" multiple>
          <mat-option *ngFor="let area of areasList" [value]="area.areaId">{{area.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label label="First">by Tile IDs ({{countTiles}})</ng-template>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label class="textarea-label">Enter NDS Tile ID(s)</mat-label>
        <textarea formControlName="tileIds" matInput
                  placeholder="Example: 545556681, 545556680, 545556687, 545556686, 545556685, 545556663, 545556684, 545556683, 545556661, 545556682, 545556708, 545556707, 545556706"
                  type="number" rows="6"></textarea>
      </mat-form-field>
      <button (click)="clearTileIds()" class="kf-button-clear-textarea" aria-label="clear" color="accent" mat-icon-button matSuffix><mat-icon>close</mat-icon></button>
    </mat-tab>
  </mat-tab-group>
  <p *ngIf="hasTilesAndAreas" class="mat-error">There are tiles and areas defined. Is this intended?</p>
  <section>
    <h2>Define Route</h2>
    <mat-form-field appearance="fill" color="accent" floatLabel="always">
      <mat-label class="textarea-label">Enter Route ID(s)</mat-label>
      <textarea formControlName="routeIds" matInput
                placeholder="Example: 1,2,3,4"
                type="number"></textarea>
    </mat-form-field>
  </section>
</form>
