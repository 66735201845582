import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MapEvent,
  MapEventService,
  ShowLegendPayload
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerTile } from '@modules/map-viewer/service/map-viewer-tile';
import { MapViewerValueScale } from '@modules/map-viewer/service/map-viewer-value-scale';
import { FailVisualization, ResultType } from '@shared/model/datastore';
import { Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';

@Component({
  selector: 'app-map-viewer-legend',
  templateUrl: './map-viewer-legend.component.html',
  styleUrls: ['./map-viewer-legend.component.scss']
})
export class MapViewerLegendComponent implements OnInit, OnDestroy {
  readonly RESULT_TYPE_STATISTIC = ResultType.STATISTIC;
  readonly RESULT_TYPE_STATISTIC_DETAILED = ResultType.STATISTIC_DETAILED;
  readonly RESULT_TYPE_FORMAT_CONTENT = ResultType.FORMAT_CONTENT;
  readonly RESULT_TYPE_COMBINED_DETAILED_STATISTIC =
    ResultType.COMBINED_DETAILED_STATISTIC;
  readonly RESULT_TYPE_FORMAT_CONTENT_DETAILED =
    ResultType.FORMAT_CONTENT_DETAILED;
  readonly MAP_APPROVAL = ResultType.MAP_APPROVAL;
  private $subscription: Subscription = new Subscription();

  private $testCaseId: string;
  private $layerName: string;
  private $resultType: ResultType;
  private $unit: string;
  private $scale: MapViewerValueScale;

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get testCaseId() {
    return this.$testCaseId;
  }

  get resultType() {
    return this.$resultType;
  }

  get unit() {
    return this.$unit;
  }

  get scale() {
    return this.$scale;
  }

  get layerName() {
    return this.$layerName;
  }

  get gradientOpacity() {
    return MapViewerTile.TILE_RESULT_OUTCOME_FILL_OPACITY;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.SHOW_LEGEND,
        (value: ShowLegendPayload) => {
          const { testCaseId, resultType, unit, scale, layerName } = value;
          this.$testCaseId = testCaseId;
          this.$resultType = resultType;
          this.$unit = unit;
          this.$scale = scale;
          this.$layerName = layerName;
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.HIDE_LEGEND, () => {
        this.$scale = undefined;
      })
    );
  }

  visualizationFailRate(): boolean {
    if (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_RATE
    ) {
      return true;
    }
  }
  visualizationFailCount(): boolean {
    if (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_COUNT
    ) {
      return true;
    }
  }
}
