import { FocusMonitor } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, NgControl } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';
import { ScenarioService } from '@modules/test-execution/service/scenario.service';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import { ScenarioApiEntity } from '@shared/model/productserver';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-scenario-id-list-form-control',
  templateUrl: './scenario-id-list-form-control.component.html',
  styleUrls: ['./scenario-id-list-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ScenarioIdListFormControlComponent
    }
  ]
})
export class ScenarioIdListFormControlComponent extends AbstractInputFormControlComponent<
  number[]
> {
  @ViewChild('scenarioIds') scenarioIdsInput: HTMLInputElement;
  public scenarioCtrl = new FormControl();
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('scenarioIds') scenarioInput: ElementRef<HTMLInputElement>;
  @ViewChild('scenarioAutocomplete') matAutocomplete: MatAutocomplete;

  private subscription: Subscription = new Subscription();

  private $scenarioList: Map<number, ScenarioApiEntity> = new Map<
    number,
    ScenarioApiEntity
  >();
  public filteredScenarios$: Observable<ScenarioApiEntity[]>;

  parts = this._formBuilder.group({
    scenarioIds: this._formBuilder.control<number[]>([], { nonNullable: true })
  });

  readonly controlType = 'scenario-ids-input';
  @HostBinding() id: string = `${
    this.controlType
  }-${ScenarioIdListFormControlComponent.nextId++}`;

  get empty() {
    const {
      value: { scenarioIds }
    } = this.parts;

    return !scenarioIds.length;
  }

  @Input()
  get value(): number[] {
    if (this.parts.valid) {
      const {
        value: { scenarioIds }
      } = this.parts;
      return scenarioIds;
    }
    return null;
  }
  set value(value: number[]) {
    this.parts.setValue({ scenarioIds: value ?? [] });
    this.stateChanges.next();
  }

  constructor(
    private scenarioService: ScenarioService,
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);
    this.getScenarioList();
  }

  get scenariosSelected(): Array<ScenarioApiEntity> {
    const result = new Array<ScenarioApiEntity>();
    const scenarioIds = this.parts.get('scenarioIds').value || [];
    for (const scenarioId of scenarioIds) {
      if (this.$scenarioList.has(scenarioId)) {
        result.push(this.$scenarioList.get(scenarioId));
      }
    }
    return result;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const scenario: ScenarioApiEntity = event.option.value;
    const scenarioIds = this.parts.get('scenarioIds').value || [];

    this.value = [...scenarioIds, scenario.id];

    this.scenarioInput.nativeElement.value = '';
    this.scenarioInput.nativeElement.blur();
    this.scenarioCtrl.setValue(null);
  }

  remove(scenario: ScenarioApiEntity): void {
    const scenarioIds = this.parts.get('scenarioIds').value || [];
    const index = scenarioIds.indexOf(scenario.id);
    if (index >= 0) {
      scenarioIds.splice(index, 1);
    }
    this.value = scenarioIds;
  }

  private getScenarioList(): void {
    this.subscription.add(
      this.scenarioService.getScenarioList().subscribe(
        (response) => {
          for (const scenario of response?.data || []) {
            this.$scenarioList.set(scenario.id, scenario);
          }
          this.filteredScenarios$ = this.scenarioCtrl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map((value: string | null) => this.filterScenarios(value))
          );
        },
        (err) => {
          console.error(err);
          return observableOf([]);
        }
      )
    );
  }

  private filterScenarios(value: any) {
    const filterValue =
      value === null || value instanceof Object
        ? ''
        : value.trim().toLowerCase();
    const scenarioIds = this.parts.get('scenarioIds').value || [];

    return Array.from(this.$scenarioList.values())
      .filter((scenario) => !scenarioIds.includes(scenario.id))
      .filter((scenario) =>
        scenario.name.toLocaleLowerCase().includes(filterValue)
      );
  }
}
