
<ng-container *ngIf="title; else defaultHeader">
  <h3 mat-dialog-title>{{title}}</h3>
</ng-container>
<ng-template #defaultHeader>
  <h3 mat-dialog-title>Please Confirm</h3>
</ng-template>

<div mat-dialog-content>
  <p>
    {{message}}
  </p>
</div>
<div mat-dialog-actions>
  <button id="dialogConfirmCancel" mat-raised-button color="primary" (click)="cancel()">
    <mat-icon>close</mat-icon>
    Back
  </button>
  <div class="action-spacer"></div>
  <button id="dialogConfirmOk" mat-raised-button color="warn" (click)="confirm()">
    <mat-icon>check</mat-icon>
    Confirm
  </button>
</div>

