import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  MapViewerDeltaFailCountScale,
  MapViewerDeltaFailRateScale,
  MapViewerFailCountScale,
  MapViewerFailRateScale,
  MapViewerStatisticValueDeltaScale,
  MapViewerStatisticValueScale
} from '@modules/map-viewer/service/map-viewer-value-scale';
import {
  AttributionStatus,
  Bounds,
  ControlledAccess,
  DataVersionTileEntity,
  DeltaTileEntity,
  FailVisualization,
  FunctionalRoadClass,
  ResultType,
  TileEntity
} from '@shared/model/datastore';
import { MapViewerBoundsCache } from '@shared/model/map-viewer-bounds-cache';
import { TestCaseApiEntity } from '@shared/model/productserver';
import { ResponseMap } from '@shared/model/response';
import { DatastoreService } from '@shared/service/datastore.service';
import { TileNds } from '@shared/service/tile-utils.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ResultOutcomeQuery {
  mode: MapViewerVisualizationMode;
  catalogParams: ResultQueryCatalogParams;
  testCase: TestCaseApiEntity;
  bounds: Bounds;
  filterParams: ResultQueryFilterParams;
}

export interface DataVersionQuery {
  mode: MapViewerVisualizationMode;
  catalogParams: ResultQueryDataCatalogParams;
  layerName: string;
  bounds: Bounds;
}

export interface ResultQueryCatalogParams {
  mainCatalogName: string;
  mainCatalogVersion: number;
  mainComparisonCatalogName?: string;
  mainComparisonCatalogVersion?: number;
  deltaCatalogName?: string;
  deltaCatalogVersion?: number;
  deltaComparisonCatalogName?: string;
  deltaComparisonCatalogVersion?: number;
}

export interface ResultQueryDataCatalogParams {
  mainCatalogName: string;
  mainCatalogVersion: number;
}

export interface ResultQueryFilterParams {
  functionalRoadClass: FunctionalRoadClass;
  attributionStatus: AttributionStatus;
  controlledAccess: ControlledAccess;
}

@Injectable({
  providedIn: 'root'
})
export class MapViewerResultOutcomeService {
  private $resultOutcomeBoundsCache: MapViewerBoundsCache =
    new MapViewerBoundsCache();
  private $resultDeltaOutcomeBoundsCache: MapViewerBoundsCache =
    new MapViewerBoundsCache();
  private $dataVersionOutcomeBoundsCache: MapViewerBoundsCache =
    new MapViewerBoundsCache();

  private $resultOutcomes = new Map<string, TileEntity>();
  private $resultDeltaOutcomes = new Map<string, DeltaTileEntity>();
  private $dataVersionOutcomes = new Map<string, DataVersionTileEntity>();

  private $activeRequestsSubject: Subject<number> = new Subject<number>();
  private $totalRequestsSubject: Subject<number> = new Subject<number>();

  private $totalRequests = 0;
  private $activeRequests = 0;

  private $mapViewerVisualizationMode: MapViewerVisualizationMode = null;

  constructor(private datastoreService: DatastoreService) {}

  get resultOutcomes() {
    return this.$resultOutcomes;
  }

  get resultDeltaOutcomes() {
    return this.$resultDeltaOutcomes;
  }

  get dataVersionOutcomes() {
    return this.$dataVersionOutcomes;
  }

  set totalRequests(value: number) {
    this.$totalRequests = value;
    this.$totalRequestsSubject.next(this.$totalRequests);
  }

  get totalRequests() {
    return this.$totalRequests;
  }

  set activeRequests(value: number) {
    this.$activeRequests = value;
    this.$activeRequestsSubject.next(this.$activeRequests);
  }

  get activeRequests() {
    return this.$activeRequests;
  }

  results(mode: MapViewerVisualizationMode) {
    switch (mode) {
      case MapViewerVisualizationMode.RESULT:
        return this.$resultOutcomes;
      case MapViewerVisualizationMode.DATA:
        return this.$dataVersionOutcomes;
      case MapViewerVisualizationMode.DELTA:
        return this.$resultDeltaOutcomes;
    }
  }

  clear() {
    this.clearResultDeltaOutcomes();
    this.clearResultOutcomes();
    this.clearDataVersionOutcomes();
  }

  clearDataVersionOutcomes() {
    this.$dataVersionOutcomeBoundsCache.clear();
    this.$dataVersionOutcomes.clear();
  }

  clearResultOutcomes() {
    this.$resultOutcomeBoundsCache.clear();
    this.$resultOutcomes.clear();
  }

  clearResultDeltaOutcomes() {
    this.$resultDeltaOutcomeBoundsCache.clear();
    this.$resultDeltaOutcomes.clear();
  }

  getDataVersionResults(query: DataVersionQuery): Subject<any> {
    const { mode, catalogParams, layerName, bounds } = query;
    const result = new Subject<any>();

    if (this.totalRequests > 0) {
      // throw error
      result.error({
        error: {
          message: 'existing request running',
          error: 'BadRequest',
          status: HttpStatusCode.BadRequest
        }
      });
      return result;
    }

    this.updateMapViewerVisualizationMode(mode);
    const { mainCatalogName, mainCatalogVersion } = catalogParams;

    if (!mainCatalogName || !mainCatalogVersion || !layerName) {
      // required catalog infos missing
      result.error(false);
      return result;
    }

    const partitions =
      this.$dataVersionOutcomeBoundsCache.getPartitions(bounds);
    this.totalRequests = partitions.length;

    const observable: Observable<any> = this.loadDataVersionTilesOutcome(
      mode,
      catalogParams,
      layerName,
      partitions
    );

    observable.subscribe({
      next: (responses: ResponseMap<DataVersionTileEntity>[]) => {
        this.$dataVersionOutcomeBoundsCache.addAll(partitions);
        this.addResults(mode, responses);
        result.next(true);
      },
      error: (err) => {
        this.resetActiveRequests();
        result.error(err);
      },
      complete: () => {
        this.totalRequests = 0;
        result.complete();
      }
    });
    return result;
  }

  getResults(query: ResultOutcomeQuery): Subject<any> {
    const { mode, catalogParams, testCase, bounds, filterParams } = query;
    const result = new Subject<any>();

    if (this.totalRequests > 0) {
      // throw error
      result.error({
        error: {
          message: 'existing request running',
          error: 'BadRequest',
          status: HttpStatusCode.BadRequest
        }
      });
      return result;
    }

    this.updateMapViewerVisualizationMode(mode);
    const testCaseId = testCase.id;
    const comparisonMapDataType = testCase.comparisonMapDataType;
    const resultType = testCase.resultType;

    const {
      mainCatalogName,
      mainCatalogVersion,
      mainComparisonCatalogName,
      mainComparisonCatalogVersion,
      deltaCatalogName,
      deltaCatalogVersion,
      deltaComparisonCatalogName,
      deltaComparisonCatalogVersion
    } = catalogParams;
    if (!mainCatalogName || !mainCatalogVersion || !testCaseId || !resultType) {
      // catalog infos missing
      result.error({
        error: {
          message: 'catalog infos missing',
          error: 'BadRequest',
          status: HttpStatusCode.BadRequest
        }
      });
      return result;
    }
    if (
      mode === MapViewerVisualizationMode.DELTA &&
      (!deltaCatalogName || !deltaCatalogVersion)
    ) {
      // delta catalog infos missing
      result.error({
        error: {
          message: 'delta catalog infos missing',
          error: 'BadRequest',
          status: HttpStatusCode.BadRequest
        }
      });
      return result;
    }

    if (comparisonMapDataType) {
      if (!mainComparisonCatalogName || !mainComparisonCatalogVersion) {
        // comparison catalog infos missing
        result.error({
          error: {
            message: 'comparison catalog infos missing',
            error: 'BadRequest',
            status: HttpStatusCode.BadRequest
          }
        });
        return result;
      }

      if (
        mode === MapViewerVisualizationMode.DELTA &&
        (!deltaComparisonCatalogName || !deltaComparisonCatalogVersion)
      ) {
        // delta comparison catalog infos missing
        result.error({
          error: {
            message: 'delta comparison catalog infos missing',
            error: 'BadRequest',
            status: HttpStatusCode.BadRequest
          }
        });
        return result;
      }
    }

    let partitions: TileNds[];
    if (mode === MapViewerVisualizationMode.DELTA) {
      partitions = this.$resultDeltaOutcomeBoundsCache.getPartitions(bounds);
    } else {
      partitions = this.$resultOutcomeBoundsCache.getPartitions(bounds);
    }
    this.totalRequests = partitions.length;

    const observable: Observable<any> = this.loadResultsTilesResultOutcomes(
      mode,
      catalogParams,
      testCaseId,
      resultType,
      partitions,
      filterParams
    );

    observable.subscribe({
      next: (responses: ResponseMap<TileEntity | DeltaTileEntity>[]) => {
        if (mode === MapViewerVisualizationMode.DELTA) {
          this.$resultDeltaOutcomeBoundsCache.addAll(partitions);
        } else {
          this.$resultOutcomeBoundsCache.addAll(partitions);
        }
        this.addResults(mode, responses);
        result.next(true);
      },
      error: (err) => {
        this.resetActiveRequests();
        result.error(err);
      },
      complete: () => {
        this.totalRequests = 0;
        result.complete();
      }
    });
    return result;
  }

  public addResults(
    mode: MapViewerVisualizationMode,
    responses: ResponseMap<
      TileEntity | DeltaTileEntity | DataVersionTileEntity
    >[]
  ) {
    for (const response of responses) {
      this.addResult(mode, response);
    }
  }

  getResultsUnit(mode: MapViewerVisualizationMode): string {
    switch (mode) {
      case MapViewerVisualizationMode.RESULT:
        return this.getResultOutcomesUnit();
      case MapViewerVisualizationMode.DELTA:
        return this.getResultDeltaOutcomesUnit();
      default:
        throw new Error(`Unknown visualization mode: ${mode}`);
    }
  }

  createScale(
    mode: MapViewerVisualizationMode,
    resultType: ResultType,
    failVisualization: FailVisualization
  ) {
    switch (mode) {
      case MapViewerVisualizationMode.RESULT:
        return this.createResultOutcomeScale(resultType, failVisualization);
      case MapViewerVisualizationMode.DATA:
        return this.createDataVersionOutcomeScale();
      case MapViewerVisualizationMode.DELTA:
        return this.createResultDeltaOutcomeScale(
          resultType,
          failVisualization
        );
      default:
        throw new Error(`Unknown visualization mode: ${mode}`);
    }
  }

  createResultOutcomeScale(
    resultType: ResultType,
    failVisualization: FailVisualization
  ) {
    switch (resultType) {
      case ResultType.FORMAT_CONTENT:
      case ResultType.COMBINED_DETAILED_STATISTIC:
      case ResultType.FORMAT_CONTENT_DETAILED:
      case ResultType.MAP_APPROVAL:
        if (failVisualization === FailVisualization.FAIL_COUNT) {
          return new MapViewerFailCountScale(this.getFailCountDomain());
        } else {
          return new MapViewerFailRateScale();
        }
      case ResultType.STATISTIC:
      case ResultType.STATISTIC_DETAILED:
        const domain = this.getValueDomain();
        return new MapViewerStatisticValueScale(domain);
    }
  }

  getValueDomain(): number[] {
    let min = null;
    let max = 1;
    for (const tileEntity of this.resultOutcomes.values()) {
      if (typeof tileEntity.value === 'number') {
        min = min !== null ? Math.min(min, tileEntity.value) : tileEntity.value;
        max = max !== null ? Math.max(max, tileEntity.value) : tileEntity.value;
      }
    }
    return [min || 0, max || 0];
  }

  getDataVersionValueDomain(): number[] {
    let min = null;
    let max = 1;
    for (const dataVersionEntity of this.dataVersionOutcomes.values()) {
      if (typeof dataVersionEntity.dataVersion === 'number') {
        if (dataVersionEntity.dataVersion === 0) {
          continue;
        }
        min =
          min !== null
            ? Math.min(min, dataVersionEntity.dataVersion)
            : dataVersionEntity.dataVersion;
        max =
          max !== null
            ? Math.max(max, dataVersionEntity.dataVersion)
            : dataVersionEntity.dataVersion;
      }
    }
    return [min || 0, max || 0];
  }

  getFailCountDomain(): number[] {
    let min = null;
    let max = 1;
    for (const tileEntity of this.resultOutcomes.values()) {
      if (typeof tileEntity.failCount === 'number') {
        min =
          min !== null
            ? Math.min(min, tileEntity.failCount)
            : tileEntity.failCount;
        max =
          max !== null
            ? Math.max(max, tileEntity.failCount)
            : tileEntity.failCount;
      }
    }
    return [min || 0, max || 0];
  }

  getValueDeltaDomain(): number[] {
    let min = null;
    let max = null;
    for (const tileEntity of this.resultDeltaOutcomes.values()) {
      if (typeof tileEntity.valueDelta === 'number') {
        min =
          min !== null
            ? Math.min(min, tileEntity.valueDelta)
            : tileEntity.valueDelta;
        max =
          max !== null
            ? Math.max(max, tileEntity.valueDelta)
            : tileEntity.valueDelta;
      }
    }
    return [min || -1, 0, max || 1];
  }

  createResultDeltaOutcomeScale(
    resultType: ResultType,
    failVisualization: FailVisualization
  ) {
    switch (resultType) {
      case ResultType.FORMAT_CONTENT:
      case ResultType.COMBINED_DETAILED_STATISTIC:
      case ResultType.FORMAT_CONTENT_DETAILED:
      case ResultType.MAP_APPROVAL:
        if (failVisualization === FailVisualization.FAIL_COUNT) {
          return new MapViewerDeltaFailCountScale(this.getValueDeltaDomain());
        } else {
          return new MapViewerDeltaFailRateScale();
        }
      case ResultType.STATISTIC:
      case ResultType.STATISTIC_DETAILED:
        const domain = this.getValueDeltaDomain();
        return new MapViewerStatisticValueDeltaScale(domain);
    }
  }

  observeActiveRequests(): Observable<number> {
    return this.$activeRequestsSubject.asObservable();
  }

  observeTotalRequests(): Observable<number> {
    return this.$totalRequestsSubject.asObservable();
  }

  private addResult(
    mode: MapViewerVisualizationMode,
    response: ResponseMap<TileEntity | DeltaTileEntity | DataVersionTileEntity>
  ) {
    const { data } = response || {};
    switch (mode) {
      case MapViewerVisualizationMode.RESULT:
        Object.keys(data).forEach((k) => {
          this.$resultOutcomes.set(`${k}`, data[k] as TileEntity);
        });
        break;
      case MapViewerVisualizationMode.DATA:
        Object.keys(data).forEach((k) => {
          this.$dataVersionOutcomes.set(
            `${k}`,
            data[k] as DataVersionTileEntity
          );
        });
        break;
      case MapViewerVisualizationMode.DELTA:
        Object.keys(data).forEach((k) => {
          this.$resultDeltaOutcomes.set(`${k}`, data[k] as DeltaTileEntity);
        });
        break;
      default:
        throw new Error(`Unknown visualization mode: ${mode}`);
    }
  }

  private loadResultOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    tile: TileNds,
    filterParams: ResultQueryFilterParams
  ) {
    const { mainCatalogName, mainCatalogVersion } = catalogParams;
    const { functionalRoadClass, attributionStatus, controlledAccess } =
      filterParams;
    return this.datastoreService
      .getResultsTilesResultOutcome(
        mainCatalogName,
        mainCatalogVersion,
        testCaseId,
        tile.getBounds(),
        functionalRoadClass,
        attributionStatus,
        controlledAccess
      )
      .pipe(
        map((repsonse) => {
          this.decreaseActiveRequests(1);
          return repsonse;
        })
      );
  }

  private loadResultComparisonOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    tile: TileNds,
    filterParams: ResultQueryFilterParams
  ) {
    const {
      mainCatalogName,
      mainCatalogVersion,
      mainComparisonCatalogName,
      mainComparisonCatalogVersion
    } = catalogParams;
    const { functionalRoadClass, attributionStatus, controlledAccess } =
      filterParams;
    return this.datastoreService
      .getComparisonResultsTilesResultOutcome(
        mainCatalogName,
        mainCatalogVersion,
        mainComparisonCatalogName,
        mainComparisonCatalogVersion,
        testCaseId,
        tile.getBounds(),
        functionalRoadClass,
        attributionStatus,
        controlledAccess
      )
      .pipe(
        map((repsonse) => {
          this.decreaseActiveRequests(1);
          return repsonse;
        })
      );
  }

  private getResultOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    partitionTiles: TileNds[],
    filterParams: ResultQueryFilterParams
  ): Observable<ResponseMap<TileEntity>[]> {
    const observableRequests: Observable<ResponseMap<TileEntity>>[] =
      partitionTiles.map((tile) =>
        this.loadResultOutcomes(catalogParams, testCaseId, tile, filterParams)
      );
    this.increaseActiveRequests(partitionTiles.length);
    return this.datastoreService.loadWithMaxConcurrency(observableRequests);
  }

  private loadDataVersionOutcome(
    catalogParams: ResultQueryDataCatalogParams,
    layerName: string,
    tile: TileNds
  ) {
    const { mainCatalogName, mainCatalogVersion } = catalogParams;
    return this.datastoreService
      .getDataVersionTilesOutcome(
        mainCatalogName,
        mainCatalogVersion,
        layerName,
        tile.getBounds()
      )
      .pipe(
        map((repsonse) => {
          this.decreaseActiveRequests(1);
          return repsonse;
        })
      );
  }

  private getDataVersionOutcome(
    catalogParams: ResultQueryDataCatalogParams,
    layerName: string,
    partitionTiles: TileNds[]
  ): Observable<any[]> {
    this.increaseActiveRequests(partitionTiles.length);
    const observableRequests: Observable<ResponseMap<DataVersionTileEntity>>[] =
      partitionTiles.map((tile) =>
        this.loadDataVersionOutcome(catalogParams, layerName, tile)
      );
    return this.datastoreService.loadWithMaxConcurrency(observableRequests);
  }

  private loadResultDeltaOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    resultType: ResultType,
    tile: TileNds,
    filterParams: ResultQueryFilterParams
  ) {
    const {
      mainCatalogName,
      mainCatalogVersion,
      mainComparisonCatalogName,
      mainComparisonCatalogVersion,
      deltaCatalogName,
      deltaCatalogVersion,
      deltaComparisonCatalogName,
      deltaComparisonCatalogVersion
    } = catalogParams;

    const { functionalRoadClass, attributionStatus, controlledAccess } =
      filterParams;
    return this.datastoreService
      .getResultsTilesResultOutcomeDelta(
        mainCatalogName,
        mainCatalogVersion,
        mainComparisonCatalogName,
        mainComparisonCatalogVersion,
        deltaCatalogName,
        deltaCatalogVersion,
        deltaComparisonCatalogName,
        deltaComparisonCatalogVersion,
        testCaseId,
        resultType,
        tile.getBounds(),
        functionalRoadClass,
        attributionStatus,
        controlledAccess
      )
      .pipe(
        map((repsonse) => {
          this.decreaseActiveRequests(1);
          return repsonse;
        })
      );
  }

  private getResultDeltaOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    resultType: ResultType,
    partitionTiles: TileNds[],
    filterParams: ResultQueryFilterParams
  ): Observable<any[]> {
    this.increaseActiveRequests(partitionTiles.length);
    const observableRequests: Observable<ResponseMap<DeltaTileEntity>>[] =
      partitionTiles.map((tile) =>
        this.loadResultDeltaOutcomes(
          catalogParams,
          testCaseId,
          resultType,
          tile,
          filterParams
        )
      );
    return this.datastoreService.loadWithMaxConcurrency(observableRequests);
  }

  private createDataVersionOutcomeScale() {
    const domain = this.getDataVersionValueDomain();
    return new MapViewerStatisticValueScale(domain);
  }

  private getResultOutcomesUnit() {
    for (const tileEntity of this.$resultOutcomes.values()) {
      if (tileEntity.unit !== null) {
        return tileEntity.unit;
      }
    }
  }

  private getResultDeltaOutcomesUnit() {
    for (const tileEntity of this.$resultDeltaOutcomes.values()) {
      if (tileEntity.unit !== null) {
        return tileEntity.unit;
      }
    }
  }

  private getResultComparisonOutcomes(
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    partitionTiles: TileNds[],
    filterParams: ResultQueryFilterParams
  ): Observable<any[]> {
    const observableRequests: Observable<ResponseMap<TileEntity>>[] =
      partitionTiles.map((tile) =>
        this.loadResultComparisonOutcomes(
          catalogParams,
          testCaseId,
          tile,
          filterParams
        )
      );
    this.increaseActiveRequests(partitionTiles.length);
    return this.datastoreService.loadWithMaxConcurrency(observableRequests);
  }

  private loadResultsTilesResultOutcomes(
    mode: MapViewerVisualizationMode,
    catalogParams: ResultQueryCatalogParams,
    testCaseId: string,
    resultType: ResultType,
    partitionTiles: TileNds[],
    filterParams: ResultQueryFilterParams
  ): Observable<any[]> {
    switch (mode) {
      case MapViewerVisualizationMode.RESULT:
        if (
          catalogParams.mainComparisonCatalogName &&
          catalogParams.mainComparisonCatalogVersion
        ) {
          return this.getResultComparisonOutcomes(
            catalogParams,
            testCaseId,
            partitionTiles,
            filterParams
          );
        } else {
          return this.getResultOutcomes(
            catalogParams,
            testCaseId,
            partitionTiles,
            filterParams
          );
        }
      case MapViewerVisualizationMode.DELTA:
        return this.getResultDeltaOutcomes(
          catalogParams,
          testCaseId,
          resultType,
          partitionTiles,
          filterParams
        );
      default:
        throw new Error(`Unknown visualization mode: ${mode}`);
    }
  }

  private loadDataVersionTilesOutcome(
    mode: MapViewerVisualizationMode,
    catalogParams: ResultQueryDataCatalogParams,
    layerName: string,
    partitionTiles: TileNds[]
  ): Observable<any[]> {
    if (mode === MapViewerVisualizationMode.DATA) {
      return this.getDataVersionOutcome(
        catalogParams,
        layerName,
        partitionTiles
      );
    } else {
      throw new Error(`Unknown visualization mode: ${mode}`);
    }
  }

  private increaseActiveRequests(addRequests: number): void {
    this.activeRequests = this.$activeRequests + addRequests;
  }

  private decreaseActiveRequests(subRequests: number): void {
    this.activeRequests = Math.max(0, this.$activeRequests - subRequests);
  }

  private resetActiveRequests(): void {
    this.totalRequests = 0;
    this.activeRequests = 0;
  }

  private updateMapViewerVisualizationMode(
    mode: MapViewerVisualizationMode
  ): void {
    if (mode !== this.$mapViewerVisualizationMode) {
      this.clear();
    }
    this.$mapViewerVisualizationMode = mode;
  }
}
