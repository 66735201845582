import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TestcaseResultsComponent } from './page/testcase-results/testcase-results.component';

import { TestResultsRoutingModule } from './test-results.routing';
import { ScenarioRunComponent } from './component/scenario-run/scenario-run.component';
import { TestCasesComponent } from './component/testcases/testcases.component';
import { TestcaseDetailsComponent } from './component/testcase-details/testcase-details.component';

@NgModule({
  declarations: [
    TestcaseResultsComponent,
    ScenarioRunComponent,
    TestCasesComponent,
    TestcaseDetailsComponent
  ],
  exports: [TestcaseResultsComponent, TestcaseDetailsComponent],
  imports: [SharedModule, TestResultsRoutingModule]
})
export class TestResultsModule {}
