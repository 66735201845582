import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { AuthService } from '@shared/service/auth.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent {
  pendingMessage: string;
  public hideMainMenu = false;

  constructor(
    private authService: AuthService,
    private responsive: BreakpointObserver
  ) {
    this.authService.pendingUserMessage.subscribe(
      (message) => (this.pendingMessage = message)
    );
    this.registerResponsiveHandler();
  }

  private registerResponsiveHandler(): void {
    this.responsive
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result) => {
        this.hideMainMenu = false;
        if (result.matches) {
          this.hideMainMenu = true;
        }
      });
  }
}
