import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapViewerSelectVisualizationComponentComponent } from '@modules/map-viewer/component/map-viewer-select-catalog/views/common/map-viewer-select-visualization-component/map-viewer-select-visualization-component.component';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import { CatalogApiEntity } from '@shared/model/productserver';
import { CatalogService } from '@shared/service/catalog.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { NotificationService } from '@shared/service/notification.service';
import { Observable, of as observableOf } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-map-viewer-select-result-visualization',
  templateUrl: './map-viewer-select-result-visualization.component.html',
  styleUrls: ['./map-viewer-select-result-visualization.component.scss']
})
export class MapViewerSelectResultVisualizationComponent
  extends MapViewerSelectVisualizationComponentComponent
  implements OnInit, OnDestroy
{
  @ViewChild('mainCatalogVersionInput') mainCatalogVersionInput: ElementRef;
  @ViewChild('mainComparisonCatalogVersionInput')
  mainComparisonCatalogVersionInput: ElementRef;

  mainComparisonCatalogVersionsFormControl = new FormControl();
  autoCompleteComparisonCatalogVersionList: Observable<number[]>;

  protected $mainComparisonCatalogVersionList: number[];

  protected $mainComparisonCatalog: CatalogApiEntity;
  protected $mainComparisonCatalogVersion: number;

  protected $comparisonCatalogs: Map<string, CatalogApiEntity[]> = new Map<
    string,
    CatalogApiEntity[]
  >();

  constructor(
    protected mapViewerUserSelectionService: MapViewerUserSelectionService,
    protected catalogService: CatalogService,
    protected datastoreService: DatastoreService,
    protected notificationService: NotificationService
  ) {
    super(
      mapViewerUserSelectionService,
      catalogService,
      datastoreService,
      notificationService
    );
  }

  get mainComparisonCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.mainComparisonCatalog;
  }

  get comparisonMapDataType(): string {
    return this.mapViewerUserSelectionService.testCase?.comparisonMapDataType;
  }

  get comparisonCatalogList(): CatalogApiEntity[] {
    return (
      (this.$comparisonCatalogs.has(
        this.mapViewerUserSelectionService.testCase?.comparisonMapDataType
      ) &&
        this.$comparisonCatalogs.get(
          this.mapViewerUserSelectionService.testCase?.comparisonMapDataType
        )) ||
      []
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getComparisonCatalogs();
    this.getComparisonCatalogVersionList();
    this.updateMainComparisonCatalogVersionsFormControl();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  selectMainCatalog(catalogName: string) {
    super.selectMainCatalog(catalogName);
    this.mainCatalogVersionInput.nativeElement.blur();
  }

  selectComparisonCatalog(catalogName: string) {
    if (
      catalogName ===
      this.mapViewerUserSelectionService.mainComparisonCatalog?.name
    ) {
      return;
    }
    this.mapViewerUserSelectionService.mainComparisonCatalog = null;
    this.mapViewerUserSelectionService.mainComparisonCatalogVersion = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mapViewerUserSelectionService.mainComparisonCatalog = catalog;
      });
  }

  selectMainComparisonCatalogVersion(value: number): void {
    this.mapViewerUserSelectionService.mainComparisonCatalogVersion =
      parseInt(String(value).trim(), 10) || null;
    this.mainComparisonCatalogVersionInput.nativeElement.blur();
  }

  getComparisonCatalogs(): void {
    this.$comparisonCatalogs.clear();
    this.isLoadingResults = true;
    this.$subscription.add(
      this.datastoreService.getDeltaCatalogNameList().subscribe(
        (catalogs: CatalogApiEntity[]) => {
          this.isLoadingResults = false;
          this.createDeltaGroupedCatalogs(catalogs);
        },
        (err) => {
          this.isLoadingResults = false;
          this.showApiError(err);
          return observableOf([]);
        }
      )
    );
  }

  getComparisonCatalogVersionList(): void {
    this.$mainComparisonCatalogVersionList = [];
    if (this.mapViewerUserSelectionService.mainComparisonCatalog?.name) {
      this.$subscription.add(
        this.datastoreService
          .getComparisonCatalogVersionList(
            this.mapViewerUserSelectionService.mainComparisonCatalog.name
          )
          .subscribe(
            (response) => {
              this.$mainComparisonCatalogVersionList = response.data;
              this.$mainComparisonCatalogVersionList.sort((a, b) => a - b);
              this.$mainComparisonCatalogVersionList.reverse();
              if (this.$mainComparisonCatalogVersionList.length) {
                this.autoCompleteComparisonCatalogVersionList =
                  this.mainComparisonCatalogVersionsFormControl.valueChanges.pipe(
                    startWith(''),
                    map((value) =>
                      this.getAutoCompleteCatalogVersions(
                        this.$mainComparisonCatalogVersionList,
                        value
                      )
                    )
                  );
              }
            },
            (err) => {
              this.showApiError(err);
              return observableOf([]);
            }
          )
      );
    }
  }

  protected registerListeners() {
    super.registerListeners();
    this.registerMainComparisonCatalogListener();
  }

  protected registerMainComparisonCatalogListener() {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalog()
        .subscribe((_catalog: CatalogApiEntity) => {
          this.getComparisonCatalogs();
        })
    );

    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainComparisonCatalog()
        .subscribe((catalog: CatalogApiEntity) => {
          this.$mainComparisonCatalog = catalog;
          this.getComparisonCatalogVersionList();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainComparisonCatalogVersion()
        .subscribe((catalogVersion: number) => {
          this.$mainComparisonCatalogVersion = catalogVersion;
          this.updateMainComparisonCatalogVersionsFormControl();
        })
    );
  }

  private createDeltaGroupedCatalogs(catalogs: CatalogApiEntity[]): void {
    this.$comparisonCatalogs.clear();
    for (const catalog of catalogs || []) {
      const { mapDataType } = catalog;
      if (!this.$comparisonCatalogs.has(mapDataType)) {
        this.$comparisonCatalogs.set(mapDataType, []);
      }
      this.$comparisonCatalogs.get(mapDataType).push(catalog);
    }
  }

  private updateMainComparisonCatalogVersionsFormControl(): void {
    const value =
      this.mapViewerUserSelectionService.mainComparisonCatalogVersion;
    this.mainComparisonCatalogVersionsFormControl.setValue(value);
  }
}
