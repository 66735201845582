import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import { TestCaseApiEntity } from '@shared/model/productserver';

export interface HdmlViewerOptions {
  latitude: number;
  longitude: number;
  mainCatalogName: string;
  mainCatalogVersion: number;
  zoom: number;
  testCase: TestCaseApiEntity;
  layerName: string;
  visualizationMode: MapViewerVisualizationMode;
  mapDataType: string;
}

@Injectable({
  providedIn: 'root'
})
export class MapHdmlViewerUrlService {
  private $stgBaseUrl = 'https://cit.hdlmv.ext.here.com/app/olp/index.html?';
  private $prodBaseUrl = 'https://hdlmv.ext.here.com/app/olp/index.html?';
  private $stgBaseUrlV3 = 'https://hdlmv3.ext.here.com/app/?';

  private hdlmv2IgnoredMapDataTypes = new Set<string>(['VZO']);

  constructor() {}

  generateHDLMViewerUrl(options: HdmlViewerOptions): string {
    const {
      latitude,
      longitude,
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    } = options;
    if (
      mapDataType &&
      this.hdlmv2IgnoredMapDataTypes.has(mapDataType.toUpperCase())
    ) {
      return '';
    }
    const region = this.catalogName2ToRegion(mainCatalogName);
    const format = this.mapDataType2Format(mapDataType);
    const layer =
      (visualizationMode === MapViewerVisualizationMode.DATA
        ? layerName
        : testCase?.mainLayer) || 'routing';
    const url = this.getBaseUrl(region);
    let params = new HttpParams();
    params = params.append('format', String(format));
    params = params.append('region', String(region));
    params = params.append(
      'catalog_version',
      String(mainCatalogVersion || 'latest')
    );
    params = params.append('layer', String(layer));
    params = params.append('lat', String(latitude));
    params = params.append('lon', String(longitude));
    params = params.append('zoom', String(zoom));
    return `${url}${params.toString()}`;
  }

  generateHDLM3ViewerUrl(options: HdmlViewerOptions): string {
    const {
      latitude,
      longitude,
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    } = options;
    const layer =
      (visualizationMode === MapViewerVisualizationMode.DATA
        ? layerName
        : testCase?.mainLayer) || 'lane';
    const spec = this.layerToSpec(layer);
    const specFilter = this.layerToSpecFilter(layer);
    const format = this.mapDataType2Format(mapDataType);
    const url = this.$stgBaseUrlV3;
    let params = new HttpParams();
    if (mainCatalogName === undefined || mainCatalogName === null) {
      params = params.append(
        'cataloghrn',
        String('hrn:here:data::olp-here:here-hdlm-eu-nds-v2-0')
      );
      params = params.append('format', String('nds'));
    } else {
      params = params.append('cataloghrn', String(mainCatalogName));
      params = params.append('format', String(format));
      if (mainCatalogVersion !== undefined && mainCatalogVersion !== null) {
        params = params.append('selectedVersion', String(mainCatalogVersion));
      }
    }
    params = params.append('specs', String(spec));
    params = params.append('lat', String(latitude));
    params = params.append('pinlat', String(latitude));
    params = params.append('lng', String(longitude));
    params = params.append('pinlng', String(longitude));
    params = params.append('zoom', Math.max(zoom, 15));
    params = params.append('specfilter', String(specFilter));
    params = params.append('view', 'MAP');
    params = params.append('pitch', 0);
    params = params.append('yaw', 0);
    return `${url}${params.toString()}`;
  }

  private catalogName2ToRegion(catalogName: string): string {
    switch (catalogName) {
      case 'hrn:here:data::olp-here:here-cit-onemap-hdlm-nds-weu-1':
        return 'onemap-cit-weu';
      case 'hrn:here:data::olp-here:here-stg-onemap-hdlm-native-global-1':
      case 'hrn:here:data::olp-here:here-stg-adpc-hdlm-nds-weu-1':
        return 'onemap-global-staging';
      case 'hrn:here:data::olp-here:here-onemap-hdlm-nds-weu-1':
      case 'hrn:here:data::olp-here:here-onemap-hdlm-native-weu-1':
        return 'onemap-weu';
      case 'hrn:here:data::olp-here-had:here-hdlm-protobuf-weu-2':
      case 'hrn:here:data::olp-here-had:here-hdlm-nds-weu-3':
        return 'weu';
      case 'hrn:here:data::olp-here:here-hdlm-eu-nds-v2-0':
        return 'here-hdlm-eu-nds-v2-0';
      case 'hrn:here:data::olp-here:here-hdlm-na-nds-rc-v2-0':
        return 'here-hdlm-na-nds-rc-v2-0';
      case 'hrn:here:data::olp-here:here-hdlm-na-nds-v2-0':
        return 'here-hdlm-na-nds-v2-0';
    }
    return 'here-hdlm-eu-nds-rc-v2-0';
  }

  private mapDataType2Format(mapDataType: string): string {
    switch (mapDataType) {
      case 'NDS':
        return mapDataType.toLowerCase();
      case 'VZO':
        return 'hrssslpb';
      default:
        return 'protobuf';
    }
  }

  private layerToSpec(layer: string): string {
    switch (layer) {
      case 'routing':
      case 'guidance':
        return 'PLM_02,RM_02';
      case 'lane':
        return 'PLM_03,PLM_27,PLM_02,RM_02';
      case 'barriers':
        return 'LM_02,PLM_02,RM_02';
      case 'markings':
        return 'PLM_02,RM_02,LM_10';
      case 'poles':
        return 'PLM_02,LM_07,RM_02';
      case 'signs':
        return 'PLM_02,RM_02,LM_03';
      case 'trafficlights':
        return 'PLM_02,RM_02,LM_09';
      case 'speed-attributes':
        return 'CONDITIONAL_SPEED_LIMIT,RM_02,RM_15,TEMPORARY_SPEED_LIMIT';
    }
  }

  private layerToSpecFilter(layer: string): string {
    switch (layer) {
      case 'lane':
        return '57,58,5,6,7,8,9,10,11,12,13,14,15,16,1,2,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,148,188,189';
      case 'markings':
        return '1,141,142,143,144,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140';
      case 'signs':
        return '1,220,222,226,228,235,234,232,231,224,225,236,208,209,210,211,212,215,216,217,218,219,213,214';
      case 'routing':
      case 'guidance':
      case 'barriers':
      case 'poles':
      case 'trafficlights':
        return '1';
    }
    return '';
  }

  private getBaseUrl(regionName: string): string {
    switch (regionName) {
      case 'onemap-weu':
      case 'weu':
      case 'here-hdlm-eu-nds-rc-v2-0':
      case 'here-hdlm-na-nds-rc-v2-0':
      case 'here-hdlm-eu-nds-v2-0':
      case 'here-hdlm-na-nds-v2-0':
        return this.$prodBaseUrl;
    }
    return this.$stgBaseUrl;
  }
}
