import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  CatalogApiEntity,
  CatalogInfoApiEntity,
  CatalogVersionApiEntity
} from '@shared/model/productserver';
import { Subscription } from 'rxjs';
import { ProductServerService } from '@shared/service/product-server.service';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { CatalogService } from '@shared/service/catalog.service';

@Component({
  selector: 'app-map-catalog-info-form',
  templateUrl: './map-catalog-info-form.component.html',
  styleUrls: ['./map-catalog-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapCatalogInfoFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public form: FormGroup | null = null;
  public catalogNames: CatalogApiEntity[] = [];
  public comparisonCatalogNames: CatalogApiEntity[] = [];
  public catalogs: CatalogApiEntity[] = [];
  public mapDataType: string;
  public autocomplete$ = {
    catalog: new Array<CatalogVersionApiEntity>(),
    deltaCatalog: new Array<CatalogVersionApiEntity>()
  };
  public isLoadingCatalogs = false;
  public isLoadingVersions = {
    catalog: false,
    deltaCatalog: false
  };
  public mapDataTypeOptions: string[] = [];
  private $mapCatalogInfo: CatalogInfoApiEntity;
  private $enabledRelativeCatalogVersions = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private productServerService: ProductServerService,
    private catalogService: CatalogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get mapCatalogInfo(): CatalogInfoApiEntity {
    return this.$mapCatalogInfo;
  }

  @Input() set mapCatalogInfo(value: CatalogInfoApiEntity) {
    if (value === null || value === undefined) {
      this.$mapCatalogInfo = null;
    } else {
      this.$mapCatalogInfo = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get enabledRelativeCatalogVersions(): boolean {
    return this.$enabledRelativeCatalogVersions;
  }

  @Input() set enabledRelativeCatalogVersions(value: boolean) {
    if (value === null || value === undefined) {
      this.$enabledRelativeCatalogVersions = false;
    } else {
      this.$enabledRelativeCatalogVersions = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.getCatalogs();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.loadCatalogInfos();
  }

  getCatalogs(): void {
    this.isLoadingCatalogs = true;
    this.subscription.add(
      this.catalogService
        .getCatalogs()
        .subscribe((catalogs: CatalogApiEntity[]) => {
          this.catalogs = catalogs;
          this.getMapDataType();
          this.isLoadingCatalogs = false;
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  onMapDataSelectionChange(): void {
    this.mapDataType = this.form.get('mapDataType').value;
    this.catalogNames = [];

    this.catalogs
      .filter((catalog) => catalog.mapDataType === this.mapDataType)
      .forEach((catalog) => this.catalogNames.push(catalog));
    this.autocomplete$.catalog = [];
  }

  onComparisonMapDataSelectionChange(): void {
    const comparisonMapDataType = this.form.get('comparisonMapDataType').value;
    this.comparisonCatalogNames = [];
    this.catalogs
      .filter((catalog) => catalog.mapDataType === comparisonMapDataType)
      .forEach((catalog) => this.comparisonCatalogNames.push(catalog));
    this.autocomplete$.deltaCatalog = [];
  }

  getCatalogVersions(catalogName: string, controlName: string): void {
    this.isLoadingVersions[controlName] = true;
    this.subscription.add(
      this.productServerService
        .getCatalogVersions(catalogName)
        .subscribe((catalogVersions) => {
          this.autocomplete$[controlName] = catalogVersions;
          this.isLoadingVersions[controlName] = false;
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  getFormValues(): CatalogInfoApiEntity {
    const mapCatalog = this.form.getRawValue();
    const { mapDataType, name, deltaName } = mapCatalog;
    let { version, deltaVersion } = mapCatalog;

    version = parseInt(version, 10);
    const mapObject: CatalogInfoApiEntity = {
      mapDataType,
      name,
      version
    };

    deltaVersion = parseInt(deltaVersion, 10);
    if (!!deltaName && !isNaN(deltaVersion)) {
      mapObject.deltaName = deltaName;
      mapObject.deltaVersion = deltaVersion;
    }
    return mapObject;
  }

  getVersionValidators(): ValidatorFn[] {
    if (this.enabledRelativeCatalogVersions) {
      return [Validators.required];
    } else {
      return [Validators.required, Validators.min(1)];
    }
  }

  createFormGroup(): FormGroup {
    const versionValidators = this.getVersionValidators();
    this.form = this.fb.group({
      mapDataType: [this.mapCatalogInfo?.mapDataType],
      name: [this.mapCatalogInfo?.name],
      version: [this.mapCatalogInfo?.version],
      deltaName: [this.mapCatalogInfo?.deltaName],
      deltaVersion: [this.mapCatalogInfo?.deltaVersion],
      comparisonMapDataType: [null]
    });
    this.form.controls.name.valueChanges
      .pipe(startWith(''), distinctUntilChanged(), pairwise())
      .subscribe(([oldValue, value]) => {
        if (oldValue !== value) {
          this.form.controls.version.reset();
        }
        if (value) {
          this.getCatalogVersions(value, 'catalog');
          this.onMapDataSelectionChange();
          this.form.controls.version.setValidators(versionValidators);
        } else {
          this.form.controls.version.clearValidators();
        }
        this.form.controls.version.updateValueAndValidity();
      });
    this.form.controls.deltaName.valueChanges
      .pipe(startWith(''), distinctUntilChanged(), pairwise())
      .subscribe(([oldValue, value]) => {
        if (oldValue !== value) {
          this.form.controls.deltaVersion.reset();
        }
        if (value) {
          this.initComparisonMapDataType();
          this.getCatalogVersions(value, 'deltaCatalog');
          this.form.controls.name.setValidators([Validators.required]);
          this.form.controls.deltaVersion.setValidators(versionValidators);
        } else {
          this.form.controls.name.clearValidators();
          this.form.controls.deltaVersion.clearValidators();
        }
        this.form.controls.name.updateValueAndValidity();
        this.form.controls.deltaVersion.updateValueAndValidity();
      });
    return this.form;
  }

  private loadCatalogInfos() {
    if (!this.mapCatalogInfo) {
      return;
    }
    this.subscription.add(
      this.catalogService
        .getCatalogByName(this.mapCatalogInfo.name)
        .subscribe((mainCatalog) => {
          this.form.get('name').setValue(mainCatalog.name);
          this.form.get('mapDataType').setValue(mainCatalog.mapDataType);
          this.form.get('version').setValue(this.mapCatalogInfo.version);
          this.onMapDataSelectionChange();
        })
    );
    this.subscription.add(
      this.catalogService
        .getCatalogByName(this.mapCatalogInfo.deltaName)
        .subscribe((deltaCatalog) => {
          this.form.get('deltaName').setValue(deltaCatalog.name);
          this.form
            .get('comparisonMapDataType')
            .setValue(deltaCatalog.mapDataType);
          this.form
            .get('deltaVersion')
            .setValue(this.mapCatalogInfo.deltaVersion);
          this.onComparisonMapDataSelectionChange();
        })
    );
  }

  private initComparisonMapDataType(): void {
    const comparisonMapDataType = this.form.get('comparisonMapDataType');
    let mapDataType = null;
    const deltaName = this.form.get('deltaName').value;
    if (deltaName) {
      mapDataType = this.catalogs.find((cat) => cat.name === deltaName)
        ?.mapDataType;
    }
    comparisonMapDataType.setValue(mapDataType);
    this.onComparisonMapDataSelectionChange();
  }

  private getMapDataType() {
    const mapDataTypeSet = new Set<string>();
    this.catalogs.forEach((catalog) => mapDataTypeSet.add(catalog.mapDataType));
    this.mapDataTypeOptions = Array.from(mapDataTypeSet);
  }
}
