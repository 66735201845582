<app-main-toolbar (sidenavToggle)="sidenav.toggle()"></app-main-toolbar>

<mat-sidenav-container>
  <mat-sidenav class="kf-side-navigation" mode="over" opened="false" #sidenav>
    <app-side-navigation
      *ngIf="hideMainMenu"
      (sidenavClose)="sidenav.close()"
    ></app-side-navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="wrapper">
      <div class="main-panel" id="main-panel">
        <div *ngIf="pendingMessage" class="container-fluid">
          <div class="notification">
            <p class="notification-text">{{pendingMessage}}</p>
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>



