import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractThresholdDetailedDefinitionComponent } from '@modules/thresholds/component/shared/abstract-threshold-detailed-definition.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ThresholdFormEventService } from '@modules/thresholds/service/threshold-form-event.service';

@Component({
  selector: 'app-threshold-failrate-detailed-entity',
  templateUrl: './threshold-failrate-detailed-entity.component.html',
  styleUrls: ['../shared/shared.scss']
})
export class ThresholdFailrateDetailedEntityComponent extends AbstractThresholdDetailedDefinitionComponent {
  constructor(
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected thresholdFormEventService: ThresholdFormEventService
  ) {
    super(formBuilder, dialog, thresholdFormEventService);
  }

  protected buildForm(): FormGroup {
    const formGroup = super.buildForm();
    formGroup.addControl(
      'thresholdRateAllFrcGreen',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc0Green',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc1Green',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc2Green',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc3Green',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc4Green',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateAllFrcYellow',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc0Yellow',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc1Yellow',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc2Yellow',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc3Yellow',
      this.formBuilder.control('')
    );
    formGroup.addControl(
      'thresholdRateFrc4Yellow',
      this.formBuilder.control('')
    );
    formGroup.addControl('areaName', this.formBuilder.control(''));
    return formGroup;
  }
}
