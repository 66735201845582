import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '@shared/service/settings.service';
import { ActuatorInfoResponse, BuildInfo } from '@shared/model/actuator';
import { environment } from '@env';
import {
  CatalogApiEntity,
  CatalogVersionApiEntity,
  ComplexTriggerJobApiEntity,
  ComplexTriggerJobRequestObject
} from '../model/productserver';
import { ResponseCollection, ResponseObject } from '../model/response';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductServerService {
  systemInfoUrl: string;
  catalogsUrl: string;
  automaticExecutionsUrl: string;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.systemInfoUrl = settingsService.getProductServerUrl(
      `${environment.productServerInfoUrl}`
    );
    this.catalogsUrl = settingsService.getProductServerUrl(
      `${environment.productServerCatalogsUrl}`
    );
    this.automaticExecutionsUrl = settingsService.getProductServerUrl(
      `${environment.productServerAutomaticExecutionsUrl}`
    );
  }

  getSystemInfo(): Observable<ActuatorInfoResponse<BuildInfo>> {
    return this.httpClient.get<ActuatorInfoResponse<BuildInfo>>(
      this.systemInfoUrl
    );
  }

  getCatalogs(): Observable<CatalogApiEntity[]> {
    return this.httpClient
      .get<ResponseCollection<CatalogApiEntity>>(this.catalogsUrl)
      .pipe(
        map((response) => response.data),
        map((entities) => {
          entities.sort((a, b) => a.name.localeCompare(b.name));
          return entities;
        })
      );
  }

  getCatalogVersions(
    catalogName: string,
    limit: number = 10
  ): Observable<CatalogVersionApiEntity[]> {
    return this.httpClient
      .get<ResponseCollection<CatalogVersionApiEntity>>(
        `${this.catalogsUrl}/${catalogName}`,
        {
          params: { limit: String(limit) }
        }
      )
      .pipe(map((response) => response.data));
  }

  getComplexTriggerJobs(): Observable<ComplexTriggerJobApiEntity[]> {
    return this.httpClient
      .get<ResponseCollection<ComplexTriggerJobApiEntity>>(
        `${this.automaticExecutionsUrl}`
      )
      .pipe(map((response) => response.data));
  }

  getComplexTriggerJob(id: number): Observable<ComplexTriggerJobApiEntity> {
    return this.httpClient
      .get<ResponseObject<ComplexTriggerJobApiEntity>>(
        `${this.automaticExecutionsUrl}/${id}`
      )
      .pipe(map((response) => response.data));
  }

  createComplexTriggerJob(
    triggerJob: ComplexTriggerJobRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      `${this.automaticExecutionsUrl}`,
      triggerJob,
      {
        observe: 'response'
      }
    );
  }

  replaceComplexTriggerJob(
    id: number,
    triggerJob: ComplexTriggerJobRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.put<string>(
      `${this.automaticExecutionsUrl}/${id}`,
      triggerJob,
      {
        observe: 'response'
      }
    );
  }

  deleteComplexTriggerJob(id: number): Observable<HttpResponse<string>> {
    return this.httpClient.delete<string>(
      `${this.automaticExecutionsUrl}/${id}`,
      {
        observe: 'response'
      }
    );
  }

  sortCatalogsByMapDataType(catalogs: CatalogApiEntity[]): CatalogApiEntity[] {
    const sortedCatalogs = [...catalogs];
    sortedCatalogs.sort((a, b) => {
      if (a.mapDataType === b.mapDataType) {
        return b.publishedAt.localeCompare(a.publishedAt);
      }
      const sortStringA =
        this.getSortPrefixByMapDataType(a.mapDataType) +
        a.mapDataType.toUpperCase();
      const sortStringB =
        this.getSortPrefixByMapDataType(b.mapDataType) +
        b.mapDataType.toUpperCase();
      return sortStringA.localeCompare(sortStringB);
    });
    return sortedCatalogs;
  }

  groupCatalogsByMapDataType(
    catalogApiEntities: CatalogApiEntity[]
  ): Map<string, CatalogApiEntity[]> {
    const catalogsByMapDataType = new Map<string, CatalogApiEntity[]>();

    if (catalogApiEntities?.length > 0) {
      for (const catalog of catalogApiEntities) {
        if (!catalogsByMapDataType.has(catalog.mapDataType)) {
          catalogsByMapDataType.set(catalog.mapDataType, []);
        }
        catalogsByMapDataType.get(catalog.mapDataType).push(catalog);
      }
    }
    return catalogsByMapDataType;
  }

  sortCatalogsByPublishedAt(catalogs: CatalogApiEntity[]): CatalogApiEntity[] {
    const sortedCatalogs = [...catalogs];
    sortedCatalogs.sort((a, b) => b.publishedAt.localeCompare(a.publishedAt));
    return sortedCatalogs;
  }

  private getSortPrefixByMapDataType(mapDataType: string): number {
    if (mapDataType === 'CSC') {
      return 0;
    }
    if (mapDataType === 'NDS') {
      return 1;
    }
    return 2;
  }
}
