<ng-container>
    <label class="kf-label">Select Main Catalog</label>
    <div class="kf-form-catalog-select">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <span matSuffix [class.spinner]="isLoadingResults"></span>
            <input matInput *ngIf="isLoadingResults" />
            <mat-select *ngIf="!isLoadingResults"
                        id="selectedCatalogName"
                        [value]="mainCatalog?.name"
                        (valueChange)="selectMainCatalog($event)"
                        panelClass="kf-catalog-select"
                        required
            >
                <mat-option [value]="null">None</mat-option>
                <mat-optgroup *ngFor="let catalogGroup of catalogs | keyvalue:asIsOrder" [label]="catalogGroup.key">
                    <mat-option
                            *ngFor="let catalog of catalogGroup.value"
                            [value]="catalog.name"
                    >{{catalog.name|catalogName}}</mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input
                    id="selectedCatalogVersion"
                    #mainCatalogVersionInput
                    [formControl]="mainCatalogVersionsFormControl"
                    [matAutocomplete]="autocompleteCatalogVersion"
                    matInput
                    placeholder="eg. 123"
                    type="text"
                    required
            >
            <mat-autocomplete
                #autocompleteCatalogVersion="matAutocomplete"
                (optionSelected)="selectMainCatalogVersion($event.option.value)"
            >
                <mat-option
                        *ngFor="let catalogVersion of autoCompleteCatalogVersionList | async"
                        [value]="catalogVersion"
                >{{catalogVersion}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-container>

<ng-container *ngIf="comparisonMapDataType">
    <label class="kf-label">Select Comparison for Main Catalog</label>
    <div class="kf-form-catalog-select">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <mat-select
                    id="selectedComparisonCatalogName"
                    [value]="mainComparisonCatalog?.name"
                    (valueChange)="selectComparisonCatalog($event)"
                    panelClass="kf-catalog-select"
                    required
            >
                <mat-option [value]="null">None</mat-option>
                <mat-option
                        *ngFor="let comparisonCatalog of comparisonCatalogList"
                        [value]="comparisonCatalog.name"
                >{{comparisonCatalog.name|catalogName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input
                    id="selectedComparisonCatalogVersion"
                    #mainComparisonCatalogVersionInput
                    [formControl]="mainComparisonCatalogVersionsFormControl"
                    [matAutocomplete]="autocompleteMainComparisonCatalogVersion"
                    matInput
                    placeholder="eg. 123"
                    type="text"
                    required
            >
            <mat-autocomplete
                #autocompleteMainComparisonCatalogVersion="matAutocomplete"
                (optionSelected)="selectMainComparisonCatalogVersion($event.option.value)"
            >
                <mat-option
                        *ngFor="let comparisonCatalogVersion of autoCompleteComparisonCatalogVersionList | async"
                        [value]="comparisonCatalogVersion"
                >{{comparisonCatalogVersion}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <hr/>
</ng-container>

<ng-container>
    <label class="kf-label">Select Delta Catalog</label>
    <div class="kf-form-catalog-select">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <mat-select
                    id="selectDeltaCatalogName"
                    [value]="deltaCatalog?.name"
                    (valueChange)="selectDeltaCatalog($event)"
                    panelClass="kf-catalog-select"
                    required
            >
                <mat-option [value]="null">None</mat-option>
                <mat-option
                        *ngFor="let deltaCatalog of deltaCatalogList"
                        [value]="deltaCatalog.name"
                >{{deltaCatalog.name|catalogName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input
                    id="selectedDeltaCatalogVersion"
                    #deltaCatalogVersionInput
                    [formControl]="deltaCatalogVersionsFormControl"
                    [matAutocomplete]="autocompleteDeltaCatalogVersion"
                    matInput
                    placeholder="eg. 123"
                    type="text"
                    required
            >
            <mat-autocomplete
                #autocompleteDeltaCatalogVersion="matAutocomplete"
                (optionSelected)="selectDeltaCatalogVersion($event.option.value)"
            >
                <mat-option
                        *ngFor="let deltaCatalogVersion of autoCompleteDeltaCatalogVersionList | async"
                        [value]="deltaCatalogVersion"
                >{{deltaCatalogVersion}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-container>

<div *ngIf="comparisonMapDataType">
    <label class="kf-label">Select Comparison for Delta Catalog</label>
    <div class="kf-form-catalog-select">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <mat-select
                    id="selectedDeltaComparisonCatalogName"
                    [value]="deltaComparisonCatalog?.name"
                    (valueChange)="selectDeltaComparisonCatalog($event)"
                    panelClass="kf-catalog-select"
                    required
            >
                <mat-option [value]="null">None</mat-option>
                <mat-option
                        *ngFor="let deltaComparisonCatalog of comparisonCatalogList"
                        [value]="deltaComparisonCatalog.name"
                >{{deltaComparisonCatalog.name|catalogName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Version</mat-label>
            <input
                    id="selectedDeltaComparisonCatalogVersion"
                    #deltaComparisonCatalogVersionInput
                    [formControl]="deltaComparisonCatalogVersionsFormControl"
                    [matAutocomplete]="autocompleteDeltaComparisonCatalogVersion"
                    matInput
                    placeholder="eg. 123"
                    type="text"
                    required
            >
            <mat-autocomplete
                #autocompleteDeltaComparisonCatalogVersion="matAutocomplete"
                (optionSelected)="selectDeltaComparisonCatalogVersion($event.option.value)"
            >
                <mat-option
                        *ngFor="let comparisonCatalogVersion of autoCompleteDeltaComparisonCatalogVersionList | async"
                        [value]="comparisonCatalogVersion"
                >{{comparisonCatalogVersion}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
