<mat-toolbar class="kf-main-toolbar mat-elevation-z8">
  <button
    *ngIf="hideMainMenu"
    mat-icon-button
    (click)="emitSidenavToggle()"
    aria-label="toggle sideNavigation"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-button class="kf-logo" routerLink="/">
    Kingfisher
  </button>

  <div
    *ngIf="!hideMainMenu"
    class="kf-main-toolbar-left"
  >
    <app-main-toolbar-item
      *ngFor="let item of mainNavigation"
      [item]="item"
      [events]="eventsSubject.asObservable()"
      (submenuClose)="onSubmenuClose()"
    ></app-main-toolbar-item>
  </div>

  <span class="toolbar-spacer"></span>

  <app-main-toolbar-user-menu
    [events]="eventsSubject.asObservable()"
    (submenuClose)="onSubmenuClose()"
  ></app-main-toolbar-user-menu>
</mat-toolbar>
