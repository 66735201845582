import { Component, Input, OnInit } from '@angular/core';
import { PointWgs84 } from '@shared/service/coordinate-utils.service';

export interface MapViewerMarkerPopupOptions {
  pointWgs84: PointWgs84;
  ndsTileId: number;
  hnativeTileId: number;
  hdmlViewerUrl: string;
  hdml3ViewerUrl: string;
}

@Component({
  selector: 'app-map-viewer-marker-popup',
  templateUrl: './map-viewer-marker-popup.component.html',
  styleUrls: ['./map-viewer-marker-popup.component.scss']
})
export class MapViewerMarkerPopupComponent implements OnInit {
  @Input() data!: MapViewerMarkerPopupOptions;

  constructor() {}

  ngOnInit(): void {}
}
