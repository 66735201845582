<h2 mat-dialog-title>Unsaved Changes!</h2>
<mat-dialog-content>
  Unsaved changes will be lost, do you want to continue?
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button
          [mat-dialog-close]="false"
          (click)="onNoClick()">No</button>
  <button mat-raised-button color="warn"
          [mat-dialog-close]="true"
          (click)="onYesClick()">Yes</button>
</mat-dialog-actions>
