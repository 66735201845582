import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

export interface RedirectionExtras extends NavigationExtras {
  target?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    readonly router: Router,
    @Inject(DOCUMENT) readonly document: Document
  ) {}

  /**
   * Redirects instantly to the external link without the mediation of the router
   *
   * @param url An absolute URL.
   * @param extras (optional). An object containing properties that modify the navigation strategy.
   */
  public redirect(url: string, extras?: RedirectionExtras): Promise<boolean> {
    const target = (extras && extras.target) || '_blank';
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!this.document.defaultView.open(url, target));
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Returns true if the given url looks external
   *
   * @param url An absolute URL.
   * @return true if external URL
   */
  public external(url: string): boolean {
    return /^https?:\/{2}\S+$/.test(url);
  }

  /**
   * Navigates to the given url, redirecting when necessary
   *
   * @param url An absolute URL. The function does not apply any delta to the current URL. When starting with 'http(s)://' triggers the external redirection.
   * @param extras (optional). An object containing properties that modify the navigation strategy. The function ignores any properties that would change the provided URL.
   */
  public navigate(url: string, extras?: RedirectionExtras): Promise<boolean> {
    // Extracts the target from the extras
    return this.external(url)
      ? // Redirects to external link
        this.redirect(url, extras)
      : // Navigates with the router otherwise
        this.router.navigateByUrl(url, extras);
  }
}
