import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { NavigationNode } from '@shared/model/navigation';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '@shared/service/auth.service';

@Component({
  template: '',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ]
})
export abstract class ToolbarMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() item: NavigationNode;
  @Input() events: Observable<void>;

  @Output() submenuClose = new EventEmitter();

  @HostBinding('attr.aria-expanded') ariaExpanded = false;

  expanded = false;

  protected $router: Router;
  protected $eventsSubscription: Subscription;
  protected $authService: AuthService;

  protected constructor(router: Router) {
    this.$router = router;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.closeSubmenu();
  }

  ngOnInit(): void {
    if (this.events) {
      this.$eventsSubscription = this.events.subscribe(() => {
        this.closeSubmenu();
      });
    }
  }

  ngOnDestroy() {
    if (this.$eventsSubscription) {
      this.$eventsSubscription.unsubscribe();
    }
  }

  isActive(path: string): boolean {
    return path ? this.$router.isActive(path, false) : false;
  }

  onMenuSelect = () => {
    this.emitCloseSubmenu();
  };

  onMenuOpen = () => {
    this.emitCloseSubmenu();
  };

  menuOpened = () => {
    this.setExpanded(true);
  };

  menuClosed = () => {
    this.setExpanded(false);
  };

  closeSubmenu = () => {
    if (this.trigger && this.expanded) {
      this.trigger.closeMenu();
    }
  };

  emitCloseSubmenu = () => {
    this.submenuClose.emit();
  };

  async onItemSelected(item: NavigationNode) {
    if (item.queryParams?.logout) {
      this.$authService.logout();
    }
    if (!item.children || !item.children.length) {
      this.emitCloseSubmenu();
      await this.$router.navigate([item.path]);
    }
  }

  private setExpanded(expanded: boolean) {
    this.expanded = expanded;
    this.ariaExpanded = this.expanded;
  }
}
