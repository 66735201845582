import { Injectable } from '@angular/core';
import { Bounds } from '@shared/model/datastore';
import {
  InvalidCoordinateException,
  InvalidFormatException,
  InvalidFormatLevelException
} from '../model/errors';
import {
  CoordinateUtilsService,
  PointMortonCode,
  PointNds,
  PointWgs84
} from './coordinate-utils.service';

const bigInt = require('big-integer');

export class TileNds {
  /**
   * NDS (packed) tile id (tileId with the level encoded)
   */
  readonly tileId: number;
  /**
   * The corresponding level of the tile in the tile schema (range from 0 to 15)
   */
  readonly level: number;
  /**
   * The number of cells per row or per column is 2^(31-level).
   */
  readonly tileSize: number;
  /**
   * Center point of the tile, also called tile anchor point
   */
  readonly centerPoint: PointNds;
  /**
   * The corner point in the south-west of the tile
   */
  readonly southWestPoint: PointNds;

  // Constructor
  /**
   * Constructs an NDS tile for a given tileId and calculates the corresponding level, tile center
   * point, south-west point and the tile size in NDS.
   *
   * @param tileId NDS (packed) tileId
   * @throws InvalidFormatException if the encoded level in the tileId is not in the allowed range
   */
  constructor(tileId: number) {
    TileNds.invalidTileIdNds(tileId);
    const tileLevel = TileUtilsService.extractLevelFromNdsTileId(tileId);
    this.tileId = tileId;
    this.level = tileLevel;
    this.southWestPoint = TileNds.calcSouthWestPoint(tileId, tileLevel);
    this.centerPoint = TileNds.calcTileCenterPoint(tileId, tileLevel);
    this.tileSize = TileNds.calcTileSize(tileLevel);
  }

  /**
   * Method to calculate the corner point of the tile in the south-west direction.
   *
   * @param tileId NDS (packed) tileId
   * @param level in NDS
   * @return South west corner point of the tile in NDS format.
   */
  public static calcSouthWestPoint(tileId: number, level: number): PointNds {
    const andWith = bigInt('7fffffff', 16);
    const shiftWith = 63 - ((level << 1) + 1);
    const southWestMortonCode = bigInt(tileId)
      .and(andWith)
      .shiftLeft(shiftWith);
    return new PointMortonCode(bigInt(southWestMortonCode)).convertToPointNds();
  }

  /**
   * Method to calculate the corner point of the tile in the north-east direction. According to NDS
   * definition, this point is defined to be excluded from the tile, but included in the neighboring
   * tile. Thus, the use of this method should be limited to visualization purposes.
   *
   * @return North-east corner point of the tile in NDS format.
   * @throws InvalidFormatException if neighbor tile could not be calculated
   */
  public static calcNorthEastPoint(tileId: number, level: number): PointNds {
    const easternNeighbor = TileUtilsService.easternNeighbors(tileId, 1).pop();
    const northEasternNeighbor = TileUtilsService.northernNeighbors(
      easternNeighbor,
      1
    ).pop();
    return this.calcSouthWestPoint(northEasternNeighbor, level);
  }

  /**
   * The tile anchor is the center point of the tile. NDS Spec: The tile anchor point is defined as
   * number of rows divided by two and the number of columns divided by two. The number of cells per
   * row or per column is 2^(31-k) also called the tile size (k = level number and N number of
   * columns/rows). P_anchor = (N/2, N/2)
   *
   * @param tileId NDS (packed) tileId
   * @param level in NDS
   * @return center point of the tile in NDS format
   * @throws InvalidCoordinateException if point cannot be created because of invalid coordinates
   */
  public static calcTileCenterPoint(tileId: number, level: number): PointNds {
    const southWest = TileNds.calcSouthWestPoint(tileId, level);
    const tileSizeLevel = TileNds.calcTileSize(level);
    const centerLatitude = southWest.latitude + (tileSizeLevel >> 1);
    const centerLongitude = southWest.longitude + (tileSizeLevel >> 1);
    return new PointNds(centerLongitude, centerLatitude);
  }

  /**
   * The number of cells per row or per column is 2^(31-k) (k = level number. This is used for
   * example to calculate the outermost coordinates of a tile.
   *
   * @param level in NDS
   * @return tileSize for one tile level in NDS
   */
  public static calcTileSize(level: number): number {
    return 1 << (31 - level);
  }

  /**
   * Checks if the NDS tileId has a right level encoded.
   *
   * @param tileId NDS (packed) tileId
   * @throws InvalidFormatException if the encoded level is not valid
   */
  public static invalidTileIdNds(tileId: number): void {
    try {
      TileUtilsService.extractLevelFromNdsTileId(tileId);
    } catch (e) {
      if (e instanceof InvalidFormatLevelException) {
        throw new InvalidFormatException('Tile id is invalid!');
      }
      throw e;
    }
  }

  /**
   * Method calculating the NDS tileNumber which is encoded in the NDS (packed) tileId.
   *
   * @return tileNumber of the tileId
   */
  public getTileNumber(): bigint {
    return BigInt(this.tileId - Math.pow(2, 16 + this.level));
  }

  /**
   * Calculates for a level 13 NDS tile the corresponding HNative tileId in the same level. Note
   * that NDS starts counting the levels from 0 and HNative from 1. That means a NDS level 13
   * corresponds to an HNative level of 14.
   *
   * @return HNative tileId in HNative level 14 (in NDS level 13)
   * @throws InvalidFormatLevelException If the NDS level of the NDS tile is not 13
   * @throws InvalidFormatException if the NDS tileId is not valid
   */
  public getHNativeTileId(): number {
    const requiredNdsLevel = 13;
    if (this.level !== requiredNdsLevel) {
      throw new InvalidFormatLevelException(
        'The level of the NDS tile must be 13!'
      );
    }
    return TileUtilsService.hNativeTileIdFromNdsTileId(this.tileId);
  }

  public getBounds(): Bounds {
    return TileUtilsService.getBoundsOfTileId(this.tileId);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TileUtilsService {
  public static readonly MORTON_BIT_COUNT: number = 63;

  public static readonly MAX_NDS_LEVEL: number = 15;
  public static readonly MIN_NDS_LEVEL: number = 0;
  public static readonly MAX_HNATIVE_LEVEL: number = 16;
  public static readonly MIN_HNATIVE_LEVEL: number = 1;
  public static readonly DEFAULT_NDS_LEVEL_13: number = 13;
  public static readonly MAXIMUM_COORD_WIDTH: number = 31;

  /**
   * Method checks that the NDS level is in the valid range
   *
   * @param level to be checked
   * @throws InvalidFormatLevelException if the level is not in the valid range
   */
  public static invalidNdsLevel(level: number): void {
    if (
      level < TileUtilsService.MIN_NDS_LEVEL ||
      level > TileUtilsService.MAX_NDS_LEVEL
    ) {
      throw new InvalidFormatLevelException(
        'The level in NDS must be in the range of 0 and 15!'
      );
    }
  }

  // Methods for calculating the NDS or HNative tileId from a point
  /**
   * Method to calculate the NDS tileId for a point in Morton Code for a given level.
   *
   * @param mortonCode longitude and latitude coordinates encoded in morton code
   * @param level in the tile schema (NDS: range from 0 to 15)
   * @return NDS tileId
   * @throws InvalidFormatLevelException if the NDS level is out of range
   */
  public static tileIdNdsFromMortonCode(
    mortonCode: bigint,
    level: number
  ): number {
    TileUtilsService.invalidNdsLevel(level);
    // tileNumber = 2*level + 1 most significant bits of a 63 bit number
    const tileNumber = bigInt(mortonCode).shiftRight(
      TileUtilsService.MORTON_BIT_COUNT - (2 * level + 1)
    );
    const levelId = Math.pow(2, 16 + level);
    return tileNumber | levelId;
  }

  /**
   * Method to calculate the HNative range factor which represents the amount of degrees a tile is
   * covering in the given level, e.g. each level 14 HNative tiles cover 0.02197265625 degrees per
   * tile
   *
   * @param level level in the tile schema (HNative: range from 1 to 16)
   * @return tileSize
   * @throws InvalidFormatLevelException If the HNative level is out of range
   */
  public static tileRangeFactorHNative(level: number): number {
    TileUtilsService.invalidHNativeLevel(level);
    return 360 / Math.pow(2, level);
  }

  /**
   * Method checks that the HNative level is in the valid range
   *
   * @param level to be checked
   * @throws InvalidFormatLevelException if the level is not in the valid range
   */
  public static invalidHNativeLevel(level: number) {
    if (
      level < TileUtilsService.MIN_HNATIVE_LEVEL ||
      level > TileUtilsService.MAX_HNATIVE_LEVEL
    ) {
      throw new InvalidFormatLevelException(
        'The level in HNative must be in the range of 1 and 16!'
      );
    }
  }

  /**
   * Method to calculate the HNative tileId for a point in WGS84 format for a given level based on
   * the description of the "HDLM 2020.02 Native Data Spec.pdf".
   *
   * @param pointWgs84 point in WGS84 format
   * @param level in the tile schema (HNative: range from 1 to 16)
   * @return HNative tileId
   * @throws InvalidFormatLevelException if the HNative level is not between 1 and 16
   */
  public static tileIdHNativeFromWgsPoint(
    pointWgs84: PointWgs84,
    level: number
  ): number {
    const hNativeTileSize = TileUtilsService.tileRangeFactorHNative(level);
    const x = Math.floor((180.0 + pointWgs84.longitude) / hNativeTileSize);
    const y = Math.floor((90.0 + pointWgs84.latitude) / hNativeTileSize);
    // calculates the quad key and adds a 1 in front of the 14th bit to get the HNative
    // tileId
    const result =
      '1' +
      bigInt(CoordinateUtilsService.ndsToMortonCode(x, y))
        .toString(4)
        .padStart(14, '0');
    return bigInt(result, 4).valueOf();
  }

  /**
   * This method extracts the level from the packed tileId which consists of the tile number and the
   * level.
   *
   * @param tileId NDS (packed) tileId
   * @return level of the tileId
   * @throws InvalidFormatLevelException if the level is not in range of 0 and 15
   */
  public static extractLevelFromNdsTileId(tileId: number): number {
    const level = Math.floor(Math.log(tileId) / Math.log(2) - 16);
    TileUtilsService.invalidNdsLevel(level);
    return level;
  }

  /**
   * Method to calculate the HNative tileId of hNativeLevel 14 from an NDS tileId of ndsLevel 13.
   * Note: HNative counts the level from 1-16 and NDS from 0-15. That means a HNative level 14 is
   * equal to the NDS level 13.
   *
   * @param ndsTileId NDS (packed) tileId of ndsLevel 13
   * @return HNative tileId of hNativeLevel 14
   * @throws InvalidFormatLevelException If the level of the NDS tileId is not 13
   * @throws InvalidFormatException if the tileId is not valid
   */
  public static hNativeTileIdFromNdsTileId(ndsTileId: number): number {
    const ndsLevel = 13;
    const hNativeLevel = 14;
    if (Math.floor(Math.log(ndsTileId) / Math.log(2) - 16) !== ndsLevel) {
      throw new InvalidFormatLevelException(
        'The NDS level of the NDS tile must be 13!'
      );
    }
    const tileNds = new TileNds(ndsTileId);
    const pointWgs84 = tileNds.centerPoint.convert2Wgs84();
    return TileUtilsService.tileIdHNativeFromWgsPoint(pointWgs84, hNativeLevel);
  }

  /**
   * Method to calculate the NDS tileId in ndsLevel 13 from a HNative tile of hNativeLevel 14 Note:
   * HNative counts the level from 1-16 and NDS from 0-15. That means a HNative level 14 is equal to
   * the NDS level 13.
   *
   * @param hnativeTileId a HNative tile id
   * @return NDS (packed tileId)
   */
  public static ndsTileIdFromHNativeTileId(hnativeTileId: number): number {
    const ndsLevel = 13;
    const hNativeLevel = 14;
    const mortonCode = bigInt(hnativeTileId.toString(4).substring(1), 4);
    const ndsPoint = CoordinateUtilsService.mortonCodeToNds(mortonCode);
    const hNativeTileSize =
      TileUtilsService.tileRangeFactorHNative(hNativeLevel);
    const lon = ndsPoint.longitude * hNativeTileSize - 180.0;
    const lat = ndsPoint.latitude * hNativeTileSize - 90.0;
    return TileUtilsService.tileIdNdsFromWgsPoint(
      new PointWgs84(lon, lat),
      ndsLevel
    );
  }

  /**
   * Method to calculate the NDS tileId for a point in WGS84 format for a given level.
   *
   * @param pointWgs84 point in WGS84 format
   * @param level in the tile schema (NDS: range from 0 to 15)
   * @return NDS tileId
   * @throws InvalidFormatLevelException If the pointWgs84 is null or the ndsLevel is out of range
   */
  public static tileIdNdsFromWgsPoint(
    pointWgs84: PointWgs84,
    level: number
  ): number {
    TileUtilsService.invalidNdsLevel(level);
    const mortonCode = CoordinateUtilsService.ndsToMortonCode(
      pointWgs84.convert2PointNds().longitude,
      pointWgs84.convert2PointNds().latitude
    );
    return TileUtilsService.tileIdNdsFromMortonCode(mortonCode, level);
  }

  /**
   * Method calculates the northern neighbors of the NDS tileId in the same tile level.
   *
   * @param tileId NDS (packed) tileId
   * @param numNeighbors number of neighbors
   * @return Set of tileIds of the northern neighbors
   * @throws InvalidFormatException if one northern neighbor could not be calculated
   * @throws InvalidFormatLevelException if the encoded level of the tileId is not in the valid
   *         range
   */
  public static northernNeighbors(
    tileId: number,
    numNeighbors: number
  ): number[] {
    const opLat = (a, b, c) => (a + b) & ((1 << c) - 1);
    const opLon = (a, _b, _c) => a;
    return TileUtilsService.getNeighbors(tileId, numNeighbors, opLat, opLon);
  }

  /**
   * Method calculates the southern neighbors of the NDS tileId in the same tile level.
   *
   * @param tileId NDS (packed) tileId
   * @param numNeighbors number of neighbors
   * @return Set of tileIds southern neighbors
   * @throws InvalidCoordinateException if one southern neighbor could not be calculated
   * @throws InvalidFormatLevelException if the encoded level of the tileId is not in the valid
   *         range
   */
  public static southernNeighbors(
    tileId: number,
    numNeighbors: number
  ): number[] {
    const opLat = (a, b, c) => (a - b) & ((1 << c) - 1);
    const opLon = (a, _b, _c) => a;
    return TileUtilsService.getNeighbors(tileId, numNeighbors, opLat, opLon);
  }

  /**
   * Method calculates the eastern neighbors of the NDS tileId in the same tile level.
   *
   * @param tileId NDS (packed) tileId
   * @param numNeighbors number of neighbors
   * @return Set of tileIds eastern neighbors
   * @throws InvalidFormatException if one eastern neighbor could not be calculated
   * @throws InvalidFormatLevelException if the encoded level of the tileId is not in the valid
   *         range
   */
  public static easternNeighbors(
    tileId: number,
    numNeighbors: number
  ): number[] {
    const opLat = (a, _b, _c) => a;
    const opLon = (a, b, c) => (a + b) & ((1 << (c + 1)) - 1);
    return TileUtilsService.getNeighbors(tileId, numNeighbors, opLat, opLon);
  }

  /**
   * Method calculates the western neighbors of the NDS tileId in the same tile level.
   *
   * @param tileId NDS (packed) tileId
   * @param numNeighbors number of neighbors
   * @return Set of tileIds western neighbors
   * @throws InvalidFormatException if one eastern neighbor could not be calculated
   * @throws InvalidFormatLevelException if the encoded level of the tileId is not in the valid
   *         range
   */
  public static westernNeighbors(
    tileId: number,
    numNeighbors: number
  ): number[] {
    const opLat = (a, _b, _c) => a;
    const opLon = (a, b, c) => (a - b) & ((1 << (c + 1)) - 1);
    return TileUtilsService.getNeighbors(tileId, numNeighbors, opLat, opLon);
  }

  /**
   * Method calculating the NDS (packed) tileId from the tileNumber and the level of the tile
   *
   * @param tileNumber of the tile
   * @param level corresponding NDS level
   * @return (packed) tileId of NDS
   */
  public static calcNdsPackedTileId(tileNumber: bigint, level: number): number {
    return bigInt(tileNumber).add(Math.pow(2, 16 + level));
  }

  /**
   * Method split the tileNumber in two component (decoding from morton code)
   *
   * @return decoded tileNumber
   */
  public static tileNumberSplit(tileId: number): PointNds {
    return CoordinateUtilsService.mortonCodeToNds(
      new TileNds(tileId).getTileNumber()
    );
  }

  /**
   * Converts WGS84 coordinates to an NDS TileId
   *
   * @param lng longitude
   * @param lat latitude
   * @param level tile level
   * @return NDS tileId
   * @throws InvalidFormatLevelException If the pointWgs84 is null or the ndsLevel is out of range
   */
  public static wgs84ToNdsTile(lng: number, lat: number, level: number = 13) {
    const tileId = TileUtilsService.wgs84ToNdsTileId(lng, lat, level);
    return new TileNds(tileId);
  }

  /**
   * Converts WGS84 coordinates to an NDS TileId
   *
   * @param lng longitude
   * @param lat latitude
   * @param level tile level
   * @return NDS tileId
   * @throws InvalidFormatLevelException If the pointWgs84 is null or the ndsLevel is out of range
   */
  public static wgs84ToNdsTileId(lng: number, lat: number, level: number = 13) {
    const pointWgs84 = new PointWgs84(lng, lat);
    return TileUtilsService.tileIdNdsFromWgsPoint(pointWgs84, level);
  }

  /**
   * Updates the bounds to match the dimensions of the tile grid
   *
   * @param bounds
   * @param level tile level
   * @return Bounds bounds
   * @throws InvalidFormatLevelException If the pointWgs84 is null or the ndsLevel is out of range
   */
  public static fitBoundsToTileGrid(
    bounds: Bounds,
    level: number = 13
  ): Bounds {
    const {
      ne: [neLat, neLng],
      sw: [swLat, swLng]
    } = bounds;
    const neTile = TileUtilsService.wgs84ToNdsTile(neLng, neLat, level);
    const swTile = TileUtilsService.wgs84ToNdsTile(swLng, swLat, level);
    const southWestPoint = TileNds.calcSouthWestPoint(
      swTile.tileId,
      swTile.level
    ).convert2Wgs84();
    const northEastPoint = TileNds.calcNorthEastPoint(
      neTile.tileId,
      neTile.level
    ).convert2Wgs84();
    return {
      ne: [northEastPoint.latitude, northEastPoint.longitude],
      sw: [southWestPoint.latitude, southWestPoint.longitude]
    };
  }

  /**
   * Method calculates all the tileIds in the rectangle spanned by the given two tileIds.
   *
   * @param tileNds NDS tile
   * @param otherTileNds another NDS tile
   * @return Set of tileIds spanning the rectangle of the two given tileIds. Including the tiles
   *     itself.
   * @throws InvalidFormatException if one of the neighborhood calculations failed
   */
  public static calcTileIdRectangle(
    tileNds: TileNds,
    otherTileNds: TileNds,
    level = TileUtilsService.DEFAULT_NDS_LEVEL_13
  ): number[] {
    if (tileNds == null || otherTileNds == null) {
      console.warn(
        `At least one tile passed in to calculated tile id rectangle was null.`
      );
      return [];
    }
    if (tileNds.level !== level || otherTileNds.level !== level) {
      throw new InvalidFormatException(
        `Nds Tile ${tileNds.tileId} or ${otherTileNds.tileId} is not level 13.`
      );
    }

    const shiftValue: number = TileUtilsService.MAXIMUM_COORD_WIDTH - level;
    const allTileIds: Set<number> = new Set<number>();

    const firstSouthWest: PointNds = tileNds.southWestPoint;
    const secondSouthWest: PointNds = otherTileNds.southWestPoint;

    const numberLongitude: number =
      (firstSouthWest.longitude >> shiftValue) -
      (secondSouthWest.longitude >> shiftValue);
    const numberLatitude: number =
      (firstSouthWest.latitude >> shiftValue) -
      (secondSouthWest.latitude >> shiftValue);

    allTileIds.add(tileNds.tileId);

    const longitudeNeighbors: Set<number> = new Set<number>();

    longitudeNeighbors.add(tileNds.tileId);
    if (numberLongitude > 0) {
      TileUtilsService.westernNeighbors(
        tileNds.tileId,
        Math.abs(numberLongitude)
      ).forEach((value) => longitudeNeighbors.add(value));
    } else {
      TileUtilsService.easternNeighbors(
        tileNds.tileId,
        Math.abs(numberLongitude)
      ).forEach((value) => longitudeNeighbors.add(value));
    }
    if (numberLatitude > 0) {
      for (const longitudeNeighbor of longitudeNeighbors) {
        TileUtilsService.southernNeighbors(
          longitudeNeighbor,
          Math.abs(numberLatitude)
        ).forEach((value) => allTileIds.add(value));
      }
    } else {
      for (const longitudeNeighbor of longitudeNeighbors) {
        TileUtilsService.northernNeighbors(
          longitudeNeighbor,
          Math.abs(numberLatitude)
        ).forEach((value) => allTileIds.add(value));
      }
    }
    longitudeNeighbors.forEach((value) => allTileIds.add(value));
    return Array.from(allTileIds);
  }

  public static getBoundsOfTile(tile: TileNds): Bounds {
    return TileUtilsService.getBoundsOfTileId(tile.tileId);
  }

  public static getBoundsOfTileId(tileId: number): Bounds {
    const level = TileUtilsService.extractLevelFromNdsTileId(tileId);
    const sw = TileNds.calcSouthWestPoint(tileId, level).convert2Wgs84();
    const ne = TileNds.calcNorthEastPoint(tileId, level).convert2Wgs84();
    return {
      sw: [sw.latitude, sw.longitude],
      ne: [ne.latitude, ne.longitude]
    };
  }

  /**
   * Method calculates the neighbors in the direction specified with the IntTernaryOperators.
   *
   * @param tileId NDS (packed) tileId
   * @param numNeighbors number of neighbors
   * @param opLat operator for latitude value
   * @param opLon operator for longitude value
   * @return Set of neighbors
   * @throws InvalidFormatException if a neighbor could not be calculated
   */
  private static getNeighbors(
    tileId: number,
    numNeighbors: number,
    opLat,
    opLon
  ): number[] {
    const neighbors = new Set<number>();
    const tilePoint = TileUtilsService.tileNumberSplit(tileId);
    const level = TileUtilsService.extractLevelFromNdsTileId(tileId);

    for (let i = 1; i <= numNeighbors; i++) {
      try {
        const neighLatitude = opLat(tilePoint.latitude, i, level);
        const neighLongitude = opLon(tilePoint.longitude, i, level);
        const neighbor = new PointNds(neighLongitude, neighLatitude);
        const mortonCode = CoordinateUtilsService.ndsToMortonCode(
          neighbor.longitude,
          neighbor.latitude
        );
        neighbors.add(
          TileUtilsService.calcNdsPackedTileId(mortonCode, level).valueOf()
        );
      } catch (e) {
        if (e instanceof InvalidCoordinateException) {
          throw new InvalidFormatException(
            `A neighbor of ${tileId} could not be calculated!`
          );
        }
        throw e;
      }
    }
    return Array.from(neighbors);
  }
}
