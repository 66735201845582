export class InvalidFormatException extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class InvalidFormatLevelException extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class InvalidCoordinateException extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class InvalidResultTypeException extends Error {
  constructor(message?: string) {
    super(message);
  }
}
