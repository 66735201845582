import { Environment } from '@app/shared/model/environment';
import { environment as environmentDev } from './environment.aws-development';

export const environment: Environment = {
  ...environmentDev,
  production: false,
  envName: 'AWSDEV',
  keycloakServiceUrl: 'https://kingfisher-awsdev.vwapps.run/keycloak',
  keycloakLogoutRedirectUri: 'https://kingfisher-awsdev.vwapps.run/',
  keycloakGrafanaLogoutRedirectUri:
    'https://kingfisher-awsdev.vwapps.run/grafana/logout',
  ndsMapViewerUrl: 'https://mapviewer.kingfisher-awsdev.vwapps.run/'
};
