import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ResponsePage } from '@app/shared/model/response';
import { AreasService } from '@modules/areas/service/areas.service';
import { TestResultsListComponent } from '@modules/test-results/component/common/test-results-list.component';
import {
  ResultDataApiEntity,
  ScenarioRunFilter
} from '@shared/model/datastore';
import { DatastoreService } from '@shared/service/datastore.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-scenario-run',
  templateUrl: './scenario-run.component.html',
  styleUrls: ['./scenario-run.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ScenarioRunComponent
  extends TestResultsListComponent
  implements OnInit, OnDestroy
{
  displayedColumns: string[] = [
    'expandDetails',
    'timestamp',
    'testCaseId',
    'testCaseVersion',
    'catalogName',
    'catalogVersion',
    'tileId',
    'resultOutcome',
    'actions'
  ];

  filter: ScenarioRunFilter = {
    runId: null,
    testCaseIds: [],
    tileIds: [],
    areaIds: [],
    resultOutcomes: []
  };

  constructor(
    datastoreService: DatastoreService,
    snackBar: MatSnackBar,
    cdr: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router,
    areasService: AreasService
  ) {
    super(datastoreService, snackBar, cdr, route, router, areasService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  submitResetFilter(): void {
    this.filter.runId = null;
    super.submitResetFilter();
  }

  callTestCaseResults(): Observable<ResponsePage<ResultDataApiEntity>> {
    const { runId } = this.filter;
    if (!runId) {
      this.isLoadingResults = false;
      return of();
    }

    const resultFilter = this.getFilter();
    return this.datastoreService.getScenarioRunResults(
      this.filter.runId,
      resultFilter,
      this.pageIndex + 1,
      this.pageSize
    );
  }

  getQueryParams(): Params {
    const queryParams: Params = super.getQueryParams();
    const { runId } = this.filter;
    if (runId) {
      queryParams.runId = runId;
    }
    return queryParams;
  }

  getFilterFromQueryString(): ScenarioRunFilter {
    const { runId } = this.route.snapshot.queryParams;
    return {
      ...super.getFilterFromQueryString(),
      runId: runId || null
    };
  }
}
