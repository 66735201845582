import { Component } from '@angular/core';
import { SettingsService } from '@shared/service/settings.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent {
  public url: SafeResourceUrl;

  constructor(
    public settingService: SettingsService,
    private sanitizer: DomSanitizer
  ) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(
      settingService.getAdminUrl('wallboard')
    );
  }
}
