<div id="map"></div>
<app-map-viewer-control *ngIf="map"></app-map-viewer-control>

<app-map-viewer-info *ngIf="map"></app-map-viewer-info>
<app-mat-spinner-overlay
  *ngIf="isUpdatingData"
  [value]="resultOutcomeProgress"
  [labelWith]="resultOutcomeProgressLabel"
  [subLabelWith]="resultOutcomeProgressDetailsLabel"
  overlay="true"
  mode="determinate"
  color="primary"
  diameter="200"
  strokeWidth="10">
</app-mat-spinner-overlay>
