import { Component } from '@angular/core';
import { ResultComponent } from '@modules/map-viewer/component/map-viewer-results-overview/views/common/result.component';
import {
  AttributionStatus,
  ControlledAccess,
  FunctionalRoadClass
} from '@shared/model/datastore';

export interface AttributionCounts {
  attributionStatus: AttributionStatus;
  controlledAccess: ControlledAccess;
  frcCounts: FrcCounts[];
}

export interface FrcCounts {
  functionalRoadClass: FunctionalRoadClass;
  value: number;
}

@Component({ template: '' })
export abstract class StatisticDetailedResultComponent extends ResultComponent {
  get total(): number {
    const attributeCountsList: AttributionCounts[] =
      (this.resultDetails || {}).attributeCounts || [];
    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.value.valueOf()) || 0;
  }

  get comparisonTotal(): any {
    const attributeCountsList: AttributionCounts[] =
      (this.comparisonResultDetails || {}).attributeCounts || [];

    const {
      functionalRoadClass = FunctionalRoadClass.ALL,
      attributionStatus = AttributionStatus.ALL,
      controlledAccess = ControlledAccess.ALL
    } = this;
    const frcCounts = this.filterFrcCounts(
      attributionStatus,
      controlledAccess,
      functionalRoadClass,
      attributeCountsList
    );
    return (frcCounts && frcCounts.value.valueOf()) || 0;
  }

  get functionalRoadClass(): FunctionalRoadClass {
    return this.$mapViewerUserSelectionService.functionalRoadClass;
  }

  get attributionStatus(): AttributionStatus {
    return this.$mapViewerUserSelectionService.attributionStatus;
  }

  get controlledAccess(): ControlledAccess {
    return this.$mapViewerUserSelectionService.controlledAccess;
  }
  private filterFrcCounts(
    attributionStatus: AttributionStatus,
    controlledAccess: ControlledAccess,
    functionalRoadClass: FunctionalRoadClass,
    attributeCountsList: AttributionCounts[]
  ): FrcCounts {
    const attributeCounts: AttributionCounts = this.filterAttributeCounts(
      attributionStatus,
      controlledAccess,
      attributeCountsList
    );
    if (!attributeCounts) {
      return null;
    }
    return attributeCounts.frcCounts.find(
      (elem) => elem.functionalRoadClass === functionalRoadClass.valueOf()
    );
  }
  private filterAttributeCounts(
    attributionStatus: AttributionStatus,
    controlledAccess: ControlledAccess,
    attributeCountsList: AttributionCounts[]
  ): AttributionCounts {
    return attributeCountsList.find(
      (elem) =>
        elem.attributionStatus === attributionStatus.valueOf() &&
        elem.controlledAccess === controlledAccess.valueOf()
    );
  }
}
