<div class="map-viewer-control-wrapper" id="mapViewerControlsWrapper">
  <div class="map-viewer-control-panel">
    <mat-accordion [multi]="false" displayMode="flat">
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Search
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-map-viewer-search></app-map-viewer-search>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Areas
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon [color]="isAreaSelectionCompleted() ? 'primary' : 'accent'">check_circle</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-map-viewer-select-area></app-map-viewer-select-area>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Catalog/Version/Test Case
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon [color]="isVisualisationSelectionCompleted() ? 'primary' : 'accent'">check_circle</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-map-viewer-select-visualization></app-map-viewer-select-visualization>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="map-viewer-control-panel">
    <app-map-viewer-result-filter></app-map-viewer-result-filter>
  </div>

</div>
