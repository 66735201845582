<svg *ngIf="isValid" [attr.width]="width" height="30" [style.background-color]="backgroundColor">
  <svg class="markers-wrapper" [attr.x]="padding" [attr.width]="barWidth">
    <g>
      <rect *ngFor="let legendSegment of legendSegments"
            [attr.x]="legendSegment.x"
            [attr.width]="legendSegment.width"
            [attr.height]="legendSegment.height"
            [attr.opacity]="legendSegment.opacity"
            [style.fill]="legendSegment.color"
      ></rect>
      <rect *ngFor="let marker of legendColorScaleMarkers"
            [attr.x]="marker.x + marker.xOffset"
            [attr.width]="marker.width"
            [attr.height]="barHeight"
            [attr.opacity]="1"
            [style.fill]="marker.color"
      ></rect>
    </g>
  </svg>
  <svg class="markers-wrapper" [attr.x]="padding" [attr.width]="barWidth">
    <line *ngFor="let marker of legendColorScaleMarkers" [attr.x1]="marker.position + '%'" [attr.y1]="barHeight"
          [attr.x2]="marker.position + '%'"
          [attr.y2]="barHeight + 5" [attr.stroke]="markerColor" style="stroke-width: .5; opacity: .5" />
  </svg>
  <svg class="labels-wrapper" [attr.x]="padding" [attr.width]="barWidth">
    <text *ngFor="let marker of legendColorScaleMarkers" class="label" [attr.x]="marker.position + '%'"
          [attr.y]="barHeight + 10"
          text-anchor="middle" stroke="none" [attr.fill]="textColor"
          transform="matrix(1,0,0,1,0,6.6667)">{{marker.label}}</text>
  </svg>
</svg>
