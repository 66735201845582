import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CatalogInfoVisualization, ResultType } from '@shared/model/datastore';
import { DatastoreService } from '@shared/service/datastore.service';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { FormControl } from '@angular/forms';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import { LayerService } from '@shared/service/layer.service';

@Component({
  selector: 'app-map-viewer-select-layer',
  templateUrl: './map-viewer-select-layer.component.html',
  styleUrls: ['./map-viewer-select-layer.component.scss']
})
export class MapViewerSelectLayerComponent implements OnInit {
  layerFormControl = new FormControl();
  private $subscription: Subscription = new Subscription();

  private $layers: Map<string, CatalogInfoVisualization[]> = new Map<
    string,
    CatalogInfoVisualization[]
  >();

  constructor(
    private datastoreService: DatastoreService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService,
    private mapEventService: MapEventService,
    private layerCacheService: LayerService
  ) {
    this.layerFormControl.disable();
  }

  get catalogName(): string {
    return this.mapViewerUserSelectionService.mainCatalogName;
  }

  get catalogVersion(): number {
    return this.mapViewerUserSelectionService.mainCatalogVersion;
  }

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService.resultType;
  }

  get layerName(): string {
    return this.mapViewerUserSelectionService.layerName;
  }

  get layers(): CatalogInfoVisualization[] {
    return [].concat(...this.$layers.values());
  }

  get isLayerDisabled() {
    return !this.catalogName || !this.catalogVersion;
  }

  get showLayerDropDownInDataTab(): boolean {
    return [MapViewerVisualizationMode.DATA].includes(
      this.mapViewerUserSelectionService.visualizationMode
    );
  }

  get disableTestCaseDropDown(): boolean {
    return (
      this.showLayerDropDownInDataTab &&
      this.mapViewerUserSelectionService.testCase === undefined
    );
  }

  ngOnInit(): void {
    this.registerEventListener();
    this.updateFormControl();
  }

  asIsOrder(_a, _b) {
    return 1;
  }

  selectLayer(layerName: string) {
    this.mapViewerUserSelectionService.layerName = layerName;
    this.layerFormControl.setValue(layerName);
    this.emitSelectLayer();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.UPDATED_USER_SELECTION, () => {
        this.updateFormControl();
        if (this.isLayerDisabled) {
          this.resetLayerFormControl();
        }
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.RESET_VISUALIZATION_MODE, () => {
        this.resetLayerFormControl();
      })
    );
  }

  getLayersByMapDataType(): CatalogInfoVisualization[] {
    return [].concat(
      ...(this.$layers.get(
        this.mapViewerUserSelectionService.getMapDataType(this.catalogName)
      ) || [])
    );
  }

  private createGroupedLayers(catalogs: CatalogInfoVisualization[]): void {
    this.$layers.clear();
    for (const catalog of catalogs || []) {
      const { mapDataType } = catalog;
      if (!this.$layers.has(mapDataType)) {
        this.$layers.set(mapDataType, []);
      }
      this.$layers.get(mapDataType).push(catalog);
    }
  }

  private resetLayerFormControl() {
    this.mapViewerUserSelectionService.layerName = null;
    this.layerFormControl.setValue(null);
    this.layerFormControl.disable();
  }

  private emitSelectLayer() {
    this.mapEventService.emit(new EmitEvent(MapEvent.SELECT_LAYER, {}));
  }

  private updateFormControl(): void {
    this.layerFormControl.setValue(this.layerName);
    if (this.isLayerDisabled) {
      this.layerFormControl.disable();
    } else {
      this.layerFormControl.enable();
      this.layerCacheService
        .getLayers(this.mapViewerUserSelectionService.mapDataType)
        .subscribe((response) => {
          this.createGroupedLayers(response);
        });
    }
  }
}
