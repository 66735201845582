<mat-form-field appearance="fill">
  <mat-label>Select Scenarios</mat-label>
  <mat-chip-list #scenarioList aria-label="Scenario selection">
    <mat-chip
      *ngFor="let scenario of scenariosSelected"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(scenario)">
      {{scenario.name}}
      <mat-icon matChipRemove>remove</mat-icon>
    </mat-chip>
    <input
      placeholder="Add Scenario..."
      #scenarioInput
      [formControl]="scenarioCtrl"
      [matAutocomplete]="scenarioAutocomplete"
      [matChipInputFor]="scenarioList"
      [matChipInputAddOnBlur]="true"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      id="scenarioSelectionInput"
    >
  </mat-chip-list>
  <mat-autocomplete #scenarioAutocomplete="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let scenario of filteredScenarios$ | async" [value]="scenario">
      {{scenario.id}} - {{scenario.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
