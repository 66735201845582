<div class="container-fluid">
  <mat-card appearance="outlined" class="scenario-container">
    <app-page-title></app-page-title>

    <mat-card-content>

      <div
        *ngIf="isLoadingResults || isRateLimitReached"
        class="scenario-loading-shade"
      >
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        <div *ngIf="isRateLimitReached" class="scenario-rate-limit-reached">
          Kingfisher's API did not respond. Check Backend.
        </div>
      </div>

      <div class="kf-button-group">
        <button *ngIf="authService.hasPrivileges(['SCENARIOS_CREATE'])" mat-raised-button color="primary"
                (click)="openCreateDialog()" aria-label="Create Scenario" matTooltip="Create Scenario"
                id="createScenarioId">
          <mat-icon aria-hidden="false">add</mat-icon>
          <span>Create Scenario</span>
        </button>
      </div>

      <mat-form-field appearance="outline" floatLabel="auto">
        <mat-label>Filter</mat-label>
        <input (keyup)="applyFilter($event)" matInput placeholder="Search for Details..." id="filterListing" [value]="this.filterValue">
          <button matSuffix mat-icon-button aria-label="Clear" (click)="applyFilter(null)">
            <mat-icon>close</mat-icon>
          </button>
      </mat-form-field>

      <div class="scenario-list-container">
        <table
          class="kf-table"
          [dataSource]="data"
          mat-table
          matSort
          matSortActive="id"
          matSortDirection="desc"
          multiTemplateDataRows
          aria-describedby="tableDescription"
        >

          <colgroup>
            <col span="1" style="width:4%" />
            <col span="1" style="width:4%" />
            <col span="1" style="width:34%" />
            <col span="1" style="width:34%" />
            <col span="1" style="width:8%" />
            <col span="1" style="width:14%" />
          </colgroup>

          <ng-container matColumnDef="id">
            <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">ID</th>
            <td *matCellDef="let element" mat-cell>
              {{element.id}}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Name</th>
            <td *matCellDef="let element" mat-cell>
              {{element.name}}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Description</th>
            <td *matCellDef="let element" mat-cell>
              {{element.description}}
            </td>
          </ng-container>

          <ng-container matColumnDef="numTestCases">
            <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col"># Test Cases</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.testCases?.length || '0' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef scope="col">Actions</th>
            <td mat-cell *matCellDef="let element">
              <div class="kf-button-group">
                <button *ngIf="authService.hasPrivileges(['SCENARIOS_UPDATE'])" mat-mini-fab color="primary"
                        (click)="openEditDialog(element)" aria-label="Edit Scenario" matTooltip="Edit Scenario"
                        id="scenarioUpdateId">
                  <mat-icon aria-hidden="false">edit</mat-icon>
                </button>
                <button *ngIf="authService.hasPrivileges(['SCENARIOS_START'])" mat-mini-fab color="play"
                        (click)="startScenario(element)" aria-label="Start Scenario" matTooltip="Start Scenario"
                        id="startScenario">
                  <mat-icon aria-hidden="false">play_arrow</mat-icon>
                </button>
                <button *ngIf="authService.hasPrivileges(['SCENARIOS_DELETE'])" mat-mini-fab color="warn"
                        (click)="confirmDelete(element)" aria-label="Delete Scenario" matTooltip="Delete Scenario"
                        id="scenarioDeleteId">
                  <mat-icon aria-hidden="false">delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandDetails">
            <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col"></th>
            <td *matCellDef="let element" mat-cell>
              <button
                mat-icon-button
                (click)="element.isExpanded = !element.isExpanded"
                aria-label="Show Details" matTooltip="Show Details"
              >
                <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
              <div
                [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
                class="details-table-wrapper"
              >
                <app-scenario-details [scenarioApiEntity]="element"></app-scenario-details>
              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            *matRowDef="let element; columns: displayedColumns;"
            [class.details-table-expanded]="element.isExpanded"
            class="element-row element-row-expandable"
            mat-row
          ></tr>
          <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
          <caption id="tableDescription">List of available scenarios</caption>
        </table>
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
