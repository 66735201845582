import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AreasRoutingModule } from './areas.routing';
import { AreasComponent } from '../areas/page/areas/areas.component';
import { AreaDialogueEntryComponent } from '../areas/component/area-dialogue-entry/area-dialogue-entry.component';

@NgModule({
  declarations: [AreasComponent, AreaDialogueEntryComponent],
  imports: [SharedModule, AreasRoutingModule]
})
export class AreasModule {}
