<ng-container *ngIf="scenario && scenario.name; else elseNoNameHeader">
  <h3 mat-dialog-title>Start Scenario "<strong>{{scenario.name}}</strong>"</h3>
</ng-container>
<ng-template #elseNoNameHeader>
  <h3 mat-dialog-title>Start Scenario</h3>
</ng-template>

<app-loading-spinner *ngIf="isLoadingScenarioDetails || isLoadingScenario"></app-loading-spinner>

<div class="mat-typography" mat-dialog-content>
  <app-run-parameters-form *ngIf="!isLoadingScenario && !isLoadingScenarioDetails"
                           [areaIds]="scenarioProgressDetails?.areaIds"
                           [enabledRelativeCatalogVersions]="false"
                           [hidden]="!isOpen() || isLoadingScenarioDetails || isLoadingScenario"
                           [mapCatalogInfos]="scenarioProgressDetails?.catalogInfos"
                           [routeIds]="scenarioProgressDetails?.routeIds"
                           [tileIds]="scenarioProgressDetails?.tileIds"
  ></app-run-parameters-form>

  <div *ngIf="!isOpen() && isRunning">
    <table aria-label="Result of scenario start requests" class="kf-table">
      <thead>
      <tr>
        <th>#</th>
        <th>Request</th>
        <th>Status</th>
        <th>Result</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let progressId of processIds; index as i">
        <td>{{i + 1}}</td>
        <td>
          <ng-container *ngIf="!!getRequest(progressId)?.tileIds?.length">Starting scenario based on {{getRequest(progressId).tileIds.length}}tile(s)
          </ng-container>
          <ng-container *ngIf="!!getRequest(progressId)?.areaIds?.length">Starting scenario based on area {{getRequest(progressId).areaIds[0]}}</ng-container>
          <ng-container *ngIf="!!getRequest(progressId)?.routeIds?.length">Starting scenario based on {{getRequest(progressId).routeIds.length}}route(s)
          </ng-container>
        </td>
        <td>
          <mat-icon *ngIf="isStarting(progressId)">
            <mat-progress-spinner diameter="20" mode="indeterminate" strokeWidth="11%"></mat-progress-spinner>
          </mat-icon>
          <mat-icon *ngIf="isSuccess(progressId)">check_circle</mat-icon>
          <mat-icon *ngIf="isError(progressId)">error_outline</mat-icon>
        </td>
        <td>
          <code *ngIf="getResponse(progressId)" class="mat-small">{{getResponse(progressId).statusText}} ({{getResponse(progressId).status}})</code>
          <code *ngIf="getError(progressId)" class="mat-small">
            {{getError(progressId).message}} ({{getError(progressId).error}} - {{getError(progressId).status}})
          </code>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isOpen()" mat-dialog-actions>
  <div *ngIf="!isLoadingScenarioDetails" [formGroup]="form">
    <mat-slide-toggle
      color="primary"
      formControlName="priority"
    >
      Priority
    </mat-slide-toggle>
    <button (click)="close()" mat-stroked-button>Cancel</button>
    <button
      (click)="submitStartScenario()"
      [disabled]="disableButton()"
      color="primary"
      id="startScenarioSubmit"
      mat-raised-button
    >
      Start Scenario
    </button>
  </div>
</div>

<div *ngIf="!isRunning() || isFinished()" mat-dialog-actions>
  <button (click)="close()" mat-stroked-button>Close</button>
</div>
