import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { environment } from '@env';
import {
  MapViewerSearchFor,
  MapViewerVisualizationMode
} from '@modules/map-viewer/model/map-viewer';
import {
  EmitEvent,
  MapEvent,
  MapEventService,
  SubmitSearchPayload,
  TilePayload
} from '@modules/map-viewer/service/map-event.service';
import { MapHdmlViewerUrlService } from '@modules/map-viewer/service/map-hdml-viewer-url.service';
import { MapViewerMarkerPopupService } from '@modules/map-viewer/service/map-viewer-marker-popup.service';
import { MapViewerResultOutcomeService } from '@modules/map-viewer/service/map-viewer-result-outcome.service';
import { MapViewerTile } from '@modules/map-viewer/service/map-viewer-tile';
import { MapViewerValueScale } from '@modules/map-viewer/service/map-viewer-value-scale';
import {
  Bounds,
  DataVersionTileEntity,
  DeltaTileEntity,
  FailVisualization,
  LatLng,
  ResultDeltaOutcome,
  ResultOutcome,
  ResultType,
  TileEntity,
  TilesApiEntity
} from '@shared/model/datastore';
import { MapViewerBoundsCache } from '@shared/model/map-viewer-bounds-cache';
import { AreasApiEntity, CatalogApiEntity } from '@shared/model/productserver';
import { HttpApiErrorResponse, ResponseMap } from '@shared/model/response';
import { AreaCacheService } from '@shared/service/area-cache.service';
import { PointWgs84 } from '@shared/service/coordinate-utils.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { NotificationService } from '@shared/service/notification.service';
import { TileNds, TileUtilsService } from '@shared/service/tile-utils.service';
import { GeoJsonObject } from 'geojson';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';

@Component({
  selector: 'app-map-viewer',
  templateUrl: './map-viewer.component.html',
  styleUrls: ['./map-viewer.component.scss']
})
export class MapViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  map: L.Map;

  notifications: Record<string, string> = {};

  shownGeoJson: L.GeoJSON;

  mapViewerTileGrid = new Map<string, MapViewerTile>();
  mapViewerArea: AreasApiEntity;

  private featureGroup = L.featureGroup();
  private partitionGrid = L.featureGroup();
  private $subscription: Subscription = new Subscription();

  private pane: HTMLElement;

  private readonly dataLayerPane = 'data-layer';

  private mapDataStyle: L.CircleMarkerOptions = {
    pane: this.dataLayerPane,
    radius: 8,
    fillColor: '#673ab7',
    color: '#ffffff',
    weight: 2,
    opacity: 1,
    fillOpacity: 0.8
  };

  private $tileGridBoundsCache: MapViewerBoundsCache =
    new MapViewerBoundsCache();

  private $timeoutMapChange = null;
  private $timeoutMapChangeMs = 1000;
  private $timeoutGetResults = null;
  private $timeoutGetResultsMs = 1000;

  private IsAreaAvailable = false;

  private $mapMarkerIconOptions: L.IconOptions = {
    iconUrl: '/assets/markers/kf-marker-icon-4x_14.png',
    iconRetinaUrl: '/assets/markers/kf-marker-icon-4x_14.png',
    iconSize: [50, 67],
    iconAnchor: [25, 67],
    popupAnchor: [0, -67],
    shadowUrl: '/assets/markers/marker-shadow.png',
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
    className: 'kf-map-marker'
  };
  private $mapMarkerIcon: L.Icon;
  private $mapMarker: L.Marker;

  constructor(
    private datastoreService: DatastoreService,
    private areaCacheService: AreaCacheService,
    private mapEventService: MapEventService,
    private cdr: ChangeDetectorRef,
    private resultOutcomeService: MapViewerResultOutcomeService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService,
    private mapViewerMarkerPopupService: MapViewerMarkerPopupService,
    private mapHdmlViewerUrlService: MapHdmlViewerUrlService,
    private notificationService: NotificationService
  ) {
    this.$mapMarkerIcon = new L.Icon(this.$mapMarkerIconOptions);

    this.mapViewerUserSelectionService.init();
  }

  get timeoutMapChangeMs(): number {
    return this.$timeoutMapChangeMs;
  }

  get timeoutGetResultsMs(): number {
    return this.$timeoutGetResultsMs;
  }

  get isUpdatingData(): boolean {
    return this.totalResultRequests > 0 || this.activeResultRequests > 0;
  }

  get activeResultRequests(): number {
    return this.resultOutcomeService?.activeRequests || 0;
  }

  get totalResultRequests(): number {
    return this.resultOutcomeService?.totalRequests || 0;
  }

  get resultOutcomeProgress(): number {
    const active = this.activeResultRequests;
    const total = this.totalResultRequests;
    return ((total - active) / total) * 100;
  }

  get resultOutcomeProgressLabel(): string {
    return `Loading ${Math.ceil(this.resultOutcomeProgress)}%`;
  }

  get resultOutcomeProgressDetailsLabel(): string {
    const active = this.activeResultRequests;
    const total = this.totalResultRequests;
    return `[${total - active}/${total}]`;
  }

  ngOnInit(): void {
    this.initMap();
    this.updateMapOnChange();
    this.initialMapMove();
    this.registerMapChangeHandler();
    this.registerEventListener();
    this.registerUserSelectionListener();
  }

  ngAfterViewInit() {
    this.emitTestResultsUpdates();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    clearTimeout(this.$timeoutMapChange);
    clearTimeout(this.$timeoutGetResults);
    this.$subscription.unsubscribe();
    this.map.remove();
    this.notificationService.clear();
  }

  /* Setup Component */

  registerEventListener(): void {
    this.$subscription.add(
      this.resultOutcomeService.observeTotalRequests().subscribe(
        (totalRequests) => {
          if (totalRequests > 0) {
            this.disableMapInteraction();
          } else {
            this.enableMapInteraction();
          }
        },
        (error) => {
          console.error(error);
          this.enableMapInteraction();
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.RENDER_GEOJSON,
        (geoJson: GeoJsonObject | GeoJsonObject[]) =>
          this.displayGeoJson(geoJson)
      )
    );
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.SUBMIT_SEARCH,
        (value: SubmitSearchPayload) => {
          this.handleSubmitSearch(value);
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SELECT_TEST_CASE_DETAILS, () => {
        this.handleSelectTestCaseChangeEvent();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SELECT_LAYER, () => {
        this.handleSelectTestCaseChangeEvent();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SHOW_FAIL_COUNT, () => {
        this.resetFailRateRange();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SELECT_FAIL_RATE_RANGE, () => {
        this.handleSelectFailRateRangeEvent();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SELECT_FAIL_RATE_DELTA_RANGE, () => {
        this.handleSelectFailRateDeltaRangeEvent();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.MOUSEOVER_TILE, (value: TilePayload) => {
        this.handleMouseOverTile(value);
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.MOUSEOUT_TILE, (value: TilePayload) => {
        this.handleMouseOut(value);
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SELECT_RESULT_OUTCOMES, () => {
        this.handleSetResultOutcomes();
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.REFRESH_MAP, () => {
        this.updateMapOnChange();
      })
    );
  }

  registerUserSelectionListener(): void {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeArea()
        .subscribe((value: AreasApiEntity) => {
          this.handleSelectAreaEvent(value);
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalog()
        .subscribe((_catalog: CatalogApiEntity) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalogVersion()
        .subscribe((_catalogVersion: number) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainComparisonCatalog()
        .subscribe((_catalog: CatalogApiEntity) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainComparisonCatalogVersion()
        .subscribe((_catalogVersion: number) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaCatalog()
        .subscribe((_catalog: CatalogApiEntity) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaCatalogVersion()
        .subscribe((_catalogVersion: number) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaComparisonCatalog()
        .subscribe((_catalog: CatalogApiEntity) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeDeltaComparisonCatalogVersion()
        .subscribe((_catalogVersion: number) => {
          this.handleSetFilterCatalog();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService.observeTestCase().subscribe(() => {
        this.handleSelectTestCaseChangeEvent();
      })
    );
  }

  createLatLng(lat: number, lng: number): L.LatLng {
    return new L.LatLng(lat, lng);
  }

  /* Search */

  executeSearch() {
    const { searchFor, searchQuery } = this.mapViewerUserSelectionService;
    this.removeMapMaker();
    if (!searchQuery.length) {
      return;
    }
    try {
      switch (searchFor) {
        case MapViewerSearchFor.WGS84:
          this.executeLatLngSearch();
          break;
        case MapViewerSearchFor.NDS:
        case MapViewerSearchFor.HNATIVE:
          this.executeTileIdSearch();
          break;
      }
    } catch (e) {
      this.showException(e);
    }
  }

  executeLatLngSearch(): void {
    const { searchQuery } = this.mapViewerUserSelectionService;
    // coordinates search
    const [lat, lng] = searchQuery
      .split(',')
      .map((v) => v.trim())
      .filter(String)
      .map((v) => parseFloat(v));
    if (!lat || !lng) {
      throw new Error('Provided coordinates are invalid!');
    }
    const pointWgs84 = new PointWgs84(lng, lat);
    const tileId = TileUtilsService.tileIdNdsFromWgsPoint(pointWgs84, 13);
    if (!tileId) {
      throw new Error('Provided coordinates are invalid!');
    }
    this.setSelectedTile(String(tileId));
    this.fitMapToTile(tileId);
    this.attachMapMarker(pointWgs84);
  }

  executeTileIdSearch(): void {
    const { searchQuery, searchFor } = this.mapViewerUserSelectionService;
    const tileId = parseInt(searchQuery, 10);
    if (!tileId) {
      throw new Error('No tileId given!');
    }
    let ndsTileId: number;
    if (searchFor === MapViewerSearchFor.HNATIVE) {
      ndsTileId = TileUtilsService.ndsTileIdFromHNativeTileId(tileId);
    }
    if (searchFor === MapViewerSearchFor.NDS) {
      ndsTileId = tileId;
    }
    if (!ndsTileId) {
      throw new Error('No known tileId given!');
    }
    TileNds.invalidTileIdNds(tileId);
    this.setSelectedTile(String(ndsTileId));
    this.fitMapToTile(ndsTileId);
  }

  /* Emit Events */

  emitMouseOverTile(tileId: string) {
    let tileEntity: TileEntity;
    let deltaTileEntity: DeltaTileEntity;
    let dataVersionTileEntity: DataVersionTileEntity;
    if (this.resultOutcomeService.resultOutcomes.has(`${tileId}`)) {
      tileEntity = this.resultOutcomeService.resultOutcomes.get(`${tileId}`);
    }
    if (this.resultOutcomeService.dataVersionOutcomes.has(`${tileId}`)) {
      dataVersionTileEntity = this.resultOutcomeService.dataVersionOutcomes.get(
        `${tileId}`
      );
    }
    if (this.resultOutcomeService.resultDeltaOutcomes.has(`${tileId}`)) {
      deltaTileEntity = this.resultOutcomeService.resultDeltaOutcomes.get(
        `${tileId}`
      );
    }
    this.mapEventService.emit(
      new EmitEvent(MapEvent.MOUSEOVER_TILE, {
        tileId,
        tileEntity,
        deltaTileEntity,
        dataVersionTileEntity
      })
    );
  }

  emitMouseOutTile(tileId: string) {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.MOUSEOUT_TILE, { tileId })
    );
  }

  emitSetTileSelected(tileId: string) {
    this.setSelectedTile(tileId);
  }

  emitDataVersionUpdates() {
    const { tileId } = this.mapViewerUserSelectionService;
    let dataVersionTileEntity: DataVersionTileEntity;
    if (this.resultOutcomeService.dataVersionOutcomes.has(`${tileId}`)) {
      dataVersionTileEntity = this.resultOutcomeService.dataVersionOutcomes.get(
        `${tileId}`
      );
    }
    if (tileId) {
      this.mapEventService.emit(
        new EmitEvent(MapEvent.SUBMIT_LAYER, {
          tileId,
          dataVersionTileEntity
        })
      );
    }
  }

  emitTestResultsUpdates() {
    const {
      tileId,
      mainCatalogName,
      mainCatalogVersion,
      mainComparisonCatalogName,
      mainComparisonCatalogVersion,
      testCaseId,
      deltaCatalogName,
      deltaCatalogVersion,
      deltaComparisonCatalogName,
      deltaComparisonCatalogVersion,
      visualizationMode
    } = this.mapViewerUserSelectionService;
    if (tileId && mainCatalogName && mainCatalogVersion && testCaseId) {
      this.mapEventService.emit(
        new EmitEvent(MapEvent.SUBMIT_TEST_CASE_RESULTS, {
          tileId,
          mainCatalogName,
          mainCatalogVersion,
          mainComparisonCatalogName,
          mainComparisonCatalogVersion,
          testCaseId,
          deltaCatalogName,
          deltaCatalogVersion,
          deltaComparisonCatalogName,
          deltaComparisonCatalogVersion,
          visualizationMode
        })
      );
      return;
    }
    if (tileId && mainCatalogName && mainCatalogVersion) {
      this.mapEventService.emit(
        new EmitEvent(MapEvent.SUBMIT_CATALOG_RESULTS, {
          tileId,
          mainCatalogName,
          mainCatalogVersion,
          visualizationMode
        })
      );
    }
  }

  emitUserSelectionUpdated() {
    this.mapEventService.emit(
      new EmitEvent(
        MapEvent.UPDATED_USER_SELECTION,
        this.mapViewerUserSelectionService
      )
    );
  }

  emitShowDataLegend() {
    const { layerName } = this.mapViewerUserSelectionService;
    if (!layerName) {
      return;
    }
    const markers = this.calcColorLegend();
    if (!markers.length) {
      return;
    }
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SHOW_LEGEND, {
        layerName,
        scale: this.createScale()
      })
    );
  }

  emitShowFailCountLegend() {
    const { testCaseId, resultType, visualizationMode, failVisualization } =
      this.mapViewerUserSelectionService;
    if (!testCaseId) {
      return;
    }
    const scale: MapViewerValueScale = this.resultOutcomeService.createScale(
      visualizationMode,
      resultType,
      failVisualization
    );
    if (!scale) {
      return [];
    }
    const markers = scale.calcMarkerRange();
    if (!markers.length) {
      return;
    }
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SHOW_LEGEND, {
        testCaseId,
        resultType,
        scale
      })
    );
  }

  emitShowLegend() {
    const { testCaseId, resultType, visualizationMode } =
      this.mapViewerUserSelectionService;
    if (!testCaseId) {
      return;
    }
    const markers = this.calcColorLegend();
    if (!markers.length) {
      return;
    }
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SHOW_LEGEND, {
        testCaseId,
        resultType,
        unit: this.resultOutcomeService.getResultsUnit(visualizationMode),
        scale: this.createScale()
      })
    );
  }

  emitHideLegend() {
    this.mapEventService.emit(new EmitEvent(MapEvent.HIDE_LEGEND, null));
  }

  emitResetFailFilter() {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.RESET_FAIL_VISUALIZATION, null)
    );
  }

  emitResetResultDetails() {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.RESET_RESULT_DETAILS, null)
    );
  }

  /* Handle Events */
  handleMouseOverTile(payload: TilePayload) {
    const { tileId } = payload;
    if (this.mapViewerTileGrid.has(`${tileId}`)) {
      this.mapViewerTileGrid.get(`${tileId}`).setHighlight();
    }
  }

  handleMouseOut(payload: TilePayload) {
    const { tileId } = payload;
    const { tileId: currentTileId } = this.mapViewerUserSelectionService;
    if (String(tileId) === String(currentTileId)) {
      return;
    }
    if (this.mapViewerTileGrid.has(`${tileId}`)) {
      this.mapViewerTileGrid.get(`${tileId}`).resetHighlight();
    }
  }

  handleSubmitSearch(payload: SubmitSearchPayload): void {
    const { searchFor, searchQuery } = payload;
    this.mapViewerUserSelectionService.searchFor =
      searchFor || MapViewerSearchFor.NDS;
    this.mapViewerUserSelectionService.searchQuery = searchQuery || '';
    this.emitUserSelectionUpdated();
    this.executeSearch();
  }

  handleSelectAreaEvent(area: AreasApiEntity): void {
    if (this.mapViewerArea?.areaId) {
      this.resetAreaSelection(this.mapViewerArea.areaId);
    }
    this.mapViewerArea = area;
    this.moveToArea();
  }

  handleSelectFailRateRangeEvent() {
    this.resetTilesResultOutcome();
    this.renderResultOutcome();
  }

  handleSelectFailRateDeltaRangeEvent() {
    this.resetTilesResultOutcome();
    this.renderResultOutcome();
  }

  handleSelectTestCaseChangeEvent() {
    this.resultOutcomeService.clear();
    this.resetTilesResultOutcome();
    this.getResults();
    this.emitResetResultDetails();
    this.emitHideLegend();
  }

  getResults() {
    clearTimeout(this.$timeoutGetResults);
    this.$timeoutGetResults = setTimeout(() => {
      this.getResultsDelayed();
      clearTimeout(this.$timeoutGetResults);
    }, this.$timeoutMapChangeMs);
  }

  handleSetFilterCatalog() {
    this.resultOutcomeService.clear();
    this.mapEventService.emit(
      new EmitEvent(MapEvent.RESET_RESULT_DETAILS, null)
    );
    if (
      this.mapViewerUserSelectionService.deltaCatalogName !== null &&
      this.mapViewerUserSelectionService.deltaCatalogVersion !== null
    ) {
      this.resetTilesResultOutcome();
      this.getResults();
      this.emitResetResultDetails();
    }
    this.emitUserSelectionUpdated();
    this.emitHideLegend();
  }

  handleSetResultOutcomes() {
    this.resetTilesResultOutcome();
    this.renderResultOutcome();
  }

  handleResetUserSelection() {
    // reset selected area. needs to be done before resetting the areaId
    this.resetAreaSelection(this.mapViewerUserSelectionService.areaId);
    this.mapViewerUserSelectionService.area = null;
    this.mapViewerUserSelectionService.mainCatalog = null;
    this.mapViewerUserSelectionService.mainCatalogVersion = null;
    this.mapViewerUserSelectionService.testCase = null;
    this.mapViewerUserSelectionService.layerName = null;
    for (const resultOutcome of this.mapViewerUserSelectionService.resultOutcomes.keys()) {
      this.mapViewerUserSelectionService.resultOutcomes.set(
        resultOutcome,
        true
      );
    }
    this.resultOutcomeService.clear();
    this.resetTilesResultOutcome();
    this.emitResetResultDetails();
    this.emitHideLegend();
    this.emitUserSelectionUpdated();
    this.emitResetFailFilter();
  }

  /* Render Map and Tiles */

  resetAreaSelection(areaId: number): void {
    // reset old entries
    const area = this.getArea(areaId);
    if (!area) {
      return;
    }
    const { tileIds = [] } = area || {};
    tileIds.forEach((tileId) => {
      if (this.mapViewerTileGrid.has(`${tileId}`)) {
        this.mapViewerTileGrid.get(`${tileId}`).resetSelectedArea();
      }
    });
    this.emitUserSelectionUpdated();
  }

  getSelectedArea() {
    return this.getArea(this.mapViewerUserSelectionService.areaId);
  }

  getArea(areaId: number) {
    if (!Number.isFinite(areaId)) {
      return null;
    }
    return this.mapViewerArea;
  }

  renderSelectedArea() {
    if (this.IsAreaAvailable) {
      const selectedArea = this.getSelectedArea();
      if (!selectedArea) {
        return;
      }
      const { tileIds = [] } = selectedArea || {};
      tileIds.forEach((tileId) => {
        if (this.mapViewerTileGrid.has(`${tileId}`)) {
          this.mapViewerTileGrid.get(`${tileId}`).setSelectedArea();
        }
      });
    }
  }

  renderResultOutcome() {
    this.emitHideLegend();
    const { visualizationMode } = this.mapViewerUserSelectionService;
    switch (visualizationMode) {
      case MapViewerVisualizationMode.RESULT:
        this.renderTilesResultVisualization();
        break;
      case MapViewerVisualizationMode.DATA:
        this.renderTilesDataVisualization();
        break;
      case MapViewerVisualizationMode.DELTA:
        this.renderTilesDeltaVisualization();
        break;
    }
  }

  resetTilesResultOutcome() {
    this.mapViewerTileGrid.forEach((tile) => tile.resetResultOutcome());
  }

  moveToArea(): void {
    const selectedArea = this.getSelectedArea();
    if (!selectedArea) {
      return;
    }
    const { bounds } = selectedArea;
    const boundsResult = this.convertStringToBounds(bounds);
    const ne = boundsResult.ne as L.LatLngTuple;
    const sw = boundsResult.sw as L.LatLngTuple;
    const latLngBounds = new L.LatLngBounds(sw, ne);
    this.map.fitBounds(latLngBounds);
  }

  fitMapToTile(tileId: number): void {
    const tileNds = new TileNds(tileId);
    const southWestPoint = TileNds.calcSouthWestPoint(tileId, tileNds.level);
    const northEastPoint = TileNds.calcNorthEastPoint(tileId, tileNds.level);
    const sw = this.createLatLng(
      southWestPoint.convert2Wgs84().latitude,
      southWestPoint.convert2Wgs84().longitude
    );
    const ne = this.createLatLng(
      northEastPoint.convert2Wgs84().latitude,
      northEastPoint.convert2Wgs84().longitude
    );
    const bounds = new L.LatLngBounds(sw, ne);
    this.map.fitBounds(bounds);
  }

  setMapCenter(latLng: L.LatLng): void {
    this.map.flyTo(latLng, 11, { animate: false });
  }

  isTilesLayerVisible(): boolean {
    return this.map
      ? this.mapViewerUserSelectionService.disableZoomLimit ||
      this.map.getZoom() >=
      this.mapViewerUserSelectionService.minTileZoomLevel
      : false;
  }

  updateTilesLayerVisibility(): void {
    if (!this.isTilesLayerVisible()) {
      this.notificationService.warning(
        `Please zoom into the map to display the tiles grid. Zoom should be >= ${
          this.mapViewerUserSelectionService.minTileZoomLevel
        } (is: ${this.map.getZoom()})`
      );
      this.hideTiles();
    } else {
      this.showTiles();
    }
  }

  updateQueryViewPort(): void {
    this.mapViewerUserSelectionService.zoom = this.map.getZoom();
    this.mapViewerUserSelectionService.bounds = this.map.getBounds();
  }

  updateViewPortTiles(): void {
    if (!this.isTilesLayerVisible()) {
      return;
    }
    const currentViewPortBounds = this.getBounds();
    const normalizedBounds = TileUtilsService.fitBoundsToTileGrid(
      currentViewPortBounds,
      10
    );
    const partitionTiles =
      this.$tileGridBoundsCache.getPartitions(normalizedBounds);
    for (const partition of partitionTiles) {
      this.loadViewPortTiles(partition);
    }

    if (
      this.$tileGridBoundsCache.hasAll(partitionTiles) ||
      !partitionTiles.length
    ) {
      // Update tile rendering
      this.$subscription.add(
        this.areaCacheService
          .getSingleArea(this.mapViewerUserSelectionService.areaId)
          .subscribe((response) => {
            this.mapViewerArea = response;
            this.IsAreaAvailable = true;
            this.renderSelectedArea();
          })
      );
    }
  }

  /* Get Data from API */
  getBounds(): Bounds {
    const southWestLat = this.map.getBounds().getSouthWest().lat;
    const southWestLng = this.map.getBounds().getSouthWest().lng;
    const northEastLat = this.map.getBounds().getNorthEast().lat;
    const northEastLng = this.map.getBounds().getNorthEast().lng;
    return {
      ne: [northEastLat, northEastLng],
      sw: [southWestLat, southWestLng]
    };
  }

  openContextMenu(event: L.LeafletMouseEvent) {
    const {
      latlng: { lng, lat }
    } = event;
    if (this.$mapMarker) {
      this.removeMapMaker();
      return;
    }
    this.attachMapMarker(new PointWgs84(lng, lat));
  }

  createScale() {
    const { visualizationMode, resultType, failVisualization } =
      this.mapViewerUserSelectionService;
    return this.resultOutcomeService.createScale(
      visualizationMode,
      resultType,
      failVisualization
    );
  }

  calcColorLegend() {
    const scale = this.createScale();
    if (!scale) {
      return [];
    }
    return scale.calcMarkerRange();
  }

  getResultsTilesDataLayerOutcome(): void {
    const currentViewPortBounds: Bounds = this.getBounds();
    if (!currentViewPortBounds) {
      return;
    }
    const {
      visualizationMode,
      mainCatalogName,
      mainCatalogVersion,
      layerName
    } = this.mapViewerUserSelectionService;
    if (!visualizationMode) {
      // no mode selected
      return;
    }
    if (!mainCatalogName || !mainCatalogVersion || !layerName) {
      // required catalog infos missing
      return;
    }
    if (visualizationMode !== MapViewerVisualizationMode.DATA) {
      return;
    }

    const observable = this.resultOutcomeService.getDataVersionResults({
      mode: this.mapViewerUserSelectionService.visualizationMode,
      catalogParams: { mainCatalogName, mainCatalogVersion },
      layerName,
      bounds: currentViewPortBounds
    });

    observable.subscribe(
      () => {
        this.renderResultOutcome();
      },
      (error: HttpApiErrorResponse) => {
        console.error(error.error);
        this.showError(error);
        this.enableMapInteraction();
      },
      () => {
        this.enableMapInteraction();
      }
    );
  }

  getResultsTilesResultOutcome(): void {
    const currentViewPortBounds: Bounds = this.getBounds();
    if (!currentViewPortBounds) {
      return;
    }
    if (
      this.mapViewerUserSelectionService.visualizationMode ===
      MapViewerVisualizationMode.DATA
    ) {
      return;
    }
    if (!this.mapViewerUserSelectionService.isCompleted()) {
      return;
    }

    const {
      mainCatalogName,
      mainCatalogVersion,
      mainComparisonCatalogName,
      mainComparisonCatalogVersion,
      deltaCatalogName,
      deltaCatalogVersion,
      deltaComparisonCatalogName,
      deltaComparisonCatalogVersion,
      testCase,
      functionalRoadClass,
      attributionStatus,
      controlledAccess
    } = this.mapViewerUserSelectionService;

    const observable = this.resultOutcomeService.getResults({
      mode: this.mapViewerUserSelectionService.visualizationMode,
      catalogParams: {
        mainCatalogName,
        mainCatalogVersion,
        mainComparisonCatalogName,
        mainComparisonCatalogVersion,
        deltaCatalogName,
        deltaCatalogVersion,
        deltaComparisonCatalogVersion,
        deltaComparisonCatalogName
      },
      testCase,
      bounds: currentViewPortBounds,
      filterParams: {
        functionalRoadClass,
        attributionStatus,
        controlledAccess
      }
    });
    observable.subscribe(
      (_value) => {
        this.renderResultOutcome();
      },
      (error: HttpApiErrorResponse) => {
        console.error(error.error);
        this.showError(error);
        this.enableMapInteraction();
      },
      () => {
        this.enableMapInteraction();
      }
    );
  }

  generateHDLMViewerUrl(latitude: number, longitude: number): string {
    const {
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    } = this.mapViewerUserSelectionService;
    return this.mapHdmlViewerUrlService.generateHDLMViewerUrl({
      latitude,
      longitude,
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    });
  }

  generateHDLM3ViewerUrl(latitude: number, longitude: number): string {
    const {
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    } = this.mapViewerUserSelectionService;
    return this.mapHdmlViewerUrlService.generateHDLM3ViewerUrl({
      latitude,
      longitude,
      mainCatalogName,
      mainCatalogVersion,
      zoom,
      testCase,
      layerName,
      visualizationMode,
      mapDataType
    });
  }

  private hideTiles(): void {
    if (this.map.hasLayer(this.featureGroup)) {
      this.featureGroup.removeFrom(this.map);
    }
  }

  private showTiles(): void {
    if (!this.map.hasLayer(this.featureGroup)) {
      this.featureGroup.addTo(this.map);
    }
  }

  private initMap(): void {
    const [defaultBaseMapSettings] = environment.mapViewerBaseMaps;
    const { mapViewerOptions, mapViewerLayerOptions } = environment;

    if (this.mapViewerUserSelectionService.zoom) {
      mapViewerOptions.zoom = this.mapViewerUserSelectionService.zoom;
    }

    this.map = new L.Map('map', {
      ...mapViewerOptions,
      center: [48.8115723, 11.4793143],
      zoomControl: false,
      preferCanvas: true
    });

    const baseMap = L.tileLayer(defaultBaseMapSettings.urlTemplate, {
      ...mapViewerLayerOptions
    });
    baseMap.addTo(this.map);
    this.updateTilesLayerVisibility();

    this.partitionGrid.addTo(this.map);
  }

  private initialMapMove() {
    const { areaId, tileId, bounds } = this.mapViewerUserSelectionService;
    if (tileId && !bounds) {
      this.fitMapToTile(tileId);
      return;
    }

    if (areaId && !bounds) {
      this.$subscription.add(
        this.areaCacheService.getSingleArea(areaId).subscribe((response) => {
          this.mapViewerArea = response;
          this.IsAreaAvailable = true;
          this.moveToArea();
        })
      );
    }
    if (bounds) {
      this.map.fitBounds(bounds);
    }
  }

  private setSelectedTile(tileId: string) {
    const { tileId: currentTileId } = this.mapViewerUserSelectionService;
    if (this.mapViewerTileGrid.has(`${currentTileId}`)) {
      // reset old selection
      this.mapViewerTileGrid.get(`${currentTileId}`).resetSelected();
    }
    this.mapViewerUserSelectionService.tileId = parseInt(tileId, 10);
    if (this.mapViewerTileGrid.has(`${tileId}`)) {
      this.mapViewerTileGrid.get(`${tileId}`).setSelected();
    }
    if (
      this.mapViewerUserSelectionService.visualizationMode !== 'DATA VERSION'
    ) {
      this.emitTestResultsUpdates();
    } else {
      this.emitDataVersionUpdates();
    }
  }

  private renderViewPortTiles(tiles: Map<string, TilesApiEntity>): void {
    const { tileId: currentTileId } = this.mapViewerUserSelectionService;
    tiles.forEach((tile, tileId) => {
      if (!this.mapViewerTileGrid.has(tileId)) {
        const mapViewerTile = new MapViewerTile(tileId, tile.bounds);
        mapViewerTile.rectangle.on({
          mouseover: () => this.emitMouseOverTile(tileId),
          mouseout: () => this.emitMouseOutTile(tileId),
          click: () => this.emitSetTileSelected(tileId)
        });
        this.mapViewerTileGrid.set(tileId, mapViewerTile);
        mapViewerTile.rectangle.addTo(this.featureGroup);
      }
    });
    this.renderSelectedArea();
    if (this.mapViewerTileGrid.has(`${currentTileId}`)) {
      this.mapViewerTileGrid.get(`${currentTileId}`).setSelected();
    }
  }

  private loadViewPortTiles(partitionTile: TileNds) {
    // add requested bounds to cache
    this.$tileGridBoundsCache.add(partitionTile);
    this.$subscription.add(
      this.datastoreService.getTiles(partitionTile).subscribe(
        (response: ResponseMap<TilesApiEntity>) => {
          const { data } = response || {};
          const map = new Map<string, TilesApiEntity>(
            Object.keys(data).map((k) => [`${k}`, data[k]])
          );
          this.renderViewPortTiles(map);
        },
        (error: HttpApiErrorResponse) => {
          // remove requested bounds from cache on error
          if (error.status === 0) {
            this.notificationService.error(`Unknown Error`);
          } else {
            console.error(error.error);
            this.showError(error);
          }
        }
      )
    );
  }

  private updateMapOnChange() {
    clearTimeout(this.$timeoutMapChange);
    this.$timeoutMapChange = setTimeout(() => {
      this.updateViewPortTiles();
      this.getResults();
      this.updateTilesLayerVisibility();
      this.updateQueryViewPort();
      clearTimeout(this.$timeoutMapChange);
    }, this.$timeoutMapChangeMs);
  }

  private registerMapChangeHandler() {
    this.map.on('load', () => {
      this.initialMapMove();
      this.updateMapOnChange();
    });
    this.map.on('moveend', () => {
      this.updateMapOnChange();
    });
    this.map.on('contextmenu', (event: L.LeafletMouseEvent) => {
      L.DomEvent.preventDefault(event.originalEvent);
      this.openContextMenu(event);
    });

    this.map.on('click', (event: L.LeafletMouseEvent) => {
      if (this.shownGeoJson) {
        const { latlng } = event;
        const pointWgs84 = new PointWgs84(latlng.lng, latlng.lat);
        const tileId = TileUtilsService.tileIdNdsFromWgsPoint(pointWgs84, 13);
        if (tileId !== this.mapViewerUserSelectionService.tileId) {
          this.hideGeoJson();
          this.setSelectedTile(String(tileId));
        }
      }
    });
  }

  private createDataPane() {
    this.pane = this.map.createPane(this.dataLayerPane);
    this.map.getPane(this.dataLayerPane).style.zIndex = '601';
  }

  private deleteDataPane() {
    L.DomUtil.remove(this.pane);
    // eslint-disable-next-line no-underscore-dangle
    delete (this.map as any)._paneRenderers[this.dataLayerPane];
  }

  private hideGeoJson() {
    this.shownGeoJson.remove();
    this.shownGeoJson = null;
    this.deleteDataPane();
  }

  private displayGeoJson(geoJson: GeoJsonObject | GeoJsonObject[]) {
    if (this.shownGeoJson) {
      this.hideGeoJson();
    }

    if (geoJson) {
      this.createDataPane();
      this.shownGeoJson = L.geoJSON(geoJson, {
        pointToLayer: (_feature, latlng) =>
          L.circleMarker(latlng, this.mapDataStyle),
        onEachFeature: (feature, layer: L.Layer) => {
          if (feature.properties?.failMessage) {
            if (feature.geometry.type === 'Point') {
              const coordinates = feature.geometry.coordinates;
              const hdV3MapUrl = this.generateHDLM3ViewerUrl(
                coordinates[1],
                coordinates[0]
              );
              const linkText = 'Show in HDLM v3 Viewer';
              const popupContent = `
             <div>
                <p>${feature.properties.failMessage}</p>
                </br>
                <a href='${hdV3MapUrl}' target='_blank'>${linkText}</a>
             </div>
             `;
              layer.bindPopup(popupContent, { maxHeight: 500 });
            }
          }
          if (feature.properties?.fails?.length) {
            layer.bindPopup(
              feature.properties.fails
                .map(
                  ({ testCaseId, failMessage }) =>
                    `<p><b>${testCaseId}:</b> ${failMessage}</p>`
                )
                .join('<hr/>'),
              { maxHeight: 500 }
            );
          }
        }
      }).addTo(this.map);
    }
  }

  private renderTilesResultVisualization() {
    if (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_COUNT
    ) {
      this.prepareFailCountOutcome();
      this.emitShowFailCountLegend();
    } else {
      this.prepareResultOutcome();
      this.emitShowLegend();
    }
  }

  private prepareResultOutcome() {
    const userResultOutcomes =
      this.mapViewerUserSelectionService.resultOutcomes;
    const scale = this.createScale();
    for (const [
      tileId,
      tileEntity
    ] of this.resultOutcomeService.resultOutcomes.entries()) {
      const { resultOutcome, failRate, totalCount } = tileEntity;
      if (
        resultOutcome === ResultOutcome.FAIL &&
        !this.isInFailRateRange(failRate) && totalCount !== -1
      ) {
        continue;
      }
      if (
        userResultOutcomes.get(resultOutcome) &&
        this.mapViewerTileGrid.has(`${tileId}`)
      ) {
        this.mapViewerTileGrid
          .get(`${tileId}`)
          .setResultOutcome(tileEntity, scale);
      }
    }
  }

  private prepareDataOutcome() {
    const scale = this.createScale();
    for (const [
      tileId,
      dataVersionTileEntity
    ] of this.resultOutcomeService.dataVersionOutcomes.entries()) {
      if (this.mapViewerTileGrid.has(`${tileId}`)) {
        this.mapViewerTileGrid
          .get(`${tileId}`)
          .setDataOutcome(dataVersionTileEntity, scale);
      }
    }
  }

  private prepareFailCountOutcome() {
    const { visualizationMode, resultType, failVisualization } =
      this.mapViewerUserSelectionService;
    const scale = this.resultOutcomeService.createScale(
      visualizationMode,
      resultType,
      failVisualization
    );
    for (const [
      tileId,
      tileEntity
    ] of this.resultOutcomeService.resultOutcomes.entries()) {
      if (!this.isTileEntityIncluded(tileEntity)) {
        continue;
      }
      if (this.mapViewerTileGrid.has(`${tileId}`)) {
        this.mapViewerTileGrid
          .get(`${tileId}`)
          .setResultOutcome(tileEntity, scale);
      }
    }
  }

  private prepareFailCountDeltaOutcome() {
    const { visualizationMode, resultType, failVisualization } =
      this.mapViewerUserSelectionService;
    const scale = this.resultOutcomeService.createScale(
      visualizationMode,
      resultType,
      failVisualization
    );
    for (const [
      tileId,
      deltaTileEntity
    ] of this.resultOutcomeService.resultDeltaOutcomes.entries()) {
      if (!this.isDeltaTileEntityIncluded(deltaTileEntity)) {
        continue;
      }
      if (!this.isInFailCountDeltaRange(deltaTileEntity)) {
        continue;
      }
      if (
        !this.mapViewerUserSelectionService.resultDeltaOutcomes.get(
          ResultDeltaOutcome.EQUAL
        ) &&
        deltaTileEntity.valueDelta === 0
      ) {
        continue;
      }
      if (this.mapViewerTileGrid.has(`${tileId}`)) {
        this.mapViewerTileGrid
          .get(`${tileId}`)
          .setResultDeltaOutcome(deltaTileEntity, resultType, scale);
      }
    }
  }

  private prepareDeltaOutcome() {
    const scale = this.createScale();
    for (const [
      tileId,
      deltaTileEntity
    ] of this.resultOutcomeService.resultDeltaOutcomes.entries()) {
      const { resultType } = this.mapViewerUserSelectionService;
      if (!this.isDeltaTileEntityIncluded(deltaTileEntity)) {
        continue;
      }
      if (!this.isInFailRateDeltaRange(deltaTileEntity)) {
        continue;
      }
      if (this.mapViewerTileGrid.has(`${tileId}`)) {
        this.mapViewerTileGrid
          .get(`${tileId}`)
          .setResultDeltaOutcome(deltaTileEntity, resultType, scale);
      }
    }
  }

  private renderTilesDataVisualization() {
    this.prepareDataOutcome();
    this.emitShowDataLegend();
  }

  private renderTilesDeltaVisualization() {
    if (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_COUNT
    ) {
      this.prepareFailCountDeltaOutcome();
      this.emitShowFailCountLegend();
    } else {
      this.prepareDeltaOutcome();
      this.emitShowLegend();
    }
  }

  private isInFailRateRange(failRate: number): boolean {
    const { failRateMinValue, failRateMaxValue } =
      this.mapViewerUserSelectionService;
    if (Number.isFinite(failRate) && failRate < failRateMinValue) {
      return false;
    }
    return !(Number.isFinite(failRate) && failRate > failRateMaxValue);
  }

  private isTileEntityIncluded(entity: TileEntity) {
    return this.mapViewerUserSelectionService.resultOutcomes.get(
      entity.resultOutcome
    );
  }

  private isDeltaTileEntityIncluded(entity: DeltaTileEntity) {
    return this.mapViewerUserSelectionService.resultDeltaOutcomes.get(
      entity.deltaOutcome
    );
  }

  private isInFailRateDeltaRange(entity: DeltaTileEntity): boolean {
    const { failRateDeltaMinValue, failRateDeltaMaxValue, resultType } =
      this.mapViewerUserSelectionService;
    if (
      !Array.of(
        ResultType.FORMAT_CONTENT,
        ResultType.COMBINED_DETAILED_STATISTIC,
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.MAP_APPROVAL
      ).includes(resultType)
    ) {
      return true;
    }
    const { failRateDelta } = entity;

    if (
      Number.isFinite(failRateDelta) &&
      failRateDelta < failRateDeltaMinValue
    ) {
      return false;
    }
    return !(
      Number.isFinite(failRateDelta) && failRateDelta > failRateDeltaMaxValue
    );
  }

  private isInFailCountDeltaRange(entity: DeltaTileEntity): boolean {
    const { failRateDeltaMinValue, failRateDeltaMaxValue, resultType } =
      this.mapViewerUserSelectionService;
    if (
      !Array.of(
        ResultType.FORMAT_CONTENT,
        ResultType.COMBINED_DETAILED_STATISTIC,
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.MAP_APPROVAL
      ).includes(resultType)
    ) {
      return true;
    }
    const { valueDelta } = entity;

    if (!Number.isFinite(valueDelta)) {
      return false;
    }

    if (valueDelta < failRateDeltaMinValue) {
      return false;
    }
    return !(valueDelta > failRateDeltaMaxValue);
  }

  private resetFailRateRange() {
    const scale = this.createScale();
    if (scale?.domainMinMax && scale.domainMinMax.length > 0) {
      const [min, max] = scale.domainMinMax;
      this.mapViewerUserSelectionService.failRateMinValue = min;
      this.mapViewerUserSelectionService.failRateMaxValue = max;
    } else {
      this.mapViewerUserSelectionService.failRateMinValue = null;
      this.mapViewerUserSelectionService.failRateMaxValue = null;
    }
    this.handleSelectFailRateRangeEvent();
  }

  private attachMapMarker(pointWgs84: PointWgs84) {
    this.removeMapMaker();
    const ndsTileId = TileUtilsService.tileIdNdsFromWgsPoint(pointWgs84, 13);
    const hnativeTileId = TileUtilsService.tileIdHNativeFromWgsPoint(
      pointWgs84,
      14
    );

    this.$mapMarker = L.marker([pointWgs84.latitude, pointWgs84.longitude], {
      icon: this.$mapMarkerIcon
    }).addTo(this.map);
    const popupEl = this.mapViewerMarkerPopupService.returnMarkerPopUpHTML({
      pointWgs84,
      ndsTileId,
      hnativeTileId,
      hdmlViewerUrl: this.generateHDLMViewerUrl(
        pointWgs84.latitude,
        pointWgs84.longitude
      ),
      hdml3ViewerUrl: this.generateHDLM3ViewerUrl(
        pointWgs84.latitude,
        pointWgs84.longitude
      )
    });
    this.$mapMarker
      .bindPopup(popupEl, {
        minWidth: 300,
        maxWidth: 600
      })
      .openPopup();
  }

  private removeMapMaker() {
    if (this.$mapMarker) {
      this.$mapMarker.removeFrom(this.map);
      this.$mapMarker = null;
    }
  }

  private showError(err: HttpApiErrorResponse): void {
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }

  private showException(err: Error): void {
    this.notificationService.error(`${err.message}`, `Error`);
  }

  private convertStringToBounds(boundsString: string): Bounds {
    const boundsRegex =
      /southWest=Bounds.LatLng\((.*?)\),\s*northEast=Bounds.LatLng\((.*?)\)/;
    const matches = boundsRegex.exec(boundsString);

    if (!matches || matches.length < 3) {
      throw new Error('Invalid Bounds string');
    }

    const southWestLatLng = this.parseLatLng(matches[1]);
    const northEastLatLng = this.parseLatLng(matches[2]);

    return {
      sw: [southWestLatLng.latitude, southWestLatLng.longitude],
      ne: [northEastLatLng.latitude, northEastLatLng.longitude]
    };
  }

  private parseLatLng(latlngString: string): LatLng {
    // Regular expressions to extract latitude and longitude values
    const latLngRegex = /latitude=([^,]+),\s*longitude=([^)]+)/;
    const match = latLngRegex.exec(latlngString);
    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    }
  }

  private getResultsDelayed() {
    if (!this.isTilesLayerVisible()) {
      return;
    }
    if (this.isUpdatingData) {
      console.warn(
        `There are running resultOutcome requests [${this.activeResultRequests}, ${this.totalResultRequests}]. Please wait and try again.`
      );
      return;
    }
    switch (this.mapViewerUserSelectionService.visualizationMode) {
      case MapViewerVisualizationMode.RESULT:
      case MapViewerVisualizationMode.DELTA:
        this.getResultsTilesResultOutcome();
        break;
      case MapViewerVisualizationMode.DATA:
        this.getResultsTilesDataLayerOutcome();
        break;
    }
  }

  private disableMapInteraction() {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) {
      this.map.tap.disable();
    }
  }

  private enableMapInteraction() {
    this.map.dragging.enable();
    this.map.touchZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
    this.map.boxZoom.enable();
    this.map.keyboard.enable();
    if (this.map.tap) {
      this.map.tap.enable();
    }
  }
}
