import {
  MapViewerDeltaFailCountScale,
  MapViewerFailCountScale,
  MapViewerValueScale
} from '@modules/map-viewer/service/map-viewer-value-scale';
import {
  Bounds,
  DataVersionTileEntity,
  DeltaTileEntity,
  ResultDeltaOutcome,
  ResultOutcome,
  ResultType,
  TileEntity
} from '@shared/model/datastore';
import * as L from 'leaflet';

export class MapViewerTile {
  public static TILE_DEFAULT_COLOR = '#a0a0a0';
  public static TILE_DEFAULT_FILL_COLOR = '#ffffff';
  public static TILE_DEFAULT_FILL_OPACITY = 0.0;
  public static TILE_DEFAULT_WEIGHT = 1;

  public static TILE_SELECTED_COLOR = '#DD0000';
  public static TILE_SELECTED_WEIGHT = 4;

  public static TILE_HIGHLIGHT_WEIGHT = 4;

  public static TILE_AREA_COLOR = '#0c30e2';
  public static TILE_AREA_FILL_OPACITY = 0;
  public static TILE_AREA_WEIGHT = 2;

  public static TILE_RESULT_OUTCOME_FILL_OPACITY = 0.6;
  public static TILE_RESULT_OUTCOME_FAILURE_OPACITY = 0.75;

  public static TILE_COLOR_PASS = '#088A08';
  public static TILE_COLOR_FAIL = '#FF3333';
  public static TILE_COLOR_FAILURE = '#000000';
  public static TILE_COLOR_NOT_EXECUTED = '#202020';

  public static TILE_COLOR_EQUAL = '#0000FF';
  public static TILE_COLOR_INC = '#088A08';
  public static TILE_COLOR_DEC = '#FF3333';

  tileId: number;
  bounds: Bounds;
  latLngBounds: L.LatLngBounds;
  rectangle: L.Rectangle;

  isArea = false;
  isHighlight = false;
  isSelected = false;

  resultType: ResultType;
  resultOutcome: TileEntity = null;
  dataOutcome: DataVersionTileEntity = null;
  resultDeltaOutcome: DeltaTileEntity = null;

  private scale: MapViewerValueScale;

  constructor(tileId, bounds: Bounds) {
    this.tileId = tileId;
    this.bounds = bounds;
    this.latLngBounds = new L.LatLngBounds(
      bounds.ne as L.LatLngTuple,
      bounds.sw as L.LatLngTuple
    );
    this.rectangle = new L.Rectangle(
      this.latLngBounds,
      MapViewerTile.getDefaultStyle()
    );
  }

  public static getDefaultStyle(): L.PathOptions {
    return {
      color: MapViewerTile.TILE_DEFAULT_COLOR,
      weight: MapViewerTile.TILE_DEFAULT_WEIGHT,
      fillColor: MapViewerTile.TILE_DEFAULT_FILL_COLOR,
      fillOpacity: MapViewerTile.TILE_DEFAULT_FILL_OPACITY
    };
  }

  getStyle(): L.PathOptions {
    const style = MapViewerTile.getDefaultStyle();
    if (this.isArea) {
      this.addAreaStyle(style);
    }
    if (this.resultDeltaOutcome) {
      this.addResultDeltaOutcomeStyle(style);
    }
    if (this.resultOutcome) {
      this.addResultOutcomeStyle(style);
    }
    if (this.dataOutcome) {
      this.addDataOutcomeStyle(style);
    }
    if (this.isHighlight) {
      this.addHighlightStyle(style);
    }
    if (this.isSelected) {
      this.addSelectedStyle(style);
    }
    return style;
  }

  resetStyle(): void {
    this.rectangle.setStyle(MapViewerTile.getDefaultStyle());
  }

  setHighlight(): void {
    this.isHighlight = true;
    this.rectangle.setStyle(this.getStyle());
  }

  resetHighlight(): void {
    this.isHighlight = false;
    this.rectangle.setStyle(this.getStyle());
  }

  setSelected(): void {
    this.isSelected = true;
    this.rectangle.setStyle(this.getStyle());
    this.rectangle.bringToFront();
  }

  resetSelected(): void {
    this.isHighlight = false;
    this.isSelected = false;
    this.rectangle.setStyle(this.getStyle());
    this.rectangle.bringToBack();
  }

  setSelectedArea(): void {
    this.isArea = true;
    this.rectangle.setStyle(this.getStyle());
  }

  resetSelectedArea(): void {
    this.isArea = false;
    this.rectangle.setStyle(this.getStyle());
  }

  setResultDeltaOutcome(
    resultDeltaOutcome: DeltaTileEntity,
    resultType: ResultType,
    scale: MapViewerValueScale
  ) {
    this.resultOutcome = null;
    this.resultDeltaOutcome = resultDeltaOutcome;
    this.resultType = resultType;
    this.scale = scale;
    this.rectangle.setStyle(this.getStyle());
  }

  setResultOutcome(resultOutcome: TileEntity, scale: MapViewerValueScale) {
    this.resultOutcome = resultOutcome;
    this.resultDeltaOutcome = null;
    this.scale = scale;
    this.rectangle.setStyle(this.getStyle());
  }

  setDataOutcome(
    dataOutcome: DataVersionTileEntity,
    scale: MapViewerValueScale
  ) {
    this.dataOutcome = dataOutcome;
    this.resultDeltaOutcome = null;
    this.scale = scale;
    this.rectangle.setStyle(this.getStyle());
  }

  resetResultOutcome() {
    this.resultOutcome = null;
    this.dataOutcome = null;
    this.resultDeltaOutcome = null;
    this.rectangle.setStyle(this.getStyle());
  }

  private addAreaStyle(style: L.PathOptions) {
    style.color = MapViewerTile.TILE_AREA_COLOR;
    style.weight = MapViewerTile.TILE_AREA_WEIGHT;
    style.fillOpacity = MapViewerTile.TILE_AREA_FILL_OPACITY;
  }

  private addResultOutcomeStyle(style: L.PathOptions) {
    style.fillOpacity = MapViewerTile.TILE_RESULT_OUTCOME_FILL_OPACITY;

    const { resultOutcome, failRate, failCount, value, totalCount } = this.resultOutcome;

    if (totalCount === -1) {
      style.fillColor = MapViewerTile.TILE_COLOR_FAIL;
      return;
    }

    switch (resultOutcome) {
      case ResultOutcome.PASS:
        style.fillColor = Number.isFinite(value)
          ? this.scale.scaleColor(value)
          : MapViewerTile.TILE_COLOR_PASS;
        break;
      case ResultOutcome.FAIL:
        if (this.scale instanceof MapViewerFailCountScale) {
          style.fillColor = Number.isFinite(failCount)
            ? this.scale.scaleColor(failCount)
            : MapViewerTile.TILE_COLOR_FAIL;
          break;
        } else {
          style.fillColor = Number.isFinite(failRate)
            ? this.scale.scaleColor(failRate)
            : MapViewerTile.TILE_COLOR_FAIL;
          break;
        }
      case ResultOutcome.FAILURE:
        style.fillColor = MapViewerTile.TILE_COLOR_FAILURE;
        style.fillOpacity = MapViewerTile.TILE_RESULT_OUTCOME_FAILURE_OPACITY;
        break;
      case ResultOutcome.NOT_EXECUTED:
        style.fillColor = MapViewerTile.TILE_COLOR_NOT_EXECUTED;
        break;
    }
  }

  private addDataOutcomeStyle(style: L.PathOptions) {
    style.fillOpacity = MapViewerTile.TILE_RESULT_OUTCOME_FILL_OPACITY;
    const { dataVersion } = this.dataOutcome;
    if (dataVersion === 0) {
      style.fillColor = MapViewerTile.TILE_COLOR_NOT_EXECUTED;
    } else {
      style.fillColor = Number.isFinite(dataVersion)
        ? this.scale.scaleColor(dataVersion)
        : MapViewerTile.TILE_COLOR_PASS;
    }
  }

  private addResultDeltaOutcomeStyle(style: L.PathOptions) {
    style.fillOpacity = MapViewerTile.TILE_RESULT_OUTCOME_FILL_OPACITY;

    const { deltaOutcome, failRateDelta, valueDelta } = this.resultDeltaOutcome;

    switch (deltaOutcome) {
      case ResultDeltaOutcome.INC:
        style.fillColor = MapViewerTile.TILE_COLOR_INC;
        this.applyResultDeltaChangeStyle(style, valueDelta, failRateDelta);
        break;
      case ResultDeltaOutcome.DEC:
        style.fillColor = MapViewerTile.TILE_COLOR_DEC;
        this.applyResultDeltaChangeStyle(style, valueDelta, failRateDelta);
        break;
      case ResultDeltaOutcome.EQUAL:
        style.fillColor = MapViewerTile.TILE_COLOR_EQUAL;
        break;
      case ResultDeltaOutcome.FAILURE:
        style.fillColor = MapViewerTile.TILE_COLOR_FAILURE;
        style.fillOpacity = MapViewerTile.TILE_RESULT_OUTCOME_FAILURE_OPACITY;
        break;
      case ResultDeltaOutcome.NOT_EXECUTED:
        style.fillColor = MapViewerTile.TILE_COLOR_NOT_EXECUTED;
        break;
      default:
        throw new Error(`Unknown result delta outcome: ${deltaOutcome}`);
    }
  }

  private applyResultDeltaChangeStyle(
    style: L.PathOptions,
    valueDelta: number,
    failRateDelta: number
  ) {
    switch (this.resultType) {
      case ResultType.STATISTIC:
      case ResultType.STATISTIC_DETAILED:
        if (Number.isFinite(valueDelta)) {
          style.fillColor = this.scale.scaleColor(valueDelta);
        }
        break;
      case ResultType.FORMAT_CONTENT:
      case ResultType.COMBINED_DETAILED_STATISTIC:
      case ResultType.FORMAT_CONTENT_DETAILED:
      case ResultType.MAP_APPROVAL:
        if (this.scale instanceof MapViewerDeltaFailCountScale) {
          if (Number.isFinite(valueDelta)) {
            style.fillColor = this.scale.scaleColor(valueDelta);
          }
        } else {
          if (Number.isFinite(failRateDelta)) {
            style.fillColor = this.scale.scaleColor(failRateDelta);
          }
        }
        break;
    }
  }

  private addSelectedStyle(style: L.PathOptions) {
    style.weight = MapViewerTile.TILE_SELECTED_WEIGHT;
    style.color = MapViewerTile.TILE_SELECTED_COLOR;
  }

  private addHighlightStyle(style: L.PathOptions) {
    style.weight = MapViewerTile.TILE_HIGHLIGHT_WEIGHT;
  }
}
