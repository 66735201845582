/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapViewerSearchFor } from '@modules/map-viewer/model/map-viewer';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { AuthService } from '@shared/service/auth.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';
import { Subscription } from 'rxjs';

interface SearchForOption {
  value: MapViewerSearchFor;
  name: string;
}

type SearchForOptions = Array<SearchForOption>;

@Component({
  selector: 'app-map-viewer-search',
  templateUrl: './map-viewer-search.component.html',
  styleUrls: ['./map-viewer-search.component.scss']
})
export class MapViewerSearchComponent implements OnInit, OnDestroy {
  private $searchQuery: string;
  private $searchFor: MapViewerSearchFor;
  private $searchForOptions: SearchForOptions = [
    {
      value: MapViewerSearchFor.NDS,
      name: 'NDS'
    },
    {
      value: MapViewerSearchFor.HNATIVE,
      name: 'HNATIVE'
    },
    {
      value: MapViewerSearchFor.WGS84,
      name: 'Latitude/Longitude'
    }
  ];
  private $subscription: Subscription = new Subscription();

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService,
    public authService: AuthService
  ) {}

  get disableZoomLimit(): boolean {
    return this.mapViewerUserSelectionService.disableZoomLimit;
  }

  set disableZoomLimit(value: boolean) {
    this.mapViewerUserSelectionService.disableZoomLimit = value;
    this.mapEventService.emit(new EmitEvent(MapEvent.REFRESH_MAP, {}));
  }

  get searchForOptions(): SearchForOptions {
    return this.$searchForOptions;
  }

  get searchQuery(): string {
    return this.$searchQuery;
  }

  set searchQuery(value: string) {
    this.$searchQuery = value;
  }

  get searchFor(): MapViewerSearchFor {
    return this.$searchFor;
  }

  set searchFor(value: MapViewerSearchFor) {
    this.$searchFor = value;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.UPDATED_USER_SELECTION,
        (value: MapViewerUserSelectionService) => {
          const { searchQuery, searchFor } = value;
          this.$searchQuery = searchQuery;
          this.$searchFor = searchFor as MapViewerSearchFor;
        }
      )
    );
  }

  submitSearch(): void {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SUBMIT_SEARCH, {
        searchQuery: this.searchQuery,
        searchFor: this.searchFor
      })
    );
  }
}
