<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
<ng-container *ngIf="scenarioProgressDetails">
  <mat-tab-group dynamicHeight>
    <mat-tab label="Details">
      <ng-template mat-tab-label>
        <span>
          Details
        </span>
      </ng-template>
      <div class="tab-content">
        <ul class="property-list" dense>
          <li class="property-list-item"><span class="property-list-label">Run ID</span><span
            class="property-list-value">{{scenarioProgressDetails.runId}}</span></li>
          <li *ngIf="scenarioProgressDetails.restartedRunId !== -1" class="property-list-item"><span class="property-list-label">Restarted Run ID</span><span
            class="property-list-value line-break">{{scenarioProgressDetails.restartedRunId }}</span></li>
          <li class="property-list-item"><span class="property-list-label">Scenario ID</span><span
            class="property-list-value">{{scenarioProgressDetails.scenarioId}}</span></li>
          <li class="property-list-item">
            <span class="property-list-label">Area(s)</span>
            <span class="property-list-value line-break">{{ scenarioProgressDetails.areaIds | areaName }}</span>
          </li>
          <li class="property-list-item"><span class="property-list-label">Tiles</span><span
            class="property-list-value line-break">{{scenarioProgressDetails.tileIds | join}}</span></li>
          <li class="property-list-item"><span class="property-list-label">RouteIds</span><span
            class="property-list-value line-break">{{scenarioProgressDetails.routeIds | join}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Backend Version</span><span
            class="property-list-value">{{scenarioProgressDetails.versionBackendKf}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Status</span><span
            class="property-list-value">{{scenarioProgressDetails.status}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Message</span><span
            class="property-list-value">{{scenarioProgressDetails.message}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Last Update</span><span
            class="property-list-value">{{scenarioProgressDetails.statusUpdateTimeStamp | date: 'dd/MM/yyyy hh:mm:ss'}}</span></li>
        </ul>
      </div>
    </mat-tab>
    <mat-tab label="Catalog Infos">
      <ng-template mat-tab-label>
        <span>
          Catalog Infos
        </span>
      </ng-template>
      <div class="tab-content">
        <ng-container *ngFor="let catalogInfos of scenarioProgressDetails.catalogInfos">
          <ul class="property-list" dense>
            <li class="property-list-item"><span class="property-list-label">MapDataType</span><span
              class="property-list-value">{{catalogInfos.mapDataType}}</span></li>
            <li class="property-list-item">
              <span class="property-list-label">Name</span>
              <span class="property-list-value">
                {{catalogInfos.name}}
                <button [cdkCopyToClipboard]="catalogInfos.name" [matRippleCentered]="true" [matRippleRadius]="12" aria-label="Copy to clipboard"
                        class="button-content-copy" matRipple
                        onclick="this.blur()" type="button"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
              </span>
            </li>
            <li class="property-list-item">
              <span class="property-list-label">Version</span>
              <span class="property-list-value">{{catalogInfos.version}}</span>
            </li>
            <li class="property-list-item">
              <span class="property-list-label">Comparison Name</span>
              <span class="property-list-value">
                {{catalogInfos.deltaName}}
                <button *ngIf="catalogInfos.deltaName" [cdkCopyToClipboard]="catalogInfos.deltaName" [matRippleCentered]="true" [matRippleRadius]="12"
                        aria-label="Copy to clipboard" class="button-content-copy"
                        matRipple onclick="this.blur()" type="button"><span class="mat-button-wrapper"><mat-icon>content_copy</mat-icon></span></button>
              </span>
            </li>
            <li class="property-list-item">
              <span class="property-list-label">Comparison Version</span>
              <span class="property-list-value">{{catalogInfos.deltaVersion}}</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab label="Testcase Progress">
      <ng-template mat-tab-label>
        <span>
          Testcase Progress
        </span>
      </ng-template>
      <div class="tab-content">
        <ul class="property-list" dense>
          <li class="property-list-item"><span class="property-list-label">Pending</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcPending}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Started</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcStarted}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Failed</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcFailed}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Passed</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcPassed}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Not Executed</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcNotExecuted}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Failure</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcFailure}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Scenario Failure</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcScenarioFailure}}</span></li>
          <li class="property-list-item"><span class="property-list-label">Cancelled</span><span
            class="property-list-value">{{scenarioProgressDetails.testcaseProgress.numberTcCancelled}}</span></li>
        </ul>
      </div>
    </mat-tab>
    <mat-tab label="Performance">
      <ng-template *ngIf="performanceProfile" mat-tab-label>
        <span>
          Performance
        </span>
      </ng-template>
      <div class="tab-content">
        <table *ngIf="performanceProfile?.averageDurationForStatus" class="kf-table-basic">
          <colgroup>
            <col span="1" style="width:31%" />
            <col span="1" style="width:23%" />
            <col span="1" style="width:18%" />
            <col span="1" style="width:5%" />
            <col span="1" style="width:18%" />
            <col span="1" style="width:5%" />
          </colgroup>
          <thead>
          <tr>
            <th>Status</th>
            <th>Duration</th>
            <th colspan="2">Shortest</th>
            <th colspan="2">Last</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let averageDurationForStatus of performanceProfile.averageDurationForStatus; let i = index">
            <td>{{ averageDurationForStatus.status }}</td>
            <td class="right">{{ averageDurationForStatus.absoluteDurationInMs | number }} ms</td>
            <td class="right"><span *ngIf="averageDurationForStatus.shortestAbsoluteDurationInMs">{{ averageDurationForStatus.shortestAbsoluteDurationInMs | number }} ms</span></td>
            <td class="right"><span *ngIf="averageDurationForStatus.runIdForShortestDuration">[{{ averageDurationForStatus.runIdForShortestDuration }}]</span></td>
            <td class="right"><span *ngIf="averageDurationForStatus.lastAbsoluteDurationInMs">{{ averageDurationForStatus.lastAbsoluteDurationInMs | number }} ms</span></td>
            <td class="right"><span *ngIf="averageDurationForStatus.runIdForLastDuration">[{{ averageDurationForStatus.runIdForLastDuration }}]</span></td>
          </tr>
          </tbody>
        </table>
        <br/>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
