import { Injectable } from '@angular/core';
import { EMPTY, Observable, of as observableOf, of } from 'rxjs';
import { AreasApiEntity } from '@shared/model/productserver';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/service/settings.service';
import { environment } from '@env';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ResponseCollection, ResponseObject } from '@shared/model/response';

const CACHE_SIZE = 1;

@Injectable()
export class AreaCacheService {
  public areaUrl: string;

  private allAreasCache$: Observable<Array<AreasApiEntity>>;
  private allAreasMap: Map<string, AreasApiEntity[]> = new Map();

  private areasCache$: Map<number, Observable<AreasApiEntity>> = new Map();
  private readonly CACHE_SIZE = 1;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.areaUrl = settingsService.getProductServerUrl(
      environment.productServerAreaUrl
    );
  }

  get allAreas() {
    if (!this.allAreasCache$) {
      this.allAreasCache$ = this.areaList().pipe(shareReplay(CACHE_SIZE));
    }
    return this.allAreasCache$;
  }

  getSingleArea(id: number): Observable<AreasApiEntity> {
    if (!id) {
      return EMPTY;
    }
    if (this.areasCache$.has(id)) {
      return this.areasCache$.get(id);
    } else {
      const area$ = this.getArea(id).pipe(
        shareReplay(this.CACHE_SIZE),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      );
      this.areasCache$.set(id, area$);
      return area$;
    }
  }

  public areaList() {
    if (this.allAreasMap.has(this.areaUrl)) {
      return of(this.allAreasMap.get(this.areaUrl));
    } else {
      return this.httpClient
        .get<ResponseCollection<AreasApiEntity>>(this.areaUrl)
        .pipe(
          map(
            (response) => response.data,
            (err) => {
              console.error(err);
              return observableOf([]);
            }
          )
        );
    }
  }

  public getArea(id: number): Observable<AreasApiEntity> {
    if (!id) {
      return EMPTY;
    }
    return this.httpClient
      .get<ResponseObject<AreasApiEntity>>(`${this.areaUrl}/${id}`)
      .pipe(
        map((response) => response.data),
        catchError((err) => {
          console.error(err);
          return EMPTY;
        })
      );
  }
}
