import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env';
import {SettingsService} from '@shared/service/settings.service';
import {
  DetailedPerformanceProfileApiEntity,
  DetailedScenarioProgressApiEntity,
  ScenarioApiEntity,
  ScenarioProgressApiEntity,
  ScenarioRequestObject,
  StartScenarioRequestObject
} from '@shared/model/productserver';
import {
  ResponseCollection,
  ResponseObject,
  ResponsePage
} from '@app/shared/model/response';
import {map, shareReplay} from 'rxjs/operators';
import {RunStatus} from "@shared/enum/RunStatus";

const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  scenarioUrl: string;
  scenarioStartUrl: string;
  scenarioProgressUrl: string;
  scenarioCancelUrl: string;
  performanceProfileByRunIdUrl: string;

  private cache$: Observable<Array<ScenarioApiEntity>>;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.scenarioUrl = settingsService.getProductServerUrl(
      environment.productServerScenarioUrl
    );
    this.scenarioStartUrl = settingsService.getProductServerUrl(
      environment.productServerScenarioStartUrl
    );
    this.scenarioCancelUrl = settingsService.getProductServerUrl(
      environment.productServerScenarioCancelUrl
    );
    this.scenarioProgressUrl = settingsService.getProductServerUrl(
      environment.scenarioProgressUrl
    );
    this.performanceProfileByRunIdUrl = settingsService.getProductServerUrl(
      `${environment.productServerPerformanceProfileByRunIdUrl}`
    );
  }

  get scenariosList() {
    if (!this.cache$) {
      this.cache$ = this.getScenarioList()
        .pipe(shareReplay(CACHE_SIZE))
        .pipe(map((response) => response.data));
    }
    return this.cache$;
  }

  getScenarioList(): Observable<ResponseCollection<ScenarioApiEntity>> {
    return this.httpClient.get<ResponseCollection<ScenarioApiEntity>>(
      this.scenarioUrl
    );
  }

  getScenario(id: number): Observable<ResponseObject<ScenarioApiEntity>> {
    return this.httpClient.get<ResponseObject<ScenarioApiEntity>>(
      `${this.scenarioUrl}/${id}`
    );
  }

  createScenario(
    scenario: ScenarioRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(this.scenarioUrl, scenario, {
      observe: 'response'
    });
  }

  updateScenario(
    id: number,
    scenario: ScenarioRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.put<string>(`${this.scenarioUrl}/${id}`, scenario, {
      observe: 'response'
    });
  }

  deleteScenario(id: number): Observable<HttpResponse<string>> {
    return this.httpClient.delete<string>(`${this.scenarioUrl}/${id}`, {
      observe: 'response'
    });
  }

  startScenario(
    formObject: StartScenarioRequestObject
  ): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(this.scenarioStartUrl, formObject, {
      observe: 'response'
    });
  }

  getScenarioProgressList(
    page: number = 1,
    size: number = 10
  ): Observable<ResponsePage<ScenarioProgressApiEntity>> {
    return this.httpClient.get<ResponsePage<ScenarioProgressApiEntity>>(
      this.scenarioProgressUrl,
      {
        params: {
          page: String(page),
          size: String(size)
        }
      }
    );
  }

  getScenarioProgress(
    id: number
  ): Observable<ResponseObject<DetailedScenarioProgressApiEntity>> {
    return this.httpClient
      .get<ResponseObject<DetailedScenarioProgressApiEntity>>(
        `${this.scenarioProgressUrl}/${id}`
      )
      .pipe(map((response) => response));
  }

  cancelScenarioRun(runId: number): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      `${this.scenarioCancelUrl}/${runId}`,
      {},
      {
        observe: 'response'
      }
    );
  }


  getPerformanceProfileByRunId(runId: number): Observable<DetailedPerformanceProfileApiEntity> {
    const statusOrder = Object.values(RunStatus);
    const getStatusIndex = (status: RunStatus): number => {
      return statusOrder.indexOf(status);
    };

    return this.httpClient
      .get<ResponseObject<DetailedPerformanceProfileApiEntity>>(
        `${this.performanceProfileByRunIdUrl}/${runId}`
      )
      .pipe(map((response) => {
        response.data.averageDurationForStatus.sort((a, b) => {
          return getStatusIndex(a.status) - getStatusIndex(b.status);
        })
        return response.data
      }));
  }
}
