<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <mat-chip-list #scenarioList aria-label="Scenario selection">
    <mat-chip
      *ngFor="let scenario of scenariosSelected"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(scenario)">
      {{scenario.name}}
      <mat-icon matChipRemove>remove</mat-icon>
    </mat-chip>
    <input
      class="{{controlType}}-element"
      [formControl]="scenarioCtrl"
      aria-label="Scenarios"
      placeholder="Add Scenario..."
      [matAutocomplete]="scenarioAutocomplete"
      [matChipInputFor]="scenarioList"
      [matChipInputAddOnBlur]="true"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      #scenarioIds
    >
  </mat-chip-list>
  <mat-autocomplete #scenarioAutocomplete="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let scenario of filteredScenarios$ | async" [value]="scenario">
      {{scenario.id}} - {{scenario.name}}
    </mat-option>
  </mat-autocomplete>
</div>
