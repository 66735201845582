<div *ngIf="isVisible"
     class="kf-map-viewer-container-content checkbox-result-outcome-grid filter-container">
  <label class="kf-label">Result Delta Filter</label>
  <ul>
    <li *ngFor="let resultDeltaOutcomeOption of resultDeltaOutcomeOptions">
      <mat-checkbox
        class="kf-result-outcome-{{resultDeltaOutcomeOption}}"
        [checked]="isResultDeltaOutcomeChecked(resultDeltaOutcomeOption)"
        [value]="resultDeltaOutcomeOption"
        (change)="selectResultDeltaOutcomes($event)"
      >
        {{getOutcomeLabel(resultDeltaOutcomeOption)}}
      </mat-checkbox>
    </li>
  </ul>
</div>
<div *ngIf="isVisible && isFilterRateDeltaSliderAvailable()"
     class="kf-map-viewer-container-content filter-container" id="failratedeltaslider">
  <label class="kf-label">Range of {{isFailCount() ? "Fail Counts" : "Fail Rates"}}</label>
  <div class="kf-range-slider-wrapper">
    <mat-slider class="kf-range-slider" [min]="sliderMinValue" [max]="sliderMaxValue" step="1" showTickMarks discrete [displayWith]="formatRangeLabel">
      <input matSliderStartThumb [value]="rangeMinValue" (valueChange)="setRangeMinValue($event)">
      <input matSliderEndThumb [value]="rangeMaxValue" (valueChange)="setRangeMaxValue($event)">
    </mat-slider>
  </div>
</div>
