<div class="kf-button-group">
  <button mat-raised-button color="primary" [matMenuTriggerFor]="selectClusterMenu">
    <mat-icon aria-hidden="false">keyboard_arrow_down</mat-icon>
    {{thresholdCluster}}
  </button>
  <mat-menu #selectClusterMenu="matMenu" xPosition="after">
    <a *ngFor="let clusterVersion of thresholdClusters" mat-menu-item (click)="onSelect(clusterVersion)">{{clusterVersion}}</a>
  </mat-menu>
  <button *ngIf="authService.hasPrivileges(['THRESHOLD_CREATE'])" mat-raised-button color="primary"
          (click)="addThreshold()" aria-label="Create Threshold"
          matTooltip="Create Threshold" id="createThreshold"
          style="margin-left: 16px;">
    <mat-icon aria-hidden="false">add</mat-icon>
    Create Threshold
  </button>
</div>

<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>

<div *ngIf="!isLoadingResults" class="kf-div-table">
  <div class="kf-header">
    <div class="kf-cell"></div>
    <div class="kf-cell">Test Case-ID</div>
    <div class="kf-cell">Test Case-Name</div>
    <div class="kf-cell">Result-Type</div>
    <div class="kf-cell">Threshold Tile</div>
    <div class="kf-cell">Threshold Area</div>
    <div class="kf-cell">Comment</div>
    <div class="kf-cell">Active</div>
    <div class="kf-cell">Last Change</div>
    <div class="kf-cell">Commands</div>
  </div>
  <div *ngFor="let threshold of thresholds">
    <app-threshold-list-entity [threshold]="threshold" (thresholdList)="handleThresholdEvent($event)"></app-threshold-list-entity>
  </div>
</div>
