import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AreasService } from '@modules/areas/service/areas.service';
import {
  CatalogInfoVisualization,
  ResultDataApiEntity,
  ResultFilter,
  ResultOutcome,
  TestCaseFilter
} from '@app/shared/model/datastore';
import { HttpApiErrorResponse, ResponsePage } from '@app/shared/model/response';
import { TestResultsListComponent } from '@modules/test-results/component/common/test-results-list.component';
import { DatastoreService } from '@shared/service/datastore.service';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-testcases',
  templateUrl: './testcases.component.html',
  styleUrls: ['./testcases.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class TestCasesComponent
  extends TestResultsListComponent
  implements OnInit, OnDestroy
{
  @Input() mapDataType: string;
  displayedColumns: string[] = [
    'expandDetails',
    'timestamp',
    'testCaseId',
    'testCaseVersion',
    'catalogName',
    'catalogVersion',
    'tileId',
    'resultOutcome',
    'actions'
  ];

  catalogs: Map<string, CatalogInfoVisualization[]> = new Map<
    string,
    CatalogInfoVisualization[]
  >();
  catalogVersionList: number[] = [];
  filteredCatalogVersionArray: number[] = [];
  catalogsInfo: CatalogInfoVisualization[];
  filter: TestCaseFilter = {
    catalogName: null,
    catalogVersion: null,
    testCaseIds: [],
    tileIds: [],
    areaIds: [],
    resultOutcomes: []
  };

  catalogVersionsFormControl = new FormControl();
  autoCompleteCatalogVersions: Observable<number[]>;

  constructor(
    datastoreService: DatastoreService,
    snackBar: MatSnackBar,
    cdr: ChangeDetectorRef,
    route: ActivatedRoute,
    router: Router,
    areasService: AreasService
  ) {
    super(datastoreService, snackBar, cdr, route, router, areasService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCatalogNames();
    this.getCatalogVersions();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  handleFormValues(): void {
    super.handleFormValues();
    this.filter.catalogVersion =
      parseInt(String(this.catalogVersionsFormControl.value).trim(), 10) ||
      null;
    this.updateQueryString();
  }

  submitUpdateFilter(): void {
    this.setFilterCatalogVersion();
    super.submitUpdateFilter();
  }

  getCatalogNames(): Subscription {
    this.catalogs.clear();
    this.isLoadingFilters = true;
    return this.datastoreService.getCatalogNameList().subscribe(
      (catalogs: CatalogInfoVisualization[]) => {
        this.createGroupedCatalogs(catalogs);
        this.catalogsInfo = catalogs;
        this.isLoadingFilters = false;
      },
      (err) => {
        this.isLoadingFilters = false;
        this.showError(err.message, `${err.status}`);
        return new Error(err);
      }
    );
  }

  getCatalogVersions(): void {
    if (this.filter.catalogName) {
      this.isLoadingFilters = true;
      this.catalogVersionList = [];
      this.subscription.add(
        this.datastoreService
          .getCatalogVersionList(this.filter.catalogName)
          .subscribe(
            (response) => {
              this.isLoadingFilters = false;
              this.catalogVersionList = response.data;
              this.catalogVersionList.sort((a, b) => a - b);
              this.catalogVersionList.reverse();
              this.updateCatalogVersionFilter();
            },
            (err: HttpApiErrorResponse) => {
              this.isLoadingFilters = false;
              this.showError(
                `${err.error.message} (${err.error.error})`,
                `${err.status}`
              );
            }
          )
      );
    }
  }

  setCatalogVersionChangeListener(): void {
    this.autoCompleteCatalogVersions =
      this.catalogVersionsFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.getAutoCompleteCatalogVersions(value))
      );
  }

  handleFilterCatalogNameChange(): void {
    this.filter.catalogVersion = null;
    this.filter.testCaseIds = [];
    this.filter.tileIds = [];
    this.filter.areaIds = [];
    this.filter.resultOutcomes = this.resultOutcomeOptions as ResultOutcome[];
    this.data = null;
    this.catalogVersionsFormControl.setValue(null);
    this.updateQueryString();
    this.getCatalogVersions();
  }

  setFilterCatalogVersion(): void {
    this.filter.catalogVersion =
      parseInt(String(this.catalogVersionsFormControl.value).trim(), 10) ||
      null;
    this.updateQueryString();
  }

  updateCatalogVersionFilter(): void {
    this.filteredCatalogVersionArray = this.catalogVersionList;

    const { testCaseIds, tileIds } = this.filter;
    if (!testCaseIds.length && !tileIds.length) {
      // Do not reduce catalog versions if no other filter are defined
      this.setCatalogVersionChangeListener();
      return;
    }
    if (this.data?.filteredData?.length > 0) {
      this.filteredCatalogVersionArray = [];
      this.data.filteredData.forEach((row: any) =>
        this.filteredCatalogVersionArray.push(row.catalogVersion)
      );
      this.filteredCatalogVersionArray = [
        ...new Set(this.filteredCatalogVersionArray)
      ];
    }
    this.setCatalogVersionChangeListener();
  }

  submitResetFilter(): void {
    this.filter.catalogName = null;
    this.filter.catalogVersion = null;
    this.catalogVersionsFormControl.setValue(null);
    super.submitResetFilter();
    this.updateCatalogVersionFilter();
  }

  getFilter(): ResultFilter {
    const { catalogVersion } = this.filter;

    const resultFilter: ResultFilter = super.getFilter();
    if (catalogVersion) {
      resultFilter.catalogVersion = catalogVersion;
    }
    return resultFilter;
  }

  callTestCaseResults(): Observable<ResponsePage<ResultDataApiEntity>> {
    const { catalogName } = this.filter;

    if (!catalogName) {
      this.isLoadingResults = false;
      return observableOf();
    }

    const resultFilter = this.getFilter();

    return this.datastoreService.getTestCaseResults(
      catalogName,
      resultFilter,
      this.pageIndex + 1,
      this.pageSize
    );
  }

  setResult(testcaseArray: ResultDataApiEntity[]): void {
    super.setResult(testcaseArray);
    this.updateCatalogVersionFilter();
  }

  getQueryParams(): Params {
    const queryParams: Params = super.getQueryParams();
    const { catalogName, catalogVersion } = this.filter;
    if (catalogName) {
      queryParams.catalogName = catalogName;
    }
    if (catalogVersion) {
      queryParams.catalogVersion = catalogVersion;
    }
    return queryParams;
  }

  getFilterFromQueryString(): TestCaseFilter {
    const { catalogName, catalogVersion } = this.route.snapshot.queryParams;
    return {
      ...super.getFilterFromQueryString(),
      catalogName: catalogName || null,
      catalogVersion: catalogVersion || null
    };
  }

  setFilter(filter: any) {
    super.setFilter(filter);
    this.catalogVersionsFormControl.setValue(
      this.filter.catalogVersion || null
    );
  }

  getAutoCompleteCatalogVersions(value: number): number[] {
    if (value > 0) {
      return this.filteredCatalogVersionArray.filter((option) =>
        String(option).toLowerCase().startsWith(String(value))
      );
    } else {
      return this.filteredCatalogVersionArray;
    }
  }

  asIsOrder(_a, _b) {
    return 1;
  }

  private createGroupedCatalogs(catalogs: CatalogInfoVisualization[]): void {
    this.catalogs.clear();
    for (const catalog of catalogs || []) {
      const { mapDataType } = catalog;
      if (!this.catalogs.has(mapDataType)) {
        this.catalogs.set(mapDataType, []);
      }
      this.catalogs.get(mapDataType).push(catalog);
    }
  }
}
