import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ResultDataApiEntity } from '@shared/model/datastore';

@Component({
  selector: 'app-map-viewer-result-details-dialog',
  templateUrl: './map-viewer-result-details-dialog.component.html'
})
export class MapViewerResultDetailsDialogComponent implements OnInit {
  resultDataApiEntity: ResultDataApiEntity;

  constructor(
    public dialogRef: MatDialogRef<MapViewerResultDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) resultDataApiEntity: ResultDataApiEntity
  ) {
    this.resultDataApiEntity = resultDataApiEntity;
  }

  ngOnInit(): void {
    //
  }

  close(): void {
    this.dialogRef.close();
  }
}
