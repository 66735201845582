<div class="container-fluid">
  <mat-card appearance="outlined" class="testcases-container">
    <app-page-title></app-page-title>

    <mat-card-content>
      <div class="testcases-container">
        <app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
        <div *ngIf="isRateLimitReached" class="testcases-rate-limit-reached">
          Kingfisher's API did not respond. Check Backend.
        </div>


        <div class="table-container">
          <table
            class="kf-table"
            [dataSource]="data"
            mat-table
            matSort
            matSortDirection="asc"
            matSortActive="id"
            multiTemplateDataRows
            aria-describedby="tableDescription"
            fixedLayout
          >
            <colgroup>
              <col span="1" style="width:3%" />
              <col span="1" style="width:15%" />
              <col span="1" style="width:52%" />
              <col span="1" style="width:10%" />
              <col span="1" style="width:10%" />
              <col span="1" style="width:15%" />
              <col span="1" style="width:10%" />
              <col span="1" style="width:5%" />
            </colgroup>
            <ng-container matColumnDef="id">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">ID</th>
              <td *matCellDef="let element" mat-cell
                  [innerHTML]="element.id | highlightSearch : filter.id : true">{{element.id}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Name</th>
              <td *matCellDef="let element" mat-cell
                  [innerHTML]="element.name | highlightSearch : filter.name">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="scenarios">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Scenarios</th>
              <td *matCellDef="let element" mat-cell>
                <ng-template ngFor [ngForOf]="element.scenarios || []" let-scenarioId let-i="index">
                  <span *ngIf="i > 0">, </span>
                  <span matTooltip="{{scenarioId|scenarioDetails:'name'}}">{{scenarioId}}</span>
                </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="mapDataType">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">
                Map Data Type
              </th>
              <td *matCellDef="let element" mat-cell>
                {{element.mapDataType}}
              </td>
            </ng-container>
            <ng-container matColumnDef="mainLayer">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Main Layer</th>
              <td *matCellDef="let element" mat-cell>
                {{element.mainLayer}}
              </td>
            </ng-container>
            <ng-container matColumnDef="version">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Version</th>
              <td *matCellDef="let element" mat-cell>
                {{element.version}}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Status</th>
              <td *matCellDef="let element" mat-cell>
                {{element.active ? 'active' : 'inactive'}}
              </td>
            </ng-container>

            <!-- Filters -->
            <ng-container matColumnDef="filter-space">
              <th mat-header-cell *matHeaderCellDef scope="col"></th>
            </ng-container>
            <ng-container matColumnDef="filter-id">
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-form-field appearance="outline" floatLabel="auto">
                  <input (keyup)="applyIdFilter($event)" matInput placeholder="Filter ID" id="testCaseFilterId"
                         [value]="this.filter.id">
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="applyIdFilter(null)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </th>
            </ng-container>
            <ng-container matColumnDef="filter-name">
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-form-field appearance="outline" floatLabel="auto">
                  <input (keyup)="applyNameFilter($event)" matInput placeholder="Filter Name" id="testCaseFilterName"
                         [value]="this.filter.name">
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="applyNameFilter(null)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </th>
            </ng-container>
            <ng-container matColumnDef="filter-mapDataType">
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-form-field appearance="outline">
                  <mat-select [value]="selectedMapDataTypeOptions" multiple
                              (selectionChange)="applyMapDataTypeFilter($event)">
                    <mat-option
                      *ngFor="let filterMapDataTypeOption of filterMapDataTypeOptions"
                      [value]="filterMapDataTypeOption"
                    >{{filterMapDataTypeOption}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </th>
            </ng-container>
            <ng-container matColumnDef="filter-status">
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-form-field appearance="outline">
                  <mat-select [value]="selectedStatusOptions" multiple (selectionChange)="applyStatusFilter($event)">
                    <mat-option
                      *ngFor="let filterStatusOption of filterStatusOptions"
                      [value]="filterStatusOption"
                    >{{filterStatusOption}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </th>
            </ng-container>
            <ng-container matColumnDef="filter-mainLayer">
              <th mat-header-cell *matHeaderCellDef scope="col">
                <mat-form-field appearance="outline">
                  <mat-select [value]="selectedMainLayerOptions" multiple
                              (selectionChange)="applyMainLayerFilter($event)">
                    <mat-checkbox
                      class="filter-checkbox-select-all"
                      color="primary"
                      [checked]="allMainLayersSelected"
                      [indeterminate]="someMainLayersSelected"
                      (change)="toggleSelectAllMainLayerOptions($event.checked)"
                    >Select All
                    </mat-checkbox>
                    <mat-option [value]="filter.mainLayerUnknown">{{filter.mainLayerUnknown}}</mat-option>
                    <mat-optgroup *ngFor="let group of getFilterMainLayerOptionGroups()" [label]="group">
                      <mat-option
                        *ngFor="let filterMainLayerOption of getFilterMainLayerOptions(group)"
                        [value]="filterMainLayerOption"
                      >{{filterMainLayerOption}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </th>
            </ng-container>
            <ng-container matColumnDef="filter-placeholder">
              <th mat-header-cell *matHeaderCellDef scope="col">
              </th>
            </ng-container>

            <ng-container matColumnDef="expandDetail">
              <th *matHeaderCellDef mat-header-cell scope="col"></th>
              <td *matCellDef="let element" mat-cell>
                <button
                  mat-icon-button
                  (click)="element.isExpanded = !element.isExpanded"
                  aria-label="Show Details" matTooltip="Show Details"
                >
                  <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
                <div [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
                     class="details-table-wrapper">
                  <table class="details-table" aria-describedby="detailsTableDescription">
                    <colgroup>
                      <col span="1" style="width:20%" />
                      <col span="1" style="width:80%" />
                    </colgroup>
                    <tbody>
                    <tr>
                      <th scope="row">description:</th>
                      <td [innerHTML]="element.description | highlightSearch : filter.name  | linkify">{{element.description}}</td>
                    </tr>
                    <tr>
                      <th scope="row">details:</th>
                      <td [innerHTML]="element.details | highlightSearch : filter.name">{{element.details}}</td>
                    </tr>
                    <tr>
                      <th scope="row">comparisonMapDataType:</th>
                      <td>{{element.comparisonMapDataType}}</td>
                    </tr>
                    <tr>
                      <th scope="row">dependencies:</th>
                      <td>{{element.dependencies}}</td>
                    </tr>
                    </tbody>
                    <caption id="detailsTableDescription">Testcase details</caption>
                  </table>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"
                class="header-first-row"></tr>
            <tr mat-header-row
                *matHeaderRowDef="['filter-space', 'filter-id', 'filter-name', 'filter-placeholder', 'filter-mapDataType', 'filter-mainLayer', 'filter-status', 'filter-placeholder']"
                class="header-second-row">
            </tr>
            <tr (click)="expandedElement = expandedElement === element ? null : element"
                *matRowDef="let element; columns: displayedColumns;"
                [class.testcase-inactive]="element.active === false"
                [class.details-table-expanded]="expandedElement === element" class="element-row element-row-expandable"
                mat-row>
            </tr>
            <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
            <caption id="tableDescription">List of available testcases</caption>
          </table>

          <!--pagination start-->
          <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
          <!--pagination end-->

        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
