<div *ngIf="isVisible"
     class="kf-map-viewer-container-content checkbox-result-outcome filter-container">
  <label class="kf-label">Result Outcome Filter</label>
  <ul>
    <li *ngFor="let resultOutcomeOption of resultOutcomeOptions">
      <mat-checkbox
        class="kf-result-outcome-{{resultOutcomeOption}}"
        [checked]="isResultOutcomeChecked(resultOutcomeOption)"
        [value]="resultOutcomeOption"
        (change)="selectResultOutcomes($event)"
      >
        {{resultOutcomeOption}}
      </mat-checkbox>
    </li>
  </ul>
</div>
<div *ngIf="isVisible && isFilterRateSliderAvailable()"
     class="kf-map-viewer-container-content filter-container"
     id="failrateslider">
  <label class="kf-label">Range of Fail Rates</label>
  <div class="kf-range-slider-wrapper">
    <mat-slider class="kf-range-slider" [min]="sliderMinValue" [max]="sliderMaxValue" step="1" showTickMarks discrete [displayWith]="formatRangeLabel">
      <input matSliderStartThumb [value]="rangeMinValue" (valueChange)="setRangeMinValue($event)">
      <input matSliderEndThumb [value]="rangeMaxValue" (valueChange)="setRangeMaxValue($event)">
    </mat-slider>
  </div>
</div>
