import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, mark: string, start: boolean = false): string {
    if (!mark) {
      return value;
    }

    const regex = new RegExp(`${start ? '^' : ''}${mark}`, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(regex, `<span class="highlight">${match[0]}</span>`);
  }
}
