<label class="kf-label">Visualize</label>
<div class="mat-form-field-wrapper">
  <mat-radio-group [disabled]="isResultTypeWithFailRate()"
    [(ngModel)]="activeFailFilter"
    (change)="toggleTileFilter($event)"
  >
    <mat-radio-button value="FAIL_RATE">Fail Rate</mat-radio-button>
    <mat-radio-button value="FAIL_COUNT">Fail Count</mat-radio-button>
  </mat-radio-group>
</div>
