import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  Inject
} from '@angular/core';
import { FormBuilder, NgControl, Validators } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import { CronPattern, CronPatternForm } from './cron-pattern';

@Component({
  selector: 'app-cron-pattern-form-control',
  templateUrl: './cron-pattern-form-control.component.html',
  styleUrls: ['./cron-pattern-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CronPatternFormControlComponent
    }
  ]
})
export class CronPatternFormControlComponent extends AbstractInputFormControlComponent<string> {
  parts = this._formBuilder.group<CronPatternForm>({
    seconds: this._formBuilder.control(0),
    minutes: this._formBuilder.control('*', {
      nonNullable: false,
      validators: [Validators.required]
    }),
    hours: this._formBuilder.control('*', {
      nonNullable: false,
      validators: [Validators.required]
    }),
    dayOfMonth: this._formBuilder.control('*', {
      nonNullable: false,
      validators: [Validators.required]
    }),
    month: this._formBuilder.control('*', {
      nonNullable: false,
      validators: [Validators.required]
    }),
    dayOfWeek: this._formBuilder.control('*', {
      nonNullable: false,
      validators: [Validators.required]
    })
  });

  readonly controlType: string = 'cron-pattern-form';

  @HostBinding() id: string = `${
    this.controlType
  }-${CronPatternFormControlComponent.nextId++}`;

  get empty(): boolean {
    const {
      value: { minutes, hours, month, dayOfWeek, dayOfMonth }
    } = this.parts;
    return !minutes && !hours && !month && !dayOfWeek && !dayOfMonth;
  }

  set value(value: string) {
    const cronPattern: CronPattern = CronPattern.fromString(value);
    this.parts.setValue(cronPattern as any);
    this.stateChanges.next();
  }

  @Input()
  get value(): string {
    const rawValue = this.parts.getRawValue();
    const cronPattern = this.createCronPattern(rawValue);
    if (cronPattern.isValid()) {
      return cronPattern.toString();
    }
    return '0 * * * * *';
  }

  constructor(
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);
  }

  registerOnChange(fn: any): void {
    this.parts.valueChanges.subscribe((_) => {
      this.onChange = fn(this.value);
    });
  }

  getPreviewValue(): string {
    const { minutes, hours, dayOfMonth, month, dayOfWeek } = this.parts.value;
    return `${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`;
  }

  private createCronPattern(
    rawValue: Partial<{
      minutes: string;
      hours: string;
      dayOfMonth: string;
      month: string | null;
      dayOfWeek: string;
    }>
  ) {
    return new CronPattern(
      0,
      rawValue.minutes,
      rawValue.hours,
      rawValue.dayOfMonth,
      rawValue.month,
      rawValue.dayOfWeek
    );
  }
}
