<div id="mapViewerInfoWrapper" class="map-viewer-info-wrapper">
  <mat-accordion [multi]="true" displayMode="flat">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          User Selection
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-map-viewer-user-selection></app-map-viewer-user-selection>
    </mat-expansion-panel>

    <mat-expansion-panel [class.panel-hidden]="!showTestResults()" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-map-viewer-results-overview></app-map-viewer-results-overview>
    </mat-expansion-panel>

  </mat-accordion>
</div>
