import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-scenario-progress-details-dialog',
  templateUrl: 'scenario-progress-details-dialog.component.html'
})
export class ScenarioProgressDetailsDialogComponent {
  runId: number;

  constructor(
    public dialogRef: MatDialogRef<ScenarioProgressDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) { runId }: any
  ) {
    this.runId = runId;
  }
}
