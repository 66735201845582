import { Component, Input } from '@angular/core';
import { ResultDataApiEntity } from '@app/shared/model/datastore';

@Component({
  selector: 'app-testcase-details',
  templateUrl: './testcase-details.component.html',
  styleUrls: ['./testcase-details.component.scss']
})
export class TestcaseDetailsComponent {
  @Input() resultDataApiEntity: ResultDataApiEntity;
}
