import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trigger-action-details',
  templateUrl: './trigger-action-details.component.html',
  styleUrls: ['./trigger-action-details.component.scss']
})
export class TriggerActionDetailsComponent {
  @Input() triggerActions: any[] = [];
}
