import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import {
  CatalogApiEntity,
  TestCaseApiEntity
} from '@shared/model/productserver';
import { HttpApiErrorResponse } from '@shared/model/response';
import { CatalogService } from '@shared/service/catalog.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { NotificationService } from '@shared/service/notification.service';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({ template: '' })
export abstract class MapViewerSelectVisualizationComponentComponent
  implements OnInit, OnDestroy
{
  autoCompleteCatalogVersionList: Observable<number[]>;
  mainCatalogVersionsFormControl = new FormControl();

  protected $catalogs: Map<string, CatalogApiEntity[]> = new Map<
    string,
    CatalogApiEntity[]
  >();
  protected $catalogVersionList: number[];

  protected $subscription: Subscription = new Subscription();

  protected $testCase: TestCaseApiEntity;
  protected $mainCatalog: CatalogApiEntity;
  protected $mainCatalogVersion: number;

  protected isLoadingResults = false;

  protected constructor(
    protected mapViewerUserSelectionService: MapViewerUserSelectionService,
    protected catalogService: CatalogService,
    protected datastoreService: DatastoreService,
    protected notificationService: NotificationService
  ) {
    this.registerListeners();
    this.getCatalogs();
  }

  get catalogs(): Map<string, CatalogApiEntity[]> {
    return this.$catalogs;
  }

  get catalogVersionList(): number[] {
    return this.$catalogVersionList;
  }

  get mainCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.mainCatalog;
  }

  ngOnInit() {
    this.getCatalogVersionList();
    this.updateMainCatalogVersionsFormControlValue();
  }

  ngOnDestroy(): void {
    this.notificationService.clear();
  }

  getCatalogs(): void {
    this.$catalogs.clear();
    this.isLoadingResults = true;
    this.$subscription.add(
      this.catalogService.getCatalogs().subscribe(
        (catalogs: CatalogApiEntity[]) => {
          this.createGroupedCatalogs(catalogs);
        },
        (err) => {
          this.isLoadingResults = false;
          this.showApiError(err);
          return observableOf([]);
        },
        () => {
          this.isLoadingResults = false;
        }
      )
    );
  }

  getCatalogVersionList(): void {
    this.$catalogVersionList = [];
    this.autoCompleteCatalogVersionList = new Observable<number[]>();
    if (this.mapViewerUserSelectionService.mainCatalog?.name) {
      this.isLoadingResults = true;
      this.$subscription.add(
        this.datastoreService
          .getCatalogVersionList(
            this.mapViewerUserSelectionService.mainCatalog?.name
          )
          .subscribe(
            (response) => {
              this.$catalogVersionList = response.data;
              this.$catalogVersionList.sort((a, b) => a - b);
              this.$catalogVersionList.reverse();
              if (this.$catalogVersionList.length) {
                this.autoCompleteCatalogVersionList =
                  this.mainCatalogVersionsFormControl.valueChanges.pipe(
                    startWith(''),
                    map((value) =>
                      this.getAutoCompleteCatalogVersions(
                        this.$catalogVersionList,
                        value
                      )
                    )
                  );
              }
            },
            (err: HttpApiErrorResponse) => {
              this.isLoadingResults = false;
              this.showApiError(err);
              return observableOf([]);
            },
            () => {
              this.isLoadingResults = false;
            }
          )
      );
    }
  }

  selectMainCatalog(catalogName: string) {
    if (catalogName === this.mapViewerUserSelectionService.mainCatalog?.name) {
      return;
    }
    this.mapViewerUserSelectionService.mainCatalog = null;
    this.mapViewerUserSelectionService.mainCatalogVersion = null;
    this.catalogService
      .getCatalogByName(catalogName)
      .subscribe((catalog: CatalogApiEntity) => {
        this.mapViewerUserSelectionService.mainCatalog = catalog;
      });
  }

  selectMainCatalogVersion(value: number): void {
    this.mapViewerUserSelectionService.mainCatalogVersion =
      parseInt(String(value).trim(), 10) || null;
  }

  asIsOrder(_a: any, _b: any) {
    return 1;
  }

  protected registerListeners() {
    this.registerTestCaseListener();
    this.registerMainCatalogListener();
  }

  protected registerTestCaseListener() {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeTestCase()
        .subscribe((testCase: TestCaseApiEntity) => {
          this.$testCase = testCase;
        })
    );
  }

  protected registerMainCatalogListener() {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalog()
        .subscribe((catalog: CatalogApiEntity) => {
          this.$mainCatalog = catalog;
          this.getCatalogVersionList();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalogVersion()
        .subscribe((catalogVersion: number) => {
          this.$mainCatalogVersion = catalogVersion;
          this.updateMainCatalogVersionsFormControlValue();
        })
    );
  }

  protected getAutoCompleteCatalogVersions(
    catalogVersions: number[],
    value: number
  ): number[] {
    if (value > 0) {
      return catalogVersions.filter((option) =>
        String(option).toLowerCase().startsWith(String(value).trim())
      );
    } else {
      return catalogVersions;
    }
  }

  protected showApiError(err: HttpApiErrorResponse): void {
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }
  private createGroupedCatalogs(catalogs: CatalogApiEntity[]): void {
    this.$catalogs.clear();
    for (const catalog of catalogs || []) {
      const { mapDataType } = catalog;
      if (!this.$catalogs.has(mapDataType)) {
        this.$catalogs.set(mapDataType, []);
      }
      this.$catalogs.get(mapDataType).push(catalog);
    }
  }

  private updateMainCatalogVersionsFormControlValue(): void {
    const value = this.mapViewerUserSelectionService.mainCatalogVersion;
    this.mainCatalogVersionsFormControl.setValue(value);
  }
}
