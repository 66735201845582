import { Injectable } from '@angular/core';
import { ResultType } from '@shared/model/datastore';
import { InvalidResultTypeException } from '@shared/model/errors';
import {
  ThresholdDeltaDetailedEntity,
  ThresholdDto,
  ThresholdEntity,
  ThresholdFailRateDeltaEntity,
  ThresholdFailRateDetailedEntity
} from '@shared/model/thresholds';

@Injectable({
  providedIn: 'root'
})
export class ThresholdRequestConverterService {
  public convert(threshold: ThresholdEntity): ThresholdDto {
    return {
      testCaseId: threshold.testCaseId,
      testCaseVersion: threshold.testCaseVersion,
      cluster: threshold.cluster,
      thresholdVersion: threshold.thresholdVersion,
      testCaseName: threshold.testCaseName,
      resultType: threshold.resultType,
      comments: threshold.comments,
      active: threshold.active,
      lastChange: threshold.lastChange?.toISOString(),
      testCaseVersionChanged: threshold.testCaseVersionChanged,
      thresholdValues: [...threshold.thresholdValues.values()].filter(
        (thresholdValue) =>
          !this.isEmptyThresholdValue(thresholdValue, threshold.resultType)
      )
    };
  }

  private isEmptyThresholdValue(thresholdValue, resultType): boolean {
    switch (resultType) {
      case ResultType.FORMAT_CONTENT:
      case ResultType.STATISTIC:
      case ResultType.COMBINED_DETAILED_STATISTIC:
        return ThresholdFailRateDeltaEntity.isEmpty(thresholdValue);
      case ResultType.FORMAT_CONTENT_DETAILED:
        return ThresholdFailRateDetailedEntity.isEmpty(thresholdValue);
      case ResultType.STATISTIC_DETAILED:
        return ThresholdDeltaDetailedEntity.isEmpty(thresholdValue);
      default:
        throw new InvalidResultTypeException(resultType);
    }
  }
}
