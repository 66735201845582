import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  Optional,
  Self
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';

@Component({
  selector: 'app-tile-id-list-form-control',
  templateUrl: './tile-id-list-form-control.component.html',
  styleUrls: ['./tile-id-list-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      multi: true,
      useExisting: TileIdListFormControlComponent
    }
  ]
})
export class TileIdListFormControlComponent extends AbstractInputFormControlComponent<
  number[]
> {
  parts = this._formBuilder.group({
    tileIds: this._formBuilder.control<string>('', { nonNullable: true })
  });

  readonly controlType = 'tile-id-list-input';

  @HostBinding() id: string = `${
    this.controlType
  }-${TileIdListFormControlComponent.nextId++}`;

  constructor(
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);
  }

  get empty() {
    const {
      value: { tileIds }
    } = this.parts;

    return !tileIds.length;
  }

  @Input()
  get value(): number[] {
    if (this.parts.valid) {
      const {
        value: { tileIds }
      } = this.parts;
      return this.idListStringToArray(tileIds);
    }
    return null;
  }

  set value(value: number[]) {
    this.parts.setValue({ tileIds: value?.join(',') ?? '' });
    this.stateChanges.next();
  }

  public idListStringToArray(tileIds: string): number[] {
    if (!tileIds) {
      return [];
    }
    return `${tileIds}`
      .split(',')
      .map((id) => id.trim())
      .filter((id) => id !== '')
      .map((id) => Number(id));
  }
}
