import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { CatalogApiEntity } from '@shared/model/productserver';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/service/settings.service';
import { environment } from '@env';
import { map, shareReplay } from 'rxjs/operators';
import { ResponseCollection } from '@shared/model/response';

const CACHE_SIZE = 1;

@Injectable()
export class CatalogService {
  public catalogUrl: string;

  private cacheCurrent$: Observable<Array<CatalogApiEntity>>;
  private cache: Map<string, CatalogApiEntity[]> = new Map();

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.catalogUrl = settingsService.getProductServerUrl(
      environment.productServerCatalogsUrl
    );
  }

  getCatalogs() {
    if (!this.cacheCurrent$) {
      this.cacheCurrent$ = this.requestCurrentCatalog().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheCurrent$;
  }

  getCatalogByName(catalogName: string): Observable<CatalogApiEntity> {
    if (!catalogName) {
      return EMPTY;
    }
    if (!this.cacheCurrent$) {
      this.cacheCurrent$ = this.requestCurrentCatalog().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheCurrent$.pipe(
      map((catalogs: CatalogApiEntity[]) =>
        catalogs.find(
          (catalog: CatalogApiEntity) =>
            catalog.name.toLowerCase() === catalogName.toLowerCase()
        )
      )
    );
  }

  public requestCurrentCatalog() {
    if (this.cache.has(this.catalogUrl)) {
      return of(this.cache.get(this.catalogUrl));
    } else {
      const request$ = this.httpClient
        .get<ResponseCollection<CatalogApiEntity>>(this.catalogUrl)
        .pipe(
          map(
            (response: ResponseCollection<CatalogApiEntity>) => response.data
          ),
          map((data) => {
            data.sort((a, b) =>
              a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
            );
            this.cache.set(this.catalogUrl, data); // Cache the result
            return data;
          })
        );
      return request$;
    }
  }
}
