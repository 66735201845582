<div class="kf-threshold-value-actions">
  <button mat-raised-button color="primary" (click)="editThreshold()" [disabled]="isEditable"><mat-icon>edit</mat-icon> Edit</button>
  <button mat-raised-button color="warn" (click)="deleteThreshold()"><mat-icon>delete</mat-icon> Delete</button>
</div>

<mat-tab-group *ngIf="isStatistic()">
  <mat-tab label="by Absolute">
    <table class="kf-threshold-table kf-threshold-table-delta" aria-label="Table of threshold values">
      <thead>
      <tr>
        <th scope="row"></th>
        <th scope="row" colspan="2">Threshold</th>
      </tr>
      <tr>
        <th scope="row"></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
      </tr>
      </thead>
      <tbody *ngIf="isEditable">
      <tr>
        <th scope="col"></th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl('thresholdDeltaAbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl('thresholdDeltaAbsMinus')"></td>
      </tr>
      </tbody>
      <tbody *ngIf="!isEditable">
      <tr>
        <th scope="col"></th>
        <td>{{getFormControl('thresholdDeltaAbsPlus').value}}</td>
        <td>{{getFormControl('thresholdDeltaAbsMinus').value}}</td>
      </tr>
      </tbody>
    </table>
  </mat-tab>
  <mat-tab label="by Relative">
    <table class="kf-threshold-table kf-threshold-table-delta" aria-label="Table of threshold values">
      <thead>
      <tr>
        <th scope="row"></th>
        <th scope="row" colspan="2">Threshold</th>
      </tr>
      <tr>
        <th scope="row"></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
      </tr>
      </thead>
      <tbody *ngIf="isEditable">
      <tr>
        <th scope="col"></th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl('thresholdDeltaRelPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl('thresholdDeltaRelMinus')"></td>
      </tr>
      </tbody>
      <tbody *ngIf="!isEditable">
      <tr>
        <th scope="col"></th>
        <td>{{getFormControl('thresholdDeltaRelPlus').value|number:"1.0-3":"de"}}</td>
        <td>{{getFormControl('thresholdDeltaRelMinus').value|number:"1.0-3":"de"}}</td>
      </tr>
      </tbody>
    </table>
  </mat-tab>
</mat-tab-group>


<table *ngIf="!isStatistic()" class="kf-threshold-table" aria-label="Table of threshold values">
  <thead>
  <tr>
    <th scope="row"></th>
    <th scope="row">Threshold Green</th>
    <th scope="row">Threshold Yellow</th>
  </tr>
  </thead>
  <tbody *ngIf="isEditable">
  <tr>
    <th scope="col"></th>
    <td><input matInput type="number" step=".001" [formControl]="getFormControl('thresholdRateGreen')"></td>
    <td><input matInput type="number" step=".001" [formControl]="getFormControl('thresholdRateYellow')"></td>
  </tr>
  </tbody>
  <tbody *ngIf="!isEditable">
  <tr>
    <th scope="col"></th>
    <td>{{getFormControl('thresholdRateGreen').value|number:"1.0-3":"de"}}</td>
    <td>{{getFormControl('thresholdRateYellow').value|number:"1.0-3":"de"}}</td>
  </tr>
  </tbody>
</table>
