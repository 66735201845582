import { Injectable } from '@angular/core';
import {
  MapViewerSearchFor,
  MapViewerVisualizationMode
} from '@modules/map-viewer/model/map-viewer';
import { MapViewerValueScale } from '@modules/map-viewer/service/map-viewer-value-scale';
import {
  DataVersionTileEntity,
  DeltaTileEntity,
  ResultDeltaOutcome,
  ResultOutcome,
  ResultType,
  TileEntity
} from '@shared/model/datastore';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum MapEvent {
  RENDER_GEOJSON = 'RENDER_GEOJSON',
  RESET_RESULT_DETAILS = 'RESET_RESULT_DETAILS',
  UPDATED_USER_SELECTION = 'UPDATED_USER_SELECTION',
  SELECT_CATALOG_VERSION = 'SELECT_CATALOG_VERSION',
  SELECT_TEST_CASE_DETAILS = 'SELECT_TEST_CASE_DETAILS',
  SELECT_LAYER = 'SELECT_LAYER',
  SELECT_FAIL_RATE_RANGE = 'SELECT_FAIL_RATE_RANGE',
  SELECT_FAIL_RATE_DELTA_RANGE = 'SELECT_FAIL_RATE_DELTA_RANGE',
  SELECT_RESULT_OUTCOMES = 'SELECT_RESULT_OUTCOMES',
  MOUSEOVER_TILE = 'MOUSEOVER_TILE',
  MOUSEOUT_TILE = 'MOUSEOUT_TILE',
  SUBMIT_SEARCH = 'SUBMIT_SEARCH',
  SUBMIT_TEST_CASE_RESULTS = 'SUBMIT_TEST_CASE_RESULTS',
  SUBMIT_CATALOG_RESULTS = 'SUBMIT_CATALOG_RESULTS',
  SUBMIT_LAYER = 'SUBMIT_LAYER',
  RESET_VISUALIZATION_MODE = 'RESET_VISUALIZATION_MODE',
  SHOW_LEGEND = 'SHOW_LEGEND',
  HIDE_LEGEND = 'HIDE_LEGEND',
  RESET_FAIL_VISUALIZATION = 'RESET_FAIL_VISUALIZATION',
  SHOW_FAIL_COUNT = 'SHOW_FAIL_COUNT',
  REFRESH_MAP = 'REFRESH_MAP'
}

export class EmitEvent {
  event: MapEvent;
  value: any;

  constructor(event: MapEvent, value: any) {
    this.event = event;
    this.value = value;
  }
}

export class SetNotificationPayload {
  key: string;
  message: string;
}

export class RemoveNotificationPayload {
  key: string;
}

export class SubmitSearchPayload {
  searchQuery: string;
  searchFor: MapViewerSearchFor;
}

export class TilePayload {
  tileId: string;
  tileEntity?: TileEntity;
  deltaTileEntity?: DeltaTileEntity;
  dataVersionTileEntity?: DataVersionTileEntity;
}

export class SelectAreaPayload {
  value: number;
  prevValue: number;
}

export class SelectTestCasePayload {
  testCaseId: string;
  resultType: ResultType;
}

export class SelectFailRateRangePayload {
  failRateMinValue: number;
  failRateMaxValue: number;
}

export class SelectFailRateDeltaRangePayload {
  failRateDeltaMinValue: number;
  failRateDeltaMaxValue: number;
}

export class SelectCatalogNamePayload {
  catalogName: string;
}

export class SelectCatalogPayload {
  visualizationMode: MapViewerVisualizationMode;
  mapDataType: string;
  catalogName: string;
  catalogVersion: number;
  comparisonCatalogName: string;
  comparisonCatalogVersion: number;
}

export class ResultOutcomesPayload {
  resultOutcomes?: Map<ResultOutcome, boolean>;
  resultDeltaOutcomes?: Map<ResultDeltaOutcome, boolean>;
}

export class ShowLegendPayload {
  testCaseId: string;
  resultType: ResultType;
  unit: string;
  scale: MapViewerValueScale;
  layerName: string;
}

@Injectable({
  providedIn: 'root'
})
export class MapEventService {
  private subject$ = new Subject<EmitEvent>();

  emit(event: EmitEvent) {
    this.subject$.next(event);
  }

  on(event: MapEvent, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: EmitEvent) => e.event === event),
        map((e: EmitEvent) => e.value)
      )
      .subscribe(action);
  }
}
