<form [formGroup]="complexTriggerJobForm">
  <div class="form-group form-group-trigger-meta">
    <mat-form-field appearance="fill" class="input-full-width">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput>
      <mat-error *ngIf="getFormControl('name').hasError('required')">
        Please define a name for the TriggerJob
      </mat-error>
    </mat-form-field>
  </div>
  <div class="form-group">
    <mat-tab-group
      (selectedIndexChange)="selectedComplexTriggerType = $event"
      [selectedIndex]="selectedComplexTriggerType"
      backgroundColor="accent"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          Catalog Version Based
        </ng-template>
        <div class="tab-content">
          <mat-form-field appearance="fill">
            <mat-label>Observed Catalog</mat-label>
            <app-catalog-form-control formControlName="observedCatalog" [required]="isRequiredField('observedCatalog')"></app-catalog-form-control>
            <mat-hint>Scenarios will be triggered by updates of selected catalog</mat-hint>
            <mat-error *ngIf="getFormControl('observedCatalog').hasError('required')">
              Please select a catalog
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Version Interval</mat-label>
            <input formControlName="catalogVersionInterval" matInput min="1" type="number" [required]="isRequiredField('catalogVersionInterval')">
            <mat-hint>Scenarios will be triggered by every n-th version</mat-hint>
            <mat-error *ngIf="getFormControl('catalogVersionInterval').hasError('required')">
              Please provide a version interval
            </mat-error>
          </mat-form-field>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Time Based
        </ng-template>
        <div class="tab-content">
          <h2>Define Cron Parameters</h2>
          <app-cron-pattern-form-control formControlName="cronPattern" [required]="isRequiredField('cronPattern')"></app-cron-pattern-form-control>
          <mat-error *ngIf="getFormControl('cronPattern').hasError('required')">
            Please provide a cron pattern
          </mat-error>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-accordion formArrayName="triggerActions"
                   (cdkDropListDropped)="moveTriggerAction($event)"
                   [cdkDropListData]="triggerActions"
                   cdkDropList
                   class="kf-accordion-draggable"
    >
      <mat-expansion-panel *ngFor="let triggerAction of triggerActions.controls; let i = index"
                           [cdkDragData]="triggerAction"
                           cdkDrag
                           [formGroupName]="i"
                           [class.has-errors]="!triggerAction.valid"
      >
        <mat-expansion-panel-header>
          <div class="kf-trigger-action-header">
            <div class="kf-trigger-action-header-handle">
              <mat-icon cdkDragHandle>drag_indicator</mat-icon>
            </div>
            <app-trigger-action-title class="kf-trigger-action-header-title" [triggerAction]="triggerAction"></app-trigger-action-title>
            <div class="kf-trigger-action-header-actions">
              <mat-slide-toggle (change)="toggleTriggerAction(i, $event.checked)" color="primary" formControlName="priority">Priority</mat-slide-toggle>
              <button (click)="removeTriggerAction(i)" mat-mini-fab color="warn"><mat-icon>delete</mat-icon></button>
            </div>
          </div>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill" color="accent" floatLabel="always">
          <mat-label>Select Scenarios</mat-label>
          <app-scenario-id-list-form-control class="trigger-action-input-element"
                                             formControlName="scenarioIds"
                                             aria-label="Scenarios"
                                             [required]="isTriggerActionRequiredField(i, 'scenarioIds')"
          >
          </app-scenario-id-list-form-control>
          <mat-error *ngIf="getTriggerActionFormControl(i, 'scenarioIds').hasError('required')">
            At least one scenario is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-radio-group class="kf-select-test-area-type" formControlName="testAreaType">
          <mat-radio-button value="area">Areas</mat-radio-button>
          <mat-radio-button value="tile">Tile IDs</mat-radio-button>
          <mat-radio-button value="route">Route IDs</mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="triggerAction.get('testAreaType').value === 'area'" appearance="fill" color="accent" floatLabel="always">
          <mat-label>Areas</mat-label>
          <app-area-id-list-form-control class="trigger-action-input-element"
                                         formControlName="areaIds"
                                         aria-label="Areas"
                                         [required]="isTriggerActionRequiredField(i, 'areaIds')"
          >
          </app-area-id-list-form-control>
          <mat-error *ngIf="getTriggerActionFormControl(i, 'areaIds').hasError('required')">
            At least one area is <strong>required</strong>
          </mat-error>
        </mat-form-field>


        <mat-form-field *ngIf="triggerAction.get('testAreaType').value === 'route'" appearance="fill" color="accent" floatLabel="always">
          <mat-label>Routes</mat-label>
          <app-route-id-list-form-control class="trigger-action-input-element"
                                          formControlName="routeIds"
                                          aria-label="Routes"
                                          [required]="isTriggerActionRequiredField(i, 'routeIds')"
          >
          </app-route-id-list-form-control>
          <mat-error *ngIf="getTriggerActionFormControl(i, 'routeIds').hasError('required')">
            At least one route is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="triggerAction.get('testAreaType').value === 'tile'" appearance="fill" color="accent" floatLabel="always">
          <mat-label>Tiles</mat-label>
          <app-tile-id-list-form-control class="trigger-action-input-element"
                                         formControlName="tileIds"
                                         aria-label="Tiles"
                                         [required]="isTriggerActionRequiredField(i, 'tileIds')"
          >
          </app-tile-id-list-form-control>
          <mat-error *ngIf="getTriggerActionFormControl(i, 'tileIds').hasError('required')">
            At least one tileId is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <app-catalog-info-form-control class="trigger-action-input-element"
                                       formControlName="mapCatalogInfo"
                                       [required]="isTriggerActionRequiredField(i, 'mapCatalogInfo')"
        ></app-catalog-info-form-control>
        <mat-hint align="start">Note: Use <code>0</code> for latest catalog version,<code>&lt;0</code> for versions relative to latest, e.g. <code>-1</code></mat-hint>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="mat-form-field">
      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="getFormControl('triggerActions').hasError('required')">
        Please provide at least one TriggerAction
      </mat-error>
    </div>

    <button (click)="addTriggerAction()" class="add-accordion-button" color="primary" mat-raised-button>Add Trigger Action</button>

  </div>
  <br/>
</form>
