export enum RunStatus {
  PENDING = "PENDING",
  STARTED = "STARTED",
  FETCHING_DATA = "FETCHING_DATA",
  SCHEDULING_TESTCASES = "SCHEDULING_TESTCASES",
  QUEUED_FOR_EXECUTION = "QUEUED_FOR_EXECUTION",
  EXECUTING_TESTCASES = "EXECUTING_TESTCASES",
  SCENARIO_EXECUTED = "SCENARIO_EXECUTED",
  SCENARIO_CANCELLED = "SCENARIO_CANCELLED",
  SCENARIO_FAILURE = "SCENARIO_FAILURE",
  EXECUTED_WITH_PROBLEMS = "EXECUTED_WITH_PROBLEMS"
}
