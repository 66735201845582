import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@shared/service/settings.service';
import { environment } from '@env';
import { CatalogInfoVisualization } from '@shared/model/datastore';
import { Observable, of as observableOf } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ResponseCollection } from '@shared/model/response';

@Injectable()
export class LayerService {
  public layerUrl: string;
  private allLayersMap: Map<
    string,
    Observable<Array<CatalogInfoVisualization>>
  > = new Map();
  private readonly CACHE_SIZE = 1;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.layerUrl = settingsService.getProductServerUrl(
      environment.productServerLayerUrl
    );
  }

  /**
   * Gets List of available Layers of a given MapDataType
   *
   * @param mapDataType Requested mapDataType
   * @param noCache Ignore and refresh cache
   */
  getLayers(
    mapDataType: string,
    noCache: boolean = false
  ): Observable<CatalogInfoVisualization[]> {
    if (noCache || !this.allLayersMap.has(mapDataType)) {
      // If noCache is true, or if the data is not in the cache, it will call the fetchLayers
      this.allLayersMap.set(
        mapDataType,
        this.fetchLayers(mapDataType)
          .pipe(shareReplay(this.CACHE_SIZE))
          .pipe(map((response) => response))
      );
    }
    return this.allLayersMap.get(mapDataType);
  }

  private fetchLayers(
    mapDataType: string
  ): Observable<CatalogInfoVisualization[]> {
    return this.httpClient
      .get<ResponseCollection<CatalogInfoVisualization>>(
        `${this.layerUrl}/${mapDataType}`
      )
      .pipe(
        map(
          (response) => response.data,
          (err) => {
            console.error(err);
            return observableOf([]);
          }
        )
      );
  }
}
