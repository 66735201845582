<a
  *ngIf="!item.children || item.children.length === 0"
  mat-list-item
  [routerLink]="item.path"
  [ngClass]="{
    'active': item.path ? router.isActive(item.path, true): false,
    'expanded': expanded,
    'icon-only': !!hideSidebarTitles
  }"
  class="menu-list-item depth-{{depth}}"
>
  <mat-icon class="route-icon">{{item.icon}}</mat-icon>
  <span *ngIf="!hideSidebarTitles">{{item.title}}</span>
</a>
<a
  *ngIf="item.children && item.children.length > 0"
  mat-list-item
  (click)="toggleMenu()"
  [ngClass]="{
    'active': item.path ? router.isActive(item.path, true): false,
    'expanded': expanded,
    'icon-only': !!hideSidebarTitles
  }"
  class="menu-list-item depth-{{depth}}"
>
  <mat-icon class="route-icon">{{item.icon}}</mat-icon>
  <span *ngIf="!hideSidebarTitles">{{item.title}}</span>
  <span *ngIf="item.children && item.children.length">
    <span></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="toggle">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-sidebar-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1"
                    [hideSidebarTitles]="hideSidebarTitles" (sidenavClose)="onSidenavClose()"></app-sidebar-item>
</div>
