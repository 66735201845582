<div class="container-fluid">
  <mat-card appearance="outlined">
    <app-page-title></app-page-title>

    <mat-card-content>
      <mat-tab-group
        (selectedIndexChange)="selected.setValue($event)"
        (selectedTabChange)="onTabChanged()"
        [selectedIndex]="selected.value"
        animationDuration="0ms"
      >
        <mat-tab label="Details">
          <app-testcases *matTabContent></app-testcases>
        </mat-tab>
        <mat-tab *ngIf="authService.hasPrivileges(['VIEW_RESULTS_BY_RUNID'])" label="Scenario Run">
          <app-scenario-run *matTabContent></app-scenario-run>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
