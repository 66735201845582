import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ScenarioService } from '@app/modules/test-execution/service/scenario.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotificationService } from '@shared/service/notification.service';
import { catchError, map } from 'rxjs/operators';
import { of as observableOf, Subscription } from 'rxjs';
import {
  ScenarioApiEntity,
  ScenarioProgressApiEntity
} from '@shared/model/productserver';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioProgressDetailsDialogComponent } from '@app/modules/test-execution/component/scenario-progress-details-dialog/scenario-progress-details-dialog.component';
import { environment } from '@env';
import { IntervalService } from '@app/modules/test-execution/service/interval.service';
import { HttpApiErrorResponse } from '@app/shared/model/response';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';
import { ScenarioStartDialogComponent } from '@modules/test-execution/component/scenario-start-dialog/scenario-start-dialog.component';
import { AuthService } from '@shared/service/auth.service';

@Component({
  selector: 'app-scenario-status',
  templateUrl: './scenario-status.component.html',
  styleUrls: ['./scenario-status.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ScenarioStatusComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'runId',
    'scenarioId',
    'area',
    'status',
    'message',
    'priority',
    'statusUpdateTimeStamp',
    'actions'
  ];

  data: MatTableDataSource<ScenarioProgressApiEntity>;

  isLoadingResults = true;
  isRateLimitReached = false;

  dialogWidth = 'auto';
  confirmWidth = '300px';

  length = 0;
  pageIndex = 0;
  pageSizeOptions = environment.pageSizeOptions;
  pageSize = environment.pageSize;
  totalPages = 0;
  showFirstLastButtons = true;
  public scenario: ScenarioApiEntity;
  private subscription: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private scenarioService: ScenarioService,
    private intervalService: IntervalService,
    private notificationService: NotificationService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getProgressList();
    this.intervalService.setInterval(environment.defaultRefreshPeriod, () => {
      this.getProgressList();
    });
  }

  ngOnDestroy(): void {
    this.intervalService.clearInterval();
    this.notificationService.clear();
  }

  handlePageEvent(event: PageEvent): void {
    const sizeSwitched = this.pageSize !== event.pageSize;
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = sizeSwitched ? 0 : event.pageIndex;
    this.getProgressList();
  }

  getPagesArray(): number[] {
    return this.totalPages > 0
      ? Array(this.totalPages)
          .fill(0)
          .map((_x, i) => i)
      : [];
  }

  jumpToPage(event): void {
    this.pageIndex = event.value;
    this.getProgressList();
  }

  getProgressList(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.scenarioService
        .getScenarioProgressList(this.pageIndex + 1, this.pageSize)
        .pipe(
          map((response) => {
            const { data, pagination } = response;
            this.isLoadingResults = false;
            this.isRateLimitReached = false;

            this.totalPages = pagination.totalPages;
            this.length = pagination.totalItems;
            this.pageSize = pagination.pageSize;
            this.pageIndex = pagination.currentPage - 1;
            return data;
          }),
          catchError((err: HttpApiErrorResponse) => {
            console.error(err.error);
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            return observableOf([]);
          })
        )
        .subscribe((scenarioArray) => {
          this.data = new MatTableDataSource(scenarioArray);
        })
    );
  }

  openScenarioProgressDetailsDialog(
    runId: number
  ): MatDialogRef<ScenarioProgressDetailsDialogComponent> {
    return this.dialog.open(ScenarioProgressDetailsDialogComponent, {
      width: this.dialogWidth,
      data: {
        runId
      }
    });
  }

  confirmScenarioRunCancel(
    element: ScenarioProgressApiEntity
  ): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Cancel Scenario Run`,
        message: `Are you sure to cancel scenario run "${element.runId}"`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.cancelScenarioRun(element.runId);
      }
    });
    return dialogRef;
  }

  cancelScenarioRun(runId: number): void {
    this.scenarioService.cancelScenarioRun(runId).subscribe(
      (response) => {
        this.showResponse(response);
        this.getProgressList();
      },
      (err: HttpApiErrorResponse) => {
        this.showError(err);
      }
    );
  }

  reRunScenario(
    scenarioProgressApiEntity: ScenarioProgressApiEntity
  ): MatDialogRef<any> {
    const dialogRef: MatDialogRef<ScenarioStartDialogComponent> =
      this.dialog.open(ScenarioStartDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          runId: scenarioProgressApiEntity.runId,
          scenarioId: scenarioProgressApiEntity.scenarioId
        }
      });
    dialogRef.afterClosed().subscribe(() => {
      this.getProgressList();
    });
    return dialogRef;
  }

  private showResponse(response: HttpResponse<string>): void {
    this.notificationService.success(response.statusText, `${response.status}`);
  }

  private showError(err: HttpApiErrorResponse): void {
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }
}
