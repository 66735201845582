<div class="paginator-wrapper">
  <mat-paginator
    [color]="color"
    [disabled]="disabled"
    [hidePageSize]="hidePageSize"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
    (page)="updatePage($event)"
  >
  </mat-paginator>
  <div *ngIf="showJumpTo" class="paginator-jumpto">
    <div class="paginator-jumpto-label">Jump to page:</div>
    <mat-form-field appearance="legacy" class="paginator-jumpto-select">
      <mat-select [(value)]="pageIndex" (selectionChange)="jumpToPage($event)">
        <mat-option *ngFor="let page of getPagesArray()" [value]="page">{{page + 1}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
