import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule } from '@shared/shared.module';

import { ScenarioListingComponent } from './page/scenario-listing/scenario-listing.component';
import { TestcaseListingComponent } from './page/testcase-listing/testcase-listing.component';
import { ScenarioStatusComponent } from './page/scenario-status/scenario-status.component';

import { ScenarioDialogEntryComponent } from './component/scenario-dialog-entry/scenario-dialog-entry.component';
import { ScenarioConfirmComponent } from './component/scenario-confirm/scenario-confirm.component';
import { ScenarioProgressDetailsComponent } from './component/scenario-progress-details/scenario-progress-details.component';

import { TestsRoutingModule } from './tests.routing';
import { ScenarioProgressDetailsDialogComponent } from './component/scenario-progress-details-dialog/scenario-progress-details-dialog.component';
import { ScenarioDetailsComponent } from './component/scenario-details/scenario-details.component';
import { ScenarioAutoComponent } from './page/scenario-auto/scenario-auto.component';
import { ScenarioStartDialogComponent } from './component/scenario-start-dialog/scenario-start-dialog.component';

@NgModule({
  declarations: [
    ScenarioListingComponent,
    TestcaseListingComponent,
    ScenarioStatusComponent,
    ScenarioDialogEntryComponent,
    ScenarioConfirmComponent,
    ScenarioProgressDetailsDialogComponent,
    ScenarioProgressDetailsComponent,
    ScenarioDetailsComponent,
    ScenarioAutoComponent,
    ScenarioStartDialogComponent
  ],
  imports: [SharedModule, TestsRoutingModule, ClipboardModule, MatRippleModule]
})
export class TestsModule {}
