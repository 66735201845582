<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <mat-progress-spinner *ngIf="isLoading" color="accent" diameter="20" matSuffix mode="indeterminate"></mat-progress-spinner>
  <mat-select
    class="{{controlType}}-element"
    formControlName="catalogName"
    panelClass="kf-catalog-select"
  >
    <mat-option [value]="null">None</mat-option>
    <ng-container *ngFor="let mapDataType of mapDataTypes | async">
      <mat-optgroup *ngIf="isVisible(mapDataType)" [label]="mapDataType">
        <mat-option *ngFor="let catalog of catalogs.get(mapDataType)" [value]="catalog.name">{{catalog.name}}</mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
</div>
