<button
  #toolBarMenuTrigger
  mat-button
  id="nav-user-menu"
  (onMenuOpen)="onMenuOpen()"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  [matMenuTriggerFor]="toolBarMenu"
  [ngClass]="{
    'active': isActive('status'),
    'expanded': expanded
  }"
>
  <mat-icon>account_circle</mat-icon>
  <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="toggle">
    expand_more
  </mat-icon>
</button>
<mat-menu #toolBarMenu="matMenu" xPosition="before" class="kf-main-toolbar-menu nav-user-menu-collapse">
  <div mat-menu-item>
    Logged in as <span>{{username}}</span>
  </div>
  <ng-container *ngFor="let menuItem of rightNavigation">
    <a mat-menu-item
       [id]="menuItem.id"
       (click)="onItemSelected(menuItem)"
       [ngClass]="{'active': isActive(menuItem.path)}"
       [attr.aria-label]="menuItem.title"
       aria-hidden="false"
    >
      <mat-icon>{{menuItem.icon}}</mat-icon>
      <span>{{menuItem.title}}</span>
    </a>
  </ng-container>
</mat-menu>
