import { HttpErrorResponse } from '@angular/common/http';

export class ResponseCollection<T> {
  public data: T[];
  public size: number;

  constructor(data: T[]) {
    this.data = data;
    this.size = data.length;
  }
}

export class ResponseObject<T> {
  public data: T;

  constructor(data: T) {
    this.data = data;
  }
}

export interface Pagination {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

export class ResponsePage<T> extends ResponseCollection<T> {
  pagination: Pagination;

  constructor(data: T[], pagination: Pagination) {
    super(data);
    this.pagination = pagination;
  }
}

export interface SpringBootApiError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

export class HttpApiErrorResponse extends HttpErrorResponse {
  error: SpringBootApiError;
}

export class ResponseMap<T> {
  public data: Record<string, T>;
  public size: number;

  constructor(data: Record<string, T>) {
    this.data = data;
    this.size = Object.keys(data).length;
  }
}
