<mat-form-field appearance="fill">
  <mat-label>Select Area</mat-label>
  <mat-icon matPrefix>map</mat-icon>
  <mat-select *ngIf="!isLoadingResults"
              (valueChange)="selectArea($event);"
              [value]="areaId"
              id="selectedArea"
  >
    <mat-option [value]="null">None</mat-option>
    <mat-option *ngFor="let area of areasList" [value]="area.areaId">{{area.name}}</mat-option>
  </mat-select>
  <input matInput *ngIf="isLoadingResults" />
  <span matSuffix [class.spinner]="isLoadingResults"></span>
</mat-form-field>
<p>
  Highlights selected area<br />
</p>
