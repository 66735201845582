import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ScenarioService } from '@modules/test-execution/service/scenario.service';
import {
  ComplexTriggerActionObject,
  TestAreaType
} from '@shared/model/productserver';
import { AreaCacheService } from '@shared/service/area-cache.service';

@Component({
  selector: 'app-trigger-action-title',
  templateUrl: './trigger-action-title.component.html',
  styleUrls: ['./trigger-action-title.component.scss']
})
export class TriggerActionTitleComponent {
  private _triggerAction: AbstractControl;

  @Input() set triggerAction(value: AbstractControl) {
    if (value) {
      this._triggerAction = value;
    } else {
      this._triggerAction = null;
    }
  }

  get triggerAction(): AbstractControl {
    return this._triggerAction;
  }

  constructor(
    private scenarioService: ScenarioService,
    private areaCacheService: AreaCacheService
  ) {}

  getScenarioTitle(): string {
    let content = '';
    const scenarioIds = this.getTriggerAction().scenarioIds || [];
    if (scenarioIds?.length > 0) {
      this.scenarioService.scenariosList.subscribe((scenarios) => {
        content = scenarios
          .filter((entity) => scenarioIds.includes(entity.id))
          .map((entity) => entity.name)
          .join(', ');
      });
    }
    return content;
  }

  getTestAreaType(): TestAreaType {
    return this.triggerAction?.get('testAreaType').value || TestAreaType.AREA;
  }

  getTestAreaTitle(): string {
    let content = '';
    switch (this.getTestAreaType()) {
      case TestAreaType.AREA:
        content = this.getAreaNames();
        break;
      case TestAreaType.ROUTE:
        content = this.getTriggerAction().routeIds?.join(', ');
        break;
      case TestAreaType.TILE:
        content = this.getTriggerAction().tileIds?.join(', ');
        break;
    }
    return content;
  }

  getAreaNames(): string {
    let content = '';
    const areaIds = this.getTriggerAction().areaIds || [];
    if (areaIds?.length > 0) {
      this.areaCacheService.allAreas.subscribe((scenarios) => {
        content = scenarios
          .filter((entity) => areaIds.includes(entity.areaId))
          .map((entity) => entity.name)
          .join(', ');
      });
    }
    return content;
  }

  private getTriggerAction(): ComplexTriggerActionObject {
    const {
      scenarioIds = [],
      areaIds = [],
      routeIds = [],
      tileIds = [],
      priority,
      mapCatalogInfo = null,
      parentId = null,
      parentIndex = null
    } = this.triggerAction?.getRawValue() || {};
    return new ComplexTriggerActionObject(
      scenarioIds,
      areaIds,
      routeIds,
      tileIds,
      priority,
      mapCatalogInfo,
      parentId,
      parentIndex
    );
  }
}
