import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScenarioApiEntity } from '@app/shared/model/productserver';

@Component({
  selector: 'app-scenario-dialog',
  templateUrl: 'scenario-dialog-entry.component.html',
  styleUrls: ['scenario-dialog-entry.component.scss']
})
export class ScenarioDialogEntryComponent {
  @ViewChild('addTestCases') addTestCases: ElementRef<HTMLInputElement>;

  scenarioFormData: FormGroup;
  scenarioId: number | null;
  scenarioData: ScenarioApiEntity;

  constructor(
    public dialogRef: MatDialogRef<ScenarioDialogEntryComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    { id, name, description, testCases }: ScenarioApiEntity
  ) {
    this.scenarioId = id;
    this.scenarioData = { id, name, description, testCases };

    this.initForm();
  }

  save(): void {
    if (this.scenarioFormData.invalid) {
      return;
    }
    this.dialogRef.close(this.scenarioFormData.value);
  }

  close(): void {
    this.dialogRef.close();
  }

  reset(): void {
    this.initForm();
  }

  disableButton(): boolean {
    return this.scenarioFormData.invalid;
  }

  removeTestCase(removeTestCase: string) {
    this.scenarioData.testCases = this.scenarioData.testCases.filter(
      (testCase: string) => testCase !== removeTestCase
    );
    this.updateTestCaseFormGroup();
  }

  submitTestCases() {
    const addTestCases = this.addTestCases.nativeElement.value
      .split(',')
      .map((testCase: string) => testCase.trim())
      .filter((testCase: string) => testCase.length >= 1);
    if (addTestCases.length) {
      this.scenarioData.testCases = [
        ...new Set((this.scenarioData.testCases || []).concat(addTestCases))
      ];
      this.updateTestCaseFormGroup();
      this.addTestCases.nativeElement.value = '';
    }
  }

  private updateTestCaseFormGroup() {
    this.scenarioFormData
      .get('testCases')
      .setValue(this.scenarioData.testCases);
  }

  private initForm(): void {
    this.scenarioFormData = this.formBuilder.group({
      name: [this.scenarioData.name, Validators.required],
      description: [this.scenarioData.description],
      testCases: [this.scenarioData.testCases, [Validators.required]]
    });
  }
}
