import { Injectable } from '@angular/core';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
  protected maxConcurrency = environment.apiClientMaxConcurrency || 4;

  constructor() {}

  loadWithMaxConcurrency<T>(observables: Observable<T>[]): Observable<T[]> {
    const chunks: Observable<T>[][] = [];
    if (observables.length === 0) {
      return of([]);
    }
    for (let i = 0; i < observables.length; i++) {
      const index = i % this.maxConcurrency;
      if (!chunks[index]) {
        chunks[index] = [];
      }
      chunks[index].push(observables[i]);
    }
    return this.loadParallel(
      chunks.map((chunk) => this.loadSerial(chunk))
    ).pipe(map((x) => x.flat(1)));
  }

  private loadParallel<T>(observables: Observable<T>[]): Observable<T[]> {
    return combineLatest(observables);
  }

  private loadSerial<T>(observables: Observable<T>[]): Observable<T[]> {
    return concat(...observables).pipe(toArray());
  }
}
