<table *ngIf="localizationTestApiEntity" aria-describedby="detailsTableDescription"
       class="details-table">
  <colgroup>
    <col span="1" style="width:20%"/>
    <col span="1" style="width:80%"/>
  </colgroup>
  <tbody>
  <tr>
    <th scope="row">localizationArea:</th>
    <td>{{localizationTestApiEntity.localizationArea}}</td>
  </tr>
  <tr>
    <th scope="row">localizationSoftwareVersion:</th>
    <td>{{localizationTestApiEntity.localizationSoftwareVersion}}</td>
  </tr>
  <tr>
    <th scope="row">mapCatalog:</th>
    <td>{{localizationTestApiEntity.mapCatalog}}</td>
  </tr>
  <tr>
    <th scope="row">mapCatalogVersion:</th>
    <td>{{localizationTestApiEntity.mapCatalogVersion}}</td>
  </tr>
  <tr>
    <th scope="row">timestampExecution:</th>
    <td>{{localizationTestApiEntity.timestampExecution | date: 'dd/MM/yyyy HH:mm:ss z'}}</td>
  </tr>
  <tr>
    <th scope="row">timestampImport:</th>
    <td>{{localizationTestApiEntity.timestampImport | date: 'dd/MM/yyyy HH:mm:ss z'}}</td>
  </tr>
  <tr>
    <th scope="row">meanDeviationX:</th>
    <td>{{localizationTestApiEntity.meanDeviationX}}</td>
  </tr>
  <tr>
    <th scope="row">meanDeviationY:</th>
    <td>{{localizationTestApiEntity.meanDeviationY}}</td>
  </tr>
  <tr>
    <th scope="row">meanDeviationZ:</th>
    <td>{{localizationTestApiEntity.meanDeviationZ}}</td>
  </tr>
  <tr>
    <th scope="row">countTotalPoints:</th>
    <td>{{localizationTestApiEntity.countTotalPoints}}</td>
  </tr>
  <tr>
    <th scope="row">countNoPosition:</th>
    <td>{{localizationTestApiEntity.countNoPosition}}</td>
  </tr>
  <tr>
    <th scope="row">countGlobalPosition:</th>
    <td>{{localizationTestApiEntity.countGlobalPosition}}</td>
  </tr>
  <tr>
    <th scope="row">countLanePosition:</th>
    <td>{{localizationTestApiEntity.countLanePosition}}</td>
  </tr>
  <tr>
    <th scope="row">countStateDR:</th>
    <td>{{localizationTestApiEntity.countStateDR}}</td>
  </tr>
  <tr>
    <th scope="row">countStateMM:</th>
    <td>{{localizationTestApiEntity.countStateMM}}</td>
  </tr>
  <tr>
    <th scope="row">countStateSD:</th>
    <td>{{localizationTestApiEntity.countStateSD}}</td>
  </tr>
  <tr>
    <th scope="row">countStateHD:</th>
    <td>{{localizationTestApiEntity.countStateHD}}</td>
  </tr>
  </tbody>
  <caption id="detailsTableDescription">Localization Test Details</caption>
</table>
