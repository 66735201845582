<form [formGroup]="form">
  <section>
    <app-trigger-job-test-area-form (areaSelected)="onAreaSelected($event)" (routeIdsSelected)="onRouteIdsSelected($event)"
                        (selectionTypeChanged)="onSelectionTypeChanged($event.selectionType, index)" (tileIdsSelected)="onTileIdsSelected($event)"
                        [areaIds]="areaIds"
                        [routeIds]="routeIds" [tileIds]="tileIds">>
    </app-trigger-job-test-area-form>
  </section>
  <section>
    <h2 class="draggable-header" style="font-size: 16px;">Define Map Catalog Infos</h2>
    <app-trigger-job-map-catalog-info-form
      (catalogNameSelected)="onCatalogNameSelectionChange($event)"
      (catalogVersionSelected)="onCatalogVersionSelectionChange($event)"
      (comparisonCatalogNameSelected)="onComparisonCatalogNameSelectionChange($event)"
      (comparisonCatalogVersionSelected)="onComparisonCatalogVersionSelectionChange($event)"
      (comparisonMapDataTypeSelected)="onComparisonMapDataSelectionChange($event)"
      (mapDataTypeSelected)="onMapDataSelectionChange($event)"
      [enabledRelativeCatalogVersions]="enabledRelativeCatalogVersions"
      [mapCatalogInfo]="mapCatalogInfos?.[0]"
    ></app-trigger-job-map-catalog-info-form>
    <mat-hint *ngIf="enabledRelativeCatalogVersions" align="start">Note: Use <code>0</code> for latest catalog version,
      <code>&lt;0</code> for
      versions relative to latest, e.g. <code>-1</code></mat-hint>
    <mat-hint *ngIf="!enabledRelativeCatalogVersions" align="start">Note: Select catalog version or provide version
      number <code>&gt;0</code></mat-hint>
  </section>
</form>
