import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TestCasesService } from '@shared/service/test-cases.service';
import { HttpApiErrorResponse } from '@app/shared/model/response';
import { environment } from '@env';
import { TestCaseApiEntity } from '@shared/model/productserver';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class TestCaseFilter {
  static readonly MAIN_LAYER_UNKNOWN = 'unknown';

  mapDataType: Map<string, boolean>;
  status: Map<string, boolean>;
  mainLayer: Map<string, boolean>;
  id: string;
  name: string;

  constructor() {
    this.mapDataType = new Map<string, boolean>();
    this.status = new Map<string, boolean>([
      ['active', true],
      ['inactive', false]
    ]);
    this.mainLayer = new Map<string, boolean>();
    this.id = '';
    this.name = '';
  }

  get mainLayerUnknown() {
    return TestCaseFilter.MAIN_LAYER_UNKNOWN;
  }

  toObject() {
    return {
      mapDataType: Object.fromEntries(this.mapDataType),
      status: Object.fromEntries(this.status),
      mainLayer: Object.fromEntries(this.mainLayer),
      id: String(this.id),
      name: String(this.name)
    };
  }
}

@Component({
  selector: 'app-testcase-listing',
  templateUrl: './testcase-listing.component.html',
  styleUrls: ['./testcase-listing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class TestcaseListingComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'expandDetail',
    'id',
    'name',
    'scenarios',
    'mapDataType',
    'mainLayer',
    'status',
    'version'
  ];
  expandedElement: TestCaseApiEntity | null;
  data: MatTableDataSource<TestCaseApiEntity> =
    new MatTableDataSource<TestCaseApiEntity>();

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  pageSizeOptions = environment.pageSizeOptions;
  pageSize = environment.pageSize;

  private $filter: TestCaseFilter = new TestCaseFilter();

  private subscription: Subscription = new Subscription();

  private $filterMainLayerOptions: Map<string, string[]> = new Map<
    string,
    string[]
  >();

  constructor(private testCasesService: TestCasesService) {}

  get someMainLayersSelected(): boolean {
    return (
      this.selectedMainLayerOptions.length > 0 && !this.allMainLayersSelected
    );
  }

  get allMainLayersSelected(): boolean {
    return this.selectedMainLayerOptions.length === this.$filter.mainLayer.size;
  }

  get filter() {
    return this.$filter;
  }

  get filterMapDataTypeOptions(): string[] {
    return Array.from(this.$filter.mapDataType.keys()).sort((a, b) =>
      a.localeCompare(b)
    );
  }

  get selectedMapDataTypeOptions(): string[] {
    const selected = [];
    this.$filter.mapDataType.forEach((value, mapDataType) => {
      if (value === true) {
        selected.push(mapDataType);
      }
    });
    return selected;
  }

  get selectedStatusOptions(): string[] {
    return this.getSelectedMapOptions(this.$filter.status);
  }

  get selectedMainLayerOptions(): string[] {
    return this.getSelectedMapOptions(this.$filter.mainLayer);
  }

  get filterStatusOptions(): string[] {
    return ['active', 'inactive'];
  }

  private static includedByMapDataType(
    filterMapDataType: any,
    mapDataType: string
  ) {
    return filterMapDataType?.[mapDataType] === false ? false : true;
  }

  private static includedByStatus(filterStatus: any, status: boolean) {
    if (filterStatus.active === false && status === true) {
      return false;
    }
    if (filterStatus.inactive === false && status === false) {
      return false;
    }
    return true;
  }

  private static includedByMainLayer(
    activeMainLayers: string[],
    mainLayer: string
  ) {
    if (
      mainLayer === null &&
      !activeMainLayers.includes(TestCaseFilter.MAIN_LAYER_UNKNOWN)
    ) {
      return false;
    }
    if (mainLayer?.length) {
      if (!activeMainLayers.includes(mainLayer)) {
        return false;
      }
    }
    return true;
  }

  private static includedBySearchStartsWith(needle: string, haystack: string) {
    if (
      needle.length > 0 &&
      !haystack.trim().toLowerCase().startsWith(needle.toLowerCase())
    ) {
      return false;
    }
    return true;
  }

  private static includedBySearchContains(needle: string, haystack: string) {
    if (
      needle.length > 0 &&
      !haystack.trim().toLowerCase().includes(needle.toLowerCase())
    ) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.testCasesService.testCases.subscribe(
        // testcaseArray is the response.data which is received from the rest call
        (testcaseArray) => {
          this.isLoadingResults = false;
          this.resultsLength = testcaseArray.length;

          this.data.data = testcaseArray;
          this.data.sort = this.sort;
          this.data.paginator = this.paginator;
          this.$filter.mainLayer.set(TestCaseFilter.MAIN_LAYER_UNKNOWN, true);

          const filterEntities = testcaseArray.filter(
            (entity) => !!entity.mainLayer
          );
          filterEntities.forEach((entity) => {
            const { mainLayer, mapDataType } = entity;
            // init mapDataType filter
            this.$filter.mapDataType.set(mapDataType, true);
            // init main layer filter
            this.$filter.mainLayer.set(mainLayer, true);
            // Initialize the filter options array if it doesn't exist
            if (!this.$filterMainLayerOptions.has(mapDataType)) {
              this.$filterMainLayerOptions.set(mapDataType, []);
            }
            // Only add the layer if it's not already in the array
            if (
              !this.$filterMainLayerOptions.get(mapDataType).includes(mainLayer)
            ) {
              this.$filterMainLayerOptions.get(mapDataType).push(mainLayer);
            }
          });
          // Sort the filter options for each type
          this.$filterMainLayerOptions.forEach((mainLayers, type) => {
            mainLayers.sort((a, b) => a.localeCompare(b));
            this.$filterMainLayerOptions.set(type, mainLayers);
          });
          this.initFilter();
        },
        catchError((err: HttpApiErrorResponse) => {
          this.isLoadingResults = false;
          this.data.data = [];
          console.error(err);
          return observableOf([]);
        })
      )
    );

    // Override default filter behaviour of Material Datatable
    this.data.filterPredicate = this.createFilter();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyIdFilter(event: Event): void {
    const value = event ? (event.target as HTMLInputElement).value : '';
    this.$filter.id = value.trim();
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  applyNameFilter(event: Event): void {
    const value = event ? (event.target as HTMLInputElement).value : '';
    this.$filter.name = value.trim();
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  applyMapDataTypeFilter(event: MatSelectChange): void {
    const selected = this.selectedMapDataTypeOptions;
    const updated = event.value as string[];
    this.changeFilterMapValues('mapDataType', selected, updated);
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  applyStatusFilter(event: MatSelectChange): void {
    const selected = this.selectedStatusOptions;
    const updated = event.value as string[];
    this.changeFilterMapValues('status', selected, updated);
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  applyMainLayerFilter(event: MatSelectChange): void {
    const selected = this.selectedMainLayerOptions;
    const updated = event.value as string[];
    this.changeFilterMapValues('mainLayer', selected, updated);
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  getFilterMainLayerOptionGroups(): string[] {
    return Array.from(this.$filterMainLayerOptions.keys()).sort((a, b) =>
      a.localeCompare(b)
    );
  }

  getFilterMainLayerOptions(mapDataType: string): string[] {
    return this.$filterMainLayerOptions.get(mapDataType) || [];
  }

  toggleSelectAllMainLayerOptions(completed: boolean) {
    for (const mainLayer of this.$filter.mainLayer.keys()) {
      this.$filter.mainLayer.set(mainLayer, completed);
    }
    this.data.filter = JSON.stringify(this.$filter.toObject());
  }

  private createFilter() {
    return (data: TestCaseApiEntity, filter: any): boolean => {
      const searchTerms = JSON.parse(filter);
      const activeMainLayers = Object.keys(searchTerms.mainLayer).filter(
        (mainLayer) => searchTerms.mainLayer[mainLayer] === true
      );

      let included = true;
      included =
        included &&
        TestcaseListingComponent.includedByMapDataType(
          searchTerms.mapDataType,
          data.mapDataType
        );
      included =
        included &&
        TestcaseListingComponent.includedByStatus(
          searchTerms.status,
          data.active
        );
      included =
        included &&
        TestcaseListingComponent.includedByMainLayer(
          activeMainLayers,
          data.mainLayer
        );
      included =
        included &&
        TestcaseListingComponent.includedBySearchStartsWith(
          searchTerms.id,
          data.id
        );

      const includedName = TestcaseListingComponent.includedBySearchContains(
        searchTerms.name,
        data.name
      );
      const includedDescription =
        TestcaseListingComponent.includedBySearchContains(
          searchTerms.name,
          data.description
        );
      const includedDetails = TestcaseListingComponent.includedBySearchContains(
        searchTerms.name,
        data.details
      );

      included =
        included && (includedName || includedDescription || includedDetails);

      return included;
    };
  }

  private getSelectedMapOptions(mapValues: Map<string, boolean>): string[] {
    const selected = [];
    mapValues.forEach((value, status) => {
      if (value === true) {
        selected.push(status);
      }
    });
    return selected;
  }

  private changeFilterMapValues(mapName: string, currentValues, updatedValues) {
    const unselect = currentValues.filter(
      (item) => updatedValues.indexOf(item) < 0
    );
    updatedValues.forEach((value) => {
      this.$filter[mapName].set(value, true);
    });
    unselect.forEach((value) => {
      this.$filter[mapName].set(value, false);
    });
  }

  private initFilter(): void {
    // Override default filter behaviour of Material Datatable
    this.data.filterPredicate = this.createFilter();
    this.data.filter = JSON.stringify(this.$filter.toObject());
    this.resultsLength = this.data.filteredData.length;
  }
}
