import { Component, ViewChild } from '@angular/core';
import { MapViewerSelectCatalogComponent } from '@modules/map-viewer/component/map-viewer-select-catalog/map-viewer-select-catalog.component';
import { MapViewerSelectTestcaseComponent } from '@modules/map-viewer/component/map-viewer-select-testcase/map-viewer-select-testcase.component';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';

@Component({
  selector: 'app-map-viewer-select-visualization',
  templateUrl: './map-viewer-select-visualization.component.html'
})
export class MapViewerSelectVisualizationComponent {
  @ViewChild(MapViewerSelectCatalogComponent)
  private $mapViewerSelectCatalog: MapViewerSelectCatalogComponent;

  @ViewChild(MapViewerSelectTestcaseComponent)
  private $mapViewerSelectTestcaseComponent: MapViewerSelectTestcaseComponent;

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get hideInDataVisualisation(): boolean {
    return !Array.of(MapViewerVisualizationMode.DATA).includes(
      this.mapViewerUserSelectionService.visualizationMode
    );
  }

  resetPanel() {
    this.mapViewerUserSelectionService.resetResultOutcomes();
    this.mapViewerUserSelectionService.resetDeltaResultOutcomes();
    this.mapViewerUserSelectionService.resetFailRateSelection();
    this.emitResetVisualization();
    this.emitResetFailFilter();
  }

  emitResetVisualization(): void {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.RESET_VISUALIZATION_MODE, {})
    );
  }

  emitResetFailFilter() {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.RESET_FAIL_VISUALIZATION, null)
    );
  }

  isCompleted(): boolean {
    if (this.$mapViewerSelectCatalog) {
      return this.$mapViewerSelectCatalog.isCompleted();
    }
    return false;
  }
}
