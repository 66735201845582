import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-scenario-confirm',
  templateUrl: 'scenario-confirm.component.html',
  styleUrls: ['./scenario-confirm.component.scss']
})
export class ScenarioConfirmComponent {
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ScenarioConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) { title, message }: any
  ) {
    this.title = title;
    this.message = message;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
