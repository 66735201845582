<div
  *ngIf="isTestCaseDetailed"
  class="kf-form-testcase-detailed-select"
  [ngClass]="{'not-attributed': !isTestCaseDetailedAttributed}"
>
  <mat-form-field appearance="fill">
    <mat-label>FRC</mat-label>
    <mat-select
      id="selectFunctionalRoadClass"
      (valueChange)="selectFunctionalRoadClass($event)"
      [value]="functionalRoadClass"
      panelClass="kf-catalog-select"
    >
      <mat-option *ngFor="let optionValue of functionalRoadClassOptions" [value]="optionValue">{{optionValue}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="isTestCaseDetailedAttributed" appearance="fill">
    <mat-label>Attribution Status</mat-label>
    <mat-select
      id="selectAttributionStatus"
      (valueChange)="selectAttributionStatus($event)"
      [value]="attributionStatus"
      panelClass="kf-catalog-select"
    >
      <mat-option *ngFor="let optionValue of attributionStatusOptions" [value]="optionValue">{{optionValue}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Controlled Access</mat-label>
    <mat-select
      id="selectControlledAccess"
      (valueChange)="selectControlledAccess($event)"
      [value]="controlledAccess"
      panelClass="kf-catalog-select"
    >
      <mat-option *ngFor="let optionValue of controlledAccessOptions" [value]="optionValue">{{optionValue}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
