import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { AreasService } from '@modules/areas/service/areas.service';
import { ResultType } from '@shared/model/datastore';
import { AreasListApiEntity } from '@shared/model/productserver';
import {
  ThresholdDefinitionIdentity,
  ThresholdEntity
} from '@shared/model/thresholds';
import { of as observableOf, Subscription } from 'rxjs';

export declare class AreaSelectorEvent {
  area: any;
}

@Component({
  selector: 'app-threshold-area-selector',
  templateUrl: './threshold-area-selector.component.html',
  styleUrls: ['./threshold-area-selector.component.scss']
})
export class ThresholdAreaSelectorComponent implements OnInit {
  @ViewChild('thresholdAreaSelector', { static: false }) selector: MatSelect;
  @Output() areaSelectorEmitter: EventEmitter<AreaSelectorEvent>;
  @Input() threshold: ThresholdEntity;
  isLoading = false;
  areasList: Array<AreasListApiEntity> = [];
  subscription: Subscription = new Subscription();
  selectedOption: AreasListApiEntity;
  tileBasedArea: AreasListApiEntity = {
    areaId: ThresholdDefinitionIdentity.NO_AREA_DEFINED,
    name: 'TILE BASED'
  };

  constructor(private areasService: AreasService) {
    this.areaSelectorEmitter = new EventEmitter<AreaSelectorEvent>();
  }

  ngOnInit(): void {
    this.fetchAreasList();
  }

  fetchAreasList(): void {
    this.isLoading = true;
    this.subscription.add(
      this.areasService.getAreasList().subscribe(
        (response) => {
          this.areasList = response.data;
        },
        () => observableOf([]),
        () => {
          this.isLoading = false;
        }
      )
    );
  }

  getAreaList() {
    const areas = this.threshold.getAreas();
    // removing area ids which are already present in nested threshold tables
    return this.areasList.filter(
      (areasApiEntity) => !areas.has(areasApiEntity.areaId)
    );
  }

  selectArea() {
    this.selector.close();
    this.areaSelectorEmitter.emit({ area: this.selectedOption });
  }

  isTileBasedAllowed(resultType: ResultType) {
    return ThresholdEntity.isTileBasedAllowed(resultType);
  }
}
