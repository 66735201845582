import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { TestResultsModule } from '@modules/test-results/test-results.module';
import { SharedModule } from '@shared/shared.module';
import { MapViewerComponent } from '@modules/map-viewer/page/map-viewer/map-viewer.component';
import { MapViewerRoutingModule } from '@modules/map-viewer/map-viewer.routing';
import { MapViewerControlComponent } from '@modules/map-viewer/component/map-viewer-control/map-viewer-control.component';
import { MapViewerInfoComponent } from '@modules/map-viewer/component/map-viewer-info/map-viewer-info.component';
import { MapViewerResultsOverviewComponent } from './component/map-viewer-results-overview/map-viewer-results-overview.component';
import { MapViewerResultDetailsDialogComponent } from './component/map-viewer-result-details-dialog/map-viewer-result-details-dialog.component';
import { MapViewerUserSelectionComponent } from './component/map-viewer-user-selection/map-viewer-user-selection.component';
import { MapViewerSearchComponent } from './component/map-viewer-search/map-viewer-search.component';
import { MapViewerSelectCatalogComponent } from './component/map-viewer-select-catalog/map-viewer-select-catalog.component';
import { MapViewerSelectAreaComponent } from './component/map-viewer-select-area/map-viewer-select-area.component';
import { MapViewerLegendComponent } from './component/map-viewer-legend/map-viewer-legend.component';
import { MapViewerSelectVisualizationComponent } from './component/map-viewer-select-visualization/map-viewer-select-visualization.component';
import { MapViewerResultOutcomeFilterComponent } from './component/map-viewer-result-outcome-filter/map-viewer-result-outcome-filter.component';
import { MapViewerDeltaOutcomeFilterComponent } from './component/map-viewer-delta-outcome-filter/map-viewer-delta-outcome-filter.component';
import { MapViewerResultFilterComponent } from '@modules/map-viewer/component/map-viewer-result-filter/map-viewer-result-filter.component';
import { MapViewerSelectTestcaseComponent } from './component/map-viewer-select-testcase/map-viewer-select-testcase.component';
import { MapViewerTestResultFilterComponent } from './component/map-viewer-test-result-filter/map-viewer-test-result-filter.component';
import { SingleResultFormatContentDetailedComponent } from './component/map-viewer-results-overview/views/single-result-format-content-detailed/single-result-format-content-detailed.component';
import { SingleResultStatisticComponent } from './component/map-viewer-results-overview/views/single-result-statistic/single-result-statistic.component';
import { SingleResultFormatContentComponent } from './component/map-viewer-results-overview/views/single-result-format-content/single-result-format-content.component';
import { SingleResultCombinedDetailedStatisticComponent } from './component/map-viewer-results-overview/views/single-result-combined-detailed-statistic/single-result-combined-detailed-statistic.component';
import { DeltaResultStatisticComponent } from './component/map-viewer-results-overview/views/delta-result-statistic/delta-result-statistic.component';
import { DeltaResultFormatContentComponent } from './component/map-viewer-results-overview/views/delta-result-format-content/delta-result-format-content.component';
import { DeltaResultCombinedDetailedStatisticComponent } from './component/map-viewer-results-overview/views/delta-result-combined-detailed-statistic/delta-result-combined-detailed-statistic.component';
import { DeltaResultFormatContentDetailedComponent } from './component/map-viewer-results-overview/views/delta-result-format-content-detailed/delta-result-format-content-detailed.component';
import { DeltaResultStatisticDetailedComponent } from './component/map-viewer-results-overview/views/delta-result-statistic-detailed/delta-result-statistic-detailed.component';
import { SingleResultStatisticDetailedComponent } from './component/map-viewer-results-overview/views/single-result-statistic-detailed/single-result-statistic-detailed.component';
import { MapViewerSelectLayerComponent } from './component/map-viewer-select-layer/map-viewer-select-layer.component';
import { MapViewerFailCountFilterComponent } from './component/map-viewer-fail-count-filter/map-viewer-fail-count-filter.component';
import { SingleResultMapApprovalComponent } from '@modules/map-viewer/component/map-viewer-results-overview/views/single-result-map-approval/single-result-map-approval.component';
import { DeltaResultMapApprovalComponent } from './component/map-viewer-results-overview/views/delta-result-map-approval/delta-result-map-approval.component';
import { MapViewerMarkerPopupComponent } from './component/map-viewer-marker-popup/map-viewer-marker-popup.component';
import { MapViewerSelectResultVisualizationComponent } from './component/map-viewer-select-catalog/views/map-viewer-select-result-visualization/map-viewer-select-result-visualization.component';
import { MapViewerSelectDeltaVisualizationComponent } from './component/map-viewer-select-catalog/views/map-viewer-select-delta-visualization/map-viewer-select-delta-visualization.component';
import { MapViewerSelectDataVisualizationComponent } from './component/map-viewer-select-catalog/views/map-viewer-select-data-visualization/map-viewer-select-data-visualization.component';

@NgModule({
  declarations: [
    MapViewerComponent,
    MapViewerControlComponent,
    MapViewerInfoComponent,
    MapViewerResultsOverviewComponent,
    MapViewerResultDetailsDialogComponent,
    MapViewerUserSelectionComponent,
    MapViewerSearchComponent,
    MapViewerSelectCatalogComponent,
    MapViewerSelectAreaComponent,
    MapViewerLegendComponent,
    MapViewerSelectVisualizationComponent,
    MapViewerResultOutcomeFilterComponent,
    MapViewerDeltaOutcomeFilterComponent,
    MapViewerResultFilterComponent,
    MapViewerSelectTestcaseComponent,
    MapViewerTestResultFilterComponent,
    SingleResultFormatContentDetailedComponent,
    SingleResultStatisticComponent,
    SingleResultFormatContentComponent,
    SingleResultMapApprovalComponent,
    SingleResultCombinedDetailedStatisticComponent,
    DeltaResultStatisticComponent,
    DeltaResultFormatContentComponent,
    DeltaResultCombinedDetailedStatisticComponent,
    DeltaResultFormatContentDetailedComponent,
    DeltaResultStatisticDetailedComponent,
    SingleResultStatisticDetailedComponent,
    MapViewerFailCountFilterComponent,
    SingleResultStatisticDetailedComponent,
    MapViewerSelectLayerComponent,
    DeltaResultMapApprovalComponent,
    MapViewerMarkerPopupComponent,
    MapViewerSelectResultVisualizationComponent,
    MapViewerSelectDeltaVisualizationComponent,
    MapViewerSelectDataVisualizationComponent
  ],
  exports: [MapViewerSelectTestcaseComponent],
  imports: [
    SharedModule,
    MapViewerRoutingModule,
    TestResultsModule,
    ClipboardModule,
    MatRippleModule
  ]
})
export class MapViewerModule {}
