import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthGuard {
  constructor(
    private keycloakService: KeycloakService,
    private router: Router
  ) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.keycloakService.isLoggedIn()) {
      console.log('Login successful...');
      return true;
    }
    // navigate to login page
    if (state.url !== '/') {
      this.router.navigate(['/'], {
        queryParams: { returnUrl: state.url }
      });
    } else {
      this.router.navigate(['/']);
    }

    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
