import { FormControl } from '@angular/forms';

export class CronPattern {
  static defaultCronPattern = '0 * * * * *';

  constructor(
    public seconds: number = 0,
    public minutes: string = '*',
    public hours: string = '*',
    public dayOfMonth: string = '*',
    public month: string = '*',
    public dayOfWeek: string = '*'
  ) {}

  isValid() {
    return (
      isFinite(this.seconds) &&
      this.minutes?.length &&
      this.hours?.length &&
      this.dayOfMonth?.length &&
      this.month?.length &&
      this.dayOfWeek?.length
    );
  }

  toString() {
    return [
      this.seconds,
      this.minutes,
      this.hours,
      this.dayOfMonth,
      this.month,
      this.dayOfWeek
    ].join(' ');
  }

  static fromString(value): CronPattern {
    const [
      seconds = '0',
      minutes = '*',
      hours = '*',
      dayOfMonth = '*',
      month = '*',
      dayOfWeek = '*'
    ] = (value || CronPattern.defaultCronPattern).split(' ') || [];
    return new CronPattern(0, minutes, hours, dayOfMonth, month, dayOfWeek);
  }
}

export type CronPatternForm = {
  seconds: FormControl<number>;
  minutes: FormControl<string>;
  hours: FormControl<string>;
  dayOfMonth: FormControl<string>;
  month: FormControl<string>;
  dayOfWeek: FormControl<string>;
};
