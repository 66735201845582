<div class="kf-threshold-value-actions">
  <button mat-raised-button color="primary" (click)="editThreshold()" [disabled]="isEditable"><mat-icon>edit</mat-icon> Edit</button>
  <button mat-raised-button color="warn" (click)="deleteThreshold()"><mat-icon>delete</mat-icon> Delete</button>
</div>

<mat-tab-group animationDuration="0ms">
  <mat-tab label="by Absolute">
    <table class="kf-threshold-table kf-threshold-table-delta" aria-label="Table of threshold values">
      <thead>
        <tr>
          <th scope="row"></th>
          <th scope="row" colspan="2">All Attribution Status</th>
          <th scope="row" colspan="2">Fully Attributed</th>
          <th scope="row" colspan="2">Partly Attributed</th>
          <th scope="row" colspan="2">Not Field Surveyed</th>
        </tr>
        <tr>
          <th scope="row"></th>
          <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
          <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
        </tr>
      </thead>
      <tbody *ngIf="isEditable">
      <tr>
        <th scope="col">ALL FRC</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcAbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcAbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcAbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcAbsMinus')"></td>
      </tr>
      <tr>
        <th scope="col">FRC0</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0AbsMinus')"></td>
      </tr>
      <tr>
        <th scope="col">FRC1</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1AbsMinus')"></td>
      </tr>
      <tr>
        <th scope="col">FRC2</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2AbsMinus')"></td>
      </tr>
      <tr>
        <th scope="col">FRC3</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3AbsMinus')"></td>
      </tr>
      <tr>
        <th scope="col">FRC4</th>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsMinus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4AbsPlus')"></td>
        <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4AbsMinus')"></td>
      </tr>
      </tbody>
      <tbody *ngIf="!isEditable">
      <tr>
        <th scope="col">ALL FRC</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcAbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcAbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcAbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcAbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcAbsMinus').value}}</td>
      </tr>
      <tr>
        <th scope="col">FRC0</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0AbsMinus').value}}</td>
      </tr>
      <tr>
        <th scope="col">FRC1</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1AbsMinus').value}}</td>
      </tr>
      <tr>
        <th scope="col">FRC2</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2AbsMinus').value}}</td>
      </tr>
      <tr>
        <th scope="col">FRC3</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3AbsMinus').value}}</td>
      </tr>
      <tr>
        <th scope="col">FRC4</th>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4AbsMinus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4AbsPlus').value}}</td>
        <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4AbsMinus').value}}</td>
      </tr>
      </tbody>
    </table>
  </mat-tab>
  <mat-tab label="by Relative">
    <table class="kf-threshold-table kf-threshold-table-delta" aria-label="Table of threshold values">
      <thead>
      <tr>
        <th scope="row"></th>
        <th scope="row" colspan="2">All Attribution Status</th>
        <th scope="row" colspan="2">Fully Attributed</th>
        <th scope="row" colspan="2">Partly Attributed</th>
        <th scope="row" colspan="2">Not Field Surveyed</th>
      </tr>
      <tr>
        <th scope="row"></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>add_icon</mat-icon></th>
        <th scope="row"><mat-icon matPrefix>remove</mat-icon></th>
      </tr>
      </thead>
      <tbody *ngIf="isEditable">
        <tr>
          <th scope="col">ALL FRC</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcRelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcRelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcRelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcRelMinus')"></td>
        </tr>
        <tr>
          <th scope="col">FRC0</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0RelMinus')"></td>
        </tr>
        <tr>
          <th scope="col">FRC1</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1RelMinus')"></td>
        </tr>
        <tr>
          <th scope="col">FRC2</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2RelMinus')"></td>
        </tr>
        <tr>
          <th scope="col">FRC3</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3RelMinus')"></td>
        </tr>
        <tr>
          <th scope="col">FRC4</th>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4RelMinus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4RelPlus')"></td>
          <td><input matInput type="number" min="0" [errorStateMatcher]="matcher" step="1" [formControl]="getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4RelMinus')"></td>
        </tr>
      </tbody>
      <tbody *ngIf="!isEditable">
        <tr>
          <th scope="col">ALL FRC</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcRelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaAllFrcRelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaAllFrcRelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcRelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaAllFrcRelMinus').value}}</td>
        </tr>
        <tr>
          <th scope="col">FRC0</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc0RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc0RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc0RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc0RelMinus').value}}</td>
        </tr>
        <tr>
          <th scope="col">FRC1</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc1RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc1RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc1RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc1RelMinus').value}}</td>
        </tr>
        <tr>
          <th scope="col">FRC2</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc2RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc2RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc2RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc2RelMinus').value}}</td>
        </tr>
        <tr>
          <th scope="col">FRC3</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc3RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc3RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc3RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc3RelMinus').value}}</td>
        </tr>
        <tr>
          <th scope="col">FRC4</th>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.ALL, 'thresholdDeltaFrc4RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.FULLY_ATTRIBUTED, 'thresholdDeltaFrc4RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.PARTLY_ATTRIBUTED, 'thresholdDeltaFrc4RelMinus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4RelPlus').value}}</td>
          <td>{{getFormControl(AttributionStatus.NOT_FIELD_SURVEYED, 'thresholdDeltaFrc4RelMinus').value}}</td>
        </tr>
      </tbody>
    </table>
  </mat-tab>
</mat-tab-group>
