import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestcaseListingComponent } from '@modules/test-execution/page/testcase-listing/testcase-listing.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthGuard } from '@shared/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('@modules/security/security.module').then((m) => m.SecurityModule),
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Kingfisher'
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'testcase-list',
        component: TestcaseListingComponent,
        data: {
          title: 'Test Cases'
        }
      },
      {
        path: 'areas',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/areas/areas.module').then((m) => m.AreasModule),
        data: {
          title: 'Areas'
        }
      },
      {
        path: 'test-results',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/test-results/test-results.module').then(
            (m) => m.TestResultsModule
          ),
        data: {
          title: 'Test Results'
        }
      },
      {
        path: 'health',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/health/health.module').then((m) => m.HealthModule),
        data: {
          title: 'Health'
        }
      },
      {
        path: 'tests',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@app/modules/test-execution/tests.module').then(
            (m) => m.TestsModule
          ),
        data: {
          title: 'Test Execution '
        }
      },
      {
        path: 'status',
        loadChildren: () =>
          import('@modules/status/status.module').then((m) => m.StatushModule),
        data: {
          title: 'Status'
        }
      },
      {
        path: 'localization-test',
        loadChildren: () =>
          import('@modules/localization-test/localization-test.module').then(
            (m) => m.LocalizationTestModule
          ),
        data: {
          title: 'Localization Test'
        }
      },
      {
        path: 'map-viewer',
        loadChildren: () =>
          import('@modules/map-viewer/map-viewer.module').then(
            (m) => m.MapViewerModule
          ),
        data: {
          title: 'Map Viewer'
        }
      },
      {
        path: 'thresholds',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@modules/thresholds/thresholds.module').then(
            (m) => m.ThresholdsModule
          ),
        data: {
          title: 'Thresholds'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
