import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseCollection } from '@shared/model/response';
import { TestCaseApiEntity } from '@shared/model/productserver';
import { environment } from '@env';
import { SettingsService } from '@shared/service/settings.service';
import { map, shareReplay } from 'rxjs/operators';

const CACHE_SIZE = 1;

@Injectable()
export class TestCasesService {
  public testcaseUrl: string;

  private cacheCurrent$: Observable<TestCaseApiEntity[]>;
  private testCase: TestCaseApiEntity;

  constructor(
    private httpClient: HttpClient,
    public settingsService: SettingsService
  ) {
    this.testcaseUrl = settingsService.getProductServerUrl(
      environment.productServerTestcaseUrl
    );
  }

  get testCases(): Observable<TestCaseApiEntity[]> {
    if (!this.cacheCurrent$) {
      this.cacheCurrent$ = this.requestTestCases().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheCurrent$;
  }

  public getTestCaseById(testCaseId: string): Observable<TestCaseApiEntity> {
    if (!this.cacheCurrent$) {
      this.cacheCurrent$ = this.requestTestCases().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cacheCurrent$.pipe(
      map((testCaseArray: TestCaseApiEntity[]) =>
        testCaseArray.find(
          (testCaseApiEntity) => testCaseApiEntity.id === testCaseId
        )
      )
    );
  }

  private requestTestCases(): Observable<TestCaseApiEntity[]> {
    return this.httpClient
      .get<ResponseCollection<TestCaseApiEntity>>(this.testcaseUrl)
      .pipe(
        map((response) => {
          const currentTestCases = new Map<string, TestCaseApiEntity>();
          for (const testCase of response.data) {
            const testCaseId = testCase.id;
            if (
              !currentTestCases.has(testCaseId) ||
              currentTestCases.get(testCaseId).version < testCase.version
            ) {
              currentTestCases.set(testCaseId, testCase);
            }
          }
          return Array.from(currentTestCases.values());
        })
      );
  }
}
