<mat-nav-list>
  <app-sidebar-item *ngFor="let item of navigation" [item]="item" (sidenavClose)="emitSidenavClose()"
                    [hideSidebarTitles]="hideSidebarTitles"></app-sidebar-item>
</mat-nav-list>

<span class="sidebar-spacer"></span>
<div class="sidebar-toggle-titles">
  <mat-slide-toggle [checked]="hideSidebarTitles" (change)="changeHideSidebarTitles($event)"
                    aria-label="Toggle menu titles" title="Toggle menu titles" color="primary">
    <span *ngIf="!hideSidebarTitles">Toggle menu titles</span>
  </mat-slide-toggle>
</div>
