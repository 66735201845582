import { Component, OnDestroy, OnInit } from '@angular/core';
import { HealthService } from '@app/modules/health/service/health.service';
import { ActuatorInfoResponse, BuildInfo } from '@app/shared/model/actuator';
import { ProductServerService } from '@app/shared/service/product-server.service';
import { Subscription } from 'rxjs';
import packageInfo from '../../../../../../package.json';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy {
  public systemInfo: ActuatorInfoResponse<BuildInfo>;
  public frontendVersion: string;

  startScenarioNodeStatus = '';

  private subscription: Subscription = new Subscription();

  constructor(
    private productServerService: ProductServerService,
    private healthService: HealthService
  ) {
    this.frontendVersion = packageInfo.version;
  }

  ngOnInit(): void {
    this.getSystemInfo();
    this.startScenarioNodeHealthStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getSystemInfo(): void {
    this.subscription.add(
      this.productServerService.getSystemInfo().subscribe(
        (result) => {
          this.systemInfo = result;
        },
        (err) => {
          this.systemInfo = null;
          console.error(err);
          return new Error(err);
        }
      )
    );
  }

  public startScenarioNodeHealthStatus(): void {
    this.startScenarioNodeStatus = 'Unknown';
    this.subscription.add(
      this.healthService.getStartScenarioNodeStatus().subscribe(
        (response) => {
          this.startScenarioNodeStatus = response.status;
        },
        (err) => {
          this.startScenarioNodeStatus = 'Down';
          console.error(err);
          return new Error(err);
        }
      )
    );
  }
}
