import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Router } from '@angular/router';
import { NavigationNode } from '@shared/model/navigation';
import { AuthService } from '@shared/service/auth.service';
import { NavigationService } from '@shared/service/navigation.service';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Output() sidenavClose = new EventEmitter();

  readonly COOKIE_SIDEBAR_HIDE_TITLES = 'kf-sidebar-hide-titles';
  hideSidebarTitles = false;

  constructor(
    private $authService: AuthService,
    private $router: Router,
    private $navigationService: NavigationService,
    private $cookieService: CookieService
  ) {
    this.hideSidebarTitles = this.getCookieValue();
  }

  get navigation(): NavigationNode[] {
    return this.$navigationService.filterNavigation(
      this.$navigationService.menuItems,
      ''
    );
  }

  @HostListener('window:resize', [])
  onResize() {
    this.emitSidenavClose();
  }

  public emitSidenavClose = () => {
    this.sidenavClose.emit();
  };

  changeHideSidebarTitles(event: MatSlideToggleChange) {
    const { checked } = event;
    if (checked === true) {
      this.setHideMenuTitles();
    } else {
      this.setShowMenuTitles();
    }
    this.hideSidebarTitles = this.getCookieValue();
  }

  public setHideMenuTitles(): void {
    this.setCookieValue(true);
  }

  public setShowMenuTitles(): void {
    this.setCookieValue(false);
  }

  setCookieValue(value: boolean): void {
    this.$cookieService.set(this.COOKIE_SIDEBAR_HIDE_TITLES, String(value), {
      path: '/',
      secure: true,
      sameSite: 'Lax',
      expires: moment().add(7, 'days').toDate()
    });
  }

  private getCookieValue(): boolean {
    return (
      this.$cookieService.get(this.COOKIE_SIDEBAR_HIDE_TITLES) === String(true)
    );
  }
}
