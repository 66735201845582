import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ThresholdFormEmitEvent,
  ThresholdFormEvent,
  ThresholdFormEventService
} from '@modules/thresholds/service/threshold-form-event.service';
import { AttributionStatus, ControlledAccess } from '@shared/model/datastore';
import {
  ThresholdDetailedDefinitionIdentity,
  ThresholdId
} from '@shared/model/thresholds';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';

@Component({ template: '' })
export abstract class AbstractThresholdDetailedDefinitionComponent
  implements OnInit
{
  @Input() isEditable = false;
  @Input() thresholdValuesFormArray: FormArray;
  AttributionStatus = AttributionStatus;
  thresholdValueForm: FormGroup;
  dialogWidth = '500px';
  confirmWidth = '300px';

  private $_thresholdId: ThresholdId;
  private $_thresholdValues: Map<
    AttributionStatus,
    ThresholdDetailedDefinitionIdentity
  >;

  protected constructor(
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected thresholdFormEventService: ThresholdFormEventService
  ) {
    this.initForm();
  }

  get thresholdValues(): Map<
    AttributionStatus,
    ThresholdDetailedDefinitionIdentity
  > {
    return this.$_thresholdValues;
  }

  @Input() set thresholdValues(thresholdValues: any) {
    if (thresholdValues === null || thresholdValues === undefined) {
      this.$_thresholdValues = null;
    } else {
      this.$_thresholdValues = thresholdValues;
    }
  }

  @Input()
  set thresholdId(thresholdId: ThresholdId) {
    if (thresholdId === null || thresholdId === undefined) {
      this.$_thresholdId = null;
    } else {
      this.$_thresholdId = thresholdId;
    }
  }

  ngOnInit(): void {
    this.populateForm();
    this.thresholdValuesFormArray.push(
      this.thresholdValueForm.get(AttributionStatus.ALL)
    );
    this.thresholdValuesFormArray.push(
      this.thresholdValueForm.get(AttributionStatus.FULLY_ATTRIBUTED)
    );
    this.thresholdValuesFormArray.push(
      this.thresholdValueForm.get(AttributionStatus.PARTLY_ATTRIBUTED)
    );
    this.thresholdValuesFormArray.push(
      this.thresholdValueForm.get(AttributionStatus.NOT_FIELD_SURVEYED)
    );
  }

  getFormControl(
    attributionStatus: AttributionStatus,
    name: string
  ): FormControl {
    return (this.thresholdValueForm.get(attributionStatus) as FormGroup).get(
      name
    ) as FormControl;
  }

  editThreshold() {
    this.isEditable = true;
    this.thresholdFormEventService.emit(
      new ThresholdFormEmitEvent(ThresholdFormEvent.THRESHOLD_TRIGGER_EDIT, {
        key: this.$_thresholdId
      })
    );
  }

  deleteThreshold() {
    this.confirmDelete(this.thresholdValueForm);
  }

  protected buildForm(): FormGroup {
    return this.formBuilder.group({
      areaId: [''],
      controlledAccess: [''],
      attributionStatus: ['']
    });
  }

  protected initForm() {
    this.thresholdValueForm = this.formBuilder.group({
      [AttributionStatus.ALL]: this.buildForm(),
      [AttributionStatus.FULLY_ATTRIBUTED]: this.buildForm(),
      [AttributionStatus.PARTLY_ATTRIBUTED]: this.buildForm(),
      [AttributionStatus.NOT_FIELD_SURVEYED]: this.buildForm()
    });
  }

  protected populateForm(): void {
    if (!this.thresholdValues) {
      return;
    }
    // get if
    let thresholdValueId = {};
    for (const value of this.thresholdValues.values()) {
      if (value.id) {
        thresholdValueId = value.id;
      }
    }

    for (const attributionStatus of Object.values(AttributionStatus)) {
      const formGroup = this.thresholdValueForm.get(
        attributionStatus.valueOf()
      ) as FormGroup;
      formGroup.patchValue({
        ...thresholdValueId,
        attributionStatus,
        controlledAccess: ControlledAccess.ALL
      });
      if (this.thresholdValues.has(attributionStatus)) {
        formGroup.patchValue({
          ...this.thresholdValues.get(attributionStatus)
        });
      }
    }
  }

  private confirmDelete(
    thresholdValueForm: FormGroup
  ): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Clear Threshold`,
        message: `Are you sure to clear the Threshold entries in this table`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        thresholdValueForm.reset();
        this.thresholdFormEventService.emit(
          new ThresholdFormEmitEvent(
            ThresholdFormEvent.THRESHOLD_TRIGGER_DELETE,
            {
              key: this.$_thresholdId,
              thresholdValues: [...this.thresholdValues.values()]
            }
          )
        );
      }
    });
    return dialogRef;
  }
}
