import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerResultOutcomeService } from '@modules/map-viewer/service/map-viewer-result-outcome.service';
import {
  FailVisualization,
  ResultDeltaOutcome,
  ResultType
} from '@shared/model/datastore';
import { Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';

@Component({
  selector: 'app-map-viewer-delta-outcome-filter',
  templateUrl: './map-viewer-delta-outcome-filter.component.html',
  styleUrls: ['./map-viewer-delta-outcome-filter.component.scss']
})
export class MapViewerDeltaOutcomeFilterComponent implements OnInit, OnDestroy {
  private $type = MapViewerVisualizationMode.DELTA;
  private $isVisible = false;

  private $subscription: Subscription = new Subscription();
  private $failRateResultTypes: ResultType[] = [
    ResultType.FORMAT_CONTENT,
    ResultType.COMBINED_DETAILED_STATISTIC,
    ResultType.FORMAT_CONTENT_DETAILED,
    ResultType.MAP_APPROVAL
  ];

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService,
    private mapViewerResultOutcomeService: MapViewerResultOutcomeService
  ) {}

  get sliderMinValue(): number {
    return this.getDefaultMin();
  }

  get sliderMaxValue(): number {
    return this.getDefaultMax();
  }

  get visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService?.resultType;
  }

  get resultDeltaOutcomes(): Map<ResultDeltaOutcome, boolean> {
    return this.mapViewerUserSelectionService.resultDeltaOutcomes;
  }

  get resultDeltaOutcomeOptions(): ResultDeltaOutcome[] {
    return Object.keys(ResultDeltaOutcome) as ResultDeltaOutcome[];
  }

  get isVisible() {
    return this.$isVisible;
  }

  get rangeMinValue(): number {
    return isFinite(this.mapViewerUserSelectionService.failRateDeltaMinValue)
      ? this.mapViewerUserSelectionService.failRateDeltaMinValue
      : this.getDefaultMin();
  }

  get rangeMaxValue(): number {
    return isFinite(this.mapViewerUserSelectionService.failRateDeltaMaxValue)
      ? this.mapViewerUserSelectionService.failRateDeltaMaxValue
      : this.getDefaultMax();
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.SHOW_LEGEND, () => {
        this.$isVisible = this.visualizationMode === this.$type;
      })
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.HIDE_LEGEND, () => {
        this.$isVisible = false;
      })
    );
  }

  isResultDeltaOutcomeChecked(key: ResultDeltaOutcome) {
    return (
      this.resultDeltaOutcomes.has(key) && this.resultDeltaOutcomes.get(key)
    );
  }

  selectResultDeltaOutcomes($event: MatCheckboxChange) {
    const key = $event.source.value as ResultDeltaOutcome;
    const value = $event.checked || false;
    this.mapViewerUserSelectionService.setResultDeltaOutcome(key, value);
    this.emitSelectResultDeltaOutcomes();
  }

  emitSelectFailRateDeltaRange(): void {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SELECT_FAIL_RATE_DELTA_RANGE, {})
    );
  }

  emitSelectResultDeltaOutcomes() {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SELECT_RESULT_OUTCOMES, {})
    );
  }

  isCompleted() {
    if (this.isFilterRateDeltaSliderAvailable()) {
      return (
        Number.isFinite(
          this.mapViewerUserSelectionService.failRateDeltaMinValue
        ) &&
        Number.isFinite(
          this.mapViewerUserSelectionService.failRateDeltaMaxValue
        )
      );
    }
  }

  isFilterRateDeltaSliderAvailable() {
    return this.$failRateResultTypes.includes(this.resultType);
  }

  isFailCount() {
    return (
      this.mapViewerUserSelectionService.failVisualization ===
      FailVisualization.FAIL_COUNT
    );
  }

  getOutcomeLabel(outcome: ResultDeltaOutcome): string {
    switch (outcome) {
      case ResultDeltaOutcome.INC:
        if (this.isFailRateResultType()) {
          return 'BETTER';
        } else {
          return 'MORE';
        }
      case ResultDeltaOutcome.DEC:
        if (this.isFailRateResultType()) {
          return 'WORSE';
        } else {
          return 'LESS';
        }
    }
    return String(outcome);
  }

  formatRangeLabel(value: number): string {
    return `${value}`;
  }

  setRangeMaxValue(value: number) {
    this.mapViewerUserSelectionService.failRateDeltaMaxValue = value;
    this.emitSelectFailRateDeltaRange();
  }

  setRangeMinValue(value: number) {
    this.mapViewerUserSelectionService.failRateDeltaMinValue = value;
    this.emitSelectFailRateDeltaRange();
  }

  private isFailRateResultType(): boolean {
    return this.$failRateResultTypes.includes(this.resultType);
  }

  private getDefaultMin(): number {
    const { failVisualization } = this.mapViewerUserSelectionService;
    if (failVisualization === FailVisualization.FAIL_COUNT) {
      const [min] = this.mapViewerResultOutcomeService.getValueDeltaDomain();
      return min;
    } else {
      return -100;
    }
  }

  private getDefaultMax(): number {
    const { failVisualization } = this.mapViewerUserSelectionService;
    if (failVisualization === FailVisualization.FAIL_COUNT) {
      const [, , max] =
        this.mapViewerResultOutcomeService.getValueDeltaDomain();
      return max;
    } else {
      return 100;
    }
  }
}
