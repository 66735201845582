import { Component } from '@angular/core';
import { ResultComponent } from '@modules/map-viewer/component/map-viewer-results-overview/views/common/result.component';
import { ResultOutcome } from '@shared/model/datastore';

@Component({ template: '' })
export abstract class CombinedDetailedStatisticResultComponent extends ResultComponent {
  get failCount(): number {
    if (this.resultOutcome === ResultOutcome.FAILURE) {
      return null;
    }
    if (this.resultOutcome === ResultOutcome.NOT_EXECUTED) {
      return null;
    }
    const {
      failCount1 = 0,
      failCount2 = 0,
      failCount3 = 0,
      failCount4 = 0,
      failCount5 = 0,
      failCount6 = 0,
      failCount7 = 0,
      failCount8 = 0,
      failCount9 = 0,
      failCount10 = 0,
      failCountMoreThan10 = 0
    } = this.resultDetails || {};
    return (
      failCount1 +
      failCount2 +
      failCount3 +
      failCount4 +
      failCount5 +
      failCount6 +
      failCount7 +
      failCount8 +
      failCount9 +
      failCount10 +
      failCountMoreThan10
    );
  }

  get comparisonFailCount(): number {
    if (this.comparisonResultOutcome === ResultOutcome.FAILURE) {
      return null;
    }
    if (this.comparisonResultOutcome === ResultOutcome.NOT_EXECUTED) {
      return null;
    }
    const {
      failCount1 = 0,
      failCount2 = 0,
      failCount3 = 0,
      failCount4 = 0,
      failCount5 = 0,
      failCount6 = 0,
      failCount7 = 0,
      failCount8 = 0,
      failCount9 = 0,
      failCount10 = 0,
      failCountMoreThan10 = 0
    } = this.comparisonResultDetails || {};
    return (
      failCount1 +
      failCount2 +
      failCount3 +
      failCount4 +
      failCount5 +
      failCount6 +
      failCount7 +
      failCount8 +
      failCount9 +
      failCount10 +
      failCountMoreThan10
    );
  }
}
