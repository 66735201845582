<ng-container>
  <h3 mat-dialog-title>Result Details</h3>
</ng-container>
<ng-template #elseCreateHeader>
  <h3 mat-dialog-title>Create Area</h3>
</ng-template>

<div mat-dialog-content>
  <app-testcase-details [resultDataApiEntity]="resultDataApiEntity"></app-testcase-details>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Close</button>
</div>


