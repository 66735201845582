<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>

<!--DropDown-->
<div class="kf-form-row kf-layout-gap-1">
  <div class="kf-form-col-50">
    <mat-form-field appearance="fill" floatLabel="always" id="detailsCatalogNameDropDown">
      <mat-label>Catalog Name</mat-label>
      <span matSuffix [class.spinner]="isLoadingFilters"></span>
      <input matInput *ngIf="isLoadingFilters" />
      <mat-select *ngIf="!isLoadingFilters" #selectedCatalogName (selectionChange)="handleFilterCatalogNameChange()"
                  [(ngModel)]="filter.catalogName"
                  required>
        <mat-option>--</mat-option>
        <mat-optgroup *ngFor="let catalogGroup of catalogs | keyvalue:asIsOrder" [label]="catalogGroup.key">
          <mat-option *ngFor="let catalog of catalogGroup.value" [value]="catalog.name">{{catalog.name}}</mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="kf-form-col-50">
    <mat-form-field appearance="fill" floatLabel="always" id="detailsCatalogVersionDropDown">
      <mat-label>Catalog Version</mat-label>
      <input type="number"
             placeholder="eg. 123"
             matInput
             (change)="setFilterCatalogVersion()"
             [formControl]="catalogVersionsFormControl"
             [matAutocomplete]="autoCatalogVersion">
      <mat-autocomplete #autoCatalogVersion="matAutocomplete">
        <mat-option *ngFor="let option of autoCompleteCatalogVersions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
<!--DropDown  End-->

<!--Optional Filter Start-->
<div class="kf-form-row kf-layout-gap-1">
  <div class="kf-form-col-50">
    <!--TestCaseIds -->

    <mat-form-field color="accent" appearance="fill" floatLabel="always">
      <mat-label>TestCaseId</mat-label>
      <input [(ngModel)]="filter.testCaseIds" matInput placeholder=" Example: 01.01.01.01, 01.01.03.01"
             type="text" id="detailsTestCaseIdInput"/>
      <button *ngIf="filter.testCaseIds" matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
              (click)="filter.testCaseIds=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div class="mat-form-field-wrapper">
      <mat-radio-group
        [(ngModel)]="activeTileFilter"
        (change)="toggleTileFilter($event)"
      >
        <mat-radio-button value="areas">AreaFilter</mat-radio-button>
        <mat-radio-button value="tileIds">TileIdFilter</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Areas DropDown -->
    <mat-form-field *ngIf="activeTileFilter === 'areas'" appearance="fill" floatLabel="always" id="areasId">
      <mat-label>Areas</mat-label>
      <span matSuffix [class.spinner]="isLoadingFilters"></span>
      <input matInput *ngIf="isLoadingFilters" />
      <mat-select *ngIf="!isLoadingFilters" id="selectedArea" [(ngModel)]="filter.areaIds" multiple>
        <mat-option *ngFor="let area of areasList" [value]="area.areaId">{{area.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- TileIds  -->
    <mat-form-field *ngIf="activeTileFilter === 'tileIds'" color="accent" appearance="fill" floatLabel="always">
      <mat-label>TileId</mat-label>
      <input id="tileIdArea" [(ngModel)]="filter.tileIds" matInput placeholder="Example: 545556683, 545556676"
             type="text"/>
      <button *ngIf="filter.tileIds" matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
              (click)="filter.tileIds=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

  </div>
  <div class="kf-form-col-50">
    <!-- Result Outcome  -->
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Result Outcome</mat-label>
      <mat-select id="resultOutcomes" [(ngModel)]="filter.resultOutcomes" multiple>
        <mat-option *ngFor="let resultOutcomeOption of resultOutcomeOptions"
                    [value]="resultOutcomeOption">{{resultOutcomeOption}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</div>
<div class="button-row">
  <!--Submit Button Start-->
  <button (click)="submitUpdateFilter()" mat-raised-button color="primary" aria-label="Update Filter"
          matTooltip="Update Filter" id="detailFilter">
    <mat-icon aria-hidden="false">search</mat-icon>
    <span>Filter</span>
  </button>
  <!--Submit Button End-->
  <!--Reset Button Start-->
  <button (click)="submitResetFilter()" mat-raised-button aria-label="Reset Filter" matTooltip="Reset Filter"
          id="detailReset">
    <mat-icon aria-hidden="false">undo</mat-icon>
    <span>Reset</span>
  </button>
  <!--Reset Button End-->
</div>
<!--Optional Filter End-->

<div class="table-container">
  <!--Table-->
  <table [dataSource]="data" aria-describedby="tableDescription" mat-table multiTemplateDataRows>
    <colgroup>
      <col span="1" style="width:5%"/>
      <col span="1" style="width:15%"/>
      <col span="1" style="width:10%"/>
      <col span="1" style="width:5%"/>
      <col span="1" style="width:30%"/>
      <col span="1" style="width:5%"/>
      <col span="1" style="width:10%"/>
      <col span="1" style="width:10%"/>
      <col span="1" style="width:10%"/>
    </colgroup>
    <ng-container matColumnDef="timestamp">
      <th *matHeaderCellDef mat-header-cell scope="col">Timestamp</th>
      <td *matCellDef="let element" mat-cell>
        {{element.timestamp | date: 'dd/MM/yyyy HH:mm:ss z'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="testCaseId">
      <th *matHeaderCellDef mat-header-cell scope="col">Testcase</th>
      <td *matCellDef="let element" mat-cell>
        <span matTooltip="{{element.testCaseId|testCaseDetails:'name'}}: {{element.testCaseId|testCaseDetails:'description'}}">{{element.testCaseId}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="testCaseVersion">
      <th *matHeaderCellDef mat-header-cell scope="col">Testcase Version</th>
      <td *matCellDef="let element" mat-cell>
        {{element.testCaseVersion}}
      </td>
    </ng-container>

    <ng-container matColumnDef="catalogName">
      <th *matHeaderCellDef mat-header-cell scope="col">Catalog</th>
      <td *matCellDef="let element" mat-cell>
        {{element.catalogName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="catalogVersion">
      <th *matHeaderCellDef mat-header-cell scope="col">Catalog Version</th>
      <td *matCellDef="let element" mat-cell>
        {{element.catalogVersion}}
      </td>
    </ng-container>

    <ng-container matColumnDef="tileId">
      <th *matHeaderCellDef mat-header-cell scope="col">Tile</th>
      <td *matCellDef="let element" mat-cell>
        {{element.tileId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="resultOutcome">
      <th *matHeaderCellDef mat-header-cell scope="col">Result</th>
      <td *matCellDef="let element" mat-cell>
        {{element.resultOutcome}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
      <td *matCellDef="let element" mat-cell class="testcase-actions">
        <button *ngIf="hasResultDetails(element)" (click)="downloadResultDetails(element)"
          aria-label="Download JSON" matTooltip="Download JSON" color="primary" mat-mini-fab>
          <mat-icon aria-hidden="false">file_download</mat-icon>
        </button>
        <a
          [routerLink]="['/map-viewer']"
          [queryParams]="prepareUrl(element)"
          aria-label="Jump to Test Result Map"
          matTooltip="Jump to Test Result Map"
          color="primary"
          mat-mini-fab
        >
          <mat-icon aria-hidden="false">location_on</mat-icon>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandDetails">
      <th *matHeaderCellDef mat-header-cell scope="col"> </th>
      <td *matCellDef="let element" mat-cell>
        <button mat-icon-button (click)="element.isExpanded = !element.isExpanded" aria-label="Show Details" matTooltip="Show Details">
          <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
        <div [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'" class="details-table-wrapper">
          <app-testcase-details [resultDataApiEntity]="element"></app-testcase-details>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let element; columns: displayedColumns;" [class.details-table-expanded]="element.isExpanded"
        class="element-row element-row-expandable" mat-row></tr>
    <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
    <caption id="tableDescription">List of available test results</caption>
  </table>
  <!--Table end-->

  <!--pagination start-->
  <app-paginator (page)="handlePageEvent($event)" [length]="length" [pageIndex]="pageIndex"
                 [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [showFirstLastButtons]=true
                 [showJumpTo]=true></app-paginator>
  <!--pagination end-->
</div>
