import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import {
  AttributionStatus,
  ControlledAccess,
  FunctionalRoadClass,
  ResultType
} from '@shared/model/datastore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-map-viewer-select-testcase-detailed',
  templateUrl: './map-viewer-test-result-filter.component.html',
  styleUrls: ['./map-viewer-test-result-filter.component.scss']
})
export class MapViewerTestResultFilterComponent implements OnInit, OnDestroy {
  functionalRoadClassFormControl = new FormControl();
  attributionStatusFormControl = new FormControl();
  controlledAccessFormControl = new FormControl();

  private $subscription: Subscription = new Subscription();

  private $functionalRoadClassOptions = [
    FunctionalRoadClass.ALL,
    FunctionalRoadClass.FRC_0,
    FunctionalRoadClass.FRC_1,
    FunctionalRoadClass.FRC_2,
    FunctionalRoadClass.FRC_3,
    FunctionalRoadClass.FRC_4
  ];

  private $attributionStatusOptions = [
    AttributionStatus.ALL,
    AttributionStatus.PARTLY_ATTRIBUTED,
    AttributionStatus.FULLY_ATTRIBUTED,
    AttributionStatus.NOT_FIELD_SURVEYED
  ];

  private $controlledAccessOptions = [
    ControlledAccess.ALL,
    ControlledAccess.CONTROLLED_ACCESS,
    ControlledAccess.NOT_CONTROLLED_ACCESS
  ];

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get functionalRoadClass(): any {
    return this.mapViewerUserSelectionService.functionalRoadClass;
  }

  get attributionStatus(): any {
    return this.mapViewerUserSelectionService.attributionStatus;
  }

  get controlledAccess(): any {
    return this.mapViewerUserSelectionService.controlledAccess;
  }

  get isTestCaseDetailed(): boolean {
    return (
      this.mapViewerUserSelectionService.testCase &&
      [
        ResultType.FORMAT_CONTENT_DETAILED,
        ResultType.STATISTIC_DETAILED
      ].includes(this.mapViewerUserSelectionService.testCase.resultType)
    );
  }

  get isTestCaseDetailedAttributed(): boolean {
    return (
      this.isTestCaseDetailed &&
      this.mapViewerUserSelectionService.testCase.mainLayer === 'lane'
    );
  }

  get functionalRoadClassOptions(): FunctionalRoadClass[] {
    return this.$functionalRoadClassOptions;
  }

  get attributionStatusOptions(): AttributionStatus[] {
    return this.$attributionStatusOptions;
  }

  get controlledAccessOptions(): ControlledAccess[] {
    return this.$controlledAccessOptions;
  }

  ngOnInit(): void {
    this.registerEventListener();
    this.functionalRoadClassFormControl.setValue(this.functionalRoadClass);
    this.attributionStatusFormControl.setValue(this.attributionStatus);
    this.controlledAccessFormControl.setValue(this.controlledAccess);
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.RESET_VISUALIZATION_MODE, () => {
        this.resetSelection();
      })
    );
  }

  resetSelection() {
    this.mapViewerUserSelectionService.functionalRoadClass =
      FunctionalRoadClass.ALL;
    this.mapViewerUserSelectionService.attributionStatus =
      AttributionStatus.ALL;
    this.mapViewerUserSelectionService.controlledAccess = ControlledAccess.ALL;
    this.emitSelection();
  }

  emitSelection(): void {
    this.mapEventService.emit(
      new EmitEvent(MapEvent.SELECT_TEST_CASE_DETAILS, {})
    );
  }

  selectFunctionalRoadClass(value: FunctionalRoadClass): void {
    this.mapViewerUserSelectionService.functionalRoadClass = value;
    this.emitSelection();
  }

  selectAttributionStatus(value: AttributionStatus): void {
    this.mapViewerUserSelectionService.attributionStatus = value;
    this.emitSelection();
  }

  selectControlledAccess(value: ControlledAccess): void {
    this.mapViewerUserSelectionService.controlledAccess = value;
    this.emitSelection();
  }

  asIsOrder(_a, _b) {
    return 1;
  }
}
