import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Optional,
  Self
} from '@angular/core';
import { FormBuilder, NgControl } from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl
} from '@angular/material/form-field';
import { AreasService } from '@modules/areas/service/areas.service';
import { AbstractInputFormControlComponent } from '@shared/component/form-control/common/abstract-input-form-control/abstract-input-form-control.component';
import { AreasListApiEntity } from '@shared/model/productserver';
import { of as observableOf, Subscription } from 'rxjs';

@Component({
  selector: 'app-area-id-list-form-control',
  templateUrl: './area-id-list-form-control.component.html',
  styleUrls: ['./area-id-list-form-control.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: forwardRef(() => AreaIdListFormControlComponent),
      multi: true
    }
  ]
})
export class AreaIdListFormControlComponent extends AbstractInputFormControlComponent<
  number[]
> {
  static nextId = 0;

  parts = this._formBuilder.group({
    areaIds: this._formBuilder.control<number[]>([], { nonNullable: true })
  });

  controlType = 'area-id-list-input';
  @HostBinding() id: string = `${
    this.controlType
  }-${AreaIdListFormControlComponent.nextId++}`;

  private areasList$: Array<AreasListApiEntity> = [];
  private subscription: Subscription = new Subscription();

  get empty() {
    const {
      value: { areaIds }
    } = this.parts;
    return !areaIds.length;
  }

  @Input()
  get value(): number[] {
    if (this.parts.valid) {
      const {
        value: { areaIds }
      } = this.parts;
      return areaIds || [];
    }
    return null;
  }

  set value(value: number[]) {
    this.parts.setValue({ areaIds: value ?? [] });
    this.stateChanges.next();
  }

  constructor(
    private areasService: AreasService,
    _formBuilder: FormBuilder,
    _focusMonitor: FocusMonitor,
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(_formBuilder, _focusMonitor, _elementRef, _formField, ngControl);
    this.getAreaList();
  }

  get areasList(): Array<AreasListApiEntity> {
    return this.areasList$;
  }

  getAreaList(): void {
    this.subscription.add(
      this.areasService.getAreasList().subscribe(
        (response) => {
          this.areasList$ = response.data;
        },
        (err) => {
          console.error(err);
          return observableOf([]);
        }
      )
    );
  }
}
