import { Component, OnInit } from '@angular/core';
import { NavigationNode } from '@shared/model/navigation';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@shared/service/navigation.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  public title: string;
  public navigationNode: NavigationNode;

  constructor(
    public route: ActivatedRoute,
    private $navigationService: NavigationService
  ) {
    this.title = route.snapshot?.data?.title || '';
  }

  ngOnInit(): void {
    this.$navigationService.currentUrl.subscribe((url: string) => {
      this.navigationNode =
        this.$navigationService.getNavigationNodeByRoute(url);
    });
  }
}
