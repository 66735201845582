<div role="group" class="{{controlType}}-container"
     [formGroup]="parts"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <div class="form-group-row kf-form-row kf-layout-gap-05">
    <mat-form-field appearance="fill">
      <mat-label>Minutes</mat-label>
      <input
        matInput
        formControlName="minutes"
        (blur)="onTouched($event)"
      >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Hours</mat-label>
      <input
        matInput
        formControlName="hours"
        (blur)="onTouched($event)"
      >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Day of month</mat-label>
      <input
        matInput
        formControlName="dayOfMonth"
        (blur)="onTouched($event)"
      >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Day of week</mat-label>
      <input
        matInput
        formControlName="dayOfWeek"
        (blur)="onTouched($event)"
      >
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Month</mat-label>
      <input
        matInput
        formControlName="month"
        (blur)="onTouched($event)"
      >
    </mat-form-field>
  </div>
  <div *ngIf="parts.valid" class="cron-pattern-preview">
    <mat-form-field appearance="outline">
      <mat-label>Preview</mat-label>
      <textarea matInput readonly>{{getPreviewValue()|cronParse}}</textarea>
    </mat-form-field>
  </div>
  <div class="cron-pattern-hint" [class.mat-error]="!parts.valid">
    Please provide a cron pattern, e.g.:<br/>
    <ul>
      <li><span>"* * * * *"</span>: Every minute</li>
      <li><span>"0 23 ? * MON-FRI"</span>: At 11:00 PM, Monday through Friday</li>
      <li><span>"0 23 * * *"</span>: At 11:00 PM, every day</li>
      <li><span>"23 12 * * SUN#2"</span>: At 12:23 PM, on the second Sunday of the month</li>
      <li><span>"23 14 * * SUN#2"</span>: At 14:23, on the second Sunday of the month</li>
      <li><span>"* * * ? * 2-6/2"</span>: Every second, every 2 days of the week, Monday through Friday</li>
    </ul>
  </div>
</div>
