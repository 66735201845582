import { Injectable } from '@angular/core';
import { InvalidCoordinateException } from '../model/errors';

const bigInt = require('big-integer');

export class PointMortonCode {
  /**
   * Longitude and latitude values encoded in Morton Code
   */
  readonly mortonCode: bigint;

  /**
   * Constructor using only the morton code value.
   *
   * @param mortonCode coordinate in morton code.
   */
  constructor(mortonCode: bigint) {
    this.mortonCode = bigInt(mortonCode);
  }

  /**
   * Converts the Morton Code point to an NDS point
   *
   * @return Point in NDS format
   * @throws InvalidCoordinateException if the point could not be converted to NDS
   */
  public convertToPointNds(): PointNds {
    return CoordinateUtilsService.mortonCodeToNds(this.mortonCode);
  }
}

export class PointWgs84 {
  private static readonly MAX_ABSOLUTE_LONGITUDE: number = 180.0;
  private static readonly MAX_ABSOLUTE_LATITUDE: number = 90.0;

  readonly longitude: number;
  readonly latitude: number;

  // Constructors
  /**
   * Constructs a 2D point in WGS84 format.
   *
   * @param longitude value in WGS84
   * @param latitude value in WGS84
   * @throws InvalidCoordinateException if the latitude or the longitude value is not in the valid
   *         range of the WGS84 format
   */
  constructor(longitude: number, latitude: number) {
    PointWgs84.invalidLongitude(longitude);
    PointWgs84.invalidLatitude(latitude);
    this.longitude = longitude;
    this.latitude = latitude;
  }

  // checks
  /**
   * Method checks if the longitude value is in the specified range for the WGS84 format.
   *
   * @param longitude value in WGS84 format
   * @throws InvalidCoordinateException if the longitude is out of range
   */
  public static invalidLongitude(longitude: number): void {
    if (Math.abs(longitude) > PointWgs84.MAX_ABSOLUTE_LONGITUDE) {
      throw new InvalidCoordinateException(
        'The longitude value must be in range of +-180!'
      );
    }
  }

  /**
   * Method checks if the latitude value is in the specified range for the WGS84 format.
   *
   * @param latitude value in WGS84 format
   * @throws InvalidCoordinateException if the latitude is out of range in WGS84
   */
  public static invalidLatitude(latitude: number): void {
    if (Math.abs(latitude) > PointWgs84.MAX_ABSOLUTE_LATITUDE) {
      throw new InvalidCoordinateException(
        'The latitude value must be in range of +-90!'
      );
    }
  }

  // Methods
  /**
   * Converts the WGS84 point into an NDS point including the height value if it is defined.
   *
   * @return Point in NDS format
   * @throws InvalidCoordinateException if the point could not be converted to NDS
   */
  public convert2PointNds(): PointNds {
    return new PointNds(
      CoordinateUtilsService.wgs84ToNds(this.longitude),
      CoordinateUtilsService.wgs84ToNds(this.latitude)
    );
  }

  /**
   * Converts the WGS84 point into an Morton Code point including the height value if it is defined.
   *
   * @return Point in Morton Code
   * @throws InvalidCoordinateException if the point could not be converted to Morton Code
   */
  public convert2PointMortonCode(): PointMortonCode {
    return new PointMortonCode(CoordinateUtilsService.wgs84ToMortonCode(this));
  }
}

export class PointNds {
  /**
   * Longitude value NDS coordinate
   */
  readonly longitude: number;
  /**
   * Latitude value in NDS coordinate
   */
  readonly latitude: number;

  // Constructors
  /**
   *
   * @param longitude value in NDS format
   * @param latitude value in NDS format
   * @throws InvalidCoordinateException if the longitude or latitude value in not in the defined
   *         range in NDS
   */
  constructor(longitude: number, latitude: number) {
    PointNds.invalidNdsLongitude(longitude);
    PointNds.invalidNdsLatitude(latitude);
    this.longitude = longitude;
    this.latitude = latitude;
  }

  /**
   * Method checks if the longitude value is in the specified range for the NDS format.
   *
   * @param longitude in NDS
   * @throws InvalidCoordinateException if the longitude value is out of range in NDS
   */
  public static invalidNdsLongitude(longitude: number): void {
    if (longitude > 2147483647 && longitude < -2147483648) {
      throw new InvalidCoordinateException(
        `The longitude ${longitude} value in NDS must be between -2147483648 and 2147483647!`
      );
    }
  }

  /**
   * Method checks if the latitude value is in the specified range for the NDS format.
   *
   * @param latitude in NDS
   * @throws InvalidCoordinateException if the latitude value is out of range in NDS
   */
  public static invalidNdsLatitude(latitude: number): void {
    if (Math.abs(latitude) > 1073741824) {
      throw new InvalidCoordinateException(
        `The latitude ${latitude} value in NDS must be between +- 1073741824!`
      );
    }
  }

  /**
   * Converts the point into an WGS84 point.
   *
   * @return Point in WGS84 format
   * @throws InvalidCoordinateException if the calculated latitude or longitude value is not in the
   *         allowed range of WGS84
   */
  public convert2Wgs84(): PointWgs84 {
    return new PointWgs84(
      CoordinateUtilsService.ndsToWgs84(this.longitude),
      CoordinateUtilsService.ndsToWgs84(this.latitude)
    );
  }

  /**
   * Converts the point into an Morton Code point
   *
   * @return Point in Morton Code
   * @throws InvalidCoordinateException if the longitude or latitude value is not in the allowed
   *         range for NDS
   */
  public convert2PointMortonCode(): PointMortonCode {
    return new PointMortonCode(
      CoordinateUtilsService.ndsToMortonCode(this.longitude, this.latitude)
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class CoordinateUtilsService {
  static readonly bigInt0x7FFFFFFFFFFFFFFF: bigint = bigInt(
    '7FFFFFFFFFFFFFFF',
    16
  );
  static readonly bigInt0x00000000FFFFFFFF: bigint = bigInt(
    '00000000FFFFFFFF',
    16
  );
  static readonly bigInt0x0000FFFF0000FFFF: bigint = bigInt(
    '0000FFFF0000FFFF',
    16
  );
  static readonly bigInt0x00FF00FF00FF00FF: bigint = bigInt(
    '00FF00FF00FF00FF',
    16
  );
  static readonly bigInt0x0F0F0F0F0F0F0F0F: bigint = bigInt(
    '0F0F0F0F0F0F0F0F',
    16
  );
  static readonly bigInt0x3333333333333333: bigint = bigInt(
    '3333333333333333',
    16
  );
  static readonly bigInt0x5555555555555555: bigint = bigInt(
    '5555555555555555',
    16
  );

  /**
   * In NDS, a coordinate unit corresponds to 90/(2^30) degrees of longitude or latitude.
   */
  private static readonly COORDINATE_UNIT: number = 90 / Math.pow(2, 30);

  /**
   * Converts an WGS84 to an NDS coordinate.
   *
   * @param wgsCoordinate coordinate in WGS84
   * @return converted nds coordinate
   */
  public static wgs84ToNds(wgsCoordinate: number): number {
    return Math.floor(wgsCoordinate / CoordinateUtilsService.COORDINATE_UNIT);
  }

  /**
   * Converts an NDS to an WGS84 coordinate.
   *
   * @param ndsCoordinate coordinate in NDS
   * @return converted WGS84 coordinate
   */
  public static ndsToWgs84(ndsCoordinate: number): number {
    return ndsCoordinate * CoordinateUtilsService.COORDINATE_UNIT;
  }

  /**
   * Transforms NDS longitude and latitude coordinates to mortonCode.
   *
   * @param longitude coordinate in NDS
   * @param latitude coordinate in NDS
   * @return longitude and latitude encoded in Morton Code
   * @throws InvalidCoordinateException if the longitude or latitude value is not in the allowed
   *         range for NDS
   */
  public static ndsToMortonCode(longitude: number, latitude: number): bigint {
    PointNds.invalidNdsLongitude(longitude);
    PointNds.invalidNdsLatitude(latitude);
    let morton: bigint = bigInt(CoordinateUtilsService.part1By1(longitude)).or(
      bigInt(bigInt(CoordinateUtilsService.part1By1(latitude)).shiftLeft(1))
    );
    morton = bigInt(morton).and(
      CoordinateUtilsService.bigInt0x7FFFFFFFFFFFFFFF
    );
    return morton;
  }

  /**
   * Transforms an Morton Code to NDS longitude and latitude values.
   *
   * @param mortonCode longitude and latitude encoded in Morton Code
   * @return longitude and latitude values as an NDS point
   */
  public static mortonCodeToNds(mortonCode: bigint): PointNds {
    let latitude = bigInt(
      CoordinateUtilsService.unpart1by1(bigInt(mortonCode).shiftRight(1))
    );
    let longitude = bigInt(
      CoordinateUtilsService.unpart1by1(bigInt(mortonCode))
    );
    if (
      bigInt(longitude)
        .and(bigInt(1 << 31))
        .valueOf() !== 0
    ) {
      longitude = longitude << 32;
      longitude = longitude >> 32;
    }
    if (
      bigInt(latitude)
        .and(bigInt(1 << 30))
        .valueOf() !== 0
    ) {
      latitude = latitude << 33;
      latitude = latitude >> 33;
    }
    return new PointNds(longitude.valueOf(), latitude.valueOf());
  }

  /**
   * Converts WGS84 point to Morton Code.
   *
   * @param point in WGS84 format
   * @return longitude and latitude encoded in Morton Code
   * @throws InvalidCoordinateException if the calculated coordinates in NDS is not valid
   */
  public static wgs84ToMortonCode(point: PointWgs84): any {
    return CoordinateUtilsService.ndsToMortonCode(
      CoordinateUtilsService.wgs84ToNds(point.longitude),
      CoordinateUtilsService.wgs84ToNds(point.latitude)
    );
  }

  /**
   * Converts a Morton Code to an WGS84 point
   *
   * @param mortonCode longitude and latitude encoded in Morton Code
   * @return corresponding point in WGS84 format
   * @throws InvalidCoordinateException if the conversion failed
   */
  public static mortonCodeToWgs84(mortonCode: bigint): PointWgs84 {
    const pointNds: PointNds =
      CoordinateUtilsService.mortonCodeToNds(mortonCode);
    return new PointWgs84(
      CoordinateUtilsService.ndsToWgs84(pointNds.longitude),
      CoordinateUtilsService.ndsToWgs84(pointNds.latitude)
    );
  }

  /**
   * Method helping encode a longitude or latitude coordinate to Morton Code.
   *
   * Reused and adapted from [P1942-SHIELD]
   * https://carmeq-produkte.carmeq.vw.vwg/svn/P1942-SHIELD/trunk/build/shield/src/utils/ :
   *
   * Taken from
   * <http://code.activestate.com/recipes/577558-interleave-bits-aka-morton-ize-aka-z-order-curve/>
   * and <http://fgiesen.wordpress.com/2009/12/13/decoding-morton-codes/> and adjusted to 64 bit and
   * the specific setting of # the NDS Morton code as follows: - The most significant bit (nr. 64)
   * must always be set to 0; this is done by adjusting the ndsToMortonCode with a bit operation
   * (bit_AND with 0x7FFFFFFFFFFFFFFF) that sets bit 64 to 0. - When negative numbers are encoded to
   * Morton code, their signs have to be correctly handled during decoding. We need to "recognize"
   * negative numbers by checking the respective most significant bit (as two's complement is used).
   * For longitudes, we encode to a 32 bit integer, so the most significant bit is at position 32
   * (or 31 if we start counting at 0). When this bit is set, we need to make this number negative
   * by setting all higher bits (32 in case of a 64 bit number) to 1. This can be achieved by
   * left/right shifting. For latitudes, we do the same for a 31 bit integer, so we check the bit at
   * position 31 (or 30 if we start counting at 0) and shift 33 bits. In languages that are stricter
   * w.r.t. bit widths of integers, the adjustments may not be necessary may be changed.
   *
   */
  private static part1By1(m: number): bigint {
    let n = bigInt(m);
    n = bigInt(n).and(CoordinateUtilsService.bigInt0x00000000FFFFFFFF);
    n = bigInt(n)
      .or(bigInt(n).shiftLeft(16))
      .and(CoordinateUtilsService.bigInt0x0000FFFF0000FFFF);
    n = bigInt(n)
      .or(bigInt(n).shiftLeft(8))
      .and(CoordinateUtilsService.bigInt0x00FF00FF00FF00FF);
    n = bigInt(n)
      .or(bigInt(n).shiftLeft(4))
      .and(CoordinateUtilsService.bigInt0x0F0F0F0F0F0F0F0F);
    n = bigInt(n)
      .or(bigInt(n).shiftLeft(2))
      .and(CoordinateUtilsService.bigInt0x3333333333333333);
    n = bigInt(n)
      .or(bigInt(n).shiftLeft(1))
      .and(CoordinateUtilsService.bigInt0x5555555555555555);
    return n;
  }

  /**
   * Method helping decode a longitude or latitude coordinate from Morton Code.
   */
  private static unpart1by1(m: bigint): bigint {
    let n = bigInt(m);
    n = bigInt(n).and(CoordinateUtilsService.bigInt0x5555555555555555);
    n = bigInt(n)
      .xor(bigInt(n).shiftRight(1))
      .and(CoordinateUtilsService.bigInt0x3333333333333333);
    n = bigInt(n)
      .xor(bigInt(n).shiftRight(2))
      .and(CoordinateUtilsService.bigInt0x0F0F0F0F0F0F0F0F);
    n = bigInt(n)
      .xor(bigInt(n).shiftRight(4))
      .and(CoordinateUtilsService.bigInt0x00FF00FF00FF00FF);
    n = bigInt(n)
      .xor(bigInt(n).shiftRight(8))
      .and(CoordinateUtilsService.bigInt0x0000FFFF0000FFFF);
    n = bigInt(n)
      .xor(bigInt(n).shiftRight(16))
      .and(CoordinateUtilsService.bigInt0x00000000FFFFFFFF);
    return n;
  }
}
