import { Pipe, PipeTransform } from '@angular/core';
import { ScenarioService } from '@modules/test-execution/service/scenario.service';
import { ScenarioApiEntity } from '@shared/model/productserver';

@Pipe({
  name: 'scenarioDetails',
  pure: false
})
export class ScenarioDetailsPipe implements PipeTransform {
  value: string;

  constructor(private scenarioService: ScenarioService) {}

  transform(value: number, property): string {
    this.value = '';
    this.scenarioService.scenariosList.subscribe((result) => {
      const entity: ScenarioApiEntity = result.find(
        (item) => item.id === value
      );
      if (entity?.[property]) {
        this.value = String(entity[property]);
      }
    });
    return this.value;
  }
}
