import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToolbarMenuComponent } from '@app/layout/component/common/toolbar-menu.component';
import { NavigationNode } from '@shared/model/navigation';
import { AuthService } from '@shared/service/auth.service';
import { NavigationService } from '@shared/service/navigation.service';

@Component({
  selector: 'app-main-toolbar-user-menu',
  templateUrl: './main-toolbar-user-menu.component.html',
  styleUrls: ['./main-toolbar-user-menu.component.scss']
})
export class MainToolbarUserMenuComponent extends ToolbarMenuComponent {
  constructor(
    router: Router,
    protected $authService: AuthService,
    protected $navigationService: NavigationService
  ) {
    super(router);
  }

  get username(): string {
    return this.$authService.username;
  }

  get rightNavigation(): Array<NavigationNode> {
    return this.$navigationService.filterNavigation(
      this.$navigationService.menuItems,
      'right'
    );
  }

  logout() {
    this.$authService.logout();
  }
}
