import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import {
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import {
  ResultType,
  TestCaseApiEntity as DatastoreTestCaseApiEntity
} from '@shared/model/datastore';
import { TestCaseApiEntity } from '@shared/model/productserver';
import { ResponseCollection } from '@shared/model/response';
import { DatastoreService } from '@shared/service/datastore.service';
import { Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { TestCasesService } from '@shared/service/test-cases.service';

@Component({
  selector: 'app-map-viewer-select-testcase',
  templateUrl: './map-viewer-select-testcase.component.html',
  styleUrls: ['./map-viewer-select-testcase.component.scss']
})
export class MapViewerSelectTestcaseComponent implements OnInit, OnDestroy {
  @ViewChild('testCaseInput') testCaseInput: ElementRef;

  testCaseFormControl = new FormControl();
  autoCompleteTestCases: Observable<DatastoreTestCaseApiEntity[]>;
  isLoadingResults = false;

  private $testCasesList: DatastoreTestCaseApiEntity[] = [];
  private $subscription: Subscription = new Subscription();

  private $testCasesListLoader: Subject<DatastoreTestCaseApiEntity[]> =
    new Subject<DatastoreTestCaseApiEntity[]>();
  private $testCasesListSubject: Subject<boolean> = new Subject<boolean>();

  private $testCaseChanged = false;
  private $testCase: TestCaseApiEntity;

  constructor(
    private mapEventService: MapEventService,
    private datastoreService: DatastoreService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService,
    private testCasesService: TestCasesService,
    private formBuilder: FormBuilder
  ) {
    this.testCaseFormControl.setValue(null);
    this.testCaseFormControl.addValidators([
      Validators.pattern(/^\w{2,}(\.\w{2,}){3}$/) // NOSONAR
    ]);
    this.toggleTestCaseSelectorStatus();
  }

  get isTestCaseDisabled() {
    return !this.catalogName || !this.catalogVersion;
  }

  get catalogName(): string {
    return this.mapViewerUserSelectionService.mainCatalogName;
  }

  get catalogVersion(): number {
    return this.mapViewerUserSelectionService.mainCatalogVersion;
  }

  get testCaseId(): string {
    return this.$testCase?.id;
  }

  get resultType(): ResultType {
    return this.$testCase?.resultType;
  }

  get testCasesList(): DatastoreTestCaseApiEntity[] {
    return this.$testCasesList;
  }

  set testCasesList($testcaseIdsList: DatastoreTestCaseApiEntity[]) {
    this.$testCasesList = $testcaseIdsList || [];
    if (this.$testCasesList.length) {
      this.autoCompleteTestCases = this.testCaseFormControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.getAutoCompleteTestCases(value))
      );
    }
  }

  ngOnInit(): void {
    this.registerEventListener();
    this.registerTestCaseLoader();
    this.getTestCases();
  }

  ngOnDestroy() {
    this.$subscription.unsubscribe();
    this.$testCasesListSubject.next();
    this.$testCasesListSubject.complete();
    this.$testCasesListLoader.next();
    this.$testCasesListLoader.complete();
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeTestCase()
        .subscribe((testCase: TestCaseApiEntity) => {
          this.$testCase = testCase;
          this.testCaseFormControl.setValue(this.$testCase?.id || null);
          this.$testCaseChanged = true;
          this.toggleTestCaseSelectorStatus();
        })
    );
    this.$subscription.add(
      this.mapViewerUserSelectionService
        .observeMainCatalogVersion()
        .subscribe((catalogVersion: number) => {
          this.clearTestCaseList();
          this.getTestCases();
          this.toggleTestCaseSelectorStatus();
        })
    );
  }

  registerTestCaseLoader(): void {
    this.$testCasesListLoader
      .pipe(
        switchMap(() => {
          if (!this.catalogName || !this.catalogVersion) {
            return observableOf([null]);
          }
          this.isLoadingResults = true;
          return this.datastoreService.getTestCases(
            this.catalogName,
            this.catalogVersion
          );
        }),
        takeUntil(this.$testCasesListSubject)
      )
      .subscribe(
        (response: ResponseCollection<DatastoreTestCaseApiEntity>) => {
          this.isLoadingResults = false;
          this.testCasesList = response.data;
          this.testCasesList.sort((a, b) =>
            a.testCaseId > b.testCaseId ? 1 : -1
          );
          this.checkSelectedTestCaseIdAvailable();
        },
        (err) => {
          this.isLoadingResults = false;
          this.testCasesList = [];
          console.error(err);
          return observableOf([]);
        }
      );
  }

  getTestCases(): void {
    this.$testCasesListLoader.next();
  }

  selectTestCase(event: MatAutocompleteSelectedEvent): void {
    const testCaseId = event?.option?.value || null;
    if (testCaseId === null) {
      this.testCaseFormControl.setValue(null);
    }
    this.setTestCase(testCaseId);
  }

  onTestCaseChangeEvent(event: any) {
    if (this.testCaseFormControl.invalid) {
      this.setTestCase(null);
    }
    this.setTestCase(event.target.value);
  }

  submitTestCaseSelect(): void {
    this.$testCaseChanged = false;
  }

  testCaseChanged(): boolean {
    return this.$testCaseChanged;
  }

  private getAutoCompleteTestCases(
    value: string
  ): DatastoreTestCaseApiEntity[] {
    if (value !== null && value !== '') {
      return this.testCasesList.filter(
        (option) =>
          String(option.testCaseId)
            .toLowerCase()
            .startsWith(String(value).trim()) ||
          String(option.testCaseName)
            .toLowerCase()
            .includes(String(value).trim())
      );
    } else {
      return this.testCasesList;
    }
  }

  private checkSelectedTestCaseIdAvailable(): void {
    if (!this.testCaseId) {
      return;
    }
    const isIn = this.testCasesList.some(
      (testCase) => testCase.testCaseId === this.testCaseId
    );
    if (!isIn) {
      this.selectTestCase(null);
    }
  }

  private setTestCase(testCaseId: string) {
    if (testCaseId === this.mapViewerUserSelectionService.testCase?.id) {
      return;
    }
    this.testCasesService
      .getTestCaseById(testCaseId)
      .subscribe((testCase: TestCaseApiEntity) => {
        this.mapViewerUserSelectionService.testCase = testCase;
      });
    this.$testCaseChanged = true;
  }

  private clearTestCaseList() {
    this.$testCasesList = [];
  }

  private toggleTestCaseSelectorStatus(): void {
    if (this.isTestCaseDisabled) {
      this.testCaseFormControl.disable();
    } else {
      this.testCaseFormControl.enable();
    }
  }
}
