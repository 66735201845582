<div class="kf-visualization-select">
  <label class="kf-label">Select Visualization Mode</label>
  <mat-button-toggle-group
    class="kf-button-toggle-group"
    (change)="selectVisualizationMode($event)"
    name="favoriteColor"
    aria-label="Favorite Color"
  >
    <mat-button-toggle
      *ngFor="let entry of visualizationOptions | keyvalue:asIsOrder"
      [value]="entry.key"
      [checked]="visualizationMode === entry.key"
    ><span>{{entry.key}}</span></mat-button-toggle>
  </mat-button-toggle-group>
</div>

<ng-container *ngIf="isResultVisualization"><app-map-viewer-select-result-visualization></app-map-viewer-select-result-visualization></ng-container>
<ng-container *ngIf="isDeltaVisualization"><app-map-viewer-select-delta-visualization></app-map-viewer-select-delta-visualization></ng-container>
<ng-container *ngIf="isDataVisualization"><app-map-viewer-select-data-visualization></app-map-viewer-select-data-visualization></ng-container>
