import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-save-changes-dialog',
  templateUrl: './save-changes-dialog.component.html',
  styleUrls: ['./save-changes-dialog.component.scss']
})
export class SaveChangesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SaveChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private cdr: ChangeDetectorRef
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
    this.cdr.detectChanges();
  }

  onYesClick(): void {
    this.dialogRef.close(true);
    this.cdr.detectChanges();
  }
}
