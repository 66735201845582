import { Component } from '@angular/core';
import { ResultComponent } from '@modules/map-viewer/component/map-viewer-results-overview/views/common/result.component';

@Component({
  selector: 'app-single-result-map-approval',
  templateUrl: './single-result-map-approval.component.html',
  styleUrls: []
})
export class SingleResultMapApprovalComponent extends ResultComponent {}
{
}
