<app-loading-spinner *ngIf="isLoadingResults"></app-loading-spinner>
<div *ngIf="length === 0">
  <p>No tests have been executed for this tile/version.</p>
</div>
<ng-container *ngIf="displayMode === 'SINGLE_VIEW'">
  <ng-container *ngIf="!isVisualizationModeDelta">
    <ng-container [ngSwitch]="resultType">
      <app-single-result-statistic *ngSwitchCase="STATISTIC"
                                   [testCaseResult]="testCaseResult"></app-single-result-statistic>
      <app-single-result-statistic-detailed *ngSwitchCase="STATISTIC_DETAILED"
                                   [testCaseResult]="testCaseResult"></app-single-result-statistic-detailed>
      <app-single-result-format-content *ngSwitchCase="FORMAT_CONTENT"
                                        [testCaseResult]="testCaseResult"></app-single-result-format-content>
      <app-single-result-map-approval *ngSwitchCase="MAP_APPROVAL"
                                      [testCaseResult]="testCaseResult"></app-single-result-map-approval>
      <app-single-result-format-content-detailed *ngSwitchCase="FORMAT_CONTENT_DETAILED"
                                                 [testCaseResult]="testCaseResult"></app-single-result-format-content-detailed>
      <app-single-result-combined-detailed-statistic *ngSwitchCase="COMBINED_DETAILED_STATISTIC"
                                                     [testCaseResult]="testCaseResult"></app-single-result-combined-detailed-statistic>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isVisualizationModeDelta">
    <ng-container [ngSwitch]="resultType">
      <app-delta-result-statistic *ngSwitchCase="STATISTIC" [testCaseResult]="testCaseResult"
                                  [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-statistic>
      <app-delta-result-statistic-detailed *ngSwitchCase="STATISTIC_DETAILED" [testCaseResult]="testCaseResult"
                                           [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-statistic-detailed>
      <app-delta-result-format-content *ngSwitchCase="FORMAT_CONTENT" [testCaseResult]="testCaseResult"
                                       [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-format-content>
      <app-delta-result-map-approval *ngSwitchCase="MAP_APPROVAL" [testCaseResult]="testCaseResult"
                                     [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-map-approval>
      <app-delta-result-format-content-detailed *ngSwitchCase="FORMAT_CONTENT_DETAILED"
                                                [testCaseResult]="testCaseResult"
                                                [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-format-content-detailed>
      <app-delta-result-combined-detailed-statistic *ngSwitchCase="COMBINED_DETAILED_STATISTIC"
                                                    [testCaseResult]="testCaseResult"
                                                    [testCaseDeltaResult]="testCaseDeltaResult"></app-delta-result-combined-detailed-statistic>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isVisualizationModeDataVersion">
    <ng-container *ngIf="layerResult">
    <app-single-result-data-version [layerResult]="layerResult">
    </app-single-result-data-version>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isVisualizationModeDelta && testCaseResult && testCaseDeltaResult">
    <table class="kf-result-single-view" aria-label="Result Details" aria-hidden="true">
      <colgroup>
        <col span="1" style="width:30%;">
        <col span="1" style="width:35%;">
        <col span="1" style="width:35%;">
      </colgroup>
      <tfoot>
      <tr>
        <td></td>
        <td>
          <div class="kf-button-group">
            <button color="primary" mat-mini-fab aria-label="Result Details" (click)="showDetails(testCaseResult)"
                    matTooltip="Result Details">
              <mat-icon>read_more</mat-icon>
            </button>
            <button *ngIf="isDisplayable(testCaseResult)" [color]="getColor(testCaseResult)" mat-mini-fab
                    aria-label="Show on Map" (click)="toggleResultDetails(testCaseResult)" matTooltip="Show On Map">
              <mat-icon>map</mat-icon>
            </button>
          </div>
        </td>
        <td>
          <div class="kf-button-group">
            <button color="primary" mat-mini-fab aria-label="Result Details" (click)="showDetails(testCaseDeltaResult)"
                    matTooltip="Result Details">
              <mat-icon>read_more</mat-icon>
            </button>
            <button *ngIf="isDisplayable(testCaseDeltaResult, CATALOG_DELTA)"
                    [color]="getColor(testCaseDeltaResult, CATALOG_DELTA)" mat-mini-fab aria-label="Show on Map"
                    (click)="toggleResultDetails(testCaseDeltaResult, CATALOG_DELTA)" matTooltip="Show On Map">
              <mat-icon>map</mat-icon>
            </button>
          </div>
        </td>
      </tr>
      </tfoot>
    </table>
  </ng-container>
  <ng-container *ngIf="!isVisualizationModeDelta && testCaseResult">
    <div class="kf-button-group">
      <button color="primary" mat-mini-fab aria-label="Result Details" (click)="showDetails(testCaseResult)"
              matTooltip="Result Details">
        <mat-icon>read_more</mat-icon>
      </button>
      <button *ngIf="isDisplayable(testCaseResult)" [color]="getColor(testCaseResult)" mat-mini-fab
              aria-label="Show on Map" (click)="toggleResultDetails(testCaseResult)" matTooltip="Show On Map">
        <mat-icon>map</mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="displayMode === 'LIST_VIEW' && resultData.data.length > 0">
  <table
    class="kf-result-details"
    mat-table
    [dataSource]="resultData"
    aria-describedby="tableDescription"
  >
    <colgroup>
      <col span="1" style="width:15%" />
      <col span="1" style="width:30%" />
      <col span="1" style="width:25%" />
      <col span="1" style="width:10%" />
    </colgroup>
    <ng-container matColumnDef="testCaseId">
      <th *matHeaderCellDef mat-header-cell scope="col">TestCaseId</th>
      <td *matCellDef="let element" mat-cell matTooltip="{{element.testCaseId|testCaseDetails:'description'}}">
        {{element.testCaseId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="testCaseName">
      <th *matHeaderCellDef mat-header-cell scope="col">TestCaseName</th>
      <td *matCellDef="let element" mat-cell>
        {{element.testCaseId|testCaseDetails:'name'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="resultOutcome">
      <th *matHeaderCellDef mat-header-cell scope="col">Result</th>
      <td *matCellDef="let element" mat-cell>
        {{element.resultOutcome}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
      <td *matCellDef="let result" mat-cell class="table-cell-actions">
        <button color="primary" mat-mini-fab aria-label="Result Details" (click)="showDetails(result)"
                matTooltip="Result Details">
          <mat-icon>read_more</mat-icon>
        </button>
        <button *ngIf="isDisplayable(result)" [color]="getColor(result)" mat-mini-fab aria-label="Show on Map"
                (click)="toggleResultDetails(result)" matTooltip="Show On Map">
          <mat-icon>map</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <caption id="tableDescription">List of available test results</caption>
  </table>
  <!--Table end-->

  <!--pagination start-->
  <app-paginator
    (page)="handlePageEvent($event)"
    [length]="length"
    [pageSizeOptions]="[]"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [showJumpTo]="false"
    [hidePageSize]="true"
  ></app-paginator>
  <!--pagination end-->
</ng-container>
