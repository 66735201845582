<ng-container *ngIf="testCaseResult && testCaseDeltaResult">
  <table class="kf-result-single-view" aria-label="Result Details">
    <colgroup>
      <col span="1" style="width:30%;">
      <col span="1" style="width:35%;">
      <col span="1" style="width:35%;">
    </colgroup>
    <tbody>
    <tr>
      <th scope="row">Test</th>
      <td matTooltip="{{testCaseId|testCaseDetails:'description'}}"
          colspan="2">{{testCaseId}}
        - {{testCaseId|testCaseDetails:'name'}}</td>
    </tr>
    <tr>
      <th scope="col">&nbsp;</th>
      <th scope="col">Main Catalog</th>
      <th scope="col">Delta Catalog</th>
    </tr>
    <tr>
      <th scope="row">Outcome</th>
      <td>{{resultOutcome}}</td>
      <td>{{comparisonResultOutcome}}</td>
    </tr>
    <tr>
      <th scope="row">Feature</th>
      <td class="word-break">{{featureType}}</td>
      <td class="word-break">{{comparisonFeatureType}}</td>
    </tr>
    <tr>
      <th scope="row">Total</th>
      <td>{{total|number:"1.0-2":"de"}} {{unit !== 'none' ? unit : ''}}</td>
      <td>{{comparisonTotal|number:"1.0-2":"de"}} {{comparisonUnit !== 'none' ? comparisonUnit : ''}}</td>
    </tr>
    <tr>
      <th scope="row">Delta Value</th>
      <td>{{comparisonTotal - total|number:"1.0-2":"de"}} {{comparisonUnit !== 'none' ? comparisonUnit : ''}}</td>
    </tr>
    </tbody>
    <tfoot>
  </table>

</ng-container>
