import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { HealthComponent } from './page/health/health.component';

import { HealthRoutingModule } from './health.routing';

@NgModule({
  declarations: [HealthComponent],
  imports: [SharedModule, HealthRoutingModule]
})
export class HealthModule {}
