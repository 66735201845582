import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MapViewerResultDetailsDialogComponent } from '@modules/map-viewer/component/map-viewer-result-details-dialog/map-viewer-result-details-dialog.component';
import { MapViewerVisualizationMode } from '@modules/map-viewer/model/map-viewer';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import {
  DataVersionTileEntity,
  ResultDataApiEntity,
  ResultFilter,
  ResultOutcome,
  ResultType
} from '@shared/model/datastore';
import {
  CatalogApiEntity,
  TestCaseApiEntity
} from '@shared/model/productserver';
import { Pagination, ResponsePage } from '@shared/model/response';
import { DatastoreService } from '@shared/service/datastore.service';
import * as L from 'leaflet';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MapViewerUserSelectionService } from '../../service/map-viewer-user-selection.service';

export enum DisplayMode {
  LIST_VIEW = 'LIST_VIEW',
  SINGLE_VIEW = 'SINGLE_VIEW'
}

@Component({
  selector: 'app-map-viewer-results-overview',
  templateUrl: './map-viewer-results-overview.component.html',
  styleUrls: ['./map-viewer-results-overview.component.scss']
})
export class MapViewerResultsOverviewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly CATALOG_MAIN = 'CATALOG_MAIN';
  readonly CATALOG_DELTA = 'CATALOG_DELTA';

  readonly STATISTIC = ResultType.STATISTIC;
  readonly STATISTIC_DETAILED = ResultType.STATISTIC_DETAILED;
  readonly FORMAT_CONTENT = ResultType.FORMAT_CONTENT;
  readonly COMBINED_DETAILED_STATISTIC = ResultType.COMBINED_DETAILED_STATISTIC;
  readonly FORMAT_CONTENT_DETAILED = ResultType.FORMAT_CONTENT_DETAILED;
  readonly MAP_APPROVAL = ResultType.MAP_APPROVAL;

  filter: ResultFilter = {
    testCaseIds: [],
    tileIds: [],
    areaIds: [],
    resultOutcomes: []
  };
  length = 0;
  pageSize = 5;
  pageIndex = 0;
  totalPages = 0;
  pageSizeOptions: number[] = [5];
  resultData: MatTableDataSource<ResultDataApiEntity | any>;
  resultDeltaData: MatTableDataSource<ResultDataApiEntity | any>;
  dataVersionData: MatTableDataSource<DataVersionTileEntity | any>;

  validGeoJsonMap = new Map<string, boolean>();

  displayedGeoJsonHash: string;

  displayedColumns: string[] = [
    'testCaseId',
    'testCaseName',
    'resultOutcome',
    'actions'
  ];
  isLoadingResults = false;
  private $displayMode: DisplayMode;
  private $subscription: Subscription = new Subscription();

  private resultOutcomes: ResultOutcome[] = [
    ResultOutcome.PASS,
    ResultOutcome.FAIL,
    ResultOutcome.FAILURE,
    ResultOutcome.NOT_EXECUTED
  ];

  constructor(
    private datastoreService: DatastoreService,
    private mapEventService: MapEventService,
    private dialog: MatDialog,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {
    this.resultData = new MatTableDataSource();
    this.resultDeltaData = new MatTableDataSource();
    this.dataVersionData = new MatTableDataSource();
  }

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService.resultType;
  }

  get layerName(): string {
    return this.mapViewerUserSelectionService.layerName;
  }

  get displayMode(): DisplayMode {
    return this.$displayMode;
  }

  get isVisualizationModeDelta(): boolean {
    return this.$visualizationMode === MapViewerVisualizationMode.DELTA;
  }

  get isVisualizationModeDataVersion(): boolean {
    return this.$visualizationMode === MapViewerVisualizationMode.DATA;
  }

  get testCaseResult(): ResultDataApiEntity {
    return (this.resultData.data.length && this.resultData.data[0]) || null;
  }

  get testCaseDeltaResult(): ResultDataApiEntity {
    return (
      (this.resultDeltaData.data.length && this.resultDeltaData.data[0]) || null
    );
  }

  get layerResult(): DataVersionTileEntity {
    return (
      (this.dataVersionData.data.length && this.dataVersionData.data[0]) || null
    );
  }

  private get $visualizationMode(): MapViewerVisualizationMode {
    return this.mapViewerUserSelectionService.visualizationMode;
  }

  private get $mainCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.mainCatalog;
  }

  private get $mainComparisonCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.mainComparisonCatalog;
  }

  private get $deltaCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.deltaCatalog;
  }

  private get $deltaComparisonCatalog(): CatalogApiEntity {
    return this.mapViewerUserSelectionService.deltaComparisonCatalog;
  }

  private get $mainCatalogName(): string {
    return this.$mainCatalog?.name;
  }

  private get $mainCatalogVersion(): number {
    return this.mapViewerUserSelectionService.mainCatalogVersion;
  }

  private get $mainComparisonCatalogName(): string {
    return this.$mainComparisonCatalog?.name;
  }

  private get $mainComparisonCatalogVersion(): number {
    return this.mapViewerUserSelectionService.mainComparisonCatalogVersion;
  }

  private get $deltaCatalogName(): string {
    return this.$deltaCatalog?.name;
  }

  private get $deltaCatalogVersion(): number {
    return this.mapViewerUserSelectionService.deltaCatalogVersion;
  }

  private get $deltaComparisonCatalogName(): string {
    return this.$deltaComparisonCatalog?.name;
  }

  private get $deltaComparisonCatalogVersion(): number {
    return this.mapViewerUserSelectionService.deltaComparisonCatalogVersion;
  }

  private get $tileId(): number {
    return this.mapViewerUserSelectionService.tileId;
  }

  private get $testCase(): TestCaseApiEntity {
    return this.mapViewerUserSelectionService.testCase;
  }

  private get $testCaseId(): string {
    return this.$testCase?.id;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  ngAfterViewInit(): void {
    this.getResults();
  }

  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
  }

  registerEventListener() {
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.SUBMIT_CATALOG_RESULTS,
        (value: MapViewerUserSelectionService) => {
          this.reset();
          this.$displayMode = DisplayMode.LIST_VIEW;
          if (this.$visualizationMode !== MapViewerVisualizationMode.DATA) {
            this.getResults();
          }
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(
        MapEvent.SUBMIT_TEST_CASE_RESULTS,
        (value: MapViewerUserSelectionService) => {
          this.reset();
          this.$displayMode = DisplayMode.SINGLE_VIEW;
          this.getResults();
        }
      )
    );
    this.$subscription.add(
      this.mapEventService.on(MapEvent.RESET_RESULT_DETAILS, () => {
        this.reset();
      })
    );
  }

  hasTestResults(): boolean {
    return this.resultData.data.length > 0;
  }

  showTestResults(): boolean {
    return !!this.$displayMode;
  }

  public getResults(): void {
    this.resultData.data = [];

    if (!this.$tileId) {
      return;
    }
    if (!this.$mainCatalogName) {
      return;
    }
    if (!this.$mainCatalogVersion) {
      return;
    }

    const isComparison = !!this.$testCase?.comparisonMapDataType;

    const resultFilter: ResultFilter = {
      tileIds: [this.$tileId],
      catalogVersion: this.$mainCatalogVersion,
      resultOutcomes: this.resultOutcomes,
      comparisonCatalogName: isComparison
        ? this.$mainComparisonCatalogName
        : null,
      comparisonCatalogVersion: isComparison
        ? this.$mainComparisonCatalogVersion
        : null
    };

    if (this.$testCaseId) {
      resultFilter.testCaseIds = [this.$testCaseId];
    }

    const calls: Observable<ResponsePage<ResultDataApiEntity>>[] = [];
    calls.push(
      this.datastoreService.getTestCaseResults(
        this.$mainCatalogName,
        resultFilter,
        this.pageIndex + 1,
        this.pageSize
      )
    );
    if (this.$visualizationMode === MapViewerVisualizationMode.DELTA) {
      if (
        this.$deltaCatalogName &&
        this.$deltaCatalogVersion &&
        this.$testCaseId
      ) {
        const deltaResultFilter = {
          ...resultFilter,
          catalogVersion: this.$deltaCatalogVersion,
          comparisonCatalogName: isComparison
            ? this.$deltaComparisonCatalogName
            : null,
          comparisonCatalogVersion: isComparison
            ? this.$deltaComparisonCatalogVersion
            : null
        };
        calls.push(
          this.datastoreService.getTestCaseResults(
            this.$deltaCatalogName,
            deltaResultFilter,
            this.pageIndex + 1,
            this.pageSize
          )
        );
      }
    }

    const observable = forkJoin(calls);
    this.isLoadingResults = true;
    observable.subscribe({
      next: (responses: ResponsePage<ResultDataApiEntity>[]) => {
        this.isLoadingResults = false;
        const [main, delta] = responses;
        const { data: mainData, pagination } = main;
        this.setPagination(pagination);
        this.validGeoJsonMap.clear();
        this.setGeoJsonMap(mainData, this.CATALOG_MAIN);
        this.resultData.data = mainData;
        if (delta) {
          const { data: deltaData } = delta;
          this.setGeoJsonMap(deltaData, this.CATALOG_DELTA);
          this.resultDeltaData.data = deltaData;
        }
      },
      error: (err) => {
        this.isLoadingResults = false;
        console.error(err.error);
      }
    });
  }

  handlePageEvent(event: PageEvent): void {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = Math.max(0, event.pageIndex);
    this.getResults();
  }

  showDetails(
    element: ResultDataApiEntity
  ): MatDialogRef<MapViewerResultDetailsDialogComponent> {
    return this.dialog.open(MapViewerResultDetailsDialogComponent, {
      width: '600',
      data: element
    });
  }

  isDisplayable(
    resultData: ResultDataApiEntity,
    catalog = this.CATALOG_MAIN
  ): boolean {
    return (
      this.validGeoJsonMap.get(
        this.buildGeoJsonId(resultData.testCaseId, catalog)
      ) && resultData.resultOutcome === ResultOutcome.FAIL
    );
  }

  toggleResultDetails(
    resultData: ResultDataApiEntity,
    catalog = this.CATALOG_MAIN
  ) {
    const buildGeoJsonIdNext = this.buildGeoJsonId(
      resultData.testCaseId,
      catalog
    );
    if (this.displayedGeoJsonHash === buildGeoJsonIdNext) {
      this.resetMapDataView();
    } else {
      this.displayedGeoJsonHash = buildGeoJsonIdNext;
      this.mapEventService.emit(
        new EmitEvent(
          MapEvent.RENDER_GEOJSON,
          this.validGeoJsonMap.get(this.displayedGeoJsonHash)
        )
      );
    }
  }

  getColor(
    resultData: ResultDataApiEntity,
    catalog = this.CATALOG_MAIN
  ): ThemePalette {
    return this.displayedGeoJsonHash ===
      this.buildGeoJsonId(resultData.testCaseId, catalog)
      ? 'accent'
      : 'primary';
  }

  setPagination(pagination: Pagination): void {
    this.totalPages = pagination.totalPages;
    this.length = pagination.totalItems;
    this.pageSize = pagination.pageSize;
    this.pageIndex = pagination.currentPage - 1;
  }

  private reset(): void {
    this.$displayMode = null;
    this.resultData.data = [];
    this.resetPagination();
    this.resetMapDataView();
  }

  private resetPagination(): void {
    this.totalPages = null;
    this.length = null;
    this.pageSize = 5;
    this.pageIndex = 0;
  }

  private resetMapDataView() {
    this.displayedGeoJsonHash = null;
    this.mapEventService.emit(new EmitEvent(MapEvent.RENDER_GEOJSON, null));
  }

  private setGeoJsonMap(
    results: ResultDataApiEntity[],
    catalog = this.CATALOG_MAIN
  ) {
    const validPointGeometries = (resultDetailJson) => {
      try {
        const filteredResultDetailJson = { ...resultDetailJson };
        filteredResultDetailJson.features = resultDetailJson.features.filter(
          (feature) => {
            const geometryType = feature.geometry.type;
            return (
              geometryType === 'Point' ||
              geometryType === 'MultiPoint' ||
              geometryType === 'MultiLineString'
            );
          }
        );
        if (filteredResultDetailJson.features.length > 0) {
          L.geoJSON(filteredResultDetailJson);
          return filteredResultDetailJson;
        }
      } catch (e) {}
    };
    results.forEach((result) =>
      this.validGeoJsonMap.set(
        this.buildGeoJsonId(result.testCaseId, catalog),
        validPointGeometries(result.resultDetails)
      )
    );
  }

  private buildGeoJsonId(testCaseId: string, catalog: string): string {
    return `${testCaseId}.${catalog}`;
  }
}
