/* eslint-disable */
import { Component, Input } from '@angular/core';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import {
  ResultDataApiEntity,
  ResultOutcome,
  ResultType
} from '@shared/model/datastore';

@Component({ template: '' })
export abstract class ResultComponent {
  protected $mapViewerUserSelectionService: MapViewerUserSelectionService;

  private $testCaseResult: ResultDataApiEntity;
  private $testCaseComparisonResult: ResultDataApiEntity;

  constructor(
    public mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {
    this.$mapViewerUserSelectionService = mapViewerUserSelectionService;
  }

  get testCaseId(): string {
    return this.testCaseResult && this.testCaseResult.testCaseId;
  }

  get resultOutcome(): ResultOutcome {
    return this.testCaseResult && this.testCaseResult.resultOutcome;
  }

  get resultType(): ResultType {
    return this.testCaseResult && this.testCaseResult.resultType;
  }

  get resultDetails(): any {
    return this.testCaseResult && this.testCaseResult.resultDetails;
  }

  get totalCount(): number {
    return this.resultDetails && this.resultDetails.totalCount;
  }

  get deltaValue(): number {
    return this.calculateDeltaValue(this.failCount, this.comparisonFailCount);
  }

  get failCount(): number {
    return this.resultDetails && this.resultDetails.failCount;
  }

  get failRate(): number {
    return this.calculateFailRate(this.totalCount, this.failCount);
  }

  get hasFailRate(): boolean {
    return Number.isFinite(this.totalCount) && Number.isFinite(this.failCount);
  }

  get countFeature(): string {
    return this.resultDetails && this.resultDetails.countFeature;
  }

  get featureType(): string {
    return this.resultDetails && this.resultDetails.featureType;
  }

  get unit(): string {
    return this.resultDetails && this.resultDetails.unit;
  }

  get total(): number {
    const { value } = this.resultDetails || {};
    if (value !== undefined) {
      return value.valueOf();
    }
  }

  get isFormatContent(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT;
  }

  get isFormatContentDetailed(): boolean {
    return this.resultType === ResultType.FORMAT_CONTENT_DETAILED;
  }

  get isResultTypeMapApproval(): boolean {
    return this.resultType === ResultType.MAP_APPROVAL;
  }

  get isResultTypeStatistic(): boolean {
    return this.resultType === ResultType.STATISTIC;
  }

  get isStatisticDetailed(): boolean {
    return this.resultType === ResultType.STATISTIC_DETAILED;
  }

  get isCombinedDetailedStatistic(): boolean {
    return this.resultType === ResultType.COMBINED_DETAILED_STATISTIC;
  }

  get comparisonResultOutcome(): ResultOutcome {
    return this.testCaseDeltaResult && this.testCaseDeltaResult.resultOutcome;
  }

  get comparisonResultDetails(): any {
    return this.testCaseDeltaResult && this.testCaseDeltaResult.resultDetails;
  }

  get comparisonTotalCount(): number {
    return (
      this.comparisonResultDetails && this.comparisonResultDetails.totalCount
    );
  }

  get comparisonFailCount(): number {
    return (
      this.comparisonResultDetails && this.comparisonResultDetails.failCount
    );
  }

  get comparisonFailRate(): number {
    return this.calculateFailRate(
      this.comparisonTotalCount,
      this.comparisonFailCount
    );
  }

  get hasComparisonFailRate(): boolean {
    return (
      Number.isFinite(this.comparisonTotalCount) &&
      Number.isFinite(this.comparisonFailCount)
    );
  }

  get comparisonCountFeature(): string {
    return (
      this.comparisonResultDetails && this.comparisonResultDetails.countFeature
    );
  }

  get comparisonFeatureType(): string {
    return (
      this.comparisonResultDetails && this.comparisonResultDetails.featureType
    );
  }

  get comparisonUnit(): string {
    return this.comparisonResultDetails && this.comparisonResultDetails.unit;
  }

  get comparisonTotal(): number {
    const { value } = this.comparisonResultDetails || {};
    if (value !== undefined) {
      return value.valueOf();
    }
    return value;
  }

  get deltaFailRate(): number {
    return Number(this.comparisonFailRate) - Number(this.failRate);
  }

  get testCaseDeltaResult(): ResultDataApiEntity {
    return this.$testCaseComparisonResult;
  }

  @Input() set testCaseDeltaResult(resultDataApiEntity: ResultDataApiEntity) {
    if (resultDataApiEntity) {
      this.$testCaseComparisonResult = resultDataApiEntity;
    }
  }

  get testCaseResult(): ResultDataApiEntity {
    return this.$testCaseResult;
  }

  @Input() set testCaseResult(resultDataApiEntity: ResultDataApiEntity) {
    if (resultDataApiEntity === null || resultDataApiEntity === undefined) {
      this.$testCaseResult = null;
    } else {
      this.$testCaseResult = resultDataApiEntity;
    }
  }

  protected calculateFailRate(totalCount, failCount): number {
    if (!failCount || !totalCount) {
      return 0;
    }
    return Number((failCount / totalCount) * 100);
  }

  protected calculateDeltaValue(failCount, comparisonFailCount): number {
    return Number((failCount || 0) - (comparisonFailCount || 0));
  }
}
