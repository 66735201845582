import { Component, Input } from '@angular/core';
import { MapViewerTile } from '@modules/map-viewer/service/map-viewer-tile';
import {
  MapViewerValueScale,
  ValueRangeMarker
} from '@modules/map-viewer/service/map-viewer-value-scale';
import * as d3 from 'd3';

export interface LegendColorScaleMarker extends ValueRangeMarker {
  x: number;
  width: number;
  xOffset: number;
}

export interface LegendColorScaleSegment {
  x: number;
  width: number;
  height: number;
  opacity: number;
  color: string;
}

@Component({
  selector: 'app-value-range-bar',
  templateUrl: './value-range-bar.component.html',
  styleUrls: ['./value-range-bar.component.scss']
})
export class ValueRangeBarComponent {
  @Input() width = 270;
  @Input() barHeight = 10;
  @Input() padding = 15;
  @Input() textColor = '#888888';
  @Input() markerColor = '#888888';
  @Input() backgroundColor = 'white';

  private $scale: MapViewerValueScale;
  private $legendColorScaleMarkers: LegendColorScaleMarker[] = [];
  private $legendSegments: LegendColorScaleSegment[];

  get barWidth(): number {
    return this.width - 2 * this.padding;
  }

  get domain(): number[] {
    return (this.scale && this.scale.domain) || [];
  }

  get barOpacity(): number {
    return MapViewerTile.TILE_RESULT_OUTCOME_FILL_OPACITY;
  }

  get isValid(): boolean {
    return this.scale instanceof MapViewerValueScale;
  }

  get legendScale() {
    const domainLength = this.domain.length;
    const legendRange = [];
    for (let i = 0; i < domainLength; i++) {
      const step = (this.barWidth * i) / (domainLength - 1);
      legendRange.push(step);
    }
    return d3.scaleLinear(this.domain, legendRange);
  }

  get legendColorScale() {
    return (d) => this.scale.getColor(this.scale.scale(d));
  }

  get legendColorScaleMarkers(): LegendColorScaleMarker[] {
    return this.$legendColorScaleMarkers;
  }

  get legendSegments() {
    return this.$legendSegments;
  }

  get scale() {
    return this.$scale;
  }

  @Input()
  set scale(scale: MapViewerValueScale) {
    this.$scale = scale;
    if (this.$scale) {
      this.buildLegendColorScaleSegments();
      this.buildLegendColorScaleMarker();
    }
  }

  private buildLegendColorScaleSegments(): void {
    const range = this.legendSegmentsRange();
    this.$legendSegments = [];
    for (const v of range) {
      this.$legendSegments.push({
        x: this.legendScale(v),
        width: 1,
        height: this.barHeight,
        opacity: this.barOpacity,
        color: this.legendColorScale(v)
      });
    }
  }

  private buildLegendColorScaleMarker(): void {
    const calcMarkerRange = this.scale.calcMarkerRange();
    this.$legendColorScaleMarkers = [];
    const markerDistance = this.barWidth / (calcMarkerRange.length - 1);
    for (let i = 0; i < calcMarkerRange.length; i++) {
      const x = i * markerDistance;
      this.$legendColorScaleMarkers.push({
        ...calcMarkerRange[i],
        x,
        width: 4,
        xOffset: -2
      });
    }
  }

  private legendSegmentsRange() {
    const domainSize = this.domain.length;
    const domainParts = domainSize - 1;
    const domainPartWidth = this.barWidth / domainParts;
    const ranges = [];
    for (let i = 0; i < domainSize - 1; i++) {
      const partLength = this.domain[i + 1] - this.domain[i];
      const partStep = parseFloat((partLength / domainPartWidth).toFixed(4));
      const rangeSegment = d3.range(
        this.domain[i],
        this.domain[i + 1],
        partStep
      );
      ranges.push(...rangeSegment);
    }
    return ranges;
  }
}
