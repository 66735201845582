import { Component, OnInit } from '@angular/core';
import {
  EmitEvent,
  MapEvent,
  MapEventService
} from '@modules/map-viewer/service/map-event.service';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import { FailVisualization, ResultType } from '@shared/model/datastore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-map-viewer-fail-count-filter',
  templateUrl: './map-viewer-fail-count-filter.component.html',
  styleUrls: ['./map-viewer-fail-count-filter.component.scss']
})
export class MapViewerFailCountFilterComponent implements OnInit {
  private $subscription: Subscription = new Subscription();

  constructor(
    private mapEventService: MapEventService,
    private mapViewerUserSelectionService: MapViewerUserSelectionService
  ) {}

  get resultType(): ResultType {
    return this.mapViewerUserSelectionService.resultType;
  }

  get activeFailFilter(): FailVisualization {
    return (
      this.mapViewerUserSelectionService.failVisualization ||
      FailVisualization.FAIL_RATE
    );
  }

  set activeFailFilter(failVisualization: FailVisualization) {
    this.mapViewerUserSelectionService.failVisualization = failVisualization;
  }

  ngOnInit(): void {
    this.registerEventListener();
  }

  isResultTypeWithFailRate(): boolean {
    return [ResultType.STATISTIC, ResultType.STATISTIC_DETAILED].includes(
      this.resultType
    );
  }
  toggleTileFilter(event): void {
    this.mapViewerUserSelectionService.failVisualization = event.value;
    this.emitFailCount();
  }
  registerEventListener(): void {
    this.$subscription.add(
      this.mapEventService.on(MapEvent.RESET_FAIL_VISUALIZATION, () => {
        this.mapViewerUserSelectionService.failVisualization =
          FailVisualization.FAIL_RATE;
      })
    );
  }

  private emitFailCount() {
    this.mapEventService.emit(new EmitEvent(MapEvent.SHOW_FAIL_COUNT, {}));
  }
}
