import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapViewerSelectVisualizationComponentComponent } from '@modules/map-viewer/component/map-viewer-select-catalog/views/common/map-viewer-select-visualization-component/map-viewer-select-visualization-component.component';
import { MapViewerUserSelectionService } from '@modules/map-viewer/service/map-viewer-user-selection.service';
import { CatalogService } from '@shared/service/catalog.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { NotificationService } from '@shared/service/notification.service';

@Component({
  selector: 'app-map-viewer-select-data-visualization',
  templateUrl: './map-viewer-select-data-visualization.component.html',
  styleUrls: ['./map-viewer-select-data-visualization.component.scss']
})
export class MapViewerSelectDataVisualizationComponent
  extends MapViewerSelectVisualizationComponentComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected mapViewerUserSelectionService: MapViewerUserSelectionService,
    protected catalogService: CatalogService,
    protected datastoreService: DatastoreService,
    protected notificationService: NotificationService
  ) {
    super(
      mapViewerUserSelectionService,
      catalogService,
      datastoreService,
      notificationService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
