import { Component, ViewChild } from '@angular/core';
import { MapViewerResultsOverviewComponent } from '@modules/map-viewer/component/map-viewer-results-overview/map-viewer-results-overview.component';

@Component({
  selector: 'app-map-viewer-info',
  templateUrl: './map-viewer-info.component.html',
  styleUrls: ['./map-viewer-info.component.scss']
})
export class MapViewerInfoComponent {
  @ViewChild(MapViewerResultsOverviewComponent)
  private $mapViewerResultsOverviewComponent: MapViewerResultsOverviewComponent;

  get mapViewerResultsOverviewComponent() {
    return this.$mapViewerResultsOverviewComponent;
  }

  showTestResults(): boolean {
    if (this.$mapViewerResultsOverviewComponent) {
      return this.$mapViewerResultsOverviewComponent.showTestResults();
    }
    return false;
  }
}
