import { NgModule } from '@angular/core';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { ThresholdsRoutingModule } from '@modules/thresholds/thresholds.routing';
import { SharedModule } from '@shared/shared.module';
import { ThresholdsComponent } from './page/thresholds/thresholds.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ThresholdDialogueComponent } from './component/threshold-dialogue/threshold-dialogue.component';
import { MapViewerModule } from '@modules/map-viewer/map-viewer.module';
import { ThresholdListComponent } from './component/threshold-list/threshold-list.component';
import { ThresholdListEntityComponent } from './component/threshold-list-entity/threshold-list-entity.component';
import { ThresholdEntityComponent } from './component/threshold-entity/threshold-entity.component';
import { ThresholdFailrateDeltaEntityComponent } from './component/threshold-failrate-delta-entity/threshold-failrate-delta-entity.component';
import { ThresholdFailrateDetailedEntityComponent } from './component/threshold-failrate-detailed-entity/threshold-failrate-detailed-entity.component';
import { ThresholdDeltaDetailedEntityComponent } from './component/threshold-delta-detailed-entity/threshold-delta-detailed-entity.component';
import { ThresholdAreaSelectorComponent } from './component/threshold-area-selector/threshold-area-selector.component';
import { SaveChangesDialogComponent } from './component/save-changes-dialog/save-changes-dialog.component';

@NgModule({
  declarations: [
    ThresholdsComponent,
    ThresholdDialogueComponent,
    ThresholdListComponent,
    ThresholdListEntityComponent,
    ThresholdEntityComponent,
    ThresholdFailrateDeltaEntityComponent,
    ThresholdFailrateDetailedEntityComponent,
    ThresholdDeltaDetailedEntityComponent,
    ThresholdAreaSelectorComponent,
    SaveChangesDialogComponent
  ],
  imports: [
    SharedModule,
    ThresholdsRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MapViewerModule
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ]
})
export class ThresholdsModule {}
