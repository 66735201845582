<table *ngIf="resultDataApiEntity" aria-describedby="detailsTableDescription" class="details-table">
  <colgroup>
    <col span="1" style="width:20%"/>
    <col span="1" style="width:80%"/>
  </colgroup>
  <tbody>
  <tr>
    <th scope="row">comparisonCatalogName:</th>
    <td>{{resultDataApiEntity.comparisonCatalogName}}</td>
  </tr>
  <tr>
    <th scope="row">comparisonCatalogVersion:</th>
    <td>{{resultDataApiEntity.comparisonCatalogVersion}}</td>
  </tr>
  <tr>
    <th scope="row">layer:</th>
    <td>{{resultDataApiEntity.layer}}</td>
  </tr>
  <tr>
    <th scope="row">dataVersion:</th>
    <td>{{resultDataApiEntity.dataVersion}}</td>
  </tr>
  <tr>
    <th scope="row">tileId:</th>
    <td>{{resultDataApiEntity.tileId}}</td>
  </tr>
  <tr>
    <th scope="row">resultTypeVersion:</th>
    <td>{{resultDataApiEntity.resultTypeVersion}}</td>
  </tr>
  <tr>
    <th scope="row">timestamp:</th>
    <td>{{resultDataApiEntity.timestamp | date: 'dd/MM/yyyy HH:mm:ss z'}}</td>
  </tr>
  <tr>
    <th scope="row">resultOutcome:</th>
    <td>{{resultDataApiEntity.resultOutcome}}</td>
  </tr>
  <tr>
    <th scope="row">resultType:</th>
    <td>{{resultDataApiEntity.resultType}}</td>
  </tr>
  <tr>
    <th scope="row">resultDetails:</th>
    <td>
      <div class="result-details">
        <app-jsonblock [data]="resultDataApiEntity.resultDetails"></app-jsonblock>
      </div>
    </td>
  </tr>
  <tr>
    <th scope="row">executionDetails:</th>
    <td>{{resultDataApiEntity.executionDetails}}</td>
  </tr>
  <tr>
    <th scope="row">executionTime:</th>
    <td>{{resultDataApiEntity.executionTime}} milli.sec</td>
  </tr>
  </tbody>
  <caption id="detailsTableDescription">Result details</caption>
</table>
