import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { StatusComponent } from './page/status/status.component';

import { StatusRoutingModule } from './status.routing';

@NgModule({
  declarations: [StatusComponent],
  imports: [SharedModule, StatusRoutingModule]
})
export class StatushModule {}
