import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors
} from '@angular/forms';
import { AreaSelectorEvent } from '@modules/thresholds/component/threshold-area-selector/threshold-area-selector.component';
import { ThresholdEntityComponent } from '@modules/thresholds/component/threshold-entity/threshold-entity.component';
import { ThresholdEvent } from '@modules/thresholds/component/threshold-list/threshold-list.component';
import {
  ThresholdFormEvent,
  ThresholdFormEventService,
  ThresholdValuePayload
} from '@modules/thresholds/service/threshold-form-event.service';
import {
  AttributionStatus,
  ControlledAccess,
  ResultType
} from '@shared/model/datastore';
import {
  ThresholdDefinitionIdentity,
  ThresholdDetailedDefinitionIdentity,
  ThresholdEntity
} from '@shared/model/thresholds';
import { AuthService } from '@shared/service/auth.service';
import { AreasListApiEntity } from '@shared/model/productserver';
import { of as observableOf, Subscription } from 'rxjs';
import { AreasService } from '@modules/areas/service/areas.service';

export interface FormError {
  control: string;
  error: string;
  value: any;
}

@Component({
  selector: 'app-threshold-list-entity',
  templateUrl: './threshold-list-entity.component.html',
  styleUrls: ['./threshold-list-entity.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ThresholdListEntityComponent implements OnInit {
  @Output() thresholdList: EventEmitter<ThresholdEvent>;
  @ViewChild(ThresholdEntityComponent)
  private $thresholdEntityComponent: ThresholdEntityComponent;
  formGroup: FormGroup;
  formArray: FormArray;
  expandedElement = false;
  isLoadingFilters = false;
  hasChangedEntries = false;
  areasList: Array<AreasListApiEntity> = [];

  protected subscription: Subscription = new Subscription();
  protected areasService: AreasService;

  private $_threshold: ThresholdEntity;
  private $subscription: Subscription = new Subscription();

  constructor(
    areasService: AreasService,
    public authService: AuthService,
    private fb: FormBuilder,
    private thresholdFormEventService: ThresholdFormEventService
  ) {
    this.thresholdList = new EventEmitter<ThresholdEvent>();
    this.formGroup = this.fb.group({
      formArray: this.fb.array([])
    });
    this.formArray = this.formGroup.get('formArray') as FormArray;
    this.areasService = areasService;
  }

  get threshold(): ThresholdEntity {
    return this.$_threshold;
  }

  @Input() set threshold(threshold: ThresholdEntity) {
    if (threshold === null || threshold === undefined) {
      this.$_threshold = null;
    } else {
      this.$_threshold = threshold;
    }
  }

  ngOnInit() {
    this.registerEventListener();
  }

  editThreshold(): void {
    this.thresholdList.emit({
      action: 'edit',
      threshold: this.$thresholdEntityComponent.formData.getRawValue()
    });
  }

  saveThreshold(): void {
    if (!this.$thresholdEntityComponent.formData.valid) {
      console.error(
        this.getFormValidationErrors(this.$thresholdEntityComponent.formData)
      );
      return;
    }
    this.thresholdList.emit({
      action: 'save',
      threshold: this.$thresholdEntityComponent.formData.getRawValue()
    });
  }
  deleteThreshold(): void {
    this.thresholdList.emit({
      action: 'delete',
      threshold: this.$thresholdEntityComponent.formData.getRawValue()
    });
  }

  toggleThresholdActive() {
    this.thresholdList.emit({
      action: 'toggle_active',
      threshold: this.threshold
    });
  }

  getAreasList(): void {
    const areas = this.threshold.getAreas();
    this.isLoadingFilters = true;
    this.subscription.add(
      this.areasService.getAreasList().subscribe(
        (response) => {
          this.isLoadingFilters = false;
          this.areasList = response.data;
          // removing area ids which are already present in nested threshold tables
          this.areasList = this.areasList.filter(
            (areasApiEntity) => !areas.has(areasApiEntity.areaId)
          );
        },
        () => {
          this.isLoadingFilters = false;
          return observableOf([]);
        }
      )
    );
  }

  registerEventListener(): void {
    this.$subscription.add(
      this.thresholdFormEventService.on(
        ThresholdFormEvent.THRESHOLD_TRIGGER_EDIT,
        (payload: ThresholdValuePayload) => {
          if (
            JSON.stringify(payload.key) === JSON.stringify(this.threshold.id)
          ) {
            this.hasChangedEntries = true;
          }
        }
      )
    );
    this.$subscription.add(
      this.thresholdFormEventService.on(
        ThresholdFormEvent.THRESHOLD_TRIGGER_DELETE,
        (payload: ThresholdValuePayload) => {
          if (
            JSON.stringify(payload.key) === JSON.stringify(this.threshold.id)
          ) {
            this.hasChangedEntries = true;
            const { thresholdValues } = payload;
            const deleteAreaId = thresholdValues
              .map((thresholdValue) => thresholdValue.areaId)
              .pop();
            this.threshold.thresholdValues =
              this.threshold.thresholdValues.filter(
                (thresholdValue) => thresholdValue.areaId !== deleteAreaId
              );
          }
        }
      )
    );
  }

  handleSelectAreaEvent(event: AreaSelectorEvent) {
    const { area } = event;
    const { testCaseId, testCaseVersion, cluster, thresholdVersion } =
      this.threshold;

    const appendThresholdValueProperties = {
      testCaseId,
      testCaseVersion,
      cluster,
      thresholdVersion,
      areaId: area.areaId,
      areaName: area.name,
      controlledAccess: ControlledAccess.ALL,
      attributionStatus: AttributionStatus.ALL
    };

    let appendThresholdValue;
    if (
      this.threshold.isFormatContentDetailed ||
      this.threshold.isStatisticDetailed
    ) {
      appendThresholdValueProperties.controlledAccess = ControlledAccess.ALL;
      appendThresholdValueProperties.attributionStatus = AttributionStatus.ALL;
      appendThresholdValue = new ThresholdDetailedDefinitionIdentity(
        appendThresholdValueProperties
      );
    } else {
      appendThresholdValue = new ThresholdDefinitionIdentity(
        appendThresholdValueProperties
      );
    }
    this.threshold.thresholdValues.push(appendThresholdValue);
  }

  isTileBasedAllowed(resultType: ResultType) {
    return ThresholdEntity.isTileBasedAllowed(resultType);
  }

  private getFormValidationErrors(form: FormGroup): FormError[] {
    const result = [];
    Object.keys(form.controls).forEach((key) => {
      const formProperty = form.get(key);
      if (formProperty instanceof FormGroup) {
        result.push(...this.getFormValidationErrors(formProperty));
      }
      if (formProperty instanceof FormArray) {
        formProperty.controls.forEach((formArrayControl) =>
          result.push(
            ...this.getFormValidationErrors(formArrayControl as FormGroup)
          )
        );
      }
      const controlErrors: ValidationErrors = formProperty.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach((keyError) => {
          result.push({
            control: key,
            error: keyError,
            value: controlErrors[keyError]
          });
        });
      }
    });

    return result;
  }
}
