import { Pipe, PipeTransform } from '@angular/core';
import { AreaCacheService } from '@shared/service/area-cache.service';

@Pipe({
  name: 'areaName',
  pure: false
})
export class AreaNamePipe implements PipeTransform {
  constructor(private areasCacheService: AreaCacheService) {}

  transform(areaIds: number[], seperator = ', '): string {
    if (!areaIds || areaIds.length < 1) {
      return '';
    }

    let value = '';
    this.areasCacheService.allAreas.subscribe((result) => {
      value = result
        .filter((area) => areaIds.includes(area.areaId))
        .map((area) => `${area.name}`)
        .join(seperator);
    });
    return value;
  }
}
