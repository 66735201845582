import { Component, Input } from '@angular/core';
import { ScenarioApiEntity } from '@app/shared/model/productserver';

@Component({
  selector: 'app-scenario-details',
  templateUrl: './scenario-details.component.html',
  styleUrls: ['./scenario-details.component.scss']
})
export class ScenarioDetailsComponent {
  @Input() scenarioApiEntity: ScenarioApiEntity;
}
