import { Environment } from '@app/shared/model/environment';
import defaults from './defaults';

export const environment: Environment = {
  ...defaults,
  production: false,
  envName: 'DEV',
  adminUrl: '/admin/api',
  grafanaUrl: '/grafana/dashboards?tag=Main&sort=alpha-asc',
  productServerUrl: '/productserver',
  executionNodeUrl: '/executionnode',
  dataStoreUrl: '/datastore',
  executionNodeHealthUrl: '/actuator/health',
  scenarioProgressUrl: '/scenarios/progress',
  productServerScenarioUrl: '/scenarios',
  productServerAreaUrl: '/areas',
  productServerLayerUrl: '/layers',
  productServerHealthUrl: '/actuator/health',
  productServerInfoUrl: '/actuator/info',
  productServerScenarioStartUrl: '/scenarios/start',
  productServerScenarioCancelUrl: '/scenarios/cancel',
  productServerTestcaseUrl: '/testcases',
  productServerCatalogsUrl: '/catalogs',
  productServerAutomaticExecutionsUrl: '/complex-automatic-executions',
  productServerPerformanceProfileByRunIdUrl: '/scenarios/performance',
  adminLoginUrl: '/login',
  dataStoreCatalogsUrl: '/results/catalogs',
  dataStoreComparisonCatalogsUrl: '/results/catalogs/comparison-catalogs',
  dataStoreScenarioRunsUrl: '/results/scenario-runs',
  dataStoreLocalizationTestUrl: '/results/localization',
  dataStoreMapViewerUrl: '/tiles',
  dataStoreTestCasesUrl: '/testcases',
  dataStoreResultsTilesResultOutcomeUrl: '/results/tiles/resultOutcome',
  dataStoreResultsTilesResultOutcomeDeltaUrl:
    '/results/tiles/resultOutcomeDelta',
  dataStoreAreasUrl: '/areas',
  dataStoreDataVersionOutcomeUrl: '/results/tiles/data-version',
  dataStoreThresholdsUrl: '/thresholds',
  apiClientMaxConcurrency: 4,
  defaultRefreshPeriod: 1000 * 60,
  keycloakServiceUrl: 'https://kingfisher-dev.vwapps.run/keycloak',
  keycloakLogoutRedirectUri: 'https://kingfisher-dev.vwapps.run/',
  keycloakGrafanaLogoutRedirectUri:
    'https://kingfisher-dev.vwapps.run/grafana/logout',
  clientId: 'frontend',
  ndsMapViewerUrl: 'https://mapviewer.kingfisher-dev.vwapps.run/'
};
