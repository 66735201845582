<div class="container-fluid">
  <button (click)="createAutomatedExecution()" *ngIf="authService.hasPrivileges(['TRIGGERS_CREATE'])" aria-label="Create Automated Execution"
          color="primary" id="createTrigger"
          mat-raised-button matTooltip="Create Automated Execution">
    <mat-icon aria-hidden="false">add</mat-icon>
    <span>Create Automated Execution</span>
  </button>


  <div class="scenario-list-container">
    <table
      class="kf-table"
      [dataSource]="data"
      aria-describedby="tableDescription"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows
    >
      <colgroup>
        <col span="1" style="width:5%" />
        <col span="1" style="width:5%" />
        <col span="1" style="width:25%" />
        <col span="1" style="width:15%" />
        <col span="1" style="width:15%" />
        <col span="1" style="width:15%" />
        <col span="1" style="width:5%" />
        <col span="1" style="width:15%" />
      </colgroup>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Name</th>
        <td *matCellDef="let element" mat-cell>
          {{element.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Type</th>
        <td *matCellDef="let element" mat-cell>
          <mat-icon *ngIf="element.type.toUpperCase()==='TIME' || element.type.toUpperCase()==='TIMECOMPLEX'" class="material-icons-outlined">timer</mat-icon>
          <mat-icon *ngIf="element.type.toUpperCase()==='VERSION' || element.type.toUpperCase()==='VERSIONCOMPLEX'" class="material-icons-outlined">plus_one</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Active</th>
        <td *matCellDef="let element" mat-cell>
          <mat-slide-toggle
            (toggleChange)="toggleActiveAutomatedExecution(element)"
            [checked]="element.active"
            [disabled]="!authService.hasPrivileges(['TRIGGERS_UPDATE'])"
            color="primary"
          >
          </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastExecutionTime">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Executed At</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.lastExecutionTime|date:'dd/MM/yyyy HH:mm:ss z' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nextTriggered">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Next Trigger</th>
        <td *matCellDef="let element" mat-cell>
        <span *ngIf="element.type.toUpperCase()==='TIME' || element.type.toUpperCase()==='TIMECOMPLEX'">
          {{ element.nextTriggerTime|date:'dd/MM/yyyy HH:mm:ss z' }}
        </span>
          <span *ngIf="element.type.toUpperCase()==='VERSION' || element.type.toUpperCase()==='VERSIONCOMPLEX'">
          {{element.nextTriggerVersion}}
        </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastModified">
        <th *matHeaderCellDef disableClear mat-header-cell mat-sort-header scope="col">Modified At</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.lastModified|date:'dd/MM/yyyy HH:mm:ss z' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell scope="col">Actions</th>
        <td *matCellDef="let element" mat-cell>
          <div class="kf-button-group">
            <button (click)="editAutomatedExecution(element)" *ngIf="authService.hasPrivileges(['TRIGGERS_UPDATE'])" aria-label="Edit Automated Execution"
                    color="primary" mat-mini-fab
                    matTooltip="Edit Automated Execution">
              <mat-icon aria-hidden="false">edit</mat-icon>
            </button>
            <button (click)="confirmDeleteAutomatedExecution(element)" *ngIf="authService.hasPrivileges(['TRIGGERS_DELETE'])"
                    aria-label="Delete Automated Execution"
                    color="warn" mat-mini-fab
                    matTooltip="Delete Automated Execution">
              <mat-icon aria-hidden="false">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandDetails">
        <th *matHeaderCellDef disableClear mat-header-cell scope="col"></th>
        <td *matCellDef="let element" mat-cell>
          <button
            (click)="element.isExpanded = !element.isExpanded"
            aria-label="Show Details"
            mat-icon-button matTooltip="Show Details"
          >
            <mat-icon aria-hidden="false">{{element.isExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td *matCellDef="let element" [attr.colspan]="displayedColumns.length" mat-cell>
          <div [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'" class="details-table-wrapper">
            <app-trigger-action-details [triggerActions]="element.triggerActions"></app-trigger-action-details>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr
        *matRowDef="let element; columns: displayedColumns;"
        [class.details-table-expanded]="element.isExpanded"
        class="element-row element-row-expandable"
        mat-row
      ></tr>
      <tr *matRowDef="let row; columns: ['expandedDetail']" class="details-table-collapsable" mat-row></tr>
      <caption id="tableDescription">List of available scenarios</caption>
    </table>
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
  </div>
</div>
