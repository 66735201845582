<h2 class="draggable-header" style="font-size: 16px;">Define {{ form.get('selectionType')?.value | ucfirst }}</h2>
<form [formGroup]="form">
  <mat-radio-group (change)="onSelectionTypeChanged($event.value, index)" formControlName="selectionType">
    <mat-radio-button value="areas">Areas</mat-radio-button>
    <mat-radio-button value="tileIds">Tile IDs</mat-radio-button>
    <mat-radio-button value="routeIds">Route IDs</mat-radio-button>
  </mat-radio-group>

  <!-- Display Areas form section when 'areas' is selected -->
  <div *ngIf="form.get('selectionType')?.value === 'areas'">
    <mat-form-field appearance="fill" color="accent" floatLabel="always">
      <mat-label>Areas</mat-label>
      <mat-select (selectionChange)="onAreaSelectionChange($event)" formControlName="areaIds" multiple>
        <mat-option *ngFor="let area of areasList" [value]="area.areaId">{{area.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Display Tile IDs form section when 'tileIds' is selected -->
  <div *ngIf="form.get('selectionType')?.value === 'tileIds'">
    <mat-form-field appearance="fill" color="accent" floatLabel="always">
      <mat-label class="textarea-label">Enter Tile ID(s)</mat-label>
      <textarea (input)="onTileIdsInput()" formControlName="tileIds" matInput placeholder="Example: 54" rows="6" type="number"></textarea>
      <button (click)="clearTileIds()" aria-label="clear" class="kf-button-clear-textarea" color="accent" mat-icon-button matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <!-- Display Route IDs form section when 'routeIds' is selected -->
  <div *ngIf="form.get('selectionType')?.value === 'routeIds'">
    <section>
      <mat-form-field appearance="fill" color="accent" floatLabel="always">
        <mat-label class="textarea-label">Enter Route ID(s)</mat-label>
        <textarea (input)="onRouteIdsInput()" formControlName="routeIds" matInput
                  placeholder="Example: 1,2,3,4"
                  type="number"></textarea>
      </mat-form-field>
    </section>
  </div>

  <p *ngIf="hasTilesAndAreas" class="mat-error">There are tiles and areas defined. Is this intended?</p>
</form>
