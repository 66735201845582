import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ScenarioService } from '@app/modules/test-execution/service/scenario.service';
import {DetailedPerformanceProfileApiEntity, DetailedScenarioProgressApiEntity} from '@shared/model/productserver';
import { HttpApiErrorResponse, ResponseObject } from '@shared/model/response';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-scenario-progress-details',
  templateUrl: 'scenario-progress-details.component.html',
  styleUrls: ['scenario-progress-details.component.scss'],
  providers: [ScenarioService]
})
export class ScenarioProgressDetailsComponent implements OnInit, OnDestroy {
  @Input() runId: number;
  scenarioProgressDetails: DetailedScenarioProgressApiEntity;
  isLoadingResults = false;

  performanceProfile: DetailedPerformanceProfileApiEntity;


  private subscription: Subscription = new Subscription();

  constructor(public scenarioService: ScenarioService) {}

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.subscription.add(
      this.scenarioService
        .getScenarioProgress(this.runId)
        .pipe(
          map((response: ResponseObject<DetailedScenarioProgressApiEntity>) => {
            this.isLoadingResults = false;
            return response.data;
          }),
          catchError((err: HttpApiErrorResponse) => {
            console.error(err.error);
            this.isLoadingResults = false;
            return observableOf(null);
          })
        )
        .subscribe(
          // testcaseArray is the response.data which is received from the rest call
          (scenarioProgressDetails: DetailedScenarioProgressApiEntity) => {
            this.scenarioProgressDetails = scenarioProgressDetails;
          }
        )
    );
    this.subscription.add(
      this.scenarioService.getPerformanceProfileByRunId(this.runId)
        .subscribe(
          // performanceProfile is the response.data which is received from the rest call
          (performanceProfile: DetailedPerformanceProfileApiEntity) => {
            this.performanceProfile = performanceProfile;
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
