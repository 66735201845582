import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractThresholdDefinitionComponent } from '@modules/thresholds/component/shared/abstract-threshold-definition.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ThresholdFormEventService } from '@modules/thresholds/service/threshold-form-event.service';

class ThresholdValueErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-threshold-failrate-delta-entity',
  templateUrl: './threshold-failrate-delta-entity.component.html',
  styleUrls: ['../shared/shared.scss']
})
export class ThresholdFailrateDeltaEntityComponent extends AbstractThresholdDefinitionComponent {
  matcher = new ThresholdValueErrorStateMatcher();

  constructor(
    protected formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected thresholdFormEventService: ThresholdFormEventService
  ) {
    super(formBuilder, dialog, thresholdFormEventService);
    this.extendForm();
  }

  private extendForm(): void {
    this.thresholdValueForm.addControl(
      'thresholdRateGreen',
      this.formBuilder.control('')
    );
    this.thresholdValueForm.addControl(
      'thresholdRateYellow',
      this.formBuilder.control('')
    );
    this.thresholdValueForm.addControl(
      'thresholdDeltaRelPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    this.thresholdValueForm.addControl(
      'thresholdDeltaRelMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    this.thresholdValueForm.addControl(
      'thresholdDeltaAbsPlus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    this.thresholdValueForm.addControl(
      'thresholdDeltaAbsMinus',
      this.formBuilder.control('', [Validators.min(0)])
    );
    this.thresholdValueForm.addControl(
      'areaName',
      this.formBuilder.control('')
    );
  }
}
