import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntervalService {
  changeDetector: any;

  setInterval(time: number, callback: () => void): any {
    this.changeDetector = setInterval(callback, time);
  }

  clearInterval(): any {
    clearInterval(this.changeDetector);
  }
}
