import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogApiEntity } from '@shared/model/productserver';
import { ProductServerService } from '@shared/service/product-server.service';
import { Subscription } from 'rxjs';

export interface CatalogVersionRequest {
  observedCatalog: string;
  catalogVersionInterval: number;
}

@Component({
  selector: 'app-catalog-version-form',
  templateUrl: './catalog-version-form.component.html',
  styleUrls: ['./catalog-version-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogVersionFormComponent implements OnInit, OnDestroy {
  @Input() observedCatalog: string | null = null;
  @Input() catalogVersionInterval: number | null = 1;
  public form: FormGroup | null = null;
  public catalogsList: Map<string, CatalogApiEntity[]> = new Map<
    string,
    CatalogApiEntity[]
  >();
  public mapDataType: string[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private productServerService: ProductServerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get invalid(): boolean {
    return this.form ? this.form.invalid : true;
  }

  ngOnInit(): void {
    this.getCatalogs();
    this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCatalogs(): void {
    this.subscription.add(
      this.productServerService
        .getCatalogs()
        .subscribe((catalogs: CatalogApiEntity[]) => {
          this.catalogsList =
            this.productServerService.groupCatalogsByMapDataType(catalogs);
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  createFormGroup(): FormGroup {
    this.form = this.fb.group({
      observedCatalog: [
        this.observedCatalog,
        [Validators.required, Validators.min(1)]
      ],
      catalogVersionInterval: [
        this.catalogVersionInterval,
        [Validators.required, Validators.min(1)]
      ]
    });
    return this.form;
  }

  getValue(): CatalogVersionRequest {
    const { observedCatalog, catalogVersionInterval } = this.form.getRawValue();
    return { observedCatalog, catalogVersionInterval };
  }
}
