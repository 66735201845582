import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ScenarioConfirmComponent } from '@modules/test-execution/component/scenario-confirm/scenario-confirm.component';
import { ThresholdDialogueComponent } from '@modules/thresholds/component/threshold-dialogue/threshold-dialogue.component';
import { ThresholdRequestConverterService } from '@shared/model/converter/threshold-request-converter.service';
import { HttpApiErrorResponse } from '@shared/model/response';
import {
  THRESHOLD_DEFAULT_CLUSTER,
  THRESHOLD_CLUSTERS,
  ThresholdEntity,
  ThresholdIdentity,
  ThresholdRequestObject
} from '@shared/model/thresholds';
import { AuthService } from '@shared/service/auth.service';
import { DatastoreService } from '@shared/service/datastore.service';
import { NotificationService } from '@shared/service/notification.service';
import { Subscription } from 'rxjs';
import { SaveChangesDialogComponent } from '@modules/thresholds/component/save-changes-dialog/save-changes-dialog.component';
import {
  ThresholdFormEvent,
  ThresholdFormEventService,
  ThresholdValuePayload
} from '@modules/thresholds/service/threshold-form-event.service';

export declare class ThresholdEvent {
  action: string;
  threshold: ThresholdEntity;
}

@Component({
  selector: 'app-threshold-list',
  templateUrl: './threshold-list.component.html',
  styleUrls: ['./threshold-list.component.scss']
})
export class ThresholdListComponent implements OnInit, OnDestroy {
  dialogWidth = '500px';
  confirmWidth = '300px';
  thresholdClusters: string[] = THRESHOLD_CLUSTERS;
  thresholdCluster = THRESHOLD_DEFAULT_CLUSTER;
  hasChangedEntries = false;
  private $_thresholds: ThresholdEntity[];
  private $isLoadingResults = true;
  private $subscription: Subscription = new Subscription();

  constructor(
    public authService: AuthService,
    private datastoreService: DatastoreService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private thresholdRequestConverter: ThresholdRequestConverterService,
    private thresholdFormEventService: ThresholdFormEventService
  ) {}

  get thresholds(): ThresholdEntity[] {
    return this.$_thresholds;
  }

  get isLoadingResults() {
    return this.$isLoadingResults;
  }

  ngOnInit(): void {
    this.getThresholdsList();
    this.registerEventListener();
  }

  ngOnDestroy(): void {
    this.notificationService.clear();
  }

  getThresholdsList(): void {
    this.$isLoadingResults = true;
    this.$_thresholds = [];
    this.$subscription.add(
      this.datastoreService.getThresholds(this.thresholdCluster).subscribe(
        (thresholdsList) => {
          this.$_thresholds = thresholdsList;
          this.cdr.detectChanges();
        },
        (err) => this.handleError(err),
        () => {
          this.$isLoadingResults = false;
        }
      )
    );
  }

  saveThreshold(threshold: ThresholdRequestObject): void {
    const thresholdDto = this.thresholdRequestConverter.convert(threshold);
    this.datastoreService.createThreshold(thresholdDto).subscribe(
      (res) => {
        this.showResponse(res);
        this.getThresholdsList();
      },
      (err: HttpApiErrorResponse) => this.handleError(err)
    );
  }

  deleteThreshold(thresholdKey: ThresholdIdentity): void {
    this.datastoreService.deleteThreshold(thresholdKey).subscribe(
      (res) => {
        this.showResponse(res);
        this.getThresholdsList();
      },
      (err: HttpApiErrorResponse) => this.handleError(err)
    );
  }

  toggleThresholdActive(
    testCaseId: string,
    cluster: string,
    active: boolean
  ): void {
    this.datastoreService
      .toggleThresholdActive(testCaseId, cluster, active)
      .subscribe(
        (res) => {
          this.showResponse(res);
          this.getThresholdsList();
        },
        (err: HttpApiErrorResponse) => this.handleError(err)
      );
  }

  confirmDelete(
    threshold: ThresholdEntity
  ): MatDialogRef<ScenarioConfirmComponent> {
    const dialogRef = this.dialog.open(ScenarioConfirmComponent, {
      width: this.confirmWidth,
      data: {
        title: `Delete Threshold`,
        message: `Are you sure to delete the Threshold`
      }
    });

    dialogRef.afterClosed().subscribe((confirmResult) => {
      if (confirmResult) {
        this.deleteThreshold(threshold);
      }
    });
    return dialogRef;
  }

  addThreshold(): MatDialogRef<ThresholdDialogueComponent> {
    const dialogRef = this.dialog.open(ThresholdDialogueComponent, {
      width: this.dialogWidth,
      data: {
        threshold: {
          cluster: this.thresholdCluster
        },
        assignedTestCaseIds: this.getAssignedTestCaseIds()
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        this.saveThreshold(formData);
      }
    });
    return dialogRef;
  }

  openEditDialog(
    element: ThresholdEntity
  ): MatDialogRef<ThresholdDialogueComponent> {
    const dialogRef = this.dialog.open(ThresholdDialogueComponent, {
      width: this.dialogWidth,
      data: {
        threshold: element,
        assignedTestCaseIds: this.getAssignedTestCaseIds()
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((formData) => {
      if (formData) {
        this.saveThreshold(formData);
      }
    });
    return dialogRef;
  }

  handleThresholdEvent(event: ThresholdEvent): void {
    switch (event.action) {
      case 'save':
        this.saveThreshold(event.threshold);
        break;
      case 'edit':
        this.openEditDialog(event.threshold);
        break;
      case 'delete':
        this.confirmDelete(event.threshold);
        break;
      case 'toggle_active':
        const {
          threshold: { testCaseId, cluster, active }
        } = event;
        this.toggleThresholdActive(testCaseId, cluster, !active);
        break;
    }
  }

  getAssignedTestCaseIds(): string[] {
    const testCaseIds = [];
    for (const threshold of this.thresholds) {
      testCaseIds.push(threshold.testCaseId);
    }
    return [...new Set(testCaseIds)];
  }

  onSelect(clusterVersion: string) {
    const isEditing: boolean = this.IsEditMode();
    if (!isEditing) {
      this.loadNewCluster(clusterVersion);
    } else {
      this.displayWaring(clusterVersion);
    }
  }

  private handleError(error: HttpApiErrorResponse): void {
    console.error(error);
    this.showError(error);
  }

  private loadNewCluster(clusterVersion: string) {
    this.thresholdCluster = clusterVersion;
    this.getThresholdsList();
    this.hasChangedEntries = false;
  }

  private displayWaring(clusterVersion: string) {
    const dialogRef = this.dialog.open(SaveChangesDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // User clicked "Yes" - load the selected cluster
        this.loadNewCluster(clusterVersion);
      }
    });
  }

  private IsEditMode(): boolean {
    return this.hasChangedEntries;
  }

  private registerEventListener() {
    this.$subscription.add(
      this.thresholdFormEventService.on(
        ThresholdFormEvent.THRESHOLD_TRIGGER_EDIT,
        (payload: ThresholdValuePayload) => {
          if (JSON.stringify(payload.key)) {
            this.hasChangedEntries = true;
          }
        }
      )
    );
  }

  private showResponse(response: HttpResponse<string>): void {
    this.notificationService.success(response.statusText, `${response.status}`);
  }

  private showError(err: HttpApiErrorResponse): void {
    this.notificationService.error(
      `${err.error.message} (${err.error.error})`,
      `${err.error.status}`
    );
  }
}
